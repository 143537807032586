import React from 'react';
import { createPackaging, getAllPackaging, resetPackagingState } from '../../../../../store/Slices/marketMapTableSlices';
import { getAllPackagingAttributes, getOnePackagingAttributes } from '../../../../../store/Slices/marketMapAttributeSlice.js';
import TableAddCommon from '../../../MarketMapsComponents/TableAddCommon/TableAddCommon.jsx';
import { uploadPackagingImg } from '../../../../../store/Slices/marketMapImageSlice.js';

const AddPackagingTable = () => {
  
  
  return (
    <TableAddCommon
      title="Packaging"
      getAllAttributesLink={getAllPackagingAttributes}
      getOneAttributesLink={getOnePackagingAttributes}
      createTablelink={createPackaging}
      resetTableState={resetPackagingState}
      tableDataSelector={(state) => state.Packaging}
      allAttributesGroupSelector={(state) => state.AllPackagingAttributeGroup}
      oneAttributeGroupSelector={(state) => state.OnePackagingAttributesGroup}
      classNamePrefix="Packaging"
      linkForRedirectSetting = "Add-Packaging-Details-Attributes"
      additionalAttributesName = "packagingAdditionalAttributes"
      getAllDataForReferenceLink = {getAllPackaging}
      getAllDataForReference = {(state) => state.AllPackagings}
      ImageUploadLink = {uploadPackagingImg}
      getID = "packagingId"
    />
  );
  
}


export default AddPackagingTable;









// import React ,{ useEffect, useState } from 'react';
// import { createPackaging, resetPackagingState } from '../../../../../store/Slices/marketMapTableSlices';
// import { useDispatch, useSelector} from 'react-redux';
// import STATUSES from '../../../../../store/Statuses.js';
// // import Loader from '../../../../Common/Loader/Loader';
// import { toast } from "react-toastify";
// import { getAllPackagingAttributes, getOnePackagingAttributes } from '../../../../../store/Slices/marketMapAttributeSlice.js';
// import Loader from '../../../../Common/Loader/Loader.jsx';
// import img from '../../../../../assets/Images/MarketMaps/AddData.jpg'
// import { Link } from 'react-router-dom';

// const AddPackagingTable = () => {
//   const dispatch = useDispatch();
 
//   //[1 ]Get All Attributes Group 
//   useEffect(()=>{
//     dispatch(getAllPackagingAttributes({}));
//   },[dispatch]);
  
//   const {data:AttributesGroupsData ,  status:AttributeGroupStatus , error:AttributeGroupError  } = useSelector((state) => state.AllPackagingAttributeGroup);


//   //[2] Select the Single Attribute Group 
//   const [groupNameToShowAttributes ,setGroupNameToShowAttributes] = useState("");
//   useEffect(()=>{
//       if(groupNameToShowAttributes.length>0){
//           dispatch(getOnePackagingAttributes(groupNameToShowAttributes));
//       }
//   },[dispatch ,groupNameToShowAttributes]);

//   const {data: singleAttributeGroupData , status:singleAttributeGroupStatus ,error:singleAttributeGroupError} = useSelector((state) => state.OnePackagingAttributesGroup);
//   console.log(singleAttributeGroupData);

//   console.log(groupNameToShowAttributes);




//   //[3] Handel Submit The data 
//   const [InputData, setInputData] = useState({});
//   const [additionalInputData, setAdditionalInputData] = useState({});

//   const changeInputData = (e) => {
//     const { name, value, type } = e.target;
//     const newValue = type === 'number' ? parseFloat(value) : value.trim();
//     setInputData({
//       ...InputData,
//       [name]: newValue,
//     });
//   };
//   const changeAdditionalInputData = (e) => {
//     const { name, value, type } = e.target;
//     const newValue = type === 'number' ? parseFloat(value) : value.trim();
//     setAdditionalInputData({
//       ...additionalInputData,
//       [name]: newValue,
//     });
//   };

//   const handleSubmitData =(e)=>{
//     e.preventDefault();
//     const data = {...InputData ,groupAttributes: additionalInputData,groupName:groupNameToShowAttributes}
//     if(Object.keys(InputData).length === 0 && Object.keys(additionalInputData).length===0 ){
//       toast.warning("Please fill in some fields to add new packaging");
//     }else{
//       dispatch(createPackaging(data));
//       console.log(data);
//     }
//   }
//   const { data, status , error } = useSelector((state) => state.Packaging);

//   useEffect(()=>{
//     if (data && status===STATUSES.SUCCESS) {
//       toast.success("Created Successfully");
//       dispatch(resetPackagingState({}));
//     }
//     if (error){
//       toast.error(error.detail);
//       dispatch(resetPackagingState({}));
//     }
//   }, [data ,status ,error ,dispatch]);

//   // console.log(AttributesGroupsData.length)
//   useEffect(() => {
//     // Clear input data when group name changes
//     setInputData({});
//     setAdditionalInputData({});
//   }, [groupNameToShowAttributes]);


// const RenderWarning = ({msg, showLink}) => {
//   return (
//     <div className='select_warning'>
//       <img src={img} alt="imgs" />
//       <h6>{msg}</h6>
//       {showLink?<Link to={"/market-map/market-Map-Settings/Add-Packaging-Details-Attributes"}>Create Attributes group</Link>:null}
//     </div>
//   )};

 
//   return (
//     <>
    
//     <div className='common_table_page add_table_page'>

//       <div className="select_att_group_button_Container">
//         <h6>Add Packaging</h6>
//         <select className='select_step_data' type="text" required value={groupNameToShowAttributes} onChange={(e)=>setGroupNameToShowAttributes(e.target.value)} >
//               <option value="" className='select_step_value'>Select Group</option>
//               {
//                 AttributeGroupStatus === STATUSES.LOADING ? (
//                   <option className='error_option' >Loading...</option>
//                 ) : 
//                 AttributeGroupStatus === STATUSES.ERROR ? (
//                   <option className='error_option' >Something went wrong {AttributeGroupError}</option>
//                 ) :(
//                   Object.keys(AttributesGroupsData).length>0 && AttributesGroupsData.map((item ) =>
//                       <option value={item.groupName} className='select_step_value' key={item.groupId}>{item.groupName}</option>
//                     )
//                 )
//               }
//         </select>
//       </div>

   
//       <> 
//         <div className="add_table_data_container">
//           {
//             AttributeGroupStatus === STATUSES.LOADING ? (
//               <Loader/>
//             ) :(
//               Object.keys(AttributesGroupsData).length===0 ?(
//                 <RenderWarning msg={"Please Create a Group of Attributes to add packaging"} showLink={true} />
//               ):(              
//                   groupNameToShowAttributes.length===0?(
//                     <RenderWarning msg={"Please Select the Group of Attributes to add packaging"} showLink={false} />
//                   ):(
//                     <>
//                   {

//                     singleAttributeGroupStatus === STATUSES.LOADING?(
//                       <Loader/>
//                     ):singleAttributeGroupStatus===STATUSES.ERROR? (
//                       <h6>Something went wrong {singleAttributeGroupError.message}</h6>
//                     ):singleAttributeGroupStatus===STATUSES.SUCCESS?(
//                         (Object.keys(singleAttributeGroupData.groupAttributes).length===0 && Object.keys(singleAttributeGroupData.packagingAdditionalAttributes).length ===0)?(
//                         <>
//                           <h6 className='att_header'>Attributes Group - <span>{singleAttributeGroupData?.groupName}</span></h6>
//                           <RenderWarning msg={"No attributes found to add packaging"} showLink={false} />
//                         </>
//                         ):(
                          
//                         <>
//                           <h6 className='att_header'>Attributes Group - <span>{singleAttributeGroupData?.groupName}</span></h6>
//                           <div className="all_attributes_to_add_data">
//                               {
//                                 Object.keys(singleAttributeGroupData.groupAttributes).map((i ,index)=>(
//                                   <div className='_input_box' key={index} >
//                                     <input className='table_input' type={singleAttributeGroupData.groupAttributes[i].type==="String"?"text":singleAttributeGroupData.groupAttributes[i].type==="Number"?"number":null} required name={i} onChange={changeInputData}/>
//                                     <span>{singleAttributeGroupData.groupAttributes[i].nametoDisplay}</span>
//                                   </div>
//                                 ))
//                               }
//                               {
//                                 Object.keys(singleAttributeGroupData.packagingAdditionalAttributes).map((i ,index)=>(
//                                   <div className='_input_box' key={index} >
//                                     <input className='table_input' type={singleAttributeGroupData.packagingAdditionalAttributes[i].type==="String"?"text":singleAttributeGroupData.packagingAdditionalAttributes[i].type==="Number"?"number":null} required name={i} onChange={changeAdditionalInputData}/>
//                                     <span>{singleAttributeGroupData.packagingAdditionalAttributes[i].nametoDisplay}</span>
//                                   </div>
//                                 ))
//                               }
//                           </div>
//                           <div className="submit_table_data_btn">
//                             <button onClick={handleSubmitData} className ={'common_btn'} style={{width: 300}}>Save</button>
//                           </div>
//                         </>                          
//                         )
//                       ):null                    
//                   }
//                     </>
//                   )
//               )
//             )
//           }
//         </div>
//       </>
//     </div>

    
//     </>
//   )
// }


// export default AddPackagingTable;
// export { default as Integrations } from "./Integrations";
// export { default as  Products } from "./Products";
// export { default as Functions } from "./Functions";
// export { default as RequestDemo } from "./RequestDemo"
// export { default as ZigZagSection } from "./ZigZagSection";



import React from "react";
import Products from './Products';
import ZigZagSection from './ZigZagSection';
import Integrations from './Integrations';
import WhyUs from './WhyUs';
import Functions from './Functions';
import RequestDemo from './RequestDemo'

const HomeComponents = ()=> {
  return (
    <>
      <Products />
      <ZigZagSection/>
      <Integrations />
      <WhyUs />
      {/* <Functions /> */}
      <RequestDemo />
    </>
  );
}

export default HomeComponents;



import React from "react";
import './FirstPage.css';
import logo from '../../../assets/logo/OnifiedFinalLogo.png';
import Apps from "../../../assets/Images/Apps.png";
import arrows from "../../../assets/Images/arrows.png";
import auth from "../../../assets/Images/auth.png";
import bitcoin from "../../../assets/Images/bitcoin.png";
import cards from "../../../assets/Images/cards.png";
import chats from "../../../assets/Images/chats.png";
import graphs from "../../../assets/Images/graphs.png";
import img from "../../../assets/Images/img.png";
import pdf from "../../../assets/Images/pdf.png";
import settings from "../../../assets/Images/settings.png";
import stats from "../../../assets/Images/stats.png";
import leaderboard from "../../../assets/Images/leaderboard.png";
import { Link } from "react-router-dom";


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear, faMoon, faUser } from "@fortawesome/free-solid-svg-icons";


const FirstPage = () => {

    const CardObject = [
        {
          icon: img,
          title: "Market Maps",
          url: "/market-map",
        },
        {
          icon: cards,
          title: "Customer Relationship Management",
          url: "/ekyc",
        },
        {
          icon: arrows,
          title: "Order Management System",
          url: "/ekyc",
        },
        {
          icon: chats,
          title: "Whatsapp Chatbot",
          url: "/ekyc",
        },
        {
          icon: graphs,
          title: "BI & Analytics",
          url: "/ekyc",
        },
        {
          icon: leaderboard,
          title: "Performance Management System",
          url: "/pms",
        },
        {
          icon: auth,
          title: "E-KYC",
          url: "/ekyc",
        },
        {
          icon: settings,
          title: "Shopfloor Management",
          url: "/ekyc",
        },
        {
          icon: stats,
          title: "Ticketing System",
          url: "/ekyc",
        },
        {
          icon: bitcoin,
          title: "Traceability",
          url: "/ekyc",
        },
        {
          icon: Apps,
          title: "Asset Management",
          url: "/ekyc",
        },
        {
          icon: pdf,
          title: "Contract Lifecycle Management",
          url: "/ekyc",
        },
    ];

  return (
    <>
        <Nav/>
        <div className="first_page">
            <div className="first_page_container">
              {
                CardObject.map((item ,index) =>(
                  <Card item={item} index = {index} key={index}/>
                ))
              }
            </div>


        </div>
    </>
  )
}

export default FirstPage;

const Card = ({item, index})=>{
  return (
    <div className="first_page_card" key={index}>
      <div className="col">
        <img src={item.icon} alt="img_alt"/>
      </div>
      <div className="col">
        <p>{item.title}</p>
        <Link className="link_btn" to={item.url} target="_blank">View</Link>
      </div>
    </div>
  )
}

const Nav = ()=>{
    return (
      <>
        <div className="market_nav" style={{ zIndex : 2, }}>
          <div className="col1">
            <Link >
              <img src={logo} alt="onified_logo_Img" style={{ width:150}} />
            </Link>
          </div>
          <div className="col1">
            <li>
              <FontAwesomeIcon icon={faMoon} className="market_icon" />
            </li>
            <li>
              <FontAwesomeIcon icon={faGear} className="market_icon" />
            </li>
 
            <Link to={"/pms/myProfile"}>
              <li className="user_icon">
                <FontAwesomeIcon icon={faUser} className="market_icon" />
              </li>
            </Link>
          </div>
        </div>
      </>
    );
}
import React, { useEffect } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import Logo from "../../../assets/logo/OnifiedFinalLogo.png";
import { Link, useNavigate } from "react-router-dom";
import styles from "./Header.module.css";
import { useSelector } from "react-redux";
import STATUSES from "../../../store/Statuses";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import {
  clearAuth,
  resetUserLogout,
  userLogout,
} from "../../../store/Slices/userAuthSlice";

function Header() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { token, userDetails } = useSelector((state) => state.auth);

  const { data, status, error } = useSelector((state) => state.UserLogout);

  const handleLogout = () => {
    dispatch(userLogout());
  };
  useEffect(() => {
    if (status === STATUSES.SUCCESS) {
      Cookies.remove("onifiedPKtoken");
      localStorage.removeItem("onifiedPKData");
      dispatch(clearAuth());
      dispatch(resetUserLogout({}));
    }
    if (status == STATUSES.ERROR) {
      toast.error("Something went wrong");
    }
  }, [status]);

  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        bg="white"
        variant="light"
        className={styles.headerNav}
        sticky="top"
      >
        <Container className={styles.headerTop}>
          <Navbar.Brand>
            <Link to={"/"}>
              <img src={Logo} width="150" className={styles.logo} alt="logo" />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto align-items-center">
              {/* <Link to="/features" className={`${styles.headerNavs} nav-link`}>Features</Link>
            <Link to="/" className={`${styles.headerNavs} nav-link`}>Products</Link>
            <Link to='/' className={`${styles.headerNavs} nav-link`}>Functions</Link>
            <Link to='/' className={`${styles.headerNavs} nav-link`}>Integrations</Link>
            <Link to='/' className={`${styles.headerNavs} nav-link`}>Support</Link> */}

              {!token && !userDetails ? (
                <>
                  <Link to="/login">
                    <button className={`${styles.header_btn} common_btn2`}>
                      Login
                    </button>
                  </Link>
                  <Link to="/signup">
                    <button className={`${styles.header_btn} common_btn2`}>
                      signup
                    </button>
                  </Link>
                </>
              ) : (
                <Link onClick={handleLogout}>
                  <button className={`${styles.header_btn} common_btn3`}>
                    Log out
                  </button>
                </Link>
              )}

              <Link to="/request" style={{ textDecoration: "none" }}>
                <button
                  className={`${styles.header_btn} common_btn`}
                  style={{ width: 180 }}
                >
                  Request a Demo
                </button>
              </Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default Header;

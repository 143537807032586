import React from 'react';
import './UserProfile.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faPencil, faUser } from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom';


const UserProfile = () => {
  return (
    <>
      <div className='profile_box'>
        <i className='user_icon_box'><FontAwesomeIcon icon={faUser} className="user_icon" /></i>
        <p>Pankaj Kumar</p>
        <p>pankajkumar@org.com</p>
      </div>
      <div className='profile_container'>
        <div className="profile_heading_box">
          <h5>My Profile</h5>
          <div>
            <Link className='profile_setting' to={'userPersonalSetting'}><i><FontAwesomeIcon icon={faCog} /></i>Personal Setting</Link>
            <Link className='profile_setting' to={'editProfile'}><i><FontAwesomeIcon icon={faPencil} /></i>Edit</Link>
          </div>
        </div>

        <div className="user_info_header">
          <div className='user_info_heading'>Basic Information</div>
          <div className='user_info_heading'>Personal Information</div>
          <div className='user_info_heading'>Work Information</div>
        </div>

        <div className="user_info_box">
          <div className="user_info_col">
            <div className="user_detail">Employe ID <span>CS392346322331GF</span></div>
            <div className="user_detail">Email Address <span>pankajkumar@gmail.com</span></div>
          </div>
          <div className="user_info_col">
            <div className="user_detail">First Name <span>Pankaj</span></div>
            <div className="user_detail">Last Name <span>Kumar</span></div>
          </div>
          <div className="user_info_col">
            <div className="user_detail">Nick Name<span>PK</span></div>
            <div className="user_detail">Other ID <span>WEW5634268742864</span></div>
          </div>
        </div>


        <div className="user_info_box">
          <div className="user_info_col">
            <div className="user_detail">Date of birth<span>05/01/2002</span></div>
            <div className="user_detail">Gender<span>Male</span></div>
          </div>
          <div className="user_info_col">
            <div className="user_detail">Marital Status<span>Unmarried</span></div>
            <div className="user_detail">Present Address<span>Gurugram</span></div>
          </div>
          <div className="user_info_col">
            <div className="user_detail">Permanent Address<span>Delhi, India</span></div>
            <div className="user_detail">Personal Mobile Number<span>1234567890</span></div>
          </div>
          <div className="user_info_col">
            <div className="user_detail">Personal Email Address<span>pankajkumar@gmail.com</span></div>
            <div className="user_detail">UAN<span>698716871654171</span></div>
          </div>
          <div className="user_info_col">
            <div className="user_detail">PAN<span>LIAPK284136</span></div>
            <div className="user_detail">Aadhaar<span>66981768654171</span></div>
          </div>
        </div>


        <div className="user_info_box">
          <div className="user_info_col">
            <div className="user_detail">Department<span>Sales</span></div>
            <div className="user_detail">Designation<span>Sales person</span></div>
          </div>
          <div className="user_info_col">
            <div className="user_detail">Work Location<span>Delhi</span></div>
            <div className="user_detail">Role<span>Organization admin</span></div>
          </div>
          <div className="user_info_col">
            <div className="user_detail">Employment Type<span>Full Time</span></div>
            <div className="user_detail">Employee Status<span>Active</span></div>
          </div>
          <div className="user_info_col">
            <div className="user_detail">Date of Joining<span>24/02/2020</span></div>
            <div className="user_detail">Current Experience<span>7 Years</span></div>
          </div>
          <div className="user_info_col">
            <div className="user_detail">Total Experience<span>9 Years</span></div>
            <div className="user_detail">Organization email<span>OrganizationPankaj.com</span></div>
          </div>
          <div className="user_info_col">
            <div className="user_detail">CTC<span>26 LPA</span></div>
            <div className="user_detail">Hierarchy Information<span>HOD</span></div>
          </div>
          <div className="user_info_col">
            <div className="user_detail">Skill Set<span>MS, Sales</span></div>
            <div className="user_detail">Source of Hire<span>Linkedin</span></div>
          </div>
          <div className="user_info_col">
            <div className="user_detail">Total time with us<span>2.3 Years</span></div>
            <div className="user_detail">Other Info<span>value</span></div>
          </div>
        </div>
      </div>
    </>
  )
}

export default UserProfile;
import { Link, NavLink } from "react-router-dom";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import './Breadcrumbs.css';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons";

const userNamesById = { 1: "John" };

const DynamicUserBreadcrumb = ({ match }) => (
  <span>{userNamesById[match.params.userId]}</span>
);

const CustomPropsBreadcrumb = ({ someProp }) => <span>{someProp}</span>;

// define custom breadcrumbs for certain routes.
// breadcrumbs can be components or strings.
const routes = [
  { path: "/users/:userId", breadcrumb: DynamicUserBreadcrumb },
  { path: "/example", breadcrumb: "Custom Example" },
  {
    path: "/custom-props",
    breadcrumb: CustomPropsBreadcrumb,
    props: { someProp: "Hi" },
  },
];

// map & render your breadcrumb components however you want.
const Breadcrumbs = () => {
  const breadcrumbs = useBreadcrumbs(routes);

  return (
    <div className="breadcrumbs_container">
      <Link to={-1} className='back_btn'><FontAwesomeIcon icon={faCaretLeft} /></Link>

      {breadcrumbs.map(({ match, breadcrumb }, index) => (
        <NavLink key={match.pathname} to={match.pathname} className={"breadcrumbs_link"}>
          {breadcrumb} 
          {/* <span className="breadcrumbs_space">/</span> */}

          {index < breadcrumbs.length - 1 && (
            <span className="breadcrumbs_space">/</span>
            )}

        </NavLink>
      ))}
      <Link to={+1} className='back_btn'><FontAwesomeIcon icon={faCaretRight} /></Link>
    </div>
  );
};
export default Breadcrumbs;
import React from "react";
// import ScrollAnimation from "react-animate-on-scroll";
import { Col, Container, Row } from "react-bootstrap";
// import immediate from "../../../assets/Images/immediate.png";
import immediate from "../../../assets/Images/immediate.svg";
// import integrated from "../../../assets/Images/integrated.png";
import integrated from "../../../assets/Images/integrated.svg";
// import Intelligent from "../../../assets/Images/Intelligent.png";
import Intelligent from "../../../assets/Images/intelligent.svg";
import styles from "./ZigZagSection.module.css";

function ZigZagSection() {
  return (
    <div className={styles.zigzagSection}>
      <Container className="mb-5 ">
        <Row className={styles.productsParent}>
          <h2 className={styles.sechead2}>Intelligent Applications</h2>
          <Col
            sm={12}
            md={4}
            padding="50px"
            className={`i-center mb-4 mb-md-0 flex-column`}
          >
            {/* <ScrollAnimation
              animateIn="zoomIn"
              animatePreScroll={true}
              initiallyVisible={true}
            > */}
            <div className={styles.cardbox}>
              <img
                style={{ alignSelf: "center" }}
                src={integrated}
                alt={"hero-1"}
                className={styles.heroImgSm}
              />
              <h2 className={styles.cardhead}>Integrated</h2>
              <p className={`sub-body`}>
                Our processes are interconnected so that we can deliver superior
                value to our customers, but very often our systems are not. With
                Onified.ai when you change any input in one part of the
                business, the impact is at multiples places.
              </p>
            </div>
            {/* </ScrollAnimation> */}
          </Col>
          <Col
            sm={12}
            md={4}
            padding="50px"
            className={`i-center mb-4 mb-md-0 flex-column`}
          >
            {/* <ScrollAnimation
              animateIn="zoomIn"
              animatePreScroll={true}
              initiallyVisible={true}
            > */}
            <div className={styles.cardbox}>
              <img
                style={{ alignSelf: "center" }}
                src={Intelligent}
                alt={"hero-1"}
                className={styles.heroImgSm}
              />
              <h2 className={styles.cardhead}>Intelligent</h2>
              <p className={`sub-body`}>
                We are flooded with data all around us, but the insights are
                missing. Our intelligent suite helps in filtering signal from
                noise. Dashboards and Analytics specifically tells you exactly
                where to focus so that you can have maximum impact on your
                business. It is an easy way to take tough business decisions
              </p>
            </div>
            {/* </ScrollAnimation> */}
          </Col>
          <Col
            sm={12}
            md={4}
            padding="50px"
            className={`i-center mb-4 mb-md-0 flex-column`}
          >
            {/* <ScrollAnimation
              animateIn="zoomIn"
              animatePreScroll={true}
              initiallyVisible={true}
            > */}
            <div className={styles.cardbox}>
              <img
                style={{ alignSelf: "center" }}
                src={immediate}
                alt={"hero-1"}
                className={styles.heroImgSm}
              />
              <h2 className={styles.cardhead}>Immediate</h2>
              <p className={`sub-body`}>
                We want to be in control of our business processes in real time.
                If they are running harmoniously, good, we want to be informed.
                If they are not, we want to be alerted. Onified.ai is available
                to you on web, mobile and tablet, so you are in control of your
                business 24x7, at your convenience
              </p>
            </div>
            {/* </ScrollAnimation> */}
          </Col>
          <Col sm={12} md={4} className={`text-v-center`}>
            <div>
              {/* <ScrollAnimation
                animateIn="fadeInUp"
                animatePreScroll={true}
                initiallyVisible={true}
              > */}

              {/* </ScrollAnimation> */}
              {/* <ScrollAnimation
                animateIn="fadeInUp"
                animatePreScroll={true}
                initiallyVisible={true}
              > */}

              {/* </ScrollAnimation> */}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ZigZagSection;

import React from 'react';
import './MarketMapFeatures.css'

const MarketMapFeatures = () => {
  return (
    <div className='hhh'>
        <h3>Market map Features</h3>
        <div>All the Featuressdffff</div>
    </div>
  )
}

export default MarketMapFeatures
import React from 'react';
import './UserPage.css';
import { Outlet } from 'react-router';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear, faKey, faLock, faMoon, faUser } from "@fortawesome/free-solid-svg-icons";
import SideBar from '../../components/Common/SideBar/SideBar';
import { useSelector } from 'react-redux';


const SidebarData = [
  {
    title: "Profile",
    path: "",
    title_icon : <FontAwesomeIcon icon={faUser} />,
    subNav: [
      {
        title: "Basic Info",
        path: "",
      },
      {
        title: "Personal Info",
        path: "",
      },
      {
        title: "Work Info",
        path: "",
      },
      
    ],
  },


  {
    title: "Personal Setting",
    path: "",
    title_icon : <FontAwesomeIcon icon={faGear} />,
  },
  {
    title: "Password",
    path: "",
    title_icon : <FontAwesomeIcon icon={faKey} />,
  },
  {
    title: "Privacy",
    path: "",
    title_icon : <FontAwesomeIcon icon={faLock} />,
  },
];

const UserPage = () => {

  // Sidebar toggle 
  const isSideBarToggled = useSelector((state) => state.sideBarToggle);

  return (
    <div className='user_page'>
        <Nav/>
        <SideBar SidebarData={SidebarData}/>
        <div className={isSideBarToggled ? "user_page_container open" : "user_page_container"}>
          <Outlet/>
        </div>
    </div>
  )
}

export default UserPage;

const Nav = ()=>{
  return (
    <>
      <div className="market_nav" style={{ zIndex: 2, }}>
        <div className="col1">
          <li>
            Company Logo
          </li>
        </div>
        <div className="col1">
          <li>
            <FontAwesomeIcon icon={faMoon} className="market_icon" />
          </li>
          
          <Link to={"/pms/myProfile"}>
            <li className="user_icon">
              <FontAwesomeIcon icon={faUser} className="market_icon" />
            </li>
          </Link>
        </div>
      </div>
    </>
  );
}
import React, { useState , useEffect} from "react";
import {
    Radar,
    RadarChart,
    PolarGrid,
    PolarAngleAxis,
    PolarRadiusAxis,
    Legend,
    Tooltip,
    LabelList
  } from "recharts";
  import DataSelection, { DataSelectionButton } from '../../../MarketMapsComponents/DataSelection/DataSelection';
import { useSelector } from "react-redux";
import { NoDataINGraph } from "../../../MarketMapsComponents/NoDataInAttributes/NoDataInAttributes";

const Radarchart = () => {
  // const data = [
  //   {
  //     name: "Retailer 1",
  //     "Program Name": "Silver Range",
  //     "Program Size in USD million": 1.2,
  //   },
  //   {
  //     name: "Retailer 2",
  //     "Program Name": "Silver Range",
  //     "Program Size in USD million": 5,
  //   },
  //   {
  //     name: "Retailer 3",
  //     "Program Name": "Beach Towels",
  //     "Program Size in USD million": 3.2,
  //   },
  //   {
  //     name: "Retailer 4",
  //     "Program Name": "Malung",
  //     "Program Size in USD million": 6,
  //   },
  //   {
  //     name: "Retailer 5",
  //     "Program Name": "Beach Towels",
  //     "Program Size in USD million": 1.9,
  //   },
  //   {
  //     name: "Retailer 6",
  //     "Program Name": ["Malung", "Beach Towels"],
  //     "Program Size in USD million": 4.2,
  //   },
  //   {
  //     name: "Retailer 7",
  //     "Program Name": "Malung",
  //     "Program Size in USD million": 6,
  //   },
  //   {
  //     name: "Retailer 8",
  //     "Program Name": "Beach Towels",
  //     "Program Size in USD million": 1.9,
  //   },
  //   {
    //     name: "Retailer 9",
    //     "Program Name": ["Malung", "Beach Towels"],
    //     "Program Size in USD million": 4.2,
    //   },
    // ];
    
    
    const [toggle, setToggle] = useState(false);
    // const dataToShowOnX = useSelector((state) => state.shortedXaxisData);
    // const dataToShowOnY = useSelector((state) => state.shortedYaxisData);
  const [dataToShowOnX , setDataToShowOnX] = useState([]);
  const [dataToShowOnY , setDataToShowOnY] = useState([]);
  const [dataToShowOnZ , setDataToShowOnZ] = useState([]);
  
  const DefaultDataForChart = { 
    defaultSelectTable : "Program" , 
    defaultGroupNameToShowAttributes :"Home Textile" , 
    defaultSelectedGroupIndex : 1 , 
    defaultProductGroupNameForProgram : "Sheeting" , 
    defaultXaxisProgramInRetailer : {"key": "Retailer name", "nametoDisplay": "Retailer name", "source":"group", "DisplayValue": "text",type : "String"} , 
    defaultYaxisProgramInProgram : { "key": "Yearly Volume of the program", "nametoDisplay": "Yearly Volume of the program in USD Mn (FOB)", "source": "group",DisplayValue :"sum"} ,
    defaultXaxisProgramInSupplier : null ,
    defaultXaxisProgramInManufacturer:null
  }

   
  const [barsDataValues, setBarsDataValues] = useState([]);

  // Utility functions for aggregation
  const calculateSum = (values) => values.reduce((acc, val) => acc + val, 0);
  const calculateMax = (values) => Math.max(...values);
  const calculateMin = (values) => Math.min(...values);
  const calculateAvg = (values) => calculateSum(values) / values.length;
  const calculateCount = (values) => values.length;

  // Aggregation logic based on DisplayValue
  const aggregateData = (data, barsData, xAxisLabel) => {
    const aggregated = data.reduce((acc, item) => {
      const xAxisValue = item[xAxisLabel] ? item[xAxisLabel][0] : "Unknown";

      if (!acc[xAxisValue]) {
        acc[xAxisValue] = { [xAxisLabel]: xAxisValue };
      }

      Object.keys(item).forEach(key => {
        if (key !== xAxisLabel) {
          if (!acc[xAxisValue][key]) {
            acc[xAxisValue][key] = [];
          }

          if (Array.isArray(item[key]) && typeof item[key][0] === 'string') {
            item[key].forEach(value => {
              if (!acc[xAxisValue][key].includes(value)) {
                acc[xAxisValue][key].push(value);
              }
            });
          } else if (typeof item[key] === 'number') {
            acc[xAxisValue][key].push(item[key]);
          }
        }
      });

      return acc;
    }, {});

    // Apply the DisplayValue logic
    return Object.keys(aggregated).map(key => {
      const entry = aggregated[key];
      Object.keys(entry).forEach(subKey => {
        if (subKey !== xAxisLabel) {
          const displayValue = barsData.find(b => b.nametoDisplay === subKey)?.DisplayValue || 'sum';
          const values = entry[subKey];
          switch (displayValue) {
            case 'max':
              entry[subKey] = calculateMax(values);
              break;
            case 'min':
              entry[subKey] = calculateMin(values);
              break;
            case 'avg':
              entry[subKey] = calculateAvg(values);
              break;
            case 'count':
              entry[subKey] = calculateCount(values);
              break;
            case 'sum':
            default:
              entry[subKey] = calculateSum(values);
          }
        }
      });
      return entry;
    });
  };

  const [formattedData, setFormattedData] = useState([]);
  const [xAxisLabel, setXAxisLabel] = useState(""); // Add xAxisLabel state

  useEffect(() => {
    const combinedData = [];
    const minLength = Math.min(dataToShowOnX.length, dataToShowOnY.length);

    for (let i = 0; i < minLength; i++) {
      if (dataToShowOnX[i] && dataToShowOnY[i]) {
        combinedData.push({
          ...dataToShowOnX[i],
          ...dataToShowOnY[i]
        });
      }
    }

    const stringField = combinedData.reduce((acc, curr) => {
      if (!acc) {
        const field = Object.keys(curr).find(key => Array.isArray(curr[key]) && typeof curr[key][0] === "string");
        if (field) {
          acc = field;
        }
      }
      return acc;
    }, null);

    const label = stringField || "Default XAxis Label";
    setXAxisLabel(label);

    const aggregated = aggregateData(combinedData, barsDataValues, label);
    setFormattedData(aggregated);

  }, [dataToShowOnX, dataToShowOnY, barsDataValues]);

  const allKeys = formattedData.length > 0 ? Object.keys(formattedData[0]) : [];
  const keysWithSeparator = allKeys.reduce((acc, key, index) => {
    if (index === 0) {
      return key;
    } else {
      return `${acc}${index === 1 ? ' vs ' : ' , '}${key}`;
    }
  }, '');

  const yAxisKeys = dataToShowOnY.length > 0 ? Object.keys(dataToShowOnY[0]) : [];
  const xAxisKeysToShow = allKeys.filter(key => !yAxisKeys.includes(key));

  const colors = ["#ff6384", "#36a2eb", "#ff9f40", "#4bc0c0", "#9966ff", "#ffcc00", "#3399ff", "#ff6666", "#99ff99", "#669999"];

  const isEmptyArray = (array) => {
    return array.every(item => Object.keys(item).length === 0);
  };



  return (
    <>
      <DataSelectionButton toggle={toggle} setToggle={setToggle} />
      <DataSelection toggle={toggle} setToggle={setToggle} defaultData={DefaultDataForChart} setDataToShowOnX={setDataToShowOnX} setDataToShowOnY={setDataToShowOnY} setDataToShowOnZ={setDataToShowOnZ} setBarsDataValues= {setBarsDataValues}/>   
      
    <div className= {toggle ? "chart_page open" : "chart_page"}>

      {
        isEmptyArray(formattedData) ? (
          <NoDataINGraph/>
        ) : (
          <>
          <h6 className="chart_heading">{keysWithSeparator}</h6>
          <div className="chart_container">
            
            <RadarChart 
              cx={370}
              cy={300}
              outerRadius={250}
              width={740}
              height={600}
              data={formattedData}
            >
              <PolarGrid />
              <PolarRadiusAxis stroke="#000000" tick={{ fontSize: 10 }}/>
              <Tooltip/>


                <PolarAngleAxis dataKey={xAxisLabel} tickLine={false} stroke="#000000"  tick={{ fontSize: 10 }} />
                {/* Render bars dynamically */}
                {formattedData.length > 0 && Object.keys(formattedData[0]).map((key, index) => (
                   key !== xAxisLabel && (

                     <Radar key={index} dataKey={key} fill={colors[index % colors.length]} fillOpacity={0.6} >
                      {/* <LabelList dataKey={key} position="top" /> */}
                      </Radar>
                   )
                ))}
              
              {/* <Legend /> */}
            </RadarChart>

            {/* Custom positioning of Xaxis keys */}
            <div className="x-axis-legend">
              {xAxisKeysToShow.length>0 && xAxisKeysToShow.map((key, index) => (
                <span key={index} >{key} {index < xAxisKeysToShow.length - 1 && ','} </span>
              ))}
            </div>

            {/* Y-axis Key Legend Left of the Chart */}
            <div className="y-axis-legend" >
              {yAxisKeys.length>0 && yAxisKeys.map((key, index) => (
                <span key={index} >{key} {index < yAxisKeys.length - 1 && ','}</span>
              ))}
            </div>
            
          </div>
          </>
        )
      }
    </div>
    </>
  );
};

export default Radarchart;

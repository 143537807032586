import React ,{ useEffect, useState} from 'react';
import './AllStep.css';
import { getAllRetailer } from '../../../../store/Slices/marketMapTableSlices.js';
import { useDispatch, useSelector} from 'react-redux';
import STATUSES from '../../../../store/Statuses.js';
import { toast } from 'react-toastify';
import { getAllRetailerAttributes } from '../../../../store/Slices/marketMapAttributeSlice.js';
import { addRetailerInProgram, deleteRetailerInProgram, getOneProgram, resetaddRetailerInProgramState, resetdeleteRetailerInProgramState } from '../../../../store/Slices/marketMapProgram.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';


const RetailerStep = ({onComplete ,programId ,programClassName, productGroupName}) => {
  const dispatch = useDispatch();
  console.log("productGroupName@@ ", productGroupName);
  console.log("programId@@ ", programId);
  console.log("programClassName@@ ", programClassName);

  useEffect(()=>{
      dispatch(getAllRetailerAttributes({}));
  },[dispatch]);

  const { data:AttributesGroupsData ,  status:AttributeGroupStatus , error:AttributeGroupError  } = useSelector((state) => state.AllRetailerAttributeGroup);

  const [groupNameToShowAttributes, setGroupNameToShowAttributes] = useState("");
  const [selectedGroupIndex, setSelectedGroupIndex] = useState(0);
  const handleGroupSelection = (e, groupName, selectedIndex) => {
    setGroupNameToShowAttributes(groupName);
    setSelectedGroupIndex(selectedIndex);
  };

  
  useEffect(()=>{
    if(groupNameToShowAttributes.length>0 ){
      const classNameToFetch = selectedGroupIndex === 1 ? `Retailer` : `Retailer${selectedGroupIndex}`;
      dispatch(getAllRetailer(classNameToFetch));
    }
  },[selectedGroupIndex, getAllRetailer,groupNameToShowAttributes]);
  
  const {data , status} = useSelector((state) => state.AllRetailers);
  // console.log(data);

  const [selectedItemId, setSelectedItemId] = useState("");

  useEffect(()=>{
    if(groupNameToShowAttributes.length===0){
      setSelectedItemId("");
    }
  },[groupNameToShowAttributes])


  useEffect(()=>{
      if(programId.length>0){
        if(programClassName.length>0){
          dispatch(getOneProgram({ className :programClassName , programId : programId}));
        }
      }
    
  },[programClassName, programId,]);

  const {data: OneTableItemData , status:OneTableItemStatus ,error:OneTableItemError} = useSelector((state) => state.OneProgram);

  // change icon color of step
  useEffect(() => {
    if (OneTableItemStatus === STATUSES.SUCCESS && OneTableItemData.length > 0) {
      onComplete(2, OneTableItemData[0]?.retailers.length > 0);
    }
  }, [OneTableItemStatus, OneTableItemData, onComplete]);


  console.log(OneTableItemData);
  const saveData = () => {
    if (!programId) {
      toast.error("Program not found. Please create a program first.");
      return;
    }
    if (!selectedItemId) {
      toast.error("Please select a Retailer.");
      return;
    }
    const retailerClassName = selectedGroupIndex === 1 ? `Retailer` : `Retailer${selectedGroupIndex}`;
    // Dispatch action to save Retailer in the program
    dispatch(addRetailerInProgram({ programId, retailerId :selectedItemId , retailerClassName ,programClassName}));
    setSelectedItemId('');
  }


  const {data: AddedData  ,status : AddedStatus, error :AddedError } = useSelector((state)=> state.RetailerInProgram);

  useEffect(() => {
    if (AddedData && AddedStatus === STATUSES.SUCCESS) {
      toast.success(AddedData);
      dispatch(resetaddRetailerInProgramState({}));
      // Fetch the updated list of retailers after saving
      dispatch(getOneProgram({ className: programClassName, programId }));
    }
    if (AddedStatus === STATUSES.ERROR) {
      toast.error(AddedError ? AddedError :"Something went Wrong");
      dispatch(resetaddRetailerInProgramState({}));
    }
  }, [AddedData, AddedStatus, AddedError,  dispatch, programClassName, programId]);

  const deleteData = (id)=>{
    dispatch(deleteRetailerInProgram({retailerId : id , programId , programClassName}))
  }

  const {data: DeletedData , status : DeletedStatus, error :DeletedError } = useSelector((state)=> state.DeleteRetailerInProgram);

  useEffect(() => {
    if (DeletedData && DeletedStatus === STATUSES.SUCCESS) {
      toast.success(DeletedData);
      dispatch(resetdeleteRetailerInProgramState({}));
      // Fetch the updated list of retailers after deletion
      dispatch(getOneProgram({ className: programClassName, programId }));
    }
    if (DeletedStatus === STATUSES.ERROR) {
      toast.error(DeletedError ? DeletedError :"Something went Wrong");
      dispatch(resetdeleteRetailerInProgramState({}));
    }
  }, [DeletedData, DeletedStatus, DeletedError,  dispatch, programClassName, programId]);


   console.log(selectedItemId);
   console.log(groupNameToShowAttributes)
 
return (
 
      <> 
        <div className="step_input_container">
          <h5 className='steps_heading'>Select from existing Retailers</h5>

          
          <select
            className='select_step_data'
            onChange={(e) => handleGroupSelection(e, e.target.value, e.target.selectedIndex)}
            value={groupNameToShowAttributes}
            required
          >
            <option value="">Select Group</option>
            {AttributeGroupStatus === STATUSES.LOADING ? (
              <option className='error_option' value="">Loading...</option>
            ) : AttributeGroupStatus === STATUSES.ERROR ? (
              <option className='error_option' value="">{AttributeGroupError}</option>
            ) : AttributeGroupStatus === STATUSES.SUCCESS ? (
              AttributesGroupsData.length === 0 ? (
                <option className='error_option' value="">No Groups found</option>
              ) : (
                AttributesGroupsData.map((item, index) => (
                  <option className='select_step_value' value={item.groupName} key={item.groupId}>
                    {item.groupName}
                  </option>
                ))
              )
            ) : null}
          </select>

          {
            groupNameToShowAttributes.length>0 && 
            <select className='select_step_data' type="text" required value={selectedItemId} onChange={(e)=>setSelectedItemId(e.target.value)}>
            <option value="" className='select_step_value'>Select Retailer</option>
            {
              status=== STATUSES.LOADING ? (
                <option value="" className='error_option'>Loading...</option>
              ): 
              status === STATUSES.ERROR ?(
                <option value="" className='error_option'>Something went wrong</option>
              ) :
              status === STATUSES.SUCCESS ? (
                data && typeof data === "string" ?(
                  <option  value="" className='error_option'>No retailers found</option>
                ):(
                  data?.map((item, index) => {
                    const firstKey = Object.keys(item.groupAttributes)[0]; // Get the first key dynamically
                    return (
                      <option key={item.retailerId} value={item.retailerId} className='select_step_value'>
                        {item.groupAttributes["Retailer name"]}
                      </option>
                    );
                  })
                )
                
              )
               :null
              
              
            }
          </select>
          }

        
        

        </div>


        
        <div className="step_btn_contianer">
          <button  className = {`common_btn`} style={{width:150}} onClick={saveData}>Save</button>
        </div>

        <div className='saved_data_to_remove'>
            <h4 >Saved retailers </h4>
            {OneTableItemStatus === STATUSES.SUCCESS && OneTableItemData && OneTableItemData[0] && (
            OneTableItemData[0].retailers.length === 0 ? (
                <p>No retailers found</p>
            ) :
                <div>
                {OneTableItemData[0].retailers.map((retailer, index) => (
                    <p key={index}>{retailer.groupAttributes["Retailer name"][0]} <i onClick={() => deleteData(retailer.retailerId)}><FontAwesomeIcon icon={faTrash} /></i></p>
                ))}
                </div>
            )}
        </div>
      </>
)
}



export default RetailerStep;


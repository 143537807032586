// import Routes from './routes';
// import { Provider } from 'react-redux';
// import store from './store/store.js';

// function App() {
//   return (
//     <Provider store={store}>
//       <Routes />
//     </Provider>
//   );
// }

// export default App;


// src/App.js

import React from 'react';
import Routes from './routes';
import { Provider } from 'react-redux';
import store from './store/store.js';
import Rehydrate from './components/HomeComponents/Login/Rehydrate.jsx';

function App() {
  return (
    <Provider store={store}>
      <Rehydrate>
        <Routes />
      </Rehydrate>
    </Provider>
  );
}

export default App;


import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styles from "./Integrations.module.css";

import Apps from "../../../assets/Images/Apps.png";
import arrows from "../../../assets/Images/arrows.png";
import auth from "../../../assets/Images/auth.png";
import bitcoin from "../../../assets/Images/bitcoin.png";
import cards from "../../../assets/Images/cards.png";
import chats from "../../../assets/Images/chats.png";
import graphs from "../../../assets/Images/graphs.png";
import img from "../../../assets/Images/img.png";
import pdf from "../../../assets/Images/pdf.png";
import settings from "../../../assets/Images/settings.png";
import stats from "../../../assets/Images/stats.png";
import leaderboard from "../../../assets/Images/leaderboard.png";
import { Link } from "react-router-dom";
// import ScrollAnimation from "react-animate-on-scroll";

const IntegrationItem = ({ icon, title, url }) => {
  return (
    <Col sm={12} md={6} lg={4} xl={3}>
      {/* <ScrollAnimation animateIn="flipInY" animateOut="flipOutY"> */}
        <Link to={{ pathname: url }} className={`p-2 ${styles.integrationItem}`}>
          <img src={icon} alt={title} />
          <h6 className={styles.iconText}>{title}</h6>
        </Link>
      {/* </ScrollAnimation> */}
    </Col>
  );
};

function Integrations() {
  const IntegrationObject = [
    {
      icon: img,
      title: "Market Maps",
      url: "market-map-features",
    },
    {
      icon: cards,
      title: "Customer Relationship Management",
      url: "/ekyc",
    },
    {
      icon: arrows,
      title: "Order Management System",
      url: "/ekyc",
    },
    {
      icon: chats,
      title: "Whatsapp Chatbot",
      url: "/ekyc",
    },
    {
      icon: graphs,
      title: "BI & Analytics",
      url: "/ekyc",
    },
    {
      icon: leaderboard,
      title: "Performance Management System",
      url: "/pms",
    },
    {
      icon: auth,
      title: "E-KYC",
      url: "/ekyc",
    },
    {
      icon: settings,
      title: "Shopfloor Management",
      url: "/ekyc",
    },
    {
      icon: stats,
      title: "Ticketing System",
      url: "/ekyc",
    },
    {
      icon: bitcoin,
      title: "Traceability",
      url: "/ekyc",
    },
    {
      icon: Apps,
      title: "Asset Management",
      url: "/ekyc",
    },
    {
      icon: pdf,
      title: "Contract Lifecycle Management",
      url: "/ekyc",
    },
  ];

  return (
    <>
      <div className={styles.bannerSectionArea}>
        <Container className={styles.integrationsContainer}>
          <h1 className={`main-heading ${styles.iconText}`}>Onified Toolkit</h1>
          <p className={`sub-body ${styles.iconText}`}>
            Specific problems have specific solutions Choose from our wide range
            of tools to Onifiy your business
          </p>
          <Row className="g-md-4 my-5">
            {IntegrationObject.map((elem, index) => {
              return (
                <IntegrationItem
                  key={index}
                  icon={elem.icon}
                  title={elem.title}
                  url={elem.url}
                />
              );
            })}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Integrations;

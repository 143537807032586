import React from 'react';
import './PMSnav.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear, faMoon, faUser } from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom';

const PMSnav = () => {

  return (
    <div className='market_nav' style={{zIndex:1}}>
      <div className='col1'>
        <Link style={{textDecoration: 'none', fontWeight:"bolder"}} to={'/pms'}><li >company Logo</li></Link>
      </div>
      <div className='col1'>
        <li><FontAwesomeIcon icon={faMoon} className="market_icon" /></li>
        <li><FontAwesomeIcon icon={faGear} className="market_icon" /></li>
       
       <Link to={'/pms/myProfile'}><li className="user_icon" ><FontAwesomeIcon icon={faUser} className="market_icon" /></li></Link> 
       
        
      </div>
    </div>
  )
}

export default PMSnav;
import React from 'react';
import { PieChart, Pie, Legend, Tooltip } from "recharts";

const Piechart = () => {
  const data01 = [
    { name: "Group A", value: 400 },
    { name: "Group B", value: 300 },
    { name: "Group C", value: 300 },
    { name: "Group D", value: 200 },
    { name: "Group E", value: 278 },
    { name: "Group F", value: 189 }
  ];
  
  const data02 = [
    { name: "Group A", value: 2400 },
    { name: "Group B", value: 4567 },
    { name: "Group C", value: 1398 },
    { name: "Group D", value: 9800 },
    { name: "Group E", value: 3908 },
    { name: "Group F", value: 4800 }
  ];


  return (
    <div className='chart_page'>
      <h6 className='chart_heading'>Retailer Name vs Program name , Program size in USD million</h6>
     
      <div className="chart_container">
        <PieChart width={1000} height={600}>
        <Pie
          dataKey="value"
          data={data01}
          cx={240}
          cy={300}
          outerRadius={180}
          fill="#ff6384"
          label
        />
        <Pie
          dataKey="value"
          data={data02}
          cx={800}
          cy={300}
          innerRadius={100}
          outerRadius={180}
          fill="#36a2eb"
          label
        />
        <Legend />
        <Tooltip />
        </PieChart>
      </div>
  </div>

  )
}

export default Piechart;




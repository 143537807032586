import React from 'react'
import {Col} from "react-bootstrap";
import styles from "../Ekyc.module.css";

const CompanyDetails = ({
    data
}) => {
  return (
    <Col className="col-12 col-md-6 mt-5 mb-5 kycCard">
        <span>
        <p className="sub-heading ">Company Details</p>
        </span>
        <span>
        <p className="sub-body">CIN: {data.cin}</p>
        </span>
        <span>
        <p className="sub-body">Company Name: {data.companyName}</p>
        </span>
        <span>
        <p className="sub-body">Company Status: {data.companyStatus}</p>
        </span>
        <span>
        <p className="sub-body">Email: {data.email}</p>
        </span>
        <span>
        <p className="sub-body">Registered Contact Number: {data.registeredContactNo}</p>
        </span>
        <span>
        <p className="sub-body">Registered Address: {data.registeredAddress}</p>
        </span>
        <span>
        <p className="sub-body">Financial Audit Status: {data.financialAuditStatus}</p>
        </span>
        <span>
        <p className="sub-body">Incorporation Date: {data.incorporationDate}</p>
        </span>
        <span>
        <p className="sub-body">ROC Code: {data.rocCode}</p>
        </span>
        <span>
        <p className="sub-heading">Directors : </p>
        </span>
        <ul>
            {data.directors.map((item,idx)=>(
                <li key={idx} className="sub-body">
                    Name: {item?.name}<br/>
                    DIN: {item?.din}<br/>
                    Contact: {item.contactNumber}
                </li>
            ))}
        </ul>
        <button className={styles.loginButton}>Download</button>
    </Col>
  )
};

export default CompanyDetails;
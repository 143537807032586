import React from "react";
// import ScrollAnimation from "react-animate-on-scroll";
import { Col, Container, ListGroup, Row } from "react-bootstrap";
import FeviconFinal from "../../../assets/logo/FeviconFinal.png";
// import FooterLogo from "../../../assets/logo/footer-logo.png";
import Logo from "../../../assets/logo/OnifiedFinalLogo.png";
import styles from "./Footer.module.css";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer>
        <Container className={`${styles.footerContainer}`} fluid>
          <Row className="my-3">
            <Col sm={12} md={2}>
            </Col>
            <Col sm={12} md={2}>
              <h3 className={`${styles.subHeadingFooter} ${styles.bdBtm}`}>About</h3>
              <ListGroup variant="flush" className={styles.footerListGroup}>
                <ListGroup.Item className={`sub-body ${styles.noBd}`}>
                  <Link to="/" className={styles.footerMenu}>
                    Company
                  </Link>
                </ListGroup.Item>
                <ListGroup.Item className={`sub-body ${styles.noBd}`}>
                  <Link to="/" className={styles.footerMenu}>
                    Data Security
                  </Link>
                </ListGroup.Item>
                <ListGroup.Item className={`sub-body ${styles.noBd}`}>
                  <Link to="/" className={styles.footerMenu}>
                    Use Cases
                  </Link>
                </ListGroup.Item>
                <ListGroup.Item className={`sub-body ${styles.noBd}`}>
                  <Link to="/" className={styles.footerMenu}>
                    Resources
                  </Link>
                </ListGroup.Item>
              </ListGroup>
            </Col>
            <Col sm={12} md={2}>
              <h3 className={`${styles.subHeadingFooter} ${styles.bdBtm}`}>Products</h3>
              <ListGroup variant="flush" className={styles.footerListGroup}>
                <ListGroup.Item className={`sub-body ${styles.noBd}`}>
                  <Link to="/" className={styles.footerMenu}>
                    CRM
                  </Link>
                </ListGroup.Item>
                <ListGroup.Item className={`sub-body ${styles.noBd}`}>
                  <Link to="/market-map" className={styles.footerMenu}>
                    Market Maps
                  </Link>
                </ListGroup.Item>
                <ListGroup.Item className={`sub-body ${styles.noBd}`}>
                  <Link to="/" className={styles.footerMenu}>
                    BI & Analytics
                  </Link>
                </ListGroup.Item>
                <ListGroup.Item className={`sub-body ${styles.noBd}`}>
                  <Link to="/" className={styles.footerMenu}>
                    EKYC
                  </Link>
                </ListGroup.Item>
                <ListGroup.Item className={`sub-body ${styles.noBd}`}>
                  <Link to="/pms" className={styles.footerMenu}>
                    Performance Management
                  </Link>
                </ListGroup.Item>
                <ListGroup.Item className={`sub-body ${styles.noBd}`}>
                  <Link to="/" className={styles.footerMenu}>
                    Asset Management
                  </Link>
                </ListGroup.Item>
              </ListGroup>
            </Col>
            <Col sm={12} md={2}>
              <h3 className={`${styles.subHeadingFooter} ${styles.bdBtm}`}>Functions</h3>
              <ListGroup variant="flush" className={styles.footerListGroup}>
                <ListGroup.Item className={`sub-body ${styles.noBd}`}>
                  <Link to="/" className={styles.footerMenu}>
                    Sales & Marketing
                  </Link>
                </ListGroup.Item>
                <ListGroup.Item className={`sub-body ${styles.noBd}`}>
                  <Link to="/" className={styles.footerMenu}>
                    Human Resources
                  </Link>
                </ListGroup.Item>
                <ListGroup.Item className={`sub-body ${styles.noBd}`}>
                  <Link to="/" className={styles.footerMenu}>
                    Production Management
                  </Link>
                </ListGroup.Item>
                <ListGroup.Item className={`sub-body ${styles.noBd}`}>
                  <Link to="/" className={styles.footerMenu}>
                    Project Management
                  </Link>
                </ListGroup.Item>
              </ListGroup>
            </Col>
            <Col sm={12} md={2}>
              <h3 className={`${styles.subHeadingFooter} ${styles.bdBtm}`}>Support</h3>
              <ListGroup variant="flush" className={styles.footerListGroup}>
                <ListGroup.Item className={`sub-body ${styles.noBd}`}>
                  <Link to="/request" className={styles.footerMenu}>
                    Request a Demo
                  </Link>
                </ListGroup.Item>
                <ListGroup.Item className={`sub-body ${styles.noBd}`}>
                  <Link to="/" className={styles.footerMenu}>
                    Pricing
                  </Link>
                </ListGroup.Item>
                <ListGroup.Item className={`sub-body ${styles.noBd}`}>
                  <Link to="/" className={styles.footerMenu}>
                    Write to us
                  </Link>
                </ListGroup.Item>
              </ListGroup>
            </Col>
            <Col sm={12} md={2}>
            </Col>
          </Row>
          <Row className="my-3">
            <Col sm={12}  md={2} className={styles.footerLogoMain}>
              <div className={styles.footerLogo}>
                <Link to={'/'}>
                  <img src={Logo} width="150" className={styles.logo} alt="logo" />
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
    </footer>
  );
}

export default Footer;

import React from "react";
import errorimage from "../assets/Images/errorimage.png";


function ErrorPage() {
  return (
    <div className="py-5 d-flex flex-column justify-content-center align-items-center">
      <img
        style={{ width: 300, alignSelf: "center" }}
        src={errorimage}
        alt={"hero-1"}
        className="page-image"
      />
      <div className="main-heading text-center">
        Page Not Found (404)
      </div>
    </div>
  );
}

export default ErrorPage;

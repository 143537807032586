import React, { useEffect, useState } from "react";
import "./Login.css";
import login_img from "../../../assets/Images/login_img.gif";
import trident_logo from "../../../assets/Images/image80.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import STATUSES from "../../../store/Statuses";
import { useSelector, useDispatch } from "react-redux";
import { resetUserLogin, resetUserToken, resetUserValidate, userLogin, userToken, userValidate } from "../../../store/Slices/userAuthSlice";
import { setAuthToken, setUserDetails } from "../../../store/Slices/userAuthSlice";
import Cookies from 'js-cookie';
import { LoaderCircle } from "../../Common/Loader/Loader";
import config from "../../../config";
import logoPopup from "../../../assets/Images/logopopup.svg";
import eye from "../../../assets/Images/eye.svg";
import eyeOff from "../../../assets/Images/eye.svg";

const Login = () => {
  const companyName = config.SERVICE_COMPANY_NAME;
    
  console.log("companyName" ,companyName);


  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [userName, setUserName] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [userPassword, setUserPassword] = useState("");
  const [userTokenInput, setUserTokenInput] = useState("");
  const [showTokenPopup, setShowTokenPopup] = useState(false);

  const [nameFilled, setNameFilled] = useState(false);
  const [passwordFilled, setPasswordFilled] = useState(false);
  const [tokenFilled, setTokenFilled] = useState(true); 

  const { token, userDetails } = useSelector((state) => state.auth);

  console.log(token, userDetails )
  // ===================================== redirect to the marketmap home =================================
  useEffect(() => {
    if (token && userDetails) {
      navigate('../market-map');
    }
  }, [token, userDetails ,navigate]);

  const handleSubmit = () => {
    if (!userName.trim()) {
      setNameFilled(true);
      return;
    }
    if (!userPassword.trim()) {
      setPasswordFilled(true);
      return;
    }
    if (userPassword.trim() && userName.trim()) {
      const data = { username: userName.trim(), password: userPassword.trim() };
      dispatch(userToken(data)); // Call token API first
    }
  };

  const { data, status, error } = useSelector((state) => state.UserLogin);
  const { data: tokenData, status: tokenStatus, error: tokenError } = useSelector((state) => state.UserToken);
  const { data: validateData, status: validateStatus, error: validateError } = useSelector((state) => state.UserValidate);

  useEffect(() => {
    if (tokenStatus === STATUSES.SUCCESS) {
      setShowTokenPopup(true); // Show token popup on token success
    } else if (tokenStatus === STATUSES.ERROR) {
      toast.error(tokenError ? tokenError : "Something Went Wrong");
      dispatch(resetUserToken({}));
    }
  }, [tokenStatus, tokenError, dispatch]);

  const handleTokenSubmit = () => {
    if (userTokenInput.trim()) {
      setTokenFilled(true);
      dispatch(userValidate({ token: userTokenInput.trim() })); // Validate token
    } else {
      setTokenFilled(false);
    }
  };

  useEffect(() => {
    if (validateStatus === STATUSES.SUCCESS) {
      dispatch(userLogin({ username: userName.trim(), password: userPassword.trim(),token : userTokenInput.trim()  } )); // Login API call
    } else if (validateStatus === STATUSES.ERROR) {
      toast.error(validateError || "Something Went Wrong");
      dispatch(resetUserValidate({}));
    }
  }, [validateStatus, validateError, dispatch]);

  useEffect(() => {
    if (status === STATUSES.SUCCESS) {
      const token = userTokenInput.trim(); // Assume token is returned here
      Cookies.set('onifiedPKtoken', token);
      localStorage.setItem('onifiedPKData', JSON.stringify(data));

      dispatch(setAuthToken(token));
      dispatch(setUserDetails(data));

      toast.success("Login successfully");
      dispatch(resetUserLogin({}));
      dispatch(resetUserToken({}));
      dispatch(resetUserValidate({}));
      setUserName("");
      setUserPassword("");
      setNameFilled(false);
      setPasswordFilled(false);

      navigate('/market-map');
    } else if (status === STATUSES.ERROR) {
      toast.error(error ? error : "Something Went Wrong");
      dispatch(resetUserLogin({}));
    }
  }, [data, status, error, dispatch, navigate]);
  const handleIconClick = () => {
    setPasswordVisible(!passwordVisible);
  };
  return (
    <>
      <div className="login_con">
        <div className="login_box">
          <div className="col2">
            <img src={login_img} alt={"hero"} id="login_img" />
          </div>
          <div className="col1">
            <img src={trident_logo} alt={"trident_logo"} id="trident_logo"/>
            <div className="common_bg login">
              <h3 className="main_heading">Welcome back 
                <br/>Log in to your account</h3>
              <div className={'login_input_box'}>
                <input
                  className={`input_ ${nameFilled ? 'error' : ''}`}
                  type="text"
                  name="userName"
                  value={userName}
                  placeholder="Email Id"
                  onChange={(e) => { setUserName(e.target.value); setNameFilled(false); }}
                />
                {nameFilled && <div className="error_message">* User id is required</div>}
              </div>
              <div className={'login_input_box'}>
                <input
                  className={`input_ ${passwordFilled ? 'error' : ''}`}
                  type={passwordVisible ? 'text' : 'password'}
                  name="userPassword"
                  value={userPassword}
                  placeholder="Password"
                  onChange={(e) => { setUserPassword(e.target.value); setPasswordFilled(false); }}
                />
                <div class="input-icon-div" onClick={handleIconClick}>
                      <img src={passwordVisible ? eyeOff : eye}/>
                </div>
                {passwordFilled && <div className="error_message">* Password is required</div>}
              </div>
                <div className="reset_btn">
                  <span>
                    <input
                    className={`input_ ${passwordFilled ? 'error' : ''}`}
                    type="checkbox"
                    name="remember_me"
                    value={''}
                  />
                    Remember me
                  </span> 
                  <span>Forget Password ?</span>
                </div>
                <Link  style={{ textDecoration: "none" }}>
                  <button onClick={handleSubmit} className={tokenStatus === STATUSES.LOADING ? 'common_btn disabled' : 'common_btn1'} disabled={tokenStatus === STATUSES.LOADING}  >
                    {tokenStatus === STATUSES.LOADING ? <LoaderCircle/> : "Log in"}
                  </button>
                </Link>
                <p className="login_text_bottom">Don’t have an account? <Link  to='/signup'>Sign up</Link></p>
              
            </div>
          </div>
        </div>
      </div>
      
      {showTokenPopup && (
        <div className="token_popup_bg">
          <div className="token_popup">
            <img src={logoPopup} alt={"hero"} className="tokenlogo" id="" />
            <h6>A token has been sent to your <br></br><a >{userName.trim()}</a>, please enter the token in <br></br>the space below </h6>
            <div className='_input_box' >
              <textarea
              placeholder="Enter Token Number"
                className="table_input"
                type="text"
                value={userTokenInput}
                onChange={(e) => { setUserTokenInput(e.target.value); setTokenFilled(true); }}
                required
              />
              <span>Enter Token</span>

            {!tokenFilled && <div className="error_message">* Token is required</div>}
            </div>
            <a href="" className="resendtoken">Resend Token Number</a>
            <div className="token_btn">
              
              {/* <button className="common_btn" style={{width:180 , fontSize:14}}  onClick={handleTokenSubmit}>Submit Token</button> */}

              <button className={validateStatus === STATUSES.LOADING ? 'common_btn disabled' : 'common_btn'} disabled={validateStatus === STATUSES.LOADING}  onClick={handleTokenSubmit} >
                  {validateStatus === STATUSES.LOADING ? <LoaderCircle/> : "Done"} 
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Login;

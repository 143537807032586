import { createSlice } from '@reduxjs/toolkit';

const shortedDataSlice = createSlice({
  name: 'array',
  initialState: [],
  reducers: {
    addToshortedData: (state , action ) => {
      return action.payload
    },
  },
});

const BarChartDataSlice = createSlice({
  name: 'array',
  initialState: [],
  reducers: {
    addDataToBarChart: (state , action ) => {
      return action.payload
    },
  },
});

export const { addToshortedData } = shortedDataSlice.actions;
export const { addDataToBarChart } = BarChartDataSlice.actions;

export { shortedDataSlice,BarChartDataSlice};



const shortedXaxisDataSlice = createSlice({
  name: 'array',
  initialState: [],
  reducers: {
    addToshortedXaxisData: (state , action ) => {
      return action.payload
    },
  },
});

const shortedYaxisDataSlice = createSlice({
  name: 'array',
  initialState: [],
  reducers: {
    addToshortedYaxisData: (state , action ) => {
      return action.payload
    },
  },
});

const shortedZaxisDataSlice = createSlice({
  name: 'array',
  initialState: [],
  reducers: {
    addToshortedZaxisData: (state , action ) => {
      return action.payload
    },
  },
});



export const { addToshortedXaxisData } = shortedXaxisDataSlice.actions;
export const { addToshortedYaxisData } = shortedYaxisDataSlice.actions;
export const { addToshortedZaxisData } = shortedZaxisDataSlice.actions;

export { shortedXaxisDataSlice,shortedYaxisDataSlice , shortedZaxisDataSlice};

import React, {useCallback, useEffect, useState} from 'react';
import './DataSelection.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleRight, faAngleUp, faCaretDown, faCaretUp, faClose, faMultiply,} from "@fortawesome/free-solid-svg-icons";
import { Draggable, Droppable} from 'react-beautiful-dnd';
import { DragDropContext} from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';
import { addDataToBarChart, addToshortedXaxisData, addToshortedYaxisData, addToshortedZaxisData } from '../../../../store/Slices/marketMapShortedDataSlice.js';
import { useDispatch } from 'react-redux';
import { dataSelectionToggleFun } from '../../../../store/Slices/sideBarSlice.js';
import { useLocation } from 'react-router';
import { getAllGroupAttributes, getAllManufacturerAttributes, getAllPackagingAttributes, getAllProductAttributes, getAllProgramAttributes, getAllPromotionAttributes, getAllRegionAttributes, getAllRetailerAttributes, getAllSupplierAttributes, getOneGroupAttributes, getOneManufacturerAttributes, getOnePackagingAttributes, getOneProductAttributes, getOneProgramAttributes, getOnePromotionAttributes, getOneRegionAttributes, getOneRetailerAttributes, getOneSupplierAttributes, resetOneGroupAttributesGroupState, resetOneManufacturerAttributesGroupState, resetOnePackagingAttributesGroupState, resetOneProductAttributesGroupState, resetOneProgramAttributesGroupState, resetOnePromotionAttributesGroupState, resetOneRegionAttributesGroupState, resetOneRetailerAttributesGroupState, resetOneSupplierAttributesGroupState } from '../../../../store/Slices/marketMapAttributeSlice.js';
import STATUSES from '../../../../store/Statuses.js';
import { getAllGroup, getAllManufacturer, getAllPackaging, getAllProduct, getAllPromotion, getAllRegion, getAllRetailer, getAllSupplier, resetGetAllGroupState, resetGetAllManufacturerState, resetGetAllPackagingState, resetGetAllProductState, resetGetAllPromotionState, resetGetAllRegionState, resetGetAllRetailerState, resetGetAllSupplierState } from '../../../../store/Slices/marketMapTableSlices.js';
import Loader from '../../../Common/Loader/Loader.jsx';
import RenderWarning from '../RenderWarning/RenderWarning.jsx';
import { getAllProgram, resetGetAllProgramState } from '../../../../store/Slices/marketMapProgram.js';

const DataSelection = ({ toggle, setToggle , defaultData , setDataToShowOnX ,setDataToShowOnY ,setDataToShowOnZ , setBarsDataValues}) => {

    const location = useLocation();
    const dispatch = useDispatch();
    
    // Paths where want to show the DataSelection
    const sidebarPaths = ['/market-map/Bar-Chart', '/market-map/Area-Chart', '/market-map/Vertical-Bar-Chart' , '/market-map/Line-Chart' ,'/market-map/Stacked-Bar-Chart', '/market-map/Radar-Chart', '/market-map/Bubble-Chart'];
    const DataSelectionSidebar = sidebarPaths.includes(location.pathname);

    const ExtraAxisPaths = ['/market-map/Bubble-Chart'];
    const ShowExtraAxisPaths = ExtraAxisPaths.includes(location.pathname);

    const handle_data_selection_btn = ()=>{
      setToggle(!toggle);
    }
    const [toggleSelectedData, setToggleSelectedData] = useState(false);
    const [toggleChooseContainer, setToggleChooseContainer] = useState(false);


    // handle axis tabs 
    const [activeTab, setActiveTab] = useState(1);

    const handleTabClick = (tabNumber) => {
      setActiveTab(tabNumber);
    };

    // const [showSection[0], setshowSection[0]] = useState(false);

    const [showSection, setShowSection] = useState(Array(8).fill(false));


    
    // Function to toggle the visibility of a section by index
    const toggleSection = (index) => {
        setShowSection(prevState => {
            const newState = [...prevState];
            newState[index] = !newState[index];
            return newState;
        });
    };

    

        

    // const DataMainHeading = ["Product","Program", "Retailer", "Manufacturer", "Supplier", "Packaging Detail", "Region", "Customized Group"];
    const DataMainHeading = ["Product","Program", "Retailer", "Manufacturer", "Supplier"];

    const [selectTable, setSelectTable] = useState(defaultData?.defaultSelectTable || '');
    const HandleSelectedTable = (event) => {
      setSelectTable(event.target.value);
      // clear states 
      setGroupNameToShowAttributes("");
      setSelectedGroupIndex(0);
      setProductGroupNameForProgram("");

      setGroupAttributesHeading([]);
      setAdditionalAttributesHeading([]);
      setSetsAttributesHeading([]);

      setProgramProductGroupAttributesHeading([]);
      setProgramProductAdditionalAttributesHeading([]);
      setProgramProductSetsAttributesHeading([]);
      setProgramRetailerGroupAttributesHeading([]);
      setProgramRetailerAdditionalAttributesHeading([]);
      setProgramRetailerSetsAttributesHeading([]);
      setProgramManufacturerGroupAttributesHeading([]);
      setProgramManufacturerAdditionalAttributesHeading([]);
      setProgramManufacturerSetsAttributesHeading([]);
      setProgramSupplierGroupAttributesHeading([]);
      setProgramSupplierAdditionalAttributesHeading([]);
      setProgramSupplierSetsAttributesHeading([]);
      setProgramPackagingGroupAttributesHeading([]);
      setProgramPackagingAdditionalAttributesHeading([]);
      setProgramPackagingSetsAttributesHeading([]);
      setProgramPromotionGroupAttributesHeading([]);
      setProgramPromotionAdditionalAttributesHeading([]);
      setProgramPromotionSetsAttributesHeading([]);
      setProgramRegionGroupAttributesHeading([]);
      setProgramRegionAdditionalAttributesHeading([]);
      setProgramRegionSetsAttributesHeading([]);
      setProgramGroupGroupAttributesHeading([]);
      setProgramGroupAdditionalAttributesHeading([]);
      setProgramGroupSetsAttributesHeading([]);

      setXAxisValues([]);
      setYAxisValues([]);
      setZAxisValues([]);
      setXaxisProgramProductValues([]);
      setXaxisProgramRetailerValues([]);
      setXaxisProgramManufacturerValues([]);
      setXaxisProgramSupplierValues([]);
      setXaxisProgramPackagingValues([]);
      setXaxisProgramPromotionValues([]);
      setXaxisProgramRegionValues([]);
      setXaxisProgramGroupValues([]);
      setYaxisProgramProductValues([]);
      setYaxisProgramRetailerValues([]);
      setYaxisProgramManufacturerValues([]);
      setYaxisProgramSupplierValues([]);
      setYaxisProgramPackagingValues([]);
      setYaxisProgramPromotionValues([]);
      setYaxisProgramRegionValues([]);
      setYaxisProgramGroupValues([]);
      setZaxisProgramProductValues([]);
      setZaxisProgramRetailerValues([]);
      setZaxisProgramManufacturerValues([]);
      setZaxisProgramSupplierValues([]);
      setZaxisProgramPackagingValues([]);
      setZaxisProgramPromotionValues([]);
      setZaxisProgramRegionValues([]);
      setZaxisProgramGroupValues([]);
    };
    // console.log("selectTable -- " , selectTable);

    //======================== [1] Get all Attribute Groups for the slected Table name  ===================================
    useEffect(() => {
      if(selectTable !== ''){
        getAllAttributesData(selectTable);
      }
    }, [dispatch,selectTable]);

    const getAllAttributesData = (selectTable) => {
      switch (selectTable) {
        case "Product":
          dispatch(getAllProductAttributes({}));
          break;
        case "Retailer":
          dispatch(getAllRetailerAttributes({}));
          break;
        case "Manufacturer":
          dispatch(getAllManufacturerAttributes({}));
          break;
        case "Supplier":
          dispatch(getAllSupplierAttributes({}));
          break;
        case "Program":
          dispatch(getAllProgramAttributes({}));
          break;
        case "Packaging Detail":
          dispatch(getAllPackagingAttributes({}));
          break;
        case "Marketing Callout":
          dispatch(getAllPromotionAttributes({}));
          break;
        case "Region":
          dispatch(getAllRegionAttributes({}));
          break;
        case "Customized Group":
          dispatch(getAllGroupAttributes({}));
          break;
        default:
          break;
      }
    };

    // Fetch data status and error for selected table
    const { data:AttributesGroupsData ,  status:AttributeGroupStatus , error:AttributeGroupError  } = useSelector((state) => {
      switch (selectTable) {
        case "Product":
          return state.AllProductAttributeGroup;
        case "Retailer":
          return state.AllRetailerAttributeGroup;
        case "Manufacturer":
          return state.AllManufacturerAttributeGroup;
        case "Supplier":
          return state.AllSupplierAttributeGroup;
        case "Program":
          return state.AllProgramAttributeGroup;
        case "Packaging Detail":
          return state.AllPackagingAttributeGroup;
        case "Marketing Callout":
          return state.AllPromotionAttributeGroup;
        case "Region":
          return state.AllRegionAttributeGroup;
        case "Customized Group":
          return state.AllGroupAttributeGroup;
        default:
          return {};
      }
    });


    //======================== [2] Get One Attribute Groups for the slected group name  ===================================

    const [groupNameToShowAttributes, setGroupNameToShowAttributes] = useState(defaultData?.defaultGroupNameToShowAttributes ||"");
    const [selectedGroupIndex, setSelectedGroupIndex] = useState(defaultData?.defaultSelectedGroupIndex || 0);
    // const handleGroupSelection = (e, groupName, selectedIndex) => {
    //   e.preventDefault();
    //   setGroupNameToShowAttributes(groupName);
    //   setSelectedGroupIndex(selectedIndex);
    // };
    const handleGroupSelection = (e) => {
      const groupName = e.target.value;
      setGroupNameToShowAttributes(groupName);
      setSelectedGroupIndex(e.target.selectedIndex);
  
      setProductGroupNameForProgram("");

      // Reset selected product if selectTable is "Program"
      if (selectTable === "Program") {
        setSelectedGroupIndex(0);
      }

      setGroupAttributesHeading([]);
      setAdditionalAttributesHeading([]);
      setSetsAttributesHeading([]);

      setProgramProductGroupAttributesHeading([]);
      setProgramProductAdditionalAttributesHeading([]);
      setProgramProductSetsAttributesHeading([]);
      setProgramRetailerGroupAttributesHeading([]);
      setProgramRetailerAdditionalAttributesHeading([]);
      setProgramRetailerSetsAttributesHeading([]);
      setProgramManufacturerGroupAttributesHeading([]);
      setProgramManufacturerAdditionalAttributesHeading([]);
      setProgramManufacturerSetsAttributesHeading([]);
      setProgramSupplierGroupAttributesHeading([]);
      setProgramSupplierAdditionalAttributesHeading([]);
      setProgramSupplierSetsAttributesHeading([]);
      setProgramPackagingGroupAttributesHeading([]);
      setProgramPackagingAdditionalAttributesHeading([]);
      setProgramPackagingSetsAttributesHeading([]);
      setProgramPromotionGroupAttributesHeading([]);
      setProgramPromotionAdditionalAttributesHeading([]);
      setProgramPromotionSetsAttributesHeading([]);
      setProgramRegionGroupAttributesHeading([]);
      setProgramRegionAdditionalAttributesHeading([]);
      setProgramRegionSetsAttributesHeading([]);
      setProgramGroupGroupAttributesHeading([]);
      setProgramGroupAdditionalAttributesHeading([]);
      setProgramGroupSetsAttributesHeading([]);

      setXAxisValues([]);
      setYAxisValues([]);
      setZAxisValues([]);
      setXaxisProgramProductValues([]);
      setXaxisProgramRetailerValues([]);
      setXaxisProgramManufacturerValues([]);
      setXaxisProgramSupplierValues([]);
      setXaxisProgramPackagingValues([]);
      setXaxisProgramPromotionValues([]);
      setXaxisProgramRegionValues([]);
      setXaxisProgramGroupValues([]);
      setYaxisProgramProductValues([]);
      setYaxisProgramRetailerValues([]);
      setYaxisProgramManufacturerValues([]);
      setYaxisProgramSupplierValues([]);
      setYaxisProgramPackagingValues([]);
      setYaxisProgramPromotionValues([]);
      setYaxisProgramRegionValues([]);
      setYaxisProgramGroupValues([]);
      setZaxisProgramProductValues([]);
      setZaxisProgramRetailerValues([]);
      setZaxisProgramManufacturerValues([]);
      setZaxisProgramSupplierValues([]);
      setZaxisProgramPackagingValues([]);
      setZaxisProgramPromotionValues([]);
      setZaxisProgramRegionValues([]);
      setZaxisProgramGroupValues([]);
    };
    const [productGroupNameForProgram , setProductGroupNameForProgram] = useState(defaultData?.defaultProductGroupNameForProgram || "");
    const handleProductSelection = (e) => {
      setSelectedGroupIndex(e.target.selectedIndex);
      setProductGroupNameForProgram(e.target.value);
    };

    // console.log("groupNameToShowAttributes -- " ,groupNameToShowAttributes );
    // console.log("selectedGroupIndex -- " ,selectedGroupIndex );
    // console.log("productGroupNameForProgram -- " ,productGroupNameForProgram );


    // Clear All var and table , single Attribute State while changing the selectTable name 
    useEffect(()=>{
      // setGroupNameToShowAttributes("");
      // setProductGroupNameForProgram("");
      // setSelectedGroupIndex(0);
      switch (selectTable) {
          case "Product":
            dispatch(resetOneProductAttributesGroupState({}));
            dispatch(resetGetAllProductState({}));
            break;
          case "Retailer":
            dispatch(resetOneRetailerAttributesGroupState({}));
            dispatch(resetGetAllRetailerState({}));
            break;
          case "Manufacturer":
            dispatch(resetOneManufacturerAttributesGroupState({}));
            dispatch(resetGetAllManufacturerState({}));
            break;
          case "Supplier":
            dispatch(resetOneSupplierAttributesGroupState({}));
            dispatch(resetGetAllSupplierState({}));
            break;
          case "Program":
              dispatch(resetOneProgramAttributesGroupState({}));
              dispatch(resetGetAllProgramState({}));
            break;
          case "Packaging Detail":
            dispatch(resetOnePackagingAttributesGroupState({}));
            dispatch(resetGetAllPackagingState({}));
            break;
          case "Marketing Callout":
            dispatch(resetOnePromotionAttributesGroupState({}));
            dispatch(resetGetAllPromotionState({}));
            break;
          case "Region":
            dispatch(resetOneRegionAttributesGroupState({}));
            dispatch(resetGetAllRegionState({}));
            break;
          case "Customized Group":
            dispatch(resetOneGroupAttributesGroupState({}));
            dispatch(resetGetAllGroupState({}));
            break;
          default:
            break;
      }
      setGroupAttributesHeading([]);
      setAdditionalAttributesHeading([]);
      setSetsAttributesHeading([]);
      setProgramProductGroupAttributesHeading([]);
      setProgramProductAdditionalAttributesHeading([]);
      setProgramProductSetsAttributesHeading([]);
      setProgramRetailerGroupAttributesHeading([]);
      setProgramRetailerAdditionalAttributesHeading([]);
      setProgramRetailerSetsAttributesHeading([]);
      setProgramManufacturerGroupAttributesHeading([]);
      setProgramManufacturerAdditionalAttributesHeading([]);
      setProgramManufacturerSetsAttributesHeading([]);
      setProgramSupplierGroupAttributesHeading([]);
      setProgramSupplierAdditionalAttributesHeading([]);
      setProgramSupplierSetsAttributesHeading([]);
      setProgramPackagingGroupAttributesHeading([]);
      setProgramPackagingAdditionalAttributesHeading([]);
      setProgramPackagingSetsAttributesHeading([]);
      setProgramPromotionGroupAttributesHeading([]);
      setProgramPromotionAdditionalAttributesHeading([]);
      setProgramPromotionSetsAttributesHeading([]);
      setProgramRegionGroupAttributesHeading([]);
      setProgramRegionAdditionalAttributesHeading([]);
      setProgramRegionSetsAttributesHeading([]);
      setProgramGroupGroupAttributesHeading([]);
      setProgramGroupAdditionalAttributesHeading([]);
      setProgramGroupSetsAttributesHeading([]);
    },[selectTable])

    useEffect(() => {
      if(groupNameToShowAttributes !== '' && selectTable !== ''){
          getOneAttributesData(selectTable ,groupNameToShowAttributes);
      }
    }, [dispatch,groupNameToShowAttributes ]);
  
    const getOneAttributesData = (selectTable ,groupNameToShowAttributes) => {
      switch (selectTable) {
        case "Product":
          dispatch(getOneProductAttributes(groupNameToShowAttributes));
          break;
        case "Retailer":
          dispatch(getOneRetailerAttributes(groupNameToShowAttributes));
          break;
        case "Manufacturer":
          dispatch(getOneManufacturerAttributes(groupNameToShowAttributes));
          break;
        case "Supplier":
          dispatch(getOneSupplierAttributes(groupNameToShowAttributes));
          break;
        case "Program":
          dispatch(getOneProgramAttributes(groupNameToShowAttributes));
          break;
        case "Packaging Detail":
          dispatch(getOnePackagingAttributes(groupNameToShowAttributes));
          break;
        case "Marketing Callout":
          dispatch(getOnePromotionAttributes(groupNameToShowAttributes));
          break;
        case "Region":
          dispatch(getOneRegionAttributes(groupNameToShowAttributes));
          break;
        case "Customized Group":
          dispatch(getOneGroupAttributes(groupNameToShowAttributes));
          break;
        default:
          break;
      }
    };
  
    // Fetch data status and error for group name
    const { data: OneAttributesGroupsData ,  status: OneAttributeGroupStatus , error: OneAttributeGroupError  } = useSelector((state) => {
      switch (selectTable) {
        case "Product":
          return state.OneProductAttributesGroup;
        case "Retailer":
          return state.OneRetailerAttributesGroup;
        case "Manufacturer":
          return state.OneManufacturerAttributesGroup;
        case "Supplier":
          return state.OneSupplierAttributesGroup;
        case "Program":
          return state.OneProgramAttributesGroup;
        case "Packaging Detail":
          return state.OnePackagingAttributesGroup;
        case "Marketing Callout":
          return state.OnePromotionAttributesGroup;
        case "Region":
          return state.OneRegionAttributesGroup;
        case "Customized Group":
          return state.OneGroupAttributesGroup;
        default:
          return {};
      }
    });

    // console.log(OneAttributesGroupsData)


    //======================== [3] Get One table data according to selected groupName and index  ===================================
    useEffect(() => {
      if(selectTable !== '' && groupNameToShowAttributes!=='' && selectedGroupIndex !== 0){
        getData(selectTable);
      }
    }, [dispatch,selectTable,groupNameToShowAttributes ,selectedGroupIndex]);

    const getData = (selectTable) => {
      let classNameToFetch;
      switch (selectTable) {
        case "Product":
          classNameToFetch = selectedGroupIndex === 1 ? `Product` : `Product${selectedGroupIndex}`;
          dispatch(getAllProduct(classNameToFetch));
          break;
        case "Retailer":
          classNameToFetch = selectedGroupIndex === 1 ? `Retailer` : `Retailer${selectedGroupIndex}`;
          dispatch(getAllRetailer(classNameToFetch));
          break;
        case "Manufacturer":
          classNameToFetch = selectedGroupIndex === 1 ? `Manufacturer` : `Manufacturer${selectedGroupIndex}`;
          dispatch(getAllManufacturer(classNameToFetch));
          break;
        case "Supplier":
          classNameToFetch = selectedGroupIndex === 1 ? `Supplier` : `Supplier${selectedGroupIndex}`;
          dispatch(getAllSupplier(classNameToFetch));
          break;
        case "Program":
          classNameToFetch = selectedGroupIndex === 1 ? `Program` : `Program${selectedGroupIndex}`;
          dispatch(getAllProgram(classNameToFetch));
          break;
        case "Packaging Detail":
          classNameToFetch = selectedGroupIndex === 1 ? `Packaging` : `Packaging${selectedGroupIndex}`;
          dispatch(getAllPackaging(classNameToFetch));
          break;
        case "Marketing Callout":
          classNameToFetch = selectedGroupIndex === 1 ? `Promotion` : `Promotion${selectedGroupIndex}`;
          dispatch(getAllPromotion(classNameToFetch));
          break;
        case "Region":
          classNameToFetch = selectedGroupIndex === 1 ? `Region` : `Region${selectedGroupIndex}`;
          dispatch(getAllRegion(classNameToFetch));
          break;
        case "Customized Group":
          classNameToFetch = selectedGroupIndex === 1 ? `Group` : `Group${selectedGroupIndex}`;
          dispatch(getAllGroup(classNameToFetch));
          break;
        default:
          break;
      }
    };

    // Fetch data status and error for selected table
    const { data :tableData, status :tableStatus, error:tableError} = useSelector((state) => {
      switch (selectTable) {
        case "Product":
          return state.AllProducts;
        case "Retailer":
          return state.AllRetailers;
        case "Manufacturer":
          return state.AllManufacturers;
        case "Supplier":
          return state.AllSuppliers;
        case "Program":
          return state.AllPrograms;
        case "Packaging Detail":
          return state.AllPackagings;
        case "Marketing Callout":
          return state.AllPromotions;
        case "Region":
          return state.AllRegions;
        case "Customized Group":
          return state.AllGroups;
        default:
          return {};
      }
    });


    

    // console.log(AttributesGroupsData);
    // console.log(tableData);

    const [groupAttributesHeading, setGroupAttributesHeading] = useState([]);
    const [additionalAttributesHeading, setAdditionalAttributesHeading] = useState([]);
    const [setsAttributesHeading, setSetsAttributesHeading] = useState([]);

    const [programProductGroupAttributesHeading, setProgramProductGroupAttributesHeading] = useState([]);
    const [programProductAdditionalAttributesHeading, setProgramProductAdditionalAttributesHeading] = useState([]);
    const [programProductSetsAttributesHeading, setProgramProductSetsAttributesHeading] = useState([]);
    // console.log("programProductGroupAttributesHeading" , programProductGroupAttributesHeading)
    // console.log("programProductSetsAttributesHeading" , programProductSetsAttributesHeading)

    const [programRetailerGroupAttributesHeading, setProgramRetailerGroupAttributesHeading] = useState([]);
    const [programRetailerAdditionalAttributesHeading, setProgramRetailerAdditionalAttributesHeading] = useState([]);
    const [programRetailerSetsAttributesHeading, setProgramRetailerSetsAttributesHeading] = useState([]);

    const [programManufacturerGroupAttributesHeading, setProgramManufacturerGroupAttributesHeading] = useState([]);
    const [programManufacturerAdditionalAttributesHeading, setProgramManufacturerAdditionalAttributesHeading] = useState([]);
    const [programManufacturerSetsAttributesHeading, setProgramManufacturerSetsAttributesHeading] = useState([]);

    const [programSupplierGroupAttributesHeading, setProgramSupplierGroupAttributesHeading] = useState([]);
    const [programSupplierAdditionalAttributesHeading, setProgramSupplierAdditionalAttributesHeading] = useState([]);
    const [programSupplierSetsAttributesHeading, setProgramSupplierSetsAttributesHeading] = useState([]);

    const [programPackagingGroupAttributesHeading, setProgramPackagingGroupAttributesHeading] = useState([]);
    const [programPackagingAdditionalAttributesHeading, setProgramPackagingAdditionalAttributesHeading] = useState([]);
    const [programPackagingSetsAttributesHeading, setProgramPackagingSetsAttributesHeading] = useState([]);

    const [programPromotionGroupAttributesHeading, setProgramPromotionGroupAttributesHeading] = useState([]);
    const [programPromotionAdditionalAttributesHeading, setProgramPromotionAdditionalAttributesHeading] = useState([]);
    const [programPromotionSetsAttributesHeading, setProgramPromotionSetsAttributesHeading] = useState([]);

    const [programRegionGroupAttributesHeading, setProgramRegionGroupAttributesHeading] = useState([]);
    const [programRegionAdditionalAttributesHeading, setProgramRegionAdditionalAttributesHeading] = useState([]);
    const [programRegionSetsAttributesHeading, setProgramRegionSetsAttributesHeading] = useState([]);

    const [programGroupGroupAttributesHeading, setProgramGroupGroupAttributesHeading] = useState([]);
    const [programGroupAdditionalAttributesHeading, setProgramGroupAdditionalAttributesHeading] = useState([]);
    const [programGroupSetsAttributesHeading, setProgramGroupSetsAttributesHeading] = useState([]);

    // clear state onchanging the groupNameToShowAttributes 
    // useEffect(()=>{
     
    // },[groupNameToShowAttributes , selectedGroupIndex]);

    useEffect(()=>{
      if(selectTable=== "Program" && productGroupNameForProgram.length > 0){
        dispatch(getOneProductAttributes(productGroupNameForProgram));
        dispatch(getOneRetailerAttributes("Retailer"));
        dispatch(getOneManufacturerAttributes("Manufacturer"));
        dispatch(getOneSupplierAttributes("Supplier"));
        dispatch(getOnePackagingAttributes("Packaging Details"));
        dispatch(getOnePromotionAttributes("Marketing Callout"));
        dispatch(getOneRegionAttributes("Region"));
      }
    },[selectTable , productGroupNameForProgram]);

    const { data: ProductInProgramAttributesData, status: ProductInProgramAttributesStatus, error: ProductInProgramAttributesError } = useSelector((state) => state.OneProductAttributesGroup);
    const { data:RetailerInProgramAttributesData, status: RetailerInProgramAttributesStatus, error: RetailerInProgramAttributesError } = useSelector((state) => state.OneRetailerAttributesGroup);
    const { data:ManufacturerInProgramAttributesData, status: ManufacturerInProgramAttributesStatus, error: ManufacturerInProgramAttributesError } = useSelector((state) => state.OneManufacturerAttributesGroup);
    const { data:SupplierInProgramAttributesData, status: SupplierInProgramAttributesStatus, error: SupplierInProgramAttributesError } = useSelector((state) => state.OneSupplierAttributesGroup);
    const { data:PackagingInProgramAttributesData, status: PackagingInProgramAttributesStatus, error: PackagingInProgramAttributesError } = useSelector((state) => state.OnePackagingAttributesGroup);
    const { data:PromotionInProgramAttributesData, status: PromotionInProgramAttributesStatus, error: PromotionInProgramAttributesError } = useSelector((state) => state.OnePromotionAttributesGroup);
    const { data:RegionInProgramAttributesData, status: RegionInProgramAttributesStatus, error: RegionInProgramAttributesError } = useSelector((state) => state.OneRegionAttributesGroup);




    useEffect(() => {
      if (OneAttributesGroupsData && Object.keys(OneAttributesGroupsData).length > 0) {
        if (typeof tableData !== "string") {
          if (tableData?.length > 0) {
            const groupAttrs = [];
            const additionalAttrs = [];
            const setsAttrs = [];
    
            const programProductGroupAttrs = [];
            const programProductAdditionalAttrs = [];
            const programProductSetsAttrs = [];
    
            const programRetailerGroupAttrs = [];
            const programRetailerAdditionalAttrs = [];
            const programRetailerSetsAttrs = [];
    
            const programManufacturerGroupAttrs = [];
            const programManufacturerAdditionalAttrs = [];
            const programManufacturerSetsAttrs = [];
    
            const programSupplierGroupAttrs = [];
            const programSupplierAdditionalAttrs = [];
            const programSupplierSetsAttrs = [];
    
            const programPackagingGroupAttrs = [];
            const programPackagingAdditionalAttrs = [];
            const programPackagingSetsAttrs = [];
    
            const programPromotionGroupAttrs = [];
            const programPromotionAdditionalAttrs = [];
            const programPromotionSetsAttrs = [];
    
            const programRegionGroupAttrs = [];
            const programRegionAdditionalAttrs = [];
            const programRegionSetsAttrs = [];
    
            const programGroupGroupAttrs = [];
            const programGroupAdditionalAttrs = [];
            const programGroupSetsAttrs = [];

            const handleAttributes = (attributes, targetArray) => {
              Object.entries(attributes).forEach(([key, value]) => {
                if (value.type === "Image" || value.type === "Email") { return; }
                if (value.type === "NestedDropdown") {
                  targetArray.push({ key, nametoDisplay: value.nametoDisplay , DisplayValue : "sum" , type : value?.type});
                  targetArray.push({ key: `${key}nEsTeD`, nametoDisplay: value.nametoDisplay2 , DisplayValue : "sum" , type : value?.childDataType});
                } else {
                  targetArray.push({ key, nametoDisplay: value.nametoDisplay , DisplayValue : "sum" , type : value?.type});
                }
              });
            };

            const handleSetsAttributes = (imagesAttributes, targetArray) => {
              Object.keys(imagesAttributes).forEach(imageKey => {
                const { groupsName, attgroups } = imagesAttributes[imageKey];
                Object.keys(groupsName).forEach(groupName => {
                  Object.entries(attgroups).forEach(([attributeKey, attributeValue]) => {
                    if (attributeValue.type === "NestedDropdown") {
                      targetArray.push({ key: `${groupName} ${attributeKey}`, nametoDisplay: `${groupsName[groupName].nametoDisplay} ${attributeValue.nametoDisplay}` , DisplayValue : "sum" , type : attributeKey?.type});
                      targetArray.push({ key: `${groupName} ${attributeKey}nEsTeD`, nametoDisplay: `${groupsName[groupName].nametoDisplay} ${attributeValue.nametoDisplay2}` , DisplayValue : "sum" , type : attributeKey?.childDataType});
                    } else {
                      targetArray.push({ key: `${groupName} ${attributeKey}`, nametoDisplay: `${groupsName[groupName].nametoDisplay} ${attributeValue.nametoDisplay}` , DisplayValue : "sum" , type : attributeKey?.type});
                    }
                  });
                });
              });
            };
    
    
            switch (selectTable) {
              case "Product":
                handleAttributes(OneAttributesGroupsData.groupAttributes, groupAttrs);
                handleAttributes(OneAttributesGroupsData.productAdditionalAttributes, additionalAttrs);
                handleSetsAttributes(OneAttributesGroupsData.imagesAttributes, setsAttrs);
                break;
              case "Retailer":
                handleAttributes(OneAttributesGroupsData.groupAttributes, groupAttrs);
                handleAttributes(OneAttributesGroupsData.retailerAdditionalAttributes, additionalAttrs);
                handleSetsAttributes(OneAttributesGroupsData.imagesAttributes, setsAttrs);
                break;
              case "Manufacturer":
                handleAttributes(OneAttributesGroupsData.groupAttributes, groupAttrs);
                handleAttributes(OneAttributesGroupsData.manufacturerAdditionalAttributes, additionalAttrs);
                handleSetsAttributes(OneAttributesGroupsData.imagesAttributes, setsAttrs);
                break;
              case "Supplier":
                handleAttributes(OneAttributesGroupsData.groupAttributes, groupAttrs);
                handleAttributes(OneAttributesGroupsData.supplierAdditionalAttributes, additionalAttrs);
                handleSetsAttributes(OneAttributesGroupsData.imagesAttributes, setsAttrs);
                break;
              case "Program":
                handleAttributes(OneAttributesGroupsData.groupAttributes, groupAttrs);
                handleAttributes(OneAttributesGroupsData.programAdditionalAttributes, additionalAttrs);
                handleSetsAttributes(OneAttributesGroupsData.imagesAttributes, setsAttrs);


                    if (Object.keys(ProductInProgramAttributesData).length > 0) {
                      handleAttributes(ProductInProgramAttributesData.groupAttributes, programProductGroupAttrs);
                      handleAttributes(ProductInProgramAttributesData.productAdditionalAttributes, programProductAdditionalAttrs);
                      handleSetsAttributes(ProductInProgramAttributesData.imagesAttributes, programProductSetsAttrs);
                    }
                    if (Object.keys(RetailerInProgramAttributesData).length > 0) {
                      handleAttributes(RetailerInProgramAttributesData.groupAttributes, programRetailerGroupAttrs);
                      handleAttributes(RetailerInProgramAttributesData.retailerAdditionalAttributes, programRetailerAdditionalAttrs);
                      handleSetsAttributes(RetailerInProgramAttributesData.imagesAttributes, programRetailerSetsAttrs);
                    }
                    if (Object.keys(ManufacturerInProgramAttributesData).length > 0) {
                      handleAttributes(ManufacturerInProgramAttributesData.groupAttributes, programManufacturerGroupAttrs);
                      handleAttributes(ManufacturerInProgramAttributesData.manufacturerAdditionalAttributes, programManufacturerAdditionalAttrs);
                      handleSetsAttributes(ManufacturerInProgramAttributesData.imagesAttributes, programManufacturerSetsAttrs);
                    }
                    if (Object.keys(SupplierInProgramAttributesData).length > 0) {
                      handleAttributes(SupplierInProgramAttributesData.groupAttributes, programSupplierGroupAttrs);
                      handleAttributes(SupplierInProgramAttributesData.supplierAdditionalAttributes, programSupplierAdditionalAttrs);
                      handleSetsAttributes(SupplierInProgramAttributesData.imagesAttributes, programSupplierSetsAttrs);
                    }
                  

                break;
              case "Packaging Detail":
                handleAttributes(OneAttributesGroupsData.groupAttributes, groupAttrs);
                handleAttributes(OneAttributesGroupsData.packagingAdditionalAttributes, additionalAttrs);
                handleSetsAttributes(OneAttributesGroupsData.imagesAttributes, setsAttrs);

                break;
              case "Marketing Callout":
                handleAttributes(OneAttributesGroupsData.groupAttributes, groupAttrs);
                handleAttributes(OneAttributesGroupsData.promotionAdditionalAttributes, additionalAttrs);
                handleSetsAttributes(OneAttributesGroupsData.imagesAttributes, setsAttrs);

                break;
              case "Region":
                handleAttributes(OneAttributesGroupsData.groupAttributes, groupAttrs);
                handleAttributes(OneAttributesGroupsData.regionAdditionalAttributes, additionalAttrs);
                handleSetsAttributes(OneAttributesGroupsData.imagesAttributes, setsAttrs);

                break;
              case "Customized Group":
                handleAttributes(OneAttributesGroupsData.groupAttributes, groupAttrs);
                handleAttributes(OneAttributesGroupsData.groupAdditionalAttributes, additionalAttrs);
                handleSetsAttributes(OneAttributesGroupsData.imagesAttributes, setsAttrs);

                break;
              default:
                break;
            }
    
            setGroupAttributesHeading([...new Set(groupAttrs)]);
            setAdditionalAttributesHeading([...new Set(additionalAttrs)]);
            setSetsAttributesHeading([...new Set(setsAttrs)]);
    
            setProgramProductGroupAttributesHeading([...new Set(programProductGroupAttrs)]);
            setProgramProductAdditionalAttributesHeading([...new Set(programProductAdditionalAttrs)]);
            setProgramProductSetsAttributesHeading([...new Set(programProductSetsAttrs)]);
    
            setProgramRetailerGroupAttributesHeading([...new Set(programRetailerGroupAttrs)]);
            setProgramRetailerAdditionalAttributesHeading([...new Set(programRetailerAdditionalAttrs)]);
            setProgramRetailerSetsAttributesHeading([...new Set(programRetailerSetsAttrs)]);
    
            setProgramManufacturerGroupAttributesHeading([...new Set(programManufacturerGroupAttrs)]);
            setProgramManufacturerAdditionalAttributesHeading([...new Set(programManufacturerAdditionalAttrs)]);
            setProgramManufacturerSetsAttributesHeading([...new Set(programManufacturerSetsAttrs)]);
    
            setProgramSupplierGroupAttributesHeading([...new Set(programSupplierGroupAttrs)]);
            setProgramSupplierAdditionalAttributesHeading([...new Set(programSupplierAdditionalAttrs)]);
            setProgramSupplierSetsAttributesHeading([...new Set(programSupplierSetsAttrs)]);
    
            setProgramPackagingGroupAttributesHeading([...new Set(programPackagingGroupAttrs)]);
            setProgramPackagingAdditionalAttributesHeading([...new Set(programPackagingAdditionalAttrs)]);
            setProgramPackagingSetsAttributesHeading([...new Set(programPackagingSetsAttrs)]);
    
            setProgramPromotionGroupAttributesHeading([...new Set(programPromotionGroupAttrs)]);
            setProgramPromotionAdditionalAttributesHeading([...new Set(programPromotionAdditionalAttrs)]);
            setProgramPromotionSetsAttributesHeading([...new Set(programPromotionSetsAttrs)]);
    
            setProgramRegionGroupAttributesHeading([...new Set(programRegionGroupAttrs)]);
            setProgramRegionAdditionalAttributesHeading([...new Set(programRegionAdditionalAttrs)]);
            setProgramRegionSetsAttributesHeading([...new Set(programRegionSetsAttrs)]);
    
            setProgramGroupGroupAttributesHeading([...new Set(programGroupGroupAttrs)]);
            setProgramGroupAdditionalAttributesHeading([...new Set(programGroupAdditionalAttrs)]);
            setProgramGroupSetsAttributesHeading([...new Set(programGroupSetsAttrs)]);
          }
        }
      }
    }, [selectTable, OneAttributesGroupsData, tableData , ProductInProgramAttributesData, RetailerInProgramAttributesData, ManufacturerInProgramAttributesData,  SupplierInProgramAttributesData]);
    
  

    

  // console.log("groupAttributesHeading", groupAttributesHeading);
  // console.log("additionalAttributesHeading", additionalAttributesHeading);
  // console.log("setsAttributesHeading", setsAttributesHeading);

  const [xAxisValues, setXAxisValues] = useState([]);
  const [yAxisValues, setYAxisValues] = useState([]);
  const [zAxisValues, setZAxisValues] = useState([]);
  // console.log(yAxisValues)  
  
  const [xAxisProgramProductValues, setXaxisProgramProductValues] = useState([]);
  const [xAxisProgramRetailerValues, setXaxisProgramRetailerValues] = useState([]);
  const [xAxisProgramManufacturerValues, setXaxisProgramManufacturerValues] = useState([]);
  const [xAxisProgramSupplierValues, setXaxisProgramSupplierValues] = useState([]);
  const [xAxisProgramPackagingValues, setXaxisProgramPackagingValues] = useState([]);
  const [xAxisProgramPromotionValues, setXaxisProgramPromotionValues] = useState([]);
  const [xAxisProgramRegionValues, setXaxisProgramRegionValues] = useState([]);
  const [xAxisProgramGroupValues, setXaxisProgramGroupValues] = useState([]);
  
  // console.log(xAxisProgramSupplierValues)  
  // console.log(xAxisProgramManufacturerValues)  
  // console.log(xAxisProgramRetailerValues)
  const [yAxisProgramProductValues, setYaxisProgramProductValues] = useState([]);
  const [yAxisProgramRetailerValues, setYaxisProgramRetailerValues] = useState([]);
  const [yAxisProgramManufacturerValues, setYaxisProgramManufacturerValues] = useState([]);
  const [yAxisProgramSupplierValues, setYaxisProgramSupplierValues] = useState([]);
  const [yAxisProgramPackagingValues, setYaxisProgramPackagingValues] = useState([]);
  const [yAxisProgramPromotionValues, setYaxisProgramPromotionValues] = useState([]);
  const [yAxisProgramRegionValues, setYaxisProgramRegionValues] = useState([]);
  const [yAxisProgramGroupValues, setYaxisProgramGroupValues] = useState([]);

  const [zAxisProgramProductValues, setZaxisProgramProductValues] = useState([]);
  const [zAxisProgramRetailerValues, setZaxisProgramRetailerValues] = useState([]);
  const [zAxisProgramManufacturerValues, setZaxisProgramManufacturerValues] = useState([]);
  const [zAxisProgramSupplierValues, setZaxisProgramSupplierValues] = useState([]);
  const [zAxisProgramPackagingValues, setZaxisProgramPackagingValues] = useState([]);
  const [zAxisProgramPromotionValues, setZaxisProgramPromotionValues] = useState([]);
  const [zAxisProgramRegionValues, setZaxisProgramRegionValues] = useState([]);
  const [zAxisProgramGroupValues, setZaxisProgramGroupValues] = useState([]);

  useEffect(() => {
    if (defaultData) {
      if (defaultData?.defaultYaxisProgramInProgram) {
        setYAxisValues([defaultData.defaultYaxisProgramInProgram]);
      }
      if (defaultData?.defaultXaxisProgramInRetailer) {
        setXaxisProgramRetailerValues([defaultData.defaultXaxisProgramInRetailer]);
      }
      if (defaultData?.defaultXaxisProgramInSupplier) {
        setXaxisProgramSupplierValues([defaultData.defaultXaxisProgramInSupplier]);
      }
      if (defaultData?.defaultXaxisProgramInManufacturer) {
        setXaxisProgramManufacturerValues([defaultData.defaultXaxisProgramInManufacturer]);
      }
    }
  }, []);
  // console.log(xAxisProgramProductValues)
  // console.log("xAxisProgramRetailerValues --", xAxisProgramRetailerValues)


  // const updateAxisDisplayValue = (index, newValue) => {
  //   const newValues = [...xAxisValues];
  //   newValues[index].DisplayValue = newValue;
  //   setXAxisValues(newValues);
  // };
  useEffect(() => {
    setBarsDataValues([...xAxisValues, ...yAxisValues , 
      ...xAxisProgramProductValues,
      ...xAxisProgramRetailerValues,
      ...xAxisProgramManufacturerValues,
      ...xAxisProgramSupplierValues,
      ...yAxisProgramProductValues,
      ...yAxisProgramRetailerValues,
      ...yAxisProgramManufacturerValues,
      ...yAxisProgramSupplierValues,
    ]);
  }, 
  [xAxisValues, yAxisValues, setBarsDataValues , xAxisProgramProductValues,
    xAxisProgramRetailerValues,
    xAxisProgramManufacturerValues,
    xAxisProgramSupplierValues,
    yAxisProgramProductValues,
    yAxisProgramRetailerValues,
    yAxisProgramManufacturerValues,
    yAxisProgramSupplierValues,
  ]);

  const updateDisplayValue = (setList, list, index, newValue) => {
    const newValues = [...list];
    newValues[index].DisplayValue = newValue;
    setList(newValues);
  };

  const handleXAxisSelection = (item, source) => {
    setXAxisValues([...xAxisValues, item]);
    removeItemFromLists(item, source);
  };
  
  // Function to handle Y-axis selection
  const handleYAxisSelection = (item, source) => {
    setYAxisValues([...yAxisValues, item]);
    removeItemFromLists(item, source);
  };

  const handleZAxisSelection = (item, source) => {
    setZAxisValues([...zAxisValues, item]);
    removeItemFromLists(item, source);
  };
  
  // Function to remove item from group and additional attributes lists
  const removeItemFromLists = (item, source) => {
    if (source === 'group') {
      setGroupAttributesHeading(groupAttributesHeading.filter(attribute => attribute !== item));
    } else if (source === 'additional') {
      setAdditionalAttributesHeading(additionalAttributesHeading.filter(attribute => attribute !== item));
    } else if (source === 'sets') {
      setSetsAttributesHeading(setsAttributesHeading.filter(attribute => attribute !== item));
    }
    // Optionally, store the source in the item itself or in a separate state for later reference
    item.source = source;
  };
  
  // Function to remove item from xAxisValues
  const removeFromXAxis = (item) => {
    setXAxisValues(xAxisValues.filter(value => value !== item));
    addItemToLists(item);
  };
  
  // Function to remove item from yAxisValues
  const removeFromYAxis = (item) => {
    setYAxisValues(yAxisValues.filter(value => value !== item));
    addItemToLists(item);
  };

  const removeFromZAxis = (item) => {
    setZAxisValues(zAxisValues.filter(value => value !== item));
    addItemToLists(item);
  };
  
  
  // Function to add item back to group and additional attributes lists
  const addItemToLists = (item) => {
    if (item.source === 'group') {
      setGroupAttributesHeading([...groupAttributesHeading, item]);
    } else if (item.source === 'additional') {
      setAdditionalAttributesHeading([...additionalAttributesHeading, item]);
    } else if (item.source === 'sets') {
      setSetsAttributesHeading([...setsAttributesHeading, item]);
    }
  };
  


  const updateStateAndSource = (item, type, source, setXaxisValues, setAttributesHeading) => {
    setXaxisValues(prevValues => [...prevValues, item]);
    if (source === 'group') {
        setAttributesHeading(prevHeading => prevHeading.filter(attribute => attribute !== item));
    } else if (source === 'additional') {
        setAttributesHeading(prevHeading => prevHeading.filter(attribute => attribute !== item));
    } else if (source === 'sets') {
        setAttributesHeading(prevHeading => prevHeading.filter(attribute => attribute !== item));
    }
    item.source = source;
  };

  const removeFromStateAndSource = (item, type, setXaxisValues, setAttributesHeading) => {
      setXaxisValues(prevValues => prevValues.filter(value => value !== item));
      if (item.source === 'group') {
          setAttributesHeading(prevHeading => [...prevHeading, item]);
      } else if (item.source === 'additional') {
          setAttributesHeading(prevHeading => [...prevHeading, item]);
      } else if (item.source === 'sets') {
          setAttributesHeading(prevHeading => [...prevHeading, item]);
      }
  };

  const handleProgramXAxisSelection = (item, type, source) => {
      switch (type) {
          case "Product":
              updateStateAndSource(item, type, source, setXaxisProgramProductValues, source === 'group' ? setProgramProductGroupAttributesHeading : source === 'additional' ? setProgramProductAdditionalAttributesHeading : setProgramProductSetsAttributesHeading);
              break;
          case "Retailer":
              updateStateAndSource(item, type, source, setXaxisProgramRetailerValues, source === 'group' ? setProgramRetailerGroupAttributesHeading : source === 'additional' ? setProgramRetailerAdditionalAttributesHeading : setProgramRetailerSetsAttributesHeading);
              break;
          case "Manufacturer":
              updateStateAndSource(item, type, source, setXaxisProgramManufacturerValues, source === 'group' ? setProgramManufacturerGroupAttributesHeading : source === 'additional' ? setProgramManufacturerAdditionalAttributesHeading : setProgramManufacturerSetsAttributesHeading);
              break;
          case "Supplier":
              updateStateAndSource(item, type, source, setXaxisProgramSupplierValues, source === 'group' ? setProgramSupplierGroupAttributesHeading : source === 'additional' ? setProgramSupplierAdditionalAttributesHeading : setProgramSupplierSetsAttributesHeading);
              break;
          case "Packaging":
              updateStateAndSource(item, type, source, setXaxisProgramPackagingValues, source === 'group' ? setProgramPackagingGroupAttributesHeading : source === 'additional' ? setProgramPackagingAdditionalAttributesHeading : setProgramPackagingSetsAttributesHeading);
              break;
          case "Promotion":
              updateStateAndSource(item, type, source, setXaxisProgramPromotionValues, source === 'group' ? setProgramPromotionGroupAttributesHeading : source === 'additional' ? setProgramPromotionAdditionalAttributesHeading : setProgramPromotionSetsAttributesHeading);
              break;
          case "Region":
              updateStateAndSource(item, type, source, setXaxisProgramRegionValues, source === 'group' ? setProgramRegionGroupAttributesHeading : source === 'additional' ? setProgramRegionAdditionalAttributesHeading : setProgramRegionSetsAttributesHeading);
              break;
          case "Group":
              updateStateAndSource(item, type, source, setXaxisProgramGroupValues, source === 'group' ? setProgramGroupGroupAttributesHeading : source === 'additional' ? setProgramGroupAdditionalAttributesHeading : setProgramGroupSetsAttributesHeading);
              break;
          default:
              break;
      }
  };

  const removeFromProgramXAxisSelection = (item, type) => {
      switch (type) {
          case "Product":
              removeFromStateAndSource(item, type, setXaxisProgramProductValues, item.source === 'group' ? setProgramProductGroupAttributesHeading : item.source === 'additional' ? setProgramProductAdditionalAttributesHeading : setProgramProductSetsAttributesHeading);
              break;
          case "Retailer":
              removeFromStateAndSource(item, type, setXaxisProgramRetailerValues, item.source === 'group' ? setProgramRetailerGroupAttributesHeading : item.source === 'additional' ? setProgramRetailerAdditionalAttributesHeading : setProgramRetailerSetsAttributesHeading);
              break;
          case "Manufacturer":
              removeFromStateAndSource(item, type, setXaxisProgramManufacturerValues, item.source === 'group' ? setProgramManufacturerGroupAttributesHeading : item.source === 'additional' ? setProgramManufacturerAdditionalAttributesHeading : setProgramManufacturerSetsAttributesHeading);
              break;
          case "Supplier":
              removeFromStateAndSource(item, type, setXaxisProgramSupplierValues, item.source === 'group' ? setProgramSupplierGroupAttributesHeading : item.source === 'additional' ? setProgramSupplierAdditionalAttributesHeading : setProgramSupplierSetsAttributesHeading);
              break;
          case "Packaging":
              removeFromStateAndSource(item, type, setXaxisProgramPackagingValues, item.source === 'group' ? setProgramPackagingGroupAttributesHeading : item.source === 'additional' ? setProgramPackagingAdditionalAttributesHeading : setProgramPackagingSetsAttributesHeading);
              break;
          case "Promotion":
              removeFromStateAndSource(item, type, setXaxisProgramPromotionValues, item.source === 'group' ? setProgramPromotionGroupAttributesHeading : item.source === 'additional' ? setProgramPromotionAdditionalAttributesHeading : setProgramPromotionSetsAttributesHeading);
              break;
          case "Region":
              removeFromStateAndSource(item, type, setXaxisProgramRegionValues, item.source === 'group' ? setProgramRegionGroupAttributesHeading : item.source === 'additional' ? setProgramRegionAdditionalAttributesHeading : setProgramRegionSetsAttributesHeading);
              break;
          case "Group":
              removeFromStateAndSource(item, type, setXaxisProgramGroupValues, item.source === 'group' ? setProgramGroupGroupAttributesHeading : item.source === 'additional' ? setProgramGroupAdditionalAttributesHeading : setProgramGroupSetsAttributesHeading);
              break;
          default:
              break;
      }
  };

  const handleProgramYAxisSelection = (item, type, source) => {
      switch (type) {
          case "Product":
              updateStateAndSource(item, type, source, setYaxisProgramProductValues, source === 'group' ? setProgramProductGroupAttributesHeading : source === 'additional' ? setProgramProductAdditionalAttributesHeading : setProgramProductSetsAttributesHeading);
              break;
          case "Retailer":
              updateStateAndSource(item, type, source, setYaxisProgramRetailerValues, source === 'group' ? setProgramRetailerGroupAttributesHeading : source === 'additional' ? setProgramRetailerAdditionalAttributesHeading : setProgramRetailerSetsAttributesHeading);
              break;
          case "Manufacturer":
              updateStateAndSource(item, type, source, setYaxisProgramManufacturerValues, source === 'group' ? setProgramManufacturerGroupAttributesHeading : source === 'additional' ? setProgramManufacturerAdditionalAttributesHeading : setProgramManufacturerSetsAttributesHeading);
              break;
          case "Supplier":
              updateStateAndSource(item, type, source, setYaxisProgramSupplierValues, source === 'group' ? setProgramSupplierGroupAttributesHeading : source === 'additional' ? setProgramSupplierAdditionalAttributesHeading : setProgramSupplierSetsAttributesHeading);
              break;
          case "Packaging":
              updateStateAndSource(item, type, source, setYaxisProgramPackagingValues, source === 'group' ? setProgramPackagingGroupAttributesHeading : source === 'additional' ? setProgramPackagingAdditionalAttributesHeading : setProgramPackagingSetsAttributesHeading);
              break;
          case "Promotion":
              updateStateAndSource(item, type, source, setYaxisProgramPromotionValues, source === 'group' ? setProgramPromotionGroupAttributesHeading : source === 'additional' ? setProgramPromotionAdditionalAttributesHeading : setProgramPromotionSetsAttributesHeading);
              break;
          case "Region":
              updateStateAndSource(item, type, source, setYaxisProgramRegionValues, source === 'group' ? setProgramRegionGroupAttributesHeading : source === 'additional' ? setProgramRegionAdditionalAttributesHeading : setProgramRegionSetsAttributesHeading);
              break;
          case "Group":
              updateStateAndSource(item, type, source, setYaxisProgramGroupValues, source === 'group' ? setProgramGroupGroupAttributesHeading : source === 'additional' ? setProgramGroupAdditionalAttributesHeading : setProgramGroupSetsAttributesHeading);
              break;
          default:
              break;
      }
  };

  const removeFromProgramYAxisSelection = (item, type) => {
      switch (type) {
          case "Product":
              removeFromStateAndSource(item, type, setYaxisProgramProductValues, item.source === 'group' ? setProgramProductGroupAttributesHeading : item.source === 'additional' ? setProgramProductAdditionalAttributesHeading : setProgramProductSetsAttributesHeading);
              break;
          case "Retailer":
              removeFromStateAndSource(item, type, setYaxisProgramRetailerValues, item.source === 'group' ? setProgramRetailerGroupAttributesHeading : item.source === 'additional' ? setProgramRetailerAdditionalAttributesHeading : setProgramRetailerSetsAttributesHeading);
              break;
          case "Manufacturer":
              removeFromStateAndSource(item, type, setYaxisProgramManufacturerValues, item.source === 'group' ? setProgramManufacturerGroupAttributesHeading : item.source === 'additional' ? setProgramManufacturerAdditionalAttributesHeading : setProgramManufacturerSetsAttributesHeading);
              break;
          case "Supplier":
              removeFromStateAndSource(item, type, setYaxisProgramSupplierValues, item.source === 'group' ? setProgramSupplierGroupAttributesHeading : item.source === 'additional' ? setProgramSupplierAdditionalAttributesHeading : setProgramSupplierSetsAttributesHeading);
              break;
          case "Packaging":
              removeFromStateAndSource(item, type, setYaxisProgramPackagingValues, item.source === 'group' ? setProgramPackagingGroupAttributesHeading : item.source === 'additional' ? setProgramPackagingAdditionalAttributesHeading : setProgramPackagingSetsAttributesHeading);
              break;
          case "Promotion":
              removeFromStateAndSource(item, type, setYaxisProgramPromotionValues, item.source === 'group' ? setProgramPromotionGroupAttributesHeading : item.source === 'additional' ? setProgramPromotionAdditionalAttributesHeading : setProgramPromotionSetsAttributesHeading);
              break;
          case "Region":
              removeFromStateAndSource(item, type, setYaxisProgramRegionValues, item.source === 'group' ? setProgramRegionGroupAttributesHeading : item.source === 'additional' ? setProgramRegionAdditionalAttributesHeading : setProgramRegionSetsAttributesHeading);
              break;
          case "Group":
              removeFromStateAndSource(item, type, setYaxisProgramGroupValues, item.source === 'group' ? setProgramGroupGroupAttributesHeading : item.source === 'additional' ? setProgramGroupAdditionalAttributesHeading : setProgramGroupSetsAttributesHeading);
              break;
          default:
              break;
      }
  };

  const handleProgramZAxisSelection = (item, type, source) => {
    switch (type) {
        case "Product":
            updateStateAndSource(item, type, source, setZaxisProgramProductValues, source === 'group' ? setProgramProductGroupAttributesHeading : source === 'additional' ? setProgramProductAdditionalAttributesHeading : setProgramProductSetsAttributesHeading);
            break;
        case "Retailer":
            updateStateAndSource(item, type, source, setZaxisProgramRetailerValues, source === 'group' ? setProgramRetailerGroupAttributesHeading : source === 'additional' ? setProgramRetailerAdditionalAttributesHeading : setProgramRetailerSetsAttributesHeading);
            break;
        case "Manufacturer":
            updateStateAndSource(item, type, source, setZaxisProgramManufacturerValues, source === 'group' ? setProgramManufacturerGroupAttributesHeading : source === 'additional' ? setProgramManufacturerAdditionalAttributesHeading : setProgramManufacturerSetsAttributesHeading);
            break;
        case "Supplier":
            updateStateAndSource(item, type, source, setZaxisProgramSupplierValues, source === 'group' ? setProgramSupplierGroupAttributesHeading : source === 'additional' ? setProgramSupplierAdditionalAttributesHeading : setProgramSupplierSetsAttributesHeading);
            break;
        case "Packaging":
            updateStateAndSource(item, type, source, setZaxisProgramPackagingValues, source === 'group' ? setProgramPackagingGroupAttributesHeading : source === 'additional' ? setProgramPackagingAdditionalAttributesHeading : setProgramPackagingSetsAttributesHeading);
            break;
        case "Promotion":
            updateStateAndSource(item, type, source, setZaxisProgramPromotionValues, source === 'group' ? setProgramPromotionGroupAttributesHeading : source === 'additional' ? setProgramPromotionAdditionalAttributesHeading : setProgramPromotionSetsAttributesHeading);
            break;
        case "Region":
            updateStateAndSource(item, type, source, setZaxisProgramRegionValues, source === 'group' ? setProgramRegionGroupAttributesHeading : source === 'additional' ? setProgramRegionAdditionalAttributesHeading : setProgramRegionSetsAttributesHeading);
            break;
        case "Group":
            updateStateAndSource(item, type, source, setZaxisProgramGroupValues, source === 'group' ? setProgramGroupGroupAttributesHeading : source === 'additional' ? setProgramGroupAdditionalAttributesHeading : setProgramGroupSetsAttributesHeading);
            break;
        default:
            break;
    }
  };

  const removeFromProgramZAxisSelection = (item, type) => {
      switch (type) {
          case "Product":
              removeFromStateAndSource(item, type, setZaxisProgramProductValues, item.source === 'group' ? setProgramProductGroupAttributesHeading : item.source === 'additional' ? setProgramProductAdditionalAttributesHeading : setProgramProductSetsAttributesHeading);
              break;
          case "Retailer":
              removeFromStateAndSource(item, type, setZaxisProgramRetailerValues, item.source === 'group' ? setProgramRetailerGroupAttributesHeading : item.source === 'additional' ? setProgramRetailerAdditionalAttributesHeading : setProgramRetailerSetsAttributesHeading);
              break;
          case "Manufacturer":
              removeFromStateAndSource(item, type, setZaxisProgramManufacturerValues, item.source === 'group' ? setProgramManufacturerGroupAttributesHeading : item.source === 'additional' ? setProgramManufacturerAdditionalAttributesHeading : setProgramManufacturerSetsAttributesHeading);
              break;
          case "Supplier":
              removeFromStateAndSource(item, type, setZaxisProgramSupplierValues, item.source === 'group' ? setProgramSupplierGroupAttributesHeading : item.source === 'additional' ? setProgramSupplierAdditionalAttributesHeading : setProgramSupplierSetsAttributesHeading);
              break;
          case "Packaging":
              removeFromStateAndSource(item, type, setZaxisProgramPackagingValues, item.source === 'group' ? setProgramPackagingGroupAttributesHeading : item.source === 'additional' ? setProgramPackagingAdditionalAttributesHeading : setProgramPackagingSetsAttributesHeading);
              break;
          case "Promotion":
              removeFromStateAndSource(item, type, setZaxisProgramPromotionValues, item.source === 'group' ? setProgramPromotionGroupAttributesHeading : item.source === 'additional' ? setProgramPromotionAdditionalAttributesHeading : setProgramPromotionSetsAttributesHeading);
              break;
          case "Region":
              removeFromStateAndSource(item, type, setZaxisProgramRegionValues, item.source === 'group' ? setProgramRegionGroupAttributesHeading : item.source === 'additional' ? setProgramRegionAdditionalAttributesHeading : setProgramRegionSetsAttributesHeading);
              break;
          case "Group":
              removeFromStateAndSource(item, type, setZaxisProgramGroupValues, item.source === 'group' ? setProgramGroupGroupAttributesHeading : item.source === 'additional' ? setProgramGroupAdditionalAttributesHeading : setProgramGroupSetsAttributesHeading);
              break;
          default:
              break;
      }
  };



  const [xAxisData, setxAxisData] = useState([]);
  const [yAxisData, setyAxisData] = useState([]);
  const [zAxisData, setzAxisData] = useState([]);

  const normalizeData = (data) => {
    // Collect all unique keys present in the data array
    const allKeys = data.reduce((keys, item) => {
      Object.keys(item).forEach(key => {
        if (!keys.includes(key)) {
          keys.push(key);
        }
      });
      return keys;
    }, []);
  
    // Ensure all objects contain all keys, filling missing keys with a default value of zero
    const normalizedData = data.map(item => {
      const newItem = {};
      allKeys.forEach(key => {
        let value = item[key] || ""; // Assign empty string if key is missing
        // Convert to number if the value is a string that can be converted
        if (!isNaN(value) && value !== "") {
          value = Number(value);
        }
        newItem[key] = value;
      });
      return newItem;
    });
  
    return normalizedData;
  };


  const filterData = useCallback(() => {
    if (tableData && tableData.length > 0 && typeof tableData !== "string") {

        // Objects based on selected xAxisValues
        const xData = tableData?.map(item => {
          const selectedData = {};
          xAxisValues?.forEach(({ key, nametoDisplay}) => {
            if (key in item.groupAttributes) {
              selectedData[nametoDisplay] = item.groupAttributes[key];
            } else {
              switch (selectTable) {
                case "Product":
                  if (key in item.productAdditionalAttributes) {
                    selectedData[nametoDisplay] = item.productAdditionalAttributes[key];
                  }
                  break;
                case "Program":
                  if (key in item.programAdditionalAttributes) {
                    selectedData[nametoDisplay] = item.programAdditionalAttributes[key];
                  }
                  break;
                case "Retailer":
                  if (key in item.retailerAdditionalAttributes) {
                    selectedData[nametoDisplay] = item.retailerAdditionalAttributes[key];
                  }
                  break;
                case "Manufacturer":
                  if (key in item.manufacturerAdditionalAttributes) {
                    selectedData[nametoDisplay] = item.manufacturerAdditionalAttributes[key];
                  }
                  break;
                case "Supplier":
                  if (key in item.supplierAdditionalAttributes) {
                    selectedData[nametoDisplay] = item.supplierAdditionalAttributes[key];
                  }
                  break;
                case "Packaging Detail":
                  if (key in item.packagingAdditionalAttributes) {
                    selectedData[nametoDisplay] = item.packagingAdditionalAttributes[key];
                  }
                  break;
                case "Region":
                  if (key in item.regionAdditionalAttributes) {
                    selectedData[nametoDisplay] = item.regionAdditionalAttributes[key];
                  }
                  break;
                case "Customized Group":
                  if (key in item.groupAdditionalAttributes) {
                    selectedData[nametoDisplay] = item.groupAdditionalAttributes[key];
                  }
                  break;
                default:
                  break;
              }
            }
          });

          item?.products?.length>0 && xAxisProgramProductValues?.forEach(({ key, nametoDisplay}) => {
            if (key in item?.products[0]?.groupAttributes) {
                selectedData[nametoDisplay] = item?.products[0]?.groupAttributes[key];
            } else if (key in item?.products[0]?.productAdditionalAttributes) {
                selectedData[nametoDisplay] = item?.products[0]?.productAdditionalAttributes[key];
            }
          });

          item?.retailers?.length>0 && xAxisProgramRetailerValues?.forEach(({ key, nametoDisplay}) => {
            if (key in item?.retailers[0]?.groupAttributes) {
                selectedData[nametoDisplay] = item?.retailers[0]?.groupAttributes[key];
            } else if (key in item?.retailers[0]?.retailerAdditionalAttributes) {
                selectedData[nametoDisplay] = item?.retailers[0]?.retailerAdditionalAttributes[key];
            }
          });

          item?.manufacturers?.length>0 && xAxisProgramManufacturerValues?.forEach(({ key, nametoDisplay}) => {
            if (key in item?.manufacturers[0]?.groupAttributes) {
                selectedData[nametoDisplay] = item?.manufacturers[0]?.groupAttributes[key];
            } else if (key in item?.manufacturers[0]?.manufacturerAdditionalAttributes) {
                selectedData[nametoDisplay] = item?.manufacturers[0]?.manufacturerAdditionalAttributes[key];
            }
          });

          item?.suppliers?.length>0 && xAxisProgramSupplierValues?.forEach(({ key, nametoDisplay}) => {
            if (key in item?.suppliers[0]?.groupAttributes) {
                selectedData[nametoDisplay] = item?.suppliers[0]?.groupAttributes[key];
            } else if (key in item?.suppliers[0]?.supplierAdditionalAttributes) {
                selectedData[nametoDisplay] = item?.suppliers[0]?.supplierAdditionalAttributes[key];
            }
          });

          item?.packagings?.length>0 && xAxisProgramPackagingValues?.forEach(({ key, nametoDisplay}) => {
            if (key in item?.packagings[0]?.groupAttributes) {
                selectedData[nametoDisplay] = item?.packagings[0]?.groupAttributes[key];
            } else if (key in item?.packagings[0]?.packagingAdditionalAttributes) {
                selectedData[nametoDisplay] = item?.packagings[0]?.packagingAdditionalAttributes[key];
            }
          });

          item?.promotions?.length>0 && xAxisProgramPromotionValues?.forEach(({ key, nametoDisplay}) => {
            if (key in item?.promotions[0]?.groupAttributes) {
                selectedData[nametoDisplay] = item?.promotions[0]?.groupAttributes[key];
            } else if (key in item?.promotions[0]?.promotionAdditionalAttributes) {
                selectedData[nametoDisplay] = item?.promotions[0]?.promotionAdditionalAttributes[key];
            }
          });

          item?.regions?.length>0 && xAxisProgramRegionValues?.forEach(({ key, nametoDisplay}) => {
            if (key in item?.regions[0]?.groupAttributes) {
                selectedData[nametoDisplay] = item?.regions[0]?.groupAttributes[key];
            } else if (key in item?.regions[0]?.regionAdditionalAttributes) {
                selectedData[nametoDisplay] = item?.regions[0]?.regionAdditionalAttributes[key];
            }
          });

          item?.groups?.length>0 &&  xAxisProgramGroupValues?.forEach(({ key, nametoDisplay}) => {
            if (key in item?.groups[0]?.groupAttributes) {
                selectedData[nametoDisplay] = item?.groups[0]?.groupAttributes[key];
            } else if (key in item?.groups[0]?.groupAdditionalAttributes) {
                selectedData[nametoDisplay] = item?.groups[0]?.groupAdditionalAttributes[key];
            }
          });

          return selectedData;
        });
        
                
        setxAxisData(normalizeData(xData));
    
        // Objects based on selected yAxisValues
        const yData = tableData?.map(item => {
          const selectedData = {};
          yAxisValues?.forEach(({ key, nametoDisplay}) => {
            if (key in item.groupAttributes) {
              selectedData[nametoDisplay] = item.groupAttributes[key];
            } else {
              switch (selectTable) {
                case "Product":
                  if (key in item.productAdditionalAttributes) {
                    selectedData[nametoDisplay] = item.productAdditionalAttributes[key];
                  }
                  break;
                case "Program":
                  if (key in item.programAdditionalAttributes) {
                    selectedData[nametoDisplay] = item.programAdditionalAttributes[key];
                  }
                  break;
                case "Retailer":
                  if (key in item.retailerAdditionalAttributes) {
                    selectedData[nametoDisplay] = item.retailerAdditionalAttributes[key];
                  }
                  break;
                case "Manufacturer":
                  if (key in item.manufacturerAdditionalAttributes) {
                    selectedData[nametoDisplay] = item.manufacturerAdditionalAttributes[key];
                  }
                  break;
                case "Supplier":
                  if (key in item.supplierAdditionalAttributes) {
                    selectedData[nametoDisplay] = item.supplierAdditionalAttributes[key];
                  }
                  break;
                case "Packaging Detail":
                  if (key in item.packagingAdditionalAttributes) {
                    selectedData[nametoDisplay] = item.packagingAdditionalAttributes[key];
                  }
                  break;
                case "Region":
                  if (key in item.regionAdditionalAttributes) {
                    selectedData[nametoDisplay] = item.regionAdditionalAttributes[key];
                  }
                  break;
                case "Customized Group":
                  if (key in item.groupAdditionalAttributes) {
                    selectedData[nametoDisplay] = item.groupAdditionalAttributes[key];
                  }
                  break;
                default:
                  break;
              }
            }
          });

          item?.products?.length>0 && yAxisProgramProductValues?.forEach(({ key, nametoDisplay}) => {
              if (key in item?.products[0]?.groupAttributes) {
                  selectedData[nametoDisplay] = item?.products[0]?.groupAttributes[key];
              } else if (key in item?.products[0]?.productAdditionalAttributes) {
                  selectedData[nametoDisplay] = item?.products[0]?.productAdditionalAttributes[key];
              }
          });

          item?.retailers?.length>0 && yAxisProgramRetailerValues?.forEach(({ key, nametoDisplay}) => {
            if (key in item?.retailers[0]?.groupAttributes) {
                selectedData[nametoDisplay] = item?.retailers[0]?.groupAttributes[key];
            } else if (key in item?.retailers[0]?.retailerAdditionalAttributes) {
                selectedData[nametoDisplay] = item?.retailers[0]?.retailerAdditionalAttributes[key];
            }
          });

          item?.manufacturers?.length>0 && yAxisProgramManufacturerValues?.forEach(({ key, nametoDisplay}) => {
            if (key in item?.manufacturers[0]?.groupAttributes) {
                selectedData[nametoDisplay] = item?.manufacturers[0]?.groupAttributes[key];
            } else if (key in item?.manufacturers[0]?.manufacturerAdditionalAttributes) {
                selectedData[nametoDisplay] = item?.manufacturers[0]?.manufacturerAdditionalAttributes[key];
            }
          });

          
          item?.suppliers?.length>0 && yAxisProgramSupplierValues?.forEach(({ key, nametoDisplay}) => {
            if (key in item?.suppliers[0]?.groupAttributes) {
                selectedData[nametoDisplay] = item?.suppliers[0]?.groupAttributes[key];
            } else if (key in item?.suppliers[0]?.supplierAdditionalAttributes) {
                selectedData[nametoDisplay] = item?.suppliers[0]?.supplierAdditionalAttributes[key];
            }
          });

          item?.packagings?.length>0 && yAxisProgramPackagingValues?.forEach(({ key, nametoDisplay}) => {
            if (key in item?.packagings[0]?.groupAttributes) {
                selectedData[nametoDisplay] = item?.packagings[0]?.groupAttributes[key];
            } else if (key in item?.packagings[0]?.packagingAdditionalAttributes) {
                selectedData[nametoDisplay] = item?.packagings[0]?.packagingAdditionalAttributes[key];
            }
          });
          
          item?.promotions?.length>0 && yAxisProgramPromotionValues?.forEach(({ key, nametoDisplay}) => {
            if (key in item?.promotions[0]?.groupAttributes) {
                selectedData[nametoDisplay] = item?.promotions[0]?.groupAttributes[key];
            } else if (key in item?.promotions[0]?.promotionAdditionalAttributes) {
                selectedData[nametoDisplay] = item?.promotions[0]?.promotionAdditionalAttributes[key];
            }
          });
          
          item?.regions?.length>0 && yAxisProgramRegionValues?.forEach(({ key, nametoDisplay}) => {
            if (key in item?.regions[0]?.groupAttributes) {
                selectedData[nametoDisplay] = item?.regions[0]?.groupAttributes[key];
            } else if (key in item?.regions[0]?.regionAdditionalAttributes) {
                selectedData[nametoDisplay] = item?.regions[0]?.regionAdditionalAttributes[key];
            }
          });

          return selectedData;
        });
        setyAxisData(normalizeData(yData));

        // Objects based on selected zAxisValues
        const zData = tableData?.map(item => {
          const selectedData = {};
          zAxisValues?.forEach(({ key, nametoDisplay}) => {
            if (key in item.groupAttributes) {
              selectedData[nametoDisplay] = item.groupAttributes[key];
            } else {
              switch (selectTable) {
                case "Product":
                  if (key in item.productAdditionalAttributes) {
                    selectedData[nametoDisplay] = item.productAdditionalAttributes[key];
                  }
                  break;
                case "Program":
                  if (key in item.programAdditionalAttributes) {
                    selectedData[nametoDisplay] = item.programAdditionalAttributes[key];
                  }
                  break;
                case "Retailer":
                  if (key in item.retailerAdditionalAttributes) {
                    selectedData[nametoDisplay] = item.retailerAdditionalAttributes[key];
                  }
                  break;
                case "Manufacturer":
                  if (key in item.manufacturerAdditionalAttributes) {
                    selectedData[nametoDisplay] = item.manufacturerAdditionalAttributes[key];
                  }
                  break;
                case "Supplier":
                  if (key in item.supplierAdditionalAttributes) {
                    selectedData[nametoDisplay] = item.supplierAdditionalAttributes[key];
                  }
                  break;
                case "Packaging Detail":
                  if (key in item.packagingAdditionalAttributes) {
                    selectedData[nametoDisplay] = item.packagingAdditionalAttributes[key];
                  }
                  break;
                case "Region":
                  if (key in item.regionAdditionalAttributes) {
                    selectedData[nametoDisplay] = item.regionAdditionalAttributes[key];
                  }
                  break;
                case "Customized Group":
                  if (key in item.groupAdditionalAttributes) {
                    selectedData[nametoDisplay] = item.groupAdditionalAttributes[key];
                  }
                  break;
                default:
                  break;
              }
            }
          });

          item?.products?.length>0 && zAxisProgramProductValues?.forEach(({ key, nametoDisplay}) => {
              if (key in item?.products[0]?.groupAttributes) {
                  selectedData[nametoDisplay] = item?.products[0]?.groupAttributes[key];
              } else if (key in item?.products[0]?.productAdditionalAttributes) {
                  selectedData[nametoDisplay] = item?.products[0]?.productAdditionalAttributes[key];
              }
          });

          item?.retailers?.length>0 && zAxisProgramRetailerValues?.forEach(({ key, nametoDisplay}) => {
            if (key in item?.retailers[0]?.groupAttributes) {
                selectedData[nametoDisplay] = item?.retailers[0]?.groupAttributes[key];
            } else if (key in item?.retailers[0]?.retailerAdditionalAttributes) {
                selectedData[nametoDisplay] = item?.retailers[0]?.retailerAdditionalAttributes[key];
            }
          });

          item?.manufacturers?.length>0 && zAxisProgramManufacturerValues?.forEach(({ key, nametoDisplay}) => {
            if (key in item?.manufacturers[0]?.groupAttributes) {
                selectedData[nametoDisplay] = item?.manufacturers[0]?.groupAttributes[key];
            } else if (key in item?.manufacturers[0]?.manufacturerAdditionalAttributes) {
                selectedData[nametoDisplay] = item?.manufacturers[0]?.manufacturerAdditionalAttributes[key];
            }
          });

          
          item?.suppliers?.length>0 && zAxisProgramSupplierValues?.forEach(({ key, nametoDisplay}) => {
            if (key in item?.suppliers[0]?.groupAttributes) {
                selectedData[nametoDisplay] = item?.suppliers[0]?.groupAttributes[key];
            } else if (key in item?.suppliers[0]?.supplierAdditionalAttributes) {
                selectedData[nametoDisplay] = item?.suppliers[0]?.supplierAdditionalAttributes[key];
            }
          });

          item?.packagings?.length>0 && zAxisProgramPackagingValues?.forEach(({ key, nametoDisplay}) => {
            if (key in item?.packagings[0]?.groupAttributes) {
                selectedData[nametoDisplay] = item?.packagings[0]?.groupAttributes[key];
            } else if (key in item?.packagings[0]?.packagingAdditionalAttributes) {
                selectedData[nametoDisplay] = item?.packagings[0]?.packagingAdditionalAttributes[key];
            }
          });
          
          item?.promotions?.length>0 && zAxisProgramPromotionValues?.forEach(({ key, nametoDisplay}) => {
            if (key in item?.promotions[0]?.groupAttributes) {
                selectedData[nametoDisplay] = item?.promotions[0]?.groupAttributes[key];
            } else if (key in item?.promotions[0]?.promotionAdditionalAttributes) {
                selectedData[nametoDisplay] = item?.promotions[0]?.promotionAdditionalAttributes[key];
            }
          });
          
          item?.regions?.length>0 && zAxisProgramRegionValues?.forEach(({ key, nametoDisplay}) => {
            if (key in item?.regions[0]?.groupAttributes) {
                selectedData[nametoDisplay] = item?.regions[0]?.groupAttributes[key];
            } else if (key in item?.regions[0]?.regionAdditionalAttributes) {
                selectedData[nametoDisplay] = item?.regions[0]?.regionAdditionalAttributes[key];
            }
          });

          return selectedData;
        });
        setzAxisData(normalizeData(zData));
            
    }
  },[tableData, xAxisValues, yAxisValues, zAxisValues , selectTable , xAxisProgramProductValues,xAxisProgramRetailerValues,xAxisProgramManufacturerValues,xAxisProgramSupplierValues,xAxisProgramPackagingValues,xAxisProgramPromotionValues,xAxisProgramRegionValues,xAxisProgramGroupValues,yAxisProgramProductValues,yAxisProgramRetailerValues,yAxisProgramManufacturerValues,yAxisProgramSupplierValues,yAxisProgramPackagingValues,yAxisProgramPromotionValues,yAxisProgramRegionValues,yAxisProgramGroupValues ,zAxisProgramProductValues,zAxisProgramRetailerValues,zAxisProgramManufacturerValues,zAxisProgramSupplierValues,zAxisProgramPackagingValues,zAxisProgramPromotionValues,zAxisProgramRegionValues,zAxisProgramGroupValues]);


  // console.log( " selected xAxisData ---" , xAxisData)
  // console.log( " selected yAxisData ---" , yAxisData)
  // console.log( " selected zAxisData ---" , zAxisData)




  useEffect(() => {
    filterData();
  }, [filterData]);
  

  // console.log("xAxisData==" , xAxisData);
  // console.log("yAxisData==" ,yAxisData);

  useEffect(()=>{
    if(xAxisData.length>0){
      // dispatch(addToshortedXaxisData(xAxisData));
      setDataToShowOnX(xAxisData)
    }
  },[xAxisData]);

  useEffect(()=>{
    if(yAxisData.length>0){
      // dispatch(addToshortedYaxisData(yAxisData));
      setDataToShowOnY(yAxisData)
    }
  },[yAxisData]);

  useEffect(()=>{
    if(zAxisData.length>0){
      // dispatch(addToshortedZaxisData(zAxisData));
      setDataToShowOnZ(zAxisData)
    }
  },[zAxisData]);

  
  // useEffect(() => {
  //   // Clear input data when group name changes
  //   setXAxisValues([]);
  //   setYAxisValues([]);
  //   setZAxisValues([]);
  //   setXaxisProgramProductValues([]);
  //   setXaxisProgramRetailerValues([]);
  //   setXaxisProgramManufacturerValues([]);
  //   setXaxisProgramSupplierValues([]);
  //   setXaxisProgramPackagingValues([]);
  //   setXaxisProgramPromotionValues([]);
  //   setXaxisProgramRegionValues([]);
  //   setXaxisProgramGroupValues([]);
  //   setYaxisProgramProductValues([]);
  //   setYaxisProgramRetailerValues([]);
  //   setYaxisProgramManufacturerValues([]);
  //   setYaxisProgramSupplierValues([]);
  //   setYaxisProgramPackagingValues([]);
  //   setYaxisProgramPromotionValues([]);
  //   setYaxisProgramRegionValues([]);
  //   setYaxisProgramGroupValues([]);
  //   setZaxisProgramProductValues([]);
  //   setZaxisProgramRetailerValues([]);
  //   setZaxisProgramManufacturerValues([]);
  //   setZaxisProgramSupplierValues([]);
  //   setZaxisProgramPackagingValues([]);
  //   setZaxisProgramPromotionValues([]);
  //   setZaxisProgramRegionValues([]);
  //   setZaxisProgramGroupValues([]);
  // }, [groupNameToShowAttributes,selectTable ,selectedGroupIndex]);

  // useEffect(()=>{
  //   setSelectedGroupIndex(0);
  //   setProductGroupNameForProgram("");
  // },[groupNameToShowAttributes,selectTable ])

  // console.log("xAxisValues === " , xAxisValues);
  // console.log("yAxisValues === " , yAxisValues);



  return (
    <>
    {
      DataSelectionSidebar && 
    <>
    <div className={toggle ? "data_selection" : "data_selection active"}>
        
        
        <i className="close_btn" onClick={handle_data_selection_btn}><FontAwesomeIcon icon={faMultiply} /></i>           
          
        <span className='choose_analyze' onClick={()=>setToggleChooseContainer(!toggleChooseContainer)}>Select to Analyze</span>
        
        <div className={toggleSelectedData?"drop_container":"drop_container active"}>
          <div className="tabs">
            <div className={activeTab === 1 ? "tab active" : "tab"} onClick={() => handleTabClick(1)} >X-axis</div>
            <div className={activeTab === 2 ? "tab active" : "tab"}  onClick={() => handleTabClick(2)} >Y-axis</div>
            { ShowExtraAxisPaths && <div className={activeTab === 3 ? "tab active" : "tab"}  onClick={() => handleTabClick(3)} >Z-axis</div>}
          </div>
          
          {activeTab === 1 && (
            <div className="my_drop_box" >

            { tableStatus===STATUSES.LOADING?(
                <Loader/>
              ):tableStatus === STATUSES.ERROR? (
                <div>{"Something Went wrong"}</div>
              ):tableStatus === STATUSES.SUCCESS? (
                typeof tableData === "string" ? (
                  <RenderWarning message={`No data found in ${groupNameToShowAttributes}`}/>
                ):
                <>
                  
                  {
                    programProductGroupAttributesHeading.length>0 || programProductAdditionalAttributesHeading.length>0  || programProductSetsAttributesHeading.length>0 ?(
                      <>
                      <div className='heading_header' onClick={() => toggleSection(0)}>
                        Product 
                        <i><FontAwesomeIcon icon={showSection[0] ?faCaretUp :faCaretDown}/> </i>
                      </div>
                      <div className={showSection[0] ? "heading_content open" : "heading_content"}>
                        {programProductGroupAttributesHeading.length>0 && programProductGroupAttributesHeading.map((item, index) => (
                          <div className="_property_option" key={index} onClick={() => handleProgramXAxisSelection(item ,"Product",'group' )} >{item.nametoDisplay}</div>
                        ))}
                        {programProductAdditionalAttributesHeading.length>0 && programProductAdditionalAttributesHeading.map((item, index) => (
                          <div className="_property_option" key={index} onClick={() => handleProgramXAxisSelection(item ,"Product",'additional' )} >{item.nametoDisplay}</div>
                        ))}
                        {programProductSetsAttributesHeading.length>0 && programProductSetsAttributesHeading.map((item, index) => (
                          <div className="_property_option" key={index} onClick={() => handleProgramXAxisSelection(item ,"Product",'sets' )} >{item.nametoDisplay}</div>
                        ))}
                      </div>
                      </>
                    ):null
                  }
                  {
                    programRetailerGroupAttributesHeading.length>0 || programRetailerAdditionalAttributesHeading.length>0 || programRetailerSetsAttributesHeading.length>0 ?(
                      <>
                      <div className='heading_header' onClick={() => toggleSection(1)}>
                        Retailer 
                        <i><FontAwesomeIcon icon={showSection[1] ?faCaretUp :faCaretDown}/> </i>
                      </div>
                      <div className={showSection[1] ? "heading_content open" : "heading_content"}>
                        {programRetailerGroupAttributesHeading.length>0 && programRetailerGroupAttributesHeading.map((item, index) => (
                          <div className="_property_option" key={index} onClick={() => handleProgramXAxisSelection(item ,"Retailer",'group')}  >{item.nametoDisplay}</div>
                        ))}
                        {programRetailerAdditionalAttributesHeading.length>0 && programRetailerAdditionalAttributesHeading.map((item, index) => (
                          <div className="_property_option" key={index} onClick={() => handleProgramXAxisSelection(item ,"Retailer",'additional')}  >{item.nametoDisplay}</div>
                        ))}
                        {programRetailerSetsAttributesHeading.length>0 && programRetailerSetsAttributesHeading.map((item, index) => (
                          <div className="_property_option" key={index} onClick={() => handleProgramXAxisSelection(item ,"Retailer",'sets')}  >{item.nametoDisplay}</div>
                        ))}
                      </div>
                      </>
                    ):null
                  }
                  {
                    programManufacturerGroupAttributesHeading.length>0 || programManufacturerAdditionalAttributesHeading.length>0 ||programManufacturerSetsAttributesHeading.length>0 ?(
                      <>
                      <div className='heading_header' onClick={() => toggleSection(2)}>
                        Manufacturer 
                        <i><FontAwesomeIcon icon={showSection[2] ?faCaretUp :faCaretDown}/> </i>
                      </div>
                      <div className={showSection[2] ? "heading_content open" : "heading_content"}>
                        {programManufacturerGroupAttributesHeading.length>0 && programManufacturerGroupAttributesHeading.map((item, index) => (
                          <div className="_property_option" key={index} onClick={() => handleProgramXAxisSelection(item ,"Manufacturer" , 'group')}  >{item.nametoDisplay}</div>
                        ))}
                        {programManufacturerAdditionalAttributesHeading.length>0 && programManufacturerAdditionalAttributesHeading.map((item, index) => (
                          <div className="_property_option" key={index} onClick={() => handleProgramXAxisSelection(item ,"Manufacturer" , 'additional')}  >{item.nametoDisplay}</div>
                        ))}
                        {programManufacturerSetsAttributesHeading.length>0 && programManufacturerSetsAttributesHeading.map((item, index) => (
                          <div className="_property_option" key={index} onClick={() => handleProgramXAxisSelection(item ,"Manufacturer" , 'sets')}  >{item.nametoDisplay}</div>
                        ))}
                      </div>
                      </>
                    ):null
                  }
                  {
                    programSupplierGroupAttributesHeading.length>0 || programSupplierAdditionalAttributesHeading.length>0 || programSupplierSetsAttributesHeading.length>0 ?(
                      <>
                      <div className='heading_header' onClick={() => toggleSection(3)}>
                      Supplier 
                        <i><FontAwesomeIcon icon={showSection[3] ?faCaretUp :faCaretDown}/> </i>
                      </div>
                      <div className={showSection[3] ? "heading_content open" : "heading_content"}>
                        {programSupplierGroupAttributesHeading.length>0 && programSupplierGroupAttributesHeading.map((item, index) => (
                          <div className="_property_option" key={index} onClick={() => handleProgramXAxisSelection(item ,"Supplier",'group')}  >{item.nametoDisplay}</div>
                        ))}
                        {programSupplierAdditionalAttributesHeading.length>0 && programSupplierAdditionalAttributesHeading.map((item, index) => (
                          <div className="_property_option" key={index} onClick={() => handleProgramXAxisSelection(item ,"Supplier",'additional')}  >{item.nametoDisplay}</div>
                        ))}
                        {programSupplierSetsAttributesHeading.length>0 && programSupplierSetsAttributesHeading.map((item, index) => (
                          <div className="_property_option" key={index} onClick={() => handleProgramXAxisSelection(item ,"Supplier",'sets')}  >{item.nametoDisplay}</div>
                        ))}
                      </div>
                      </>
                    ):null
                  }
                  {
                    programPackagingGroupAttributesHeading.length>0 || programPackagingAdditionalAttributesHeading.length>0 || programPackagingSetsAttributesHeading.length>0?(
                      <>
                      <div className='heading_header' onClick={() => toggleSection(4)}>
                        Packaging 
                        <i><FontAwesomeIcon icon={showSection[4] ?faCaretUp :faCaretDown}/> </i>
                      </div>
                      <div className={showSection[4] ? "heading_content open" : "heading_content"}>
                        {programPackagingGroupAttributesHeading.length>0 && programPackagingGroupAttributesHeading.map((item, index) => (
                          <div className="_property_option" key={index} onClick={() => handleProgramXAxisSelection(item ,"Packaging", 'group')}  >{item.nametoDisplay}</div>
                        ))}
                        {programPackagingAdditionalAttributesHeading.length>0 && programPackagingAdditionalAttributesHeading.map((item, index) => (
                          <div className="_property_option" key={index} onClick={() => handleProgramXAxisSelection(item ,"Packaging", 'additional')}  >{item.nametoDisplay}</div>
                        ))}
                        {programPackagingSetsAttributesHeading.length>0 && programPackagingSetsAttributesHeading.map((item, index) => (
                          <div className="_property_option" key={index} onClick={() => handleProgramXAxisSelection(item ,"Packaging", 'sets')}  >{item.nametoDisplay}</div>
                        ))}
                      </div>
                      </>
                    ):null
                  }
                  {
                    programPromotionGroupAttributesHeading.length>0 || programPromotionAdditionalAttributesHeading.length>0 ||programPromotionSetsAttributesHeading.length>0 ?(
                      <>
                      <div className='heading_header' onClick={() => toggleSection(5)}>
                      Promotion 
                        <i><FontAwesomeIcon icon={showSection[5] ?faCaretUp :faCaretDown}/> </i>
                      </div>
                      <div className={showSection[5] ? "heading_content open" : "heading_content"}>
                        {programPromotionGroupAttributesHeading.length>0 && programPromotionGroupAttributesHeading.map((item, index) => (
                          <div className="_property_option" key={index} onClick={() => handleProgramXAxisSelection(item ,"Promotion", 'group')}  >{item.nametoDisplay}</div>
                        ))}
                        {programPromotionAdditionalAttributesHeading.length>0 && programPromotionAdditionalAttributesHeading.map((item, index) => (
                          <div className="_property_option" key={index} onClick={() => handleProgramXAxisSelection(item ,"Promotion", 'additional')}  >{item.nametoDisplay}</div>
                        ))}
                        {programPromotionSetsAttributesHeading.length>0 && programPromotionSetsAttributesHeading.map((item, index) => (
                          <div className="_property_option" key={index} onClick={() => handleProgramXAxisSelection(item ,"Promotion", 'sets')}  >{item.nametoDisplay}</div>
                        ))}
                      </div>
                      </>
                    ):null
                  }
                  {
                    programRegionGroupAttributesHeading.length>0 || programRegionAdditionalAttributesHeading.length>0 ||programRegionSetsAttributesHeading.length>0  ?(
                      <>
                      <div className='heading_header' onClick={() => toggleSection(6)}>
                        Region 
                        <i><FontAwesomeIcon icon={showSection[6] ?faCaretUp :faCaretDown}/> </i>
                      </div>
                      <div className={showSection[6] ? "heading_content open" : "heading_content"}>
                        {programRegionGroupAttributesHeading.length>0 && programRegionGroupAttributesHeading.map((item, index) => (
                          <div className="_property_option" key={index} onClick={() => handleProgramXAxisSelection(item ,"Region",'group')}  >{item.nametoDisplay}</div>
                        ))}
                        {programRegionAdditionalAttributesHeading.length>0 && programRegionAdditionalAttributesHeading.map((item, index) => (
                          <div className="_property_option" key={index} onClick={() => handleProgramXAxisSelection(item ,"Region",'additional')}  >{item.nametoDisplay}</div>
                        ))}
                        {programRegionSetsAttributesHeading.length>0 && programRegionSetsAttributesHeading.map((item, index) => (
                          <div className="_property_option" key={index} onClick={() => handleProgramXAxisSelection(item ,"Region",'sets')}  >{item.nametoDisplay}</div>
                        ))}
                      </div>
                      </>
                    ):null
                  }
                  {
                    programGroupGroupAttributesHeading.length>0 || programGroupAdditionalAttributesHeading.length>0 ||
                    programGroupSetsAttributesHeading.length>0?(
                      <>
                      <div className='heading_header' onClick={() => toggleSection(7)}>
                        Group 
                        <i><FontAwesomeIcon icon={showSection[7] ?faCaretUp :faCaretDown}/> </i>
                      </div>
                      <div className={showSection[7] ? "heading_content open" : "heading_content"}>
                        {programGroupGroupAttributesHeading.length>0 && programGroupGroupAttributesHeading.map((item, index) => (
                          <div className="_property_option" key={index} onClick={() => handleProgramXAxisSelection(item ,"Group",'group')}  >{item.nametoDisplay}</div>
                        ))}
                        {programGroupAdditionalAttributesHeading.length>0 && programGroupAdditionalAttributesHeading.map((item, index) => (
                          <div className="_property_option" key={index} onClick={() => handleProgramXAxisSelection(item ,"Group",'additional')}  >{item.nametoDisplay}</div>
                        ))}
                        {programGroupSetsAttributesHeading.length>0 && programGroupSetsAttributesHeading.map((item, index) => (
                          <div className="_property_option" key={index} onClick={() => handleProgramXAxisSelection(item ,"Group",'sets')}  >{item.nametoDisplay}</div>
                        ))}
                      </div>
                      </>
                    ):null
                  }

                  {groupAttributesHeading.length > 0 && groupAttributesHeading.map((item, index) => (
                    <div className="_property_option" key={index} onClick={() => handleXAxisSelection(item, 'group')} >{item.nametoDisplay}</div>
                  ))}
                  {additionalAttributesHeading.length > 0 && additionalAttributesHeading.map((item, index) => (
                    <div className="_property_option" key={index} onClick={() => handleXAxisSelection(item, 'additional')} >{item.nametoDisplay}</div>
                  ))}
                  {setsAttributesHeading.length > 0 && setsAttributesHeading.map((item, index) => (
                    <div className="_property_option" key={index} onClick={() => handleXAxisSelection(item, 'sets')} >{item.nametoDisplay}</div>
                  ))}



                  
                </>

              ):null

            }
            
            </div>
          )}
          {activeTab === 2 && (
            <div className="my_drop_box" >
             { tableStatus===STATUSES.LOADING?(
                <Loader/>
              ):tableStatus === STATUSES.ERROR? (
                <div>{"Something Went wrong"}</div>
              ):tableStatus === STATUSES.SUCCESS? (
                typeof tableData === "string" ? (
                  <RenderWarning message={`No data found in ${groupNameToShowAttributes}`}/>
                ):
                <>


                  {
                    programProductGroupAttributesHeading.length>0 || programProductAdditionalAttributesHeading.length>0 || programProductSetsAttributesHeading.length>0 ?(
                      <>
                      <div className='heading_header' onClick={() => toggleSection(0)}>
                        Product 
                        <i><FontAwesomeIcon icon={showSection[0] ?faCaretUp :faCaretDown}/> </i>
                      </div>
                      <div className={showSection[0] ? "heading_content open" : "heading_content"}>
                        {programProductGroupAttributesHeading.length>0 && programProductGroupAttributesHeading.map((item, index) => (
                          <div className="_property_option" key={index} onClick={() => handleProgramYAxisSelection(item ,"Product", 'group')} >{item.nametoDisplay}</div>
                        ))}
                        {programProductAdditionalAttributesHeading.length>0 && programProductAdditionalAttributesHeading.map((item, index) => (
                          <div className="_property_option" key={index} onClick={() => handleProgramYAxisSelection(item ,"Product", 'additional')} >{item.nametoDisplay}</div>
                        ))}
                        {programProductSetsAttributesHeading.length>0 && programProductSetsAttributesHeading.map((item, index) => (
                          <div className="_property_option" key={index} onClick={() => handleProgramYAxisSelection(item ,"Product", 'sets')} >{item.nametoDisplay}</div>
                        ))}
                      </div>
                      </>
                    ):null
                  }
                  {
                    programRetailerGroupAttributesHeading.length>0 || programRetailerAdditionalAttributesHeading.length>0 || programRetailerSetsAttributesHeading.length>0 ?(
                      <>
                      <div className='heading_header' onClick={() => toggleSection(1)}>
                        Retailer 
                        <i><FontAwesomeIcon icon={showSection[1] ?faCaretUp :faCaretDown}/> </i>
                      </div>
                      <div className={showSection[1] ? "heading_content open" : "heading_content"}>
                        {programRetailerGroupAttributesHeading.length>0 && programRetailerGroupAttributesHeading.map((item, index) => (
                          <div className="_property_option" key={index} onClick={() => handleProgramYAxisSelection(item ,"Retailer" ,'group')}  >{item.nametoDisplay}</div>
                        ))}
                        {programRetailerAdditionalAttributesHeading.length>0 && programRetailerAdditionalAttributesHeading.map((item, index) => (
                          <div className="_property_option" key={index} onClick={() => handleProgramYAxisSelection(item ,"Retailer" ,'additional')}  >{item.nametoDisplay}</div>
                        ))}
                        {programRetailerSetsAttributesHeading.length>0 && programRetailerSetsAttributesHeading.map((item, index) => (
                          <div className="_property_option" key={index} onClick={() => handleProgramYAxisSelection(item ,"Retailer" ,'sets')}  >{item.nametoDisplay}</div>
                        ))}
                      </div>
                      </>
                    ):null
                  }
                  {
                    programManufacturerGroupAttributesHeading.length>0 || programManufacturerAdditionalAttributesHeading.length>0 ||programManufacturerSetsAttributesHeading.length>0?(
                      <>
                      <div className='heading_header' onClick={() => toggleSection(2)}>
                        Manufacturer 
                        <i><FontAwesomeIcon icon={showSection[2]?faCaretUp :faCaretDown}/> </i>
                      </div>
                      <div className={showSection[2] ? "heading_content open" : "heading_content"}>
                        {programManufacturerGroupAttributesHeading.length>0 && programManufacturerGroupAttributesHeading.map((item, index) => (
                          <div className="_property_option" key={index} onClick={() => handleProgramYAxisSelection(item ,"Manufacturer", 'group')}  >{item.nametoDisplay}</div>
                        ))}
                        {programManufacturerAdditionalAttributesHeading.length>0 && programManufacturerAdditionalAttributesHeading.map((item, index) => (
                          <div className="_property_option" key={index} onClick={() => handleProgramYAxisSelection(item ,"Manufacturer", 'additional')}  >{item.nametoDisplay}</div>
                        ))}
                        {programManufacturerSetsAttributesHeading.length>0 && programManufacturerSetsAttributesHeading.map((item, index) => (
                          <div className="_property_option" key={index} onClick={() => handleProgramYAxisSelection(item ,"Manufacturer", 'sets')}  >{item.nametoDisplay}</div>
                        ))}
                      </div>
                      </>
                    ):null
                  }
                  {
                    programSupplierGroupAttributesHeading.length>0 || programSupplierAdditionalAttributesHeading.length>0 || programSupplierSetsAttributesHeading.length>0  ?(
                      <>
                      <div className='heading_header' onClick={() => toggleSection(3)}>
                      Supplier 
                        <i><FontAwesomeIcon icon={showSection[3] ?faCaretUp :faCaretDown}/> </i>
                      </div>
                      <div className={showSection[3] ? "heading_content open" : "heading_content"}>
                        {programSupplierGroupAttributesHeading.length>0 && programSupplierGroupAttributesHeading.map((item, index) => (
                          <div className="_property_option" key={index} onClick={() => handleProgramYAxisSelection(item ,"Supplier", 'group')}  >{item.nametoDisplay}</div>
                        ))}
                        {programSupplierAdditionalAttributesHeading.length>0 && programSupplierAdditionalAttributesHeading.map((item, index) => (
                          <div className="_property_option" key={index} onClick={() => handleProgramYAxisSelection(item ,"Supplier", 'additional')}  >{item.nametoDisplay}</div>
                        ))}
                        {programSupplierSetsAttributesHeading.length>0 && programSupplierSetsAttributesHeading.map((item, index) => (
                          <div className="_property_option" key={index} onClick={() => handleProgramYAxisSelection(item ,"Supplier", 'sets')}  >{item.nametoDisplay}</div>
                        ))}
                      </div>
                      </>
                    ):null
                  }
                  {
                    programPackagingGroupAttributesHeading.length>0 || programPackagingAdditionalAttributesHeading.length>0 ||programPackagingSetsAttributesHeading.length>0?(
                      <>
                      <div className='heading_header' onClick={() => toggleSection(4)}>
                        Packaging 
                        <i><FontAwesomeIcon icon={showSection[4] ?faCaretUp :faCaretDown}/> </i>
                      </div>
                      <div className={showSection[4] ? "heading_content open" : "heading_content"}>
                        {programPackagingGroupAttributesHeading.length>0 && programPackagingGroupAttributesHeading.map((item, index) => (
                          <div className="_property_option" key={index} onClick={() => handleProgramYAxisSelection(item ,"Packaging", 'group')}  >{item.nametoDisplay}</div>
                        ))}
                        {programPackagingAdditionalAttributesHeading.length>0 && programPackagingAdditionalAttributesHeading.map((item, index) => (
                          <div className="_property_option" key={index} onClick={() => handleProgramYAxisSelection(item ,"Packaging", 'additional')}  >{item.nametoDisplay}</div>
                        ))}
                        {programPackagingSetsAttributesHeading.length>0 && programPackagingSetsAttributesHeading.map((item, index) => (
                          <div className="_property_option" key={index} onClick={() => handleProgramYAxisSelection(item ,"Packaging", 'sets')}  >{item.nametoDisplay}</div>
                        ))}
                      </div>
                      </>
                    ):null
                  }
                  {
                    programPromotionGroupAttributesHeading.length>0 || programPromotionAdditionalAttributesHeading.length>0 || programPromotionSetsAttributesHeading.length>0 ?(
                      <>
                      <div className='heading_header' onClick={() => toggleSection(5)}>
                      Promotion 
                        <i><FontAwesomeIcon icon={showSection[5] ?faCaretUp :faCaretDown}/> </i>
                      </div>
                      <div className={showSection[5] ? "heading_content open" : "heading_content"}>
                        {programPromotionGroupAttributesHeading.length>0 && programPromotionGroupAttributesHeading.map((item, index) => (
                          <div className="_property_option" key={index} onClick={() => handleProgramYAxisSelection(item ,"Promotion", 'group')}  >{item.nametoDisplay}</div>
                        ))}
                        {programPromotionAdditionalAttributesHeading.length>0 && programPromotionAdditionalAttributesHeading.map((item, index) => (
                          <div className="_property_option" key={index} onClick={() => handleProgramYAxisSelection(item ,"Promotion", 'additional')}  >{item.nametoDisplay}</div>
                        ))}
                        {programPromotionSetsAttributesHeading.length>0 && programPromotionSetsAttributesHeading.map((item, index) => (
                          <div className="_property_option" key={index} onClick={() => handleProgramYAxisSelection(item ,"Promotion", 'sets')}  >{item.nametoDisplay}</div>
                        ))}
                      </div>
                      </>
                    ):null
                  }
                  {
                    programRegionGroupAttributesHeading.length>0 || programRegionAdditionalAttributesHeading.length>0 || programRegionSetsAttributesHeading.length>0?(
                      <>
                      <div className='heading_header' onClick={() => toggleSection(6)}>
                        Region 
                        <i><FontAwesomeIcon icon={showSection[6] ?faCaretUp :faCaretDown}/> </i>
                      </div>
                      <div className={showSection[6] ? "heading_content open" : "heading_content"}>
                        {programRegionGroupAttributesHeading.length>0 && programRegionGroupAttributesHeading.map((item, index) => (
                          <div className="_property_option" key={index} onClick={() => handleProgramYAxisSelection(item ,"Region" ,'group')}  >{item.nametoDisplay}</div>
                        ))}
                        {programRegionAdditionalAttributesHeading.length>0 && programRegionAdditionalAttributesHeading.map((item, index) => (
                          <div className="_property_option" key={index} onClick={() => handleProgramYAxisSelection(item ,"Region" ,'additional')}  >{item.nametoDisplay}</div>
                        ))}
                        {programRegionSetsAttributesHeading.length>0 && programRegionSetsAttributesHeading.map((item, index) => (
                          <div className="_property_option" key={index} onClick={() => handleProgramYAxisSelection(item ,"Region" ,'sets')}  >{item.nametoDisplay}</div>
                        ))}
                      </div>
                      </>
                    ):null
                  }
                  {
                    programGroupGroupAttributesHeading.length>0 || programGroupAdditionalAttributesHeading.length>0 || programGroupSetsAttributesHeading.length>0 ?(
                      <>
                      <div className='heading_header' onClick={() => toggleSection(7)}>
                        Group 
                        <i><FontAwesomeIcon icon={showSection[7] ?faCaretUp :faCaretDown}/> </i>
                      </div>
                      <div className={showSection[7] ? "heading_content open" : "heading_content"}>
                        {programGroupGroupAttributesHeading.length>0 && programGroupGroupAttributesHeading.map((item, index) => (
                          <div className="_property_option" key={index} onClick={() => handleProgramYAxisSelection(item ,"Group" ,'group')}  >{item.nametoDisplay}</div>
                        ))}
                        {programGroupAdditionalAttributesHeading.length>0 && programGroupAdditionalAttributesHeading.map((item, index) => (
                          <div className="_property_option" key={index} onClick={() => handleProgramYAxisSelection(item ,"Group" ,'additional')}  >{item.nametoDisplay}</div>
                        ))}
                        {programGroupSetsAttributesHeading.length>0 && programGroupSetsAttributesHeading.map((item, index) => (
                          <div className="_property_option" key={index} onClick={() => handleProgramYAxisSelection(item ,"Group" ,'sets')}  >{item.nametoDisplay}</div>
                        ))}
                      </div>
                      </>
                    ):null
                  }

                  {groupAttributesHeading.length > 0 && groupAttributesHeading.map((item, index) => (
                    <div className="_property_option" key={index} onClick={() => handleYAxisSelection(item, 'group')}>{item.nametoDisplay}</div>
                  ))}
                  {additionalAttributesHeading.length > 0 && additionalAttributesHeading.map((item, index) => (
                    <div className="_property_option" key={index} onClick={() => handleYAxisSelection(item, 'additional')}>{item.nametoDisplay}</div>
                  ))}
                  {setsAttributesHeading.length > 0 && setsAttributesHeading.map((item, index) => (
                    <div className="_property_option" key={index} onClick={() => handleYAxisSelection(item, 'sets')}>{item.nametoDisplay}</div>
                  ))}
                </>

              ):null

            }
            </div>
          )}

          {ShowExtraAxisPaths &&
          (activeTab === 3 && ( 
            <div className="my_drop_box" >
            { tableStatus===STATUSES.LOADING?(
                <Loader/>
              ):tableStatus === STATUSES.ERROR? (
                <div>{"Something Went wrong"}</div>
              ):tableStatus === STATUSES.SUCCESS? (
                typeof tableData === "string" ? (
                  <RenderWarning message={`No data found in ${groupNameToShowAttributes}`}/>
                ):
                <>


                  {
                    programProductGroupAttributesHeading.length>0 || programProductAdditionalAttributesHeading.length>0 || programProductSetsAttributesHeading.length>0 ?(
                      <>
                      <div className='heading_header' onClick={() => toggleSection(0)}>
                        Product 
                        <i><FontAwesomeIcon icon={showSection[0] ?faCaretUp :faCaretDown}/> </i>
                      </div>
                      <div className={showSection[0] ? "heading_content open" : "heading_content"}>
                        {programProductGroupAttributesHeading.length>0 && programProductGroupAttributesHeading.map((item, index) => (
                          <div className="_property_option" key={index} onClick={() => handleProgramZAxisSelection(item ,"Product", 'group')} >{item.nametoDisplay}</div>
                        ))}
                        {programProductAdditionalAttributesHeading.length>0 && programProductAdditionalAttributesHeading.map((item, index) => (
                          <div className="_property_option" key={index} onClick={() => handleProgramZAxisSelection(item ,"Product", 'additional')} >{item.nametoDisplay}</div>
                        ))}
                        {programProductSetsAttributesHeading.length>0 && programProductSetsAttributesHeading.map((item, index) => (
                          <div className="_property_option" key={index} onClick={() => handleProgramZAxisSelection(item ,"Product", 'sets')} >{item.nametoDisplay}</div>
                        ))}
                      </div>
                      </>
                    ):null
                  }
                  {
                    programRetailerGroupAttributesHeading.length>0 || programRetailerAdditionalAttributesHeading.length>0 || programRetailerSetsAttributesHeading.length>0 ?(
                      <>
                      <div className='heading_header' onClick={() => toggleSection(1)}>
                        Retailer 
                        <i><FontAwesomeIcon icon={showSection[1] ?faCaretUp :faCaretDown}/> </i>
                      </div>
                      <div className={showSection[1] ? "heading_content open" : "heading_content"}>
                        {programRetailerGroupAttributesHeading.length>0 && programRetailerGroupAttributesHeading.map((item, index) => (
                          <div className="_property_option" key={index} onClick={() => handleProgramZAxisSelection(item ,"Retailer" ,'group')}  >{item.nametoDisplay}</div>
                        ))}
                        {programRetailerAdditionalAttributesHeading.length>0 && programRetailerAdditionalAttributesHeading.map((item, index) => (
                          <div className="_property_option" key={index} onClick={() => handleProgramZAxisSelection(item ,"Retailer" ,'additional')}  >{item.nametoDisplay}</div>
                        ))}
                        {programRetailerSetsAttributesHeading.length>0 && programRetailerSetsAttributesHeading.map((item, index) => (
                          <div className="_property_option" key={index} onClick={() => handleProgramZAxisSelection(item ,"Retailer" ,'sets')}  >{item.nametoDisplay}</div>
                        ))}
                      </div>
                      </>
                    ):null
                  }
                  {
                    programManufacturerGroupAttributesHeading.length>0 || programManufacturerAdditionalAttributesHeading.length>0 ||programManufacturerSetsAttributesHeading.length>0?(
                      <>
                      <div className='heading_header' onClick={() => toggleSection(2)}>
                        Manufacturer 
                        <i><FontAwesomeIcon icon={showSection[2]?faCaretUp :faCaretDown}/> </i>
                      </div>
                      <div className={showSection[2] ? "heading_content open" : "heading_content"}>
                        {programManufacturerGroupAttributesHeading.length>0 && programManufacturerGroupAttributesHeading.map((item, index) => (
                          <div className="_property_option" key={index} onClick={() => handleProgramZAxisSelection(item ,"Manufacturer", 'group')}  >{item.nametoDisplay}</div>
                        ))}
                        {programManufacturerAdditionalAttributesHeading.length>0 && programManufacturerAdditionalAttributesHeading.map((item, index) => (
                          <div className="_property_option" key={index} onClick={() => handleProgramZAxisSelection(item ,"Manufacturer", 'additional')}  >{item.nametoDisplay}</div>
                        ))}
                        {programManufacturerSetsAttributesHeading.length>0 && programManufacturerSetsAttributesHeading.map((item, index) => (
                          <div className="_property_option" key={index} onClick={() => handleProgramZAxisSelection(item ,"Manufacturer", 'sets')}  >{item.nametoDisplay}</div>
                        ))}
                      </div>
                      </>
                    ):null
                  }
                  {
                    programSupplierGroupAttributesHeading.length>0 || programSupplierAdditionalAttributesHeading.length>0 || programSupplierSetsAttributesHeading.length>0  ?(
                      <>
                      <div className='heading_header' onClick={() => toggleSection(3)}>
                      Supplier 
                        <i><FontAwesomeIcon icon={showSection[3] ?faCaretUp :faCaretDown}/> </i>
                      </div>
                      <div className={showSection[3] ? "heading_content open" : "heading_content"}>
                        {programSupplierGroupAttributesHeading.length>0 && programSupplierGroupAttributesHeading.map((item, index) => (
                          <div className="_property_option" key={index} onClick={() => handleProgramZAxisSelection(item ,"Supplier", 'group')}  >{item.nametoDisplay}</div>
                        ))}
                        {programSupplierAdditionalAttributesHeading.length>0 && programSupplierAdditionalAttributesHeading.map((item, index) => (
                          <div className="_property_option" key={index} onClick={() => handleProgramZAxisSelection(item ,"Supplier", 'additional')}  >{item.nametoDisplay}</div>
                        ))}
                        {programSupplierSetsAttributesHeading.length>0 && programSupplierSetsAttributesHeading.map((item, index) => (
                          <div className="_property_option" key={index} onClick={() => handleProgramZAxisSelection(item ,"Supplier", 'sets')}  >{item.nametoDisplay}</div>
                        ))}
                      </div>
                      </>
                    ):null
                  }
                  {
                    programPackagingGroupAttributesHeading.length>0 || programPackagingAdditionalAttributesHeading.length>0 ||programPackagingSetsAttributesHeading.length>0?(
                      <>
                      <div className='heading_header' onClick={() => toggleSection(4)}>
                        Packaging 
                        <i><FontAwesomeIcon icon={showSection[4] ?faCaretUp :faCaretDown}/> </i>
                      </div>
                      <div className={showSection[4] ? "heading_content open" : "heading_content"}>
                        {programPackagingGroupAttributesHeading.length>0 && programPackagingGroupAttributesHeading.map((item, index) => (
                          <div className="_property_option" key={index} onClick={() => handleProgramZAxisSelection(item ,"Packaging", 'group')}  >{item.nametoDisplay}</div>
                        ))}
                        {programPackagingAdditionalAttributesHeading.length>0 && programPackagingAdditionalAttributesHeading.map((item, index) => (
                          <div className="_property_option" key={index} onClick={() => handleProgramZAxisSelection(item ,"Packaging", 'additional')}  >{item.nametoDisplay}</div>
                        ))}
                        {programPackagingSetsAttributesHeading.length>0 && programPackagingSetsAttributesHeading.map((item, index) => (
                          <div className="_property_option" key={index} onClick={() => handleProgramZAxisSelection(item ,"Packaging", 'sets')}  >{item.nametoDisplay}</div>
                        ))}
                      </div>
                      </>
                    ):null
                  }
                  {
                    programPromotionGroupAttributesHeading.length>0 || programPromotionAdditionalAttributesHeading.length>0 || programPromotionSetsAttributesHeading.length>0 ?(
                      <>
                      <div className='heading_header' onClick={() => toggleSection(5)}>
                      Promotion 
                        <i><FontAwesomeIcon icon={showSection[5] ?faCaretUp :faCaretDown}/> </i>
                      </div>
                      <div className={showSection[5] ? "heading_content open" : "heading_content"}>
                        {programPromotionGroupAttributesHeading.length>0 && programPromotionGroupAttributesHeading.map((item, index) => (
                          <div className="_property_option" key={index} onClick={() => handleProgramZAxisSelection(item ,"Promotion", 'group')}  >{item.nametoDisplay}</div>
                        ))}
                        {programPromotionAdditionalAttributesHeading.length>0 && programPromotionAdditionalAttributesHeading.map((item, index) => (
                          <div className="_property_option" key={index} onClick={() => handleProgramZAxisSelection(item ,"Promotion", 'additional')}  >{item.nametoDisplay}</div>
                        ))}
                        {programPromotionSetsAttributesHeading.length>0 && programPromotionSetsAttributesHeading.map((item, index) => (
                          <div className="_property_option" key={index} onClick={() => handleProgramZAxisSelection(item ,"Promotion", 'sets')}  >{item.nametoDisplay}</div>
                        ))}
                      </div>
                      </>
                    ):null
                  }
                  {
                    programRegionGroupAttributesHeading.length>0 || programRegionAdditionalAttributesHeading.length>0 || programRegionSetsAttributesHeading.length>0?(
                      <>
                      <div className='heading_header' onClick={() => toggleSection(6)}>
                        Region 
                        <i><FontAwesomeIcon icon={showSection[6] ?faCaretUp :faCaretDown}/> </i>
                      </div>
                      <div className={showSection[6] ? "heading_content open" : "heading_content"}>
                        {programRegionGroupAttributesHeading.length>0 && programRegionGroupAttributesHeading.map((item, index) => (
                          <div className="_property_option" key={index} onClick={() => handleProgramZAxisSelection(item ,"Region" ,'group')}  >{item.nametoDisplay}</div>
                        ))}
                        {programRegionAdditionalAttributesHeading.length>0 && programRegionAdditionalAttributesHeading.map((item, index) => (
                          <div className="_property_option" key={index} onClick={() => handleProgramZAxisSelection(item ,"Region" ,'additional')}  >{item.nametoDisplay}</div>
                        ))}
                        {programRegionSetsAttributesHeading.length>0 && programRegionSetsAttributesHeading.map((item, index) => (
                          <div className="_property_option" key={index} onClick={() => handleProgramZAxisSelection(item ,"Region" ,'sets')}  >{item.nametoDisplay}</div>
                        ))}
                      </div>
                      </>
                    ):null
                  }
                  {
                    programGroupGroupAttributesHeading.length>0 || programGroupAdditionalAttributesHeading.length>0 || programGroupSetsAttributesHeading.length>0 ?(
                      <>
                      <div className='heading_header' onClick={() => toggleSection(7)}>
                        Group 
                        <i><FontAwesomeIcon icon={showSection[7] ?faCaretUp :faCaretDown}/> </i>
                      </div>
                      <div className={showSection[7] ? "heading_content open" : "heading_content"}>
                        {programGroupGroupAttributesHeading.length>0 && programGroupGroupAttributesHeading.map((item, index) => (
                          <div className="_property_option" key={index} onClick={() => handleProgramZAxisSelection(item ,"Group" ,'group')}  >{item.nametoDisplay}</div>
                        ))}
                        {programGroupAdditionalAttributesHeading.length>0 && programGroupAdditionalAttributesHeading.map((item, index) => (
                          <div className="_property_option" key={index} onClick={() => handleProgramZAxisSelection(item ,"Group" ,'additional')}  >{item.nametoDisplay}</div>
                        ))}
                        {programGroupSetsAttributesHeading.length>0 && programGroupSetsAttributesHeading.map((item, index) => (
                          <div className="_property_option" key={index} onClick={() => handleProgramZAxisSelection(item ,"Group" ,'sets')}  >{item.nametoDisplay}</div>
                        ))}
                      </div>
                      </>
                    ):null
                  }

                  {groupAttributesHeading.length > 0 && groupAttributesHeading.map((item, index) => (
                    <div className="_property_option" key={index} onClick={() => handleZAxisSelection(item, 'group')}>{item.nametoDisplay}</div>
                  ))}
                  {additionalAttributesHeading.length > 0 && additionalAttributesHeading.map((item, index) => (
                    <div className="_property_option" key={index} onClick={() => handleZAxisSelection(item, 'additional')}>{item.nametoDisplay}</div>
                  ))}
                  {setsAttributesHeading.length > 0 && setsAttributesHeading.map((item, index) => (
                    <div className="_property_option" key={index} onClick={() => handleZAxisSelection(item, 'sets')}>{item.nametoDisplay}</div>
                  ))}
                </>

              ):null

            }
            </div>
          ))
          }

          
                      


        </div>

        <div className={toggleSelectedData ? "shortlisted_data_box active" : "shortlisted_data_box"}>
            <div className='shortlisted_heading'>Selected Data</div>
            <i className="close_btn" style={{left:0}} onClick={()=> setToggleSelectedData(!toggleSelectedData)}><FontAwesomeIcon icon={toggleSelectedData ? faAngleUp :faAngleDown} /></i>   
            
            <div className="tabs">
              <div className={activeTab === 1 ? "tab active" : "tab"} onClick={() => handleTabClick(1)} >X-axis</div>
              <div className={activeTab === 2 ? "tab active" : "tab"} onClick={() => handleTabClick(2)} >Y-axis</div>
              { ShowExtraAxisPaths && <div className={activeTab === 3 ? "tab active" : "tab"} onClick={() => handleTabClick(3)} >Z-axis</div>}
            </div>


            {activeTab === 1 && (
              <div className='shortlisted_data_container'>
              {xAxisValues.length > 0 || xAxisProgramProductValues.length>0 || xAxisProgramRetailerValues.length > 0 || xAxisProgramManufacturerValues.length > 0 ||xAxisProgramSupplierValues.length > 0 || xAxisProgramPackagingValues.length > 0 || xAxisProgramPromotionValues.length > 0 ||xAxisProgramRegionValues.length > 0 || xAxisProgramGroupValues.length > 0 ? (
                <>
                
                {/* {xAxisValues?.map((item, index) => (
                    <div key={index} className="shortlisted_data" >{item.nametoDisplay} <i onClick={() => removeFromXAxis(item)}><FontAwesomeIcon icon={faMultiply} /></i> </div>
                ))} */}

                <AttributeLists list={xAxisValues}  removeItem ={removeFromXAxis}  updateDisplayValue={(index, newValue) => updateDisplayValue(setXAxisValues, xAxisValues, index, newValue)} fromRemoveValue = ""/>

                {/* {xAxisProgramProductValues?.map((item, index) => (
                    <div key={index} className="shortlisted_data" >{item.nametoDisplay} <i onClick={() => removeFromProgramXAxisSelection(item ,"Product")}><FontAwesomeIcon icon={faMultiply} /></i> </div>
                ))} */}

                <AttributeLists list={xAxisProgramProductValues}  removeItem ={removeFromProgramXAxisSelection}  updateDisplayValue={(index, newValue) => updateDisplayValue(setXaxisProgramProductValues, xAxisProgramProductValues, index, newValue)} fromRemoveValue = "Product"/>

                {/* {xAxisProgramRetailerValues?.map((item, index) => (
                    <div key={index} className="shortlisted_data" >{item.nametoDisplay} <i onClick={() => removeFromProgramXAxisSelection(item ,"Retailer")}><FontAwesomeIcon icon={faMultiply} /></i> </div>
                ))} */}

                <AttributeLists list={xAxisProgramRetailerValues}  removeItem ={removeFromProgramXAxisSelection}  updateDisplayValue={(index, newValue) => updateDisplayValue(setXaxisProgramRetailerValues, xAxisProgramRetailerValues, index, newValue)} fromRemoveValue = "Retailer"/>

                {/* {xAxisProgramManufacturerValues?.map((item, index) => (
                    <div key={index} className="shortlisted_data" >{item.nametoDisplay} <i onClick={() => removeFromProgramXAxisSelection(item ,"Manufacturer")}><FontAwesomeIcon icon={faMultiply} /></i> </div>
                ))} */}
                
                <AttributeLists list={xAxisProgramManufacturerValues}  removeItem ={removeFromProgramXAxisSelection}  updateDisplayValue={(index, newValue) => updateDisplayValue(setXaxisProgramManufacturerValues, xAxisProgramManufacturerValues, index, newValue)} fromRemoveValue = "Manufacturer"/>

                {/* {xAxisProgramSupplierValues?.map((item, index) => (
                    <div key={index} className="shortlisted_data" >{item.nametoDisplay} <i onClick={() => removeFromProgramXAxisSelection(item , "Supplier")}><FontAwesomeIcon icon={faMultiply} /></i> </div>
                ))} */}

                <AttributeLists list={xAxisProgramSupplierValues}  removeItem ={removeFromProgramXAxisSelection}  updateDisplayValue={(index, newValue) => updateDisplayValue(setXaxisProgramSupplierValues, xAxisProgramSupplierValues, index, newValue)} fromRemoveValue = "Supplier"/>

                {xAxisProgramPackagingValues?.map((item, index) => (
                    <div key={index} className="shortlisted_data" >{item.nametoDisplay} <i onClick={() => removeFromProgramXAxisSelection(item ,"Packaging")}><FontAwesomeIcon icon={faMultiply} /></i> </div>
                ))}
                {xAxisProgramPromotionValues?.map((item, index) => (
                    <div key={index} className="shortlisted_data" >{item.nametoDisplay} <i onClick={() => removeFromProgramXAxisSelection(item ,"Promotion")}><FontAwesomeIcon icon={faMultiply} /></i> </div>
                ))}
                {xAxisProgramRegionValues?.map((item, index) => (
                    <div key={index} className="shortlisted_data" >{item.nametoDisplay} <i onClick={() => removeFromProgramXAxisSelection(item ,"Region")}><FontAwesomeIcon icon={faMultiply} /></i> </div>
                ))}
                {xAxisProgramGroupValues?.map((item, index) => (
                    <div key={index} className="shortlisted_data" >{item.nametoDisplay} <i onClick={() => removeFromProgramXAxisSelection(item ,"Group")}><FontAwesomeIcon icon={faMultiply} /></i> </div>
                ))}

                </>
                ) : (
                    <div className='no_shortlisted_data'>No values selected for X Axis</div>
                )}
              </div>
            )}
            
            {activeTab === 2 && (
              <div className='shortlisted_data_container'>
              {yAxisValues.length > 0 || yAxisProgramProductValues.length>0 || yAxisProgramRetailerValues.length > 0 || yAxisProgramManufacturerValues.length > 0 || yAxisProgramSupplierValues.length > 0 || yAxisProgramPackagingValues.length > 0 || yAxisProgramPromotionValues.length > 0 || yAxisProgramRegionValues.length > 0 || yAxisProgramGroupValues.length > 0 ? (
                <>
                
                {/* {yAxisValues?.map((item, index) => (
                    <div key={index} className="shortlisted_data" >{item.nametoDisplay} <i onClick={() => removeFromYAxis(item)}><FontAwesomeIcon icon={faMultiply} /></i> </div>
                ))} */}

                <AttributeLists list={yAxisValues}  removeItem ={removeFromYAxis}  updateDisplayValue={(index, newValue) => updateDisplayValue(setYAxisValues, yAxisValues, index, newValue)} fromRemoveValue = ""/>

                {/* {yAxisProgramProductValues?.map((item, index) => (
                    <div key={index} className="shortlisted_data" >{item.nametoDisplay} <i onClick={() => removeFromProgramYAxisSelection(item ,"Product")}><FontAwesomeIcon icon={faMultiply} /></i> </div>
                ))} */}
                
                <AttributeLists list={yAxisProgramProductValues}  removeItem ={removeFromProgramYAxisSelection}  updateDisplayValue={(index, newValue) => updateDisplayValue(setYaxisProgramProductValues, yAxisProgramProductValues, index, newValue)} fromRemoveValue = "Product"/>

                {/* {yAxisProgramRetailerValues?.map((item, index) => (
                    <div key={index} className="shortlisted_data" >{item.nametoDisplay} <i onClick={() => removeFromProgramYAxisSelection(item ,"Retailer")}><FontAwesomeIcon icon={faMultiply} /></i> </div>
                ))} */}

                <AttributeLists list={yAxisProgramRetailerValues}  removeItem ={removeFromProgramYAxisSelection}  updateDisplayValue={(index, newValue) => updateDisplayValue(setYaxisProgramRetailerValues, yAxisProgramRetailerValues, index, newValue)} fromRemoveValue = "Retailer"/>

                {/* {yAxisProgramManufacturerValues?.map((item, index) => (
                    <div key={index} className="shortlisted_data" >{item.nametoDisplay} <i onClick={() => removeFromProgramYAxisSelection(item ,"Manufacturer")}><FontAwesomeIcon icon={faMultiply} /></i> </div>
                ))} */}

                
                <AttributeLists list={yAxisProgramManufacturerValues}  removeItem ={removeFromProgramYAxisSelection}  updateDisplayValue={(index, newValue) => updateDisplayValue(setYaxisProgramManufacturerValues, yAxisProgramManufacturerValues, index, newValue)} fromRemoveValue = "Manufacturer"/>

                {/* {yAxisProgramSupplierValues?.map((item, index) => (
                    <div key={index} className="shortlisted_data" >{item.nametoDisplay} <i onClick={() => removeFromProgramYAxisSelection(item , "Supplier")}><FontAwesomeIcon icon={faMultiply} /></i> </div>
                ))} */}

                                
                <AttributeLists list={yAxisProgramSupplierValues}  removeItem ={removeFromProgramYAxisSelection}  updateDisplayValue={(index, newValue) => updateDisplayValue(setYaxisProgramSupplierValues, yAxisProgramSupplierValues, index, newValue)} fromRemoveValue = "Supplier"/>

                {yAxisProgramPackagingValues?.map((item, index) => (
                    <div key={index} className="shortlisted_data" >{item.nametoDisplay} <i onClick={() => removeFromProgramYAxisSelection(item ,"Packaging")}><FontAwesomeIcon icon={faMultiply} /></i> </div>
                ))}
                {yAxisProgramPromotionValues?.map((item, index) => (
                    <div key={index} className="shortlisted_data" >{item.nametoDisplay} <i onClick={() => removeFromProgramYAxisSelection(item ,"Promotion")}><FontAwesomeIcon icon={faMultiply} /></i> </div>
                ))}
                {yAxisProgramRegionValues?.map((item, index) => (
                    <div key={index} className="shortlisted_data" >{item.nametoDisplay} <i onClick={() => removeFromProgramYAxisSelection(item ,"Region")}><FontAwesomeIcon icon={faMultiply} /></i> </div>
                ))}
                {yAxisProgramGroupValues?.map((item, index) => (
                    <div key={index} className="shortlisted_data" >{item.nametoDisplay} <i onClick={() => removeFromProgramYAxisSelection(item ,"Group")}><FontAwesomeIcon icon={faMultiply} /></i> </div>
                ))}

                </>
                ) : (
                    <div className='no_shortlisted_data'>No values selected for Y Axis</div>
                )}
              </div>
            )}

            {
              ShowExtraAxisPaths && 
              (activeTab === 3 && (
                <div className='shortlisted_data_container'>
                {zAxisValues.length > 0 || zAxisProgramProductValues.length>0 || zAxisProgramRetailerValues.length > 0 || zAxisProgramManufacturerValues.length > 0 || zAxisProgramSupplierValues.length > 0 || zAxisProgramPackagingValues.length > 0 || zAxisProgramPromotionValues.length > 0 || zAxisProgramRegionValues.length > 0 || zAxisProgramGroupValues.length > 0 ? (
                  <>
                  
                  {zAxisValues?.map((item, index) => (
                      <div key={index} className="shortlisted_data" >{item.nametoDisplay} <i onClick={() => removeFromZAxis(item)}><FontAwesomeIcon icon={faMultiply} /></i> </div>
                  ))}
                  {zAxisProgramProductValues?.map((item, index) => (
                      <div key={index} className="shortlisted_data" >{item.nametoDisplay} <i onClick={() => removeFromProgramZAxisSelection(item ,"Product")}><FontAwesomeIcon icon={faMultiply} /></i> </div>
                  ))}
                  {zAxisProgramRetailerValues?.map((item, index) => (
                      <div key={index} className="shortlisted_data" >{item.nametoDisplay} <i onClick={() => removeFromProgramZAxisSelection(item ,"Retailer")}><FontAwesomeIcon icon={faMultiply} /></i> </div>
                  ))}
                  {zAxisProgramManufacturerValues?.map((item, index) => (
                      <div key={index} className="shortlisted_data" >{item.nametoDisplay} <i onClick={() => removeFromProgramZAxisSelection(item ,"Manufacturer")}><FontAwesomeIcon icon={faMultiply} /></i> </div>
                  ))}
                  {zAxisProgramSupplierValues?.map((item, index) => (
                      <div key={index} className="shortlisted_data" >{item.nametoDisplay} <i onClick={() => removeFromProgramZAxisSelection(item , "Supplier")}><FontAwesomeIcon icon={faMultiply} /></i> </div>
                  ))}
                  {zAxisProgramPackagingValues?.map((item, index) => (
                      <div key={index} className="shortlisted_data" >{item.nametoDisplay} <i onClick={() => removeFromProgramZAxisSelection(item ,"Packaging")}><FontAwesomeIcon icon={faMultiply} /></i> </div>
                  ))}
                  {zAxisProgramPromotionValues?.map((item, index) => (
                      <div key={index} className="shortlisted_data" >{item.nametoDisplay} <i onClick={() => removeFromProgramZAxisSelection(item ,"Promotion")}><FontAwesomeIcon icon={faMultiply} /></i> </div>
                  ))}
                  {zAxisProgramRegionValues?.map((item, index) => (
                      <div key={index} className="shortlisted_data" >{item.nametoDisplay} <i onClick={() => removeFromProgramZAxisSelection(item ,"Region")}><FontAwesomeIcon icon={faMultiply} /></i> </div>
                  ))}
                  {zAxisProgramGroupValues?.map((item, index) => (
                      <div key={index} className="shortlisted_data" >{item.nametoDisplay} <i onClick={() => removeFromProgramZAxisSelection(item ,"Group")}><FontAwesomeIcon icon={faMultiply} /></i> </div>
                  ))}

                  </>
                  ) : (
                      <div className='no_shortlisted_data'>No values selected for Z Axis</div>
                  )}
                </div>
              ))
            }

        </div>

    </div>


    {toggle &&<div className={toggleChooseContainer ? "choose_container" : "choose_container active"}>
        <i onClick={()=>setToggleChooseContainer(!toggleChooseContainer)}><FontAwesomeIcon icon={faAngleRight}/></i>
        <h6>Select the data to analyze</h6>
        <select className="choose_select" onChange={HandleSelectedTable} value={selectTable}>
          <option className="select_step_value" value="">Select to Analyze</option>
          {DataMainHeading.map((item, index) => (
            <option className="select_step_value" value={item} key={index}>{item}</option>
          ))}
        </select>

        {selectTable && (
          <select
            className="choose_select"
            onChange={handleGroupSelection}
            value={groupNameToShowAttributes}
            required
          >
            <option value="" className="select_step_value" >Select Group</option>
            {AttributeGroupStatus === STATUSES.LOADING ? (
              <option className="select_step_value"  value="" >Loading...</option>
            ) : AttributeGroupStatus === STATUSES.ERROR ? (
              <option className="select_step_value"  value=""  >Something went wrong</option>
            ) : AttributeGroupStatus === STATUSES.SUCCESS ? (
              AttributesGroupsData.length === 0 ? (
                <option className="select_step_value" value="" >No Groups found</option>
              ) : (
                AttributesGroupsData.map((item, index) => (
                  <option value={item.groupName} key={item.groupId} className="select_step_value" >
                    {item.groupName}
                  </option>
                ))
              )
            ) : null}
          </select>
        )}

        {
          selectTable === "Program" && groupNameToShowAttributes && (
            <select
            className="choose_select"
            onChange={handleProductSelection}
            value={productGroupNameForProgram}
            required
          >
            <option value="" className="select_step_value" >Select Product Group</option>
            {AttributeGroupStatus === STATUSES.LOADING ? (
              <option className="select_step_value"  value="" >Loading...</option>
            ) : AttributeGroupStatus === STATUSES.ERROR ? (
              <option className="select_step_value"  value=""  >Something went wrong</option>
            ) : AttributeGroupStatus === STATUSES.SUCCESS ? (
              AttributesGroupsData.length === 0 ? (
                <option className="select_step_value" value="" >No Groups found</option>
              ) : (
                AttributesGroupsData.map((item, index) => {
                  if (item.groupName === groupNameToShowAttributes && item.productType && item.productType.productGroup) {
                    return item.productType.productGroup.map((product, index) => (
                      <option value={product} className='select_step_value' key={index}>{product}</option>
                    ));
                  }
                  return null;
                })
              )
            ) : null}
          </select>
          )
        }
                
        
    </div>}

    </>
    }
    </>
  )
}

export default DataSelection;













const DataSelectionButton = ({ toggle, setToggle }) => {

  return (
    <>
    { !toggle && <i className="data_selection_toggle_btn" onClick={()=> setToggle(!toggle)}><FontAwesomeIcon icon={faCaretDown} /><span>Select data</span> </i> }
    </>
  )
}

export {DataSelectionButton};



const AttributeLists = ({ list, removeItem, updateDisplayValue , fromRemoveValue}) => {
  const [openDropdown, setOpenDropdown] = useState(null);

  const handleDropdownOpen = (index) => {
    setOpenDropdown(openDropdown === index ? null : index);
  };

  const handleValueChange = (index, value) => {
    updateDisplayValue(index, value);
    setOpenDropdown(null);
  };

  const closeDropdown = () => {
    setOpenDropdown(null);
  };

  return (
    <>
      {list?.map((item, index) => (
        <div key={index} className="shortlisted_data">
          {item.nametoDisplay} 
          <i onClick={() => removeItem( item, fromRemoveValue)}>
            <FontAwesomeIcon icon={faMultiply} />
          </i> 
          {/* <button onClick={() => handleDropdownOpen(index)}>
          </button> */}
          {(
            <div className="dropdown">
              <select
                value={item.DisplayValue}
                onChange={(e) => handleValueChange(index, e.target.value)}
                style={{ backgroundColor: 'green' }}
              >
                {
                  item.type === "String" ? (
                    <>
                      <option value="count">Count</option>
                      <option value="text">Text</option>
                    </>
                  ) : 
                  (
                    <>
                    <option value="sum">Sum</option>
                    <option value="avg">Avg</option>
                    <option value="min">Min</option>
                    <option value="max">Max</option>
                    <option value="count">Count</option>
                    </>
                  )
                }
              </select>
              {/* <button onClick={closeDropdown}>Close</button> */}
            </div>
          )}
        </div>
      ))}
    </>
  );
};

export  {AttributeLists};
import React ,{ useEffect, useRef, useState } from "react";
import "./FilterBox.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
// import {t_data ,table_data} from '../../Data.js';
import { useSelector,useDispatch } from "react-redux";
import { getAllGroup, getAllManufacturer, getAllPackaging, getAllProduct, getAllPromotion, getAllRegion, getAllRetailer, getAllSupplier } from "../../../../store/Slices/marketMapTableSlices.js";
import { getAllGroupAttributes, getAllManufacturerAttributes, getAllPackagingAttributes, getAllProductAttributes, getAllProgramAttributes, getAllPromotionAttributes, getAllRegionAttributes, getAllRetailerAttributes, getAllSupplierAttributes, } from "../../../../store/Slices/marketMapAttributeSlice.js";
import STATUSES from "../../../../store/Statuses.js";
import {addToshortedData} from '../../../../store/Slices/marketMapShortedDataSlice.js'
import { getAllProgram } from "../../../../store/Slices/marketMapProgram.js";


const FilterBox = ({ toggle_filter_box }) => {
  const dispatch = useDispatch();

  const resetFilter = () => {
    // Add your logic to reset filters here
  };
    // Initialize dropdownRef as an array
  const dropdownRef = useRef([]);
  const [groupCustomSelectOpen, setGroupCustomSelectOpen] = useState({});
  const [additionalCustomSelectOpen, setAdditionalCustomSelectOpen] = useState({});
  
  // Toggle dropdown open/close state for groupSelectedValues
  const customSelectToggleGroupDropdown = (index) => {
    setGroupCustomSelectOpen(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };
  
  // Toggle dropdown open/close state for additionalSelectedValues
  const customSelectToggleAdditionalDropdown = (index) => {
    setAdditionalCustomSelectOpen(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };
  
  // Handle click outside of dropdowns
  const handleClickOutside = (event) => {
    for (let i = 0; i < groupSelectedValues.length; i++) {
      if (dropdownRef.current[i] && !dropdownRef.current[i].contains(event.target)) {
        setGroupCustomSelectOpen(prevState => ({
          ...prevState,
          [i]: false
        }));
      }
    }
    for (let i = 0; i < additionalSelectedValues.length; i++) {
      if (dropdownRef.current[i] && !dropdownRef.current[i].contains(event.target)) {
        setAdditionalCustomSelectOpen(prevState => ({
          ...prevState,
          [i]: false
        }));
      }
    }
  };
  
  // Effect to add event listener when component mounts
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  const DataMainHeading = ["Product", "Retailer", "Manufacturer", "Supplier", "Packaging Detail", "Region", "Customized Group"];

  const [selectTable, setSelectTable] = useState('');

  useEffect(() => {
    if(selectTable !== ''){
      getData(selectTable);
    }
  }, [dispatch,selectTable]);

 
  const HandleSelectedTable = (event) => {
    setSelectTable(event.target.value);
    setGroupAttributesHeading({});
    setAdditionalAttributesHeading({});
    setGroupSelectedValues([]);
    setAdditionalSelectedValues([]);

    setGroupSelectedFilters([]);
    setAdditionalSelectedFilters([]);
    setFilteredData([])
  };


  const getData = (selectTable) => {
    switch (selectTable) {
      case "Product":
        dispatch(getAllProduct({}));
        dispatch(getAllProductAttributes({}));
        break;
      case "Retailer":
        dispatch(getAllRetailer({}));
        dispatch(getAllRetailerAttributes({}));
        break;
      case "Manufacturer":
        dispatch(getAllManufacturer({}));
        dispatch(getAllManufacturerAttributes({}));
        break;
      case "Supplier":
        dispatch(getAllSupplier({}));
        dispatch(getAllSupplierAttributes({}));
        break;
      case "Program":
        dispatch(getAllProgram({}));
        dispatch(getAllProgramAttributes({}));
        break;
      case "Packaging Detail":
        dispatch(getAllPackaging({}));
        dispatch(getAllPackagingAttributes({}));
        break;
      case "Marketing Callout":
        dispatch(getAllPromotion({}));
        dispatch(getAllPromotionAttributes({}));
        break;
      case "Region":
        dispatch(getAllRegion({}));
        dispatch(getAllRegionAttributes({}));
        break;
      case "Customized Group":
        dispatch(getAllGroup({}));
        dispatch(getAllGroupAttributes({}));
        break;
      default:
        break;
    }
  };

  
  // Fetch data status and error for selected table
  const { data :tableData, status :tableStatus, error:tableError} = useSelector((state) => {
    switch (selectTable) {
      case "Product":
        return state.AllProducts;
      case "Retailer":
        return state.AllRetailers;
      case "Manufacturer":
        return state.AllManufacturers;
      case "Supplier":
        return state.AllSuppliers;
      case "Program":
        return state.AllPrograms;
      case "Packaging Detail":
        return state.AllPackagings;
      case "Marketing Callout":
        return state.AllPromotions;
      case "Region":
        return state.AllRegions;
      case "Customized Group":
        return state.AllGroups;
      default:
        return {};
    }
  });
  
  // Fetch data status and error for selected table
  const { data:AllAttributesGroupsData ,  status:AllAttributeGroupStatus , error:AllAttributeGroupError  } = useSelector((state) => {
    switch (selectTable) {
      case "Product":
        return state.AllProductAttributeGroup;
      case "Retailer":
        return state.AllRetailerAttributeGroup;
      case "Manufacturer":
        return state.AllManufacturerAttributeGroup;
      case "Supplier":
        return state.AllSupplierAttributeGroup;
      case "Program":
        return state.AllProgramAttributeGroup;
      case "Packaging Detail":
        return state.AllPackagingAttributeGroup;
      case "Marketing Callout":
        return state.AllPromotionAttributeGroup;
      case "Region":
        return state.AllRegionAttributeGroup;
      case "Customized Group":
        return state.AllGroupAttributeGroup;
      default:
        return {};
    }
  });


  // console.log(AllAttributesGroupsData)
  // console.log(tableData)


  const [groupAttributesHeading, setGroupAttributesHeading] = useState({});
  const [additionalAttributesHeading, setAdditionalAttributesHeading] = useState({});

  useEffect(() => {
    if (AllAttributesGroupsData?.length>0) {
      const groupAttrs = {};
      const AdditionalAttrs = {};

      AllAttributesGroupsData.forEach((group) => {
        switch (selectTable) {
          case "Product":
             Object.assign(groupAttrs, group.groupAttributes);
             Object.assign(AdditionalAttrs, group.productAdditionalAttributes);
             break;
          case "Retailer":
             Object.assign(groupAttrs, group.groupAttributes);
             Object.assign(AdditionalAttrs, group.retailerAdditionalAttributes);
             break;
          case "Manufacturer":
             Object.assign(groupAttrs, group.groupAttributes);
             Object.assign(AdditionalAttrs, group.manufacturerAdditionalAttributes);
             break;
          case "Supplier":
             Object.assign(groupAttrs, group.groupAttributes);
             Object.assign(AdditionalAttrs, group.supplierAdditionalAttributes);
             break;
          case "Program":
             Object.assign(groupAttrs, group.groupAttributes);
             Object.assign(AdditionalAttrs, group.programAdditionalAttributes);
             break;
          case "Packaging Detail":
             Object.assign(groupAttrs, group.groupAttributes);
             Object.assign(AdditionalAttrs, group.packagingAdditionalAttributes);
             break;
          case "Marketing Callout":
             Object.assign(groupAttrs, group.groupAttributes);
             Object.assign(AdditionalAttrs, group.productAdditionalAttributes);
             break;
          case "Region":
             Object.assign(groupAttrs, group.groupAttributes);
             Object.assign(AdditionalAttrs, group.regionAdditionalAttributes);
             break;
          case "Customized Group":
             Object.assign(groupAttrs, group.groupAttributes);
             Object.assign(AdditionalAttrs, group.groupAdditionalAttributes);
             break;
          default:
            break;
        }
      });
      setGroupAttributesHeading(groupAttrs);
      setAdditionalAttributesHeading(AdditionalAttrs);
    }
  }, [AllAttributesGroupsData, selectTable]);

  // console.log(groupAttributesHeading)
  // console.log(additionalAttributesHeading)
  const OnRemovingTable = ()=>{
    setGroupAttributesHeading({});
    setAdditionalAttributesHeading({});
    setSelectTable("");
    setGroupSelectedValues([]);
    setAdditionalSelectedValues([]);
    setGroupSelectedFilters([]);
    setAdditionalSelectedFilters([]);
    setFilteredData([])
  }

  const [groupSelectedValues, setGroupSelectedValues] = useState([]);
  const [additionalSelectedValues, setAdditionalSelectedValues] = useState([]);

  const handleSelectHeading = (e) => {
    const selectedValue = e.target.value;
    const attributeNametoDisplay = e.target.options[e.target.selectedIndex].textContent;
  
    // Check if the value is already selected in either groupSelectedValues or additionalSelectedValues
    const isValueAlreadySelected = (selectedValue, selectedValues) => {
      return selectedValues.some(item => item.value === selectedValue);
    };
  
    if (groupAttributesHeading.hasOwnProperty(selectedValue)) {
      if (!isValueAlreadySelected(selectedValue, groupSelectedValues)) {
        setGroupSelectedValues([...groupSelectedValues, { value: selectedValue, nametoDisplay: attributeNametoDisplay }]);
      }
    } else if (additionalAttributesHeading.hasOwnProperty(selectedValue)) {
      if (!isValueAlreadySelected(selectedValue, additionalSelectedValues)) {
        setAdditionalSelectedValues([...additionalSelectedValues, { value: selectedValue, nametoDisplay: attributeNametoDisplay }]);
      }
    }
  };
  
  const handleRemoveGroupSelectedValues = (indexToRemove) => {
    setGroupSelectedValues(groupSelectedValues.filter((_, index) => index !== indexToRemove));
    setGroupSelectedFilters(prevFilters => {
      const newFilters = [...prevFilters];
      // Remove the filter at the specified index
      newFilters.splice(indexToRemove, 1);
      return newFilters;
    });
  };
  const handleRemoveAdditionalSelectedValues = (indexToRemove) => {
    setAdditionalSelectedValues(additionalSelectedValues.filter((_, index) => index !== indexToRemove));
    setAdditionalSelectedFilters(prevFilters => {
      const newFilters = [...prevFilters];
      // Remove the filter at the specified index
      newFilters.splice(indexToRemove, 1);
      return newFilters;
    });
  };

  // console.log(groupSelectedValues);
  // console.log(additionalSelectedValues);


  const [groupSelectedFilters, setGroupSelectedFilters] = useState([]);
  const [additionalSelectedFilters, setAdditionalSelectedFilters] = useState([]);
  const handleOptionSelect = (index, value, isAdditional = false) => {
    if (isAdditional) {
        setAdditionalSelectedFilters(prevFilters => {
            const newFilters = [...prevFilters];
            const existingFilter = newFilters[index];
            if (existingFilter) {
                // Check if the value already exists in the values array
                if (!existingFilter.values.includes(value)) {
                    // If not, add the value to the values array
                    newFilters[index] = {
                        ...existingFilter,
                        values: [...existingFilter.values, value]
                    };
                }
            } else {
                // If the filter doesn't exist, create a new one
                newFilters[index] = {
                    key: additionalSelectedValues[index].value,
                    values: [value]
                };
            }
            return newFilters;
        });
        setAdditionalCustomSelectOpen(prevState => {
          const newState = { ...prevState };
          Object.keys(newState).forEach(key => {
              newState[key] = false;
          });
          newState[index] = true;
          return newState;
      });
    } else {
        setGroupSelectedFilters(prevFilters => {
            const newFilters = [...prevFilters];
            const existingFilter = newFilters[index];
            if (existingFilter) {
                // Check if the value already exists in the values array
                if (!existingFilter.values.includes(value)) {
                    // If not, add the value to the values array
                    newFilters[index] = {
                        ...existingFilter,
                        values: [...existingFilter.values, value]
                    };
                }
            } else {
                // If the filter doesn't exist, create a new one
                newFilters[index] = {
                    key: groupSelectedValues[index].value,
                    values: [value]
                };
            }
            return newFilters;
        });
        setGroupCustomSelectOpen(prevState => {
          const newState = { ...prevState };
          Object.keys(newState).forEach(key => {
              newState[key] = false;
          });
          newState[index] = true;
          return newState;
      });
    }
    // Close the dropdown after selecting an option
    // customSelectToggleDropdown(index);
  };
  
  // console.log(groupSelectedFilters);
  // console.log(additionalSelectedFilters);


  const [filteredData, setFilteredData] = useState([]);

  const filterData = () => {
      if (tableData && tableData.length > 0 ) {
          const filteredData = tableData.filter(item => {
              let meetsAllGroupConditions = true;
              let meetsAllAdditionalConditions = true;

              groupSelectedFilters&& groupSelectedFilters.length>0 && groupSelectedFilters.forEach(filter => {
                  if (
                      filter.key in item &&
                      item[filter.key] !== null &&
                      item[filter.key] !== undefined &&
                      !filter.values.some(val => val.toLowerCase() === item[filter.key].toLowerCase())
                  ) {
                      meetsAllGroupConditions = false;
                  }
              });

              additionalSelectedFilters && additionalSelectedFilters.length>0 && additionalSelectedFilters.forEach(filter => {
                  if (
                      item.groupAttributes &&
                      filter.key in item.groupAttributes &&
                      item.groupAttributes[filter.key] !== null &&
                      item.groupAttributes[filter.key] !== undefined &&
                      !filter.values.some(val => val.toLowerCase() === item.groupAttributes[filter.key].toLowerCase())
                  ) {
                      meetsAllAdditionalConditions = false;
                  }
              });

              return meetsAllGroupConditions && meetsAllAdditionalConditions;
          });

          setFilteredData(filteredData);
          // console.log(filteredData);
          return filteredData;
      } else {
          // console.log('tableData, groupSelectedFilters, or additionalSelectedFilters are missing!');
          return [];
      }
  };


  // Update filtered data whenever tableData or groupSelectedFilters change
  useEffect(() => {
    filterData();
  }, [tableData, groupSelectedFilters ,additionalSelectedFilters]);

  // console.log("filteredData" , filteredData);

  const [newData, setNewData] = useState([]);
  const AllNewFilteredData = ( )=>{
    const updatedData = filteredData.length>0 && filteredData.map(item => {
      const newItem = {};
      // Map additionalAttributesHeading
      additionalAttributesHeading && Object.keys(additionalAttributesHeading).length>0 && Object.keys(item.groupAttributes).forEach(key => {
        if (key in additionalAttributesHeading) {
          const displayName = additionalAttributesHeading[key].nametoDisplay;
          newItem[displayName] = item.groupAttributes[key];
        }
      });
      // Map groupAttributesHeading
      groupAttributesHeading && Object.keys(groupAttributesHeading).length>0 && Object.keys(item).forEach(key => {
        if (key in groupAttributesHeading) {
          const displayName = groupAttributesHeading[key].nametoDisplay;
          newItem[displayName] = item[key];
        }
      });
      
      return newItem;
    });
    setNewData(updatedData);
  }

  useEffect(()=>{
    AllNewFilteredData();
  },[filteredData, groupAttributesHeading, additionalAttributesHeading]);

  // console.log("newData" , newData);

  useEffect(()=>{
    if(newData.length>0){
      dispatch(addToshortedData(newData));
    }
  },[newData]);

  return (
    <div className="filter_box">
      <div className="col1">
        <h6>Select Filter</h6>
        <div className="box1">
          <select className="filter_select" onChange={HandleSelectedTable} value={selectTable}>
            <option className="select_options" value="">Select the filter</option>
            {DataMainHeading.map((item, index) => (
              <option className="select_options" value={item} key={index}>{item}</option>
            ))}
          </select>
        </div>
      </div>

      {selectTable.length > 0 &&
        <div className="col1">
          <h6>{selectTable}</h6>
          <div className="box1">
            <select className="filter_select" onChange={handleSelectHeading}>
              <option className="select_options" value="">Select From here</option>
              {
                tableData && tableData.length==0 ? (
                  <option className="select_options" value="">No data Found</option>
                ) : tableData && tableData.length >0 ? (
                 <>
                   
                   {Object.entries(groupAttributesHeading)
                    .filter(([_, attribute]) => attribute.type === 'String')
                    .map(([attributeName, attribute]) => (
                      <option key={attributeName} value={attributeName}> {attribute.nametoDisplay} </option>
                    ))}
                  {Object.entries(additionalAttributesHeading)
                    .filter(([_, attribute]) => attribute.type === 'String')
                    .map(([attributeName, attribute]) => (
                      <option key={attributeName} value={attributeName}> {attribute.nametoDisplay} </option>
                    ))}
        
                 </>
                ) : null
              }
            </select>
            <i>
              <FontAwesomeIcon icon={faTrash} className="filter_icon" onClick={OnRemovingTable} />
            </i>
          </div>
        </div>
      }
   
   
      {groupSelectedValues.map((item, index) => (
        <div className="col1" key={index}>
          <h6>{item.nametoDisplay}</h6>
          <div className="box1">
            <div className="custom-dropdown" ref={ref => dropdownRef.current[index] = ref}>
              <div className="selected-option" onClick={() => customSelectToggleGroupDropdown(index)}>
                {'Select an option'}
              </div>
              {groupCustomSelectOpen[index] && (
                <ul className="options">
                  {tableData.length > 0 && [
                    ...new Set(
                      tableData
                        .map(product => product[item.value])
                        .filter(value => value !== undefined && value !== null && value.trim() !== "")
                        .map(value => value.trim().toLowerCase())
                    )
                  ].map((value, i) => (
                    <li
                      key={i}
                      onClick={() => handleOptionSelect(index, value)}
                    >
                      {value}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <i>
              <FontAwesomeIcon icon={faTrash} className="filter_icon" onClick={() => handleRemoveGroupSelectedValues(index)} />
            </i>
          </div>
        </div>
        ))
      }


      {additionalSelectedValues.map((item, index) => (
        <div className="col1" key={index}>
          <h6>{item.nametoDisplay}</h6>
          <div className="box1">
            <div className="custom-dropdown" ref={ref => dropdownRef.current[index] = ref}>
              <div className="selected-option" onClick={() => customSelectToggleAdditionalDropdown(index)}>
                {'Select an option'}
              </div>
              {additionalCustomSelectOpen[index] && (
                <ul className="options">
                  {tableData.length > 0 && [
                    ...new Set(
                      tableData
                        .map(product => product.groupAttributes[item.value])
                        .filter(value => value !== undefined && value !== null && value.trim() !== "")
                        .map(value => value.trim().toLowerCase())
                    )
                  ].map((value, i) => (
                    <li
                      key={i}
                      onClick={() => handleOptionSelect(index, value, true)} // Pass true to indicate additional selected values
                    >
                      {value}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <i>
              <FontAwesomeIcon icon={faTrash} className="filter_icon" onClick={() => handleRemoveAdditionalSelectedValues(index)} />
            </i>
          </div>
        </div>
      ))}


      <div className="btn_group">
        <button className="common_btn" style={{ width: '30%', }}>Apply</button>
        <button className="common_btn2" style={{ width: '30%', }} onClick={resetFilter}>Reset</button>
        <button className="common_btn3" style={{ width: '30%', }} onClick={toggle_filter_box}>Close</button>
      </div>
    </div>
  );
};

export default FilterBox;


import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from 'axios';
import STATUSES from '../Statuses';
import config from '../../config';

const serviceURL0 = config.SERVICE_0_URL;


// ========================== Create program ================================ 
const createProgramSlice = createSlice({
    name : "createProgram",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetProgramState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(createProgram.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })

        .addCase(createProgram.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })

        .addCase(createProgram.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
});



// Create Program 
export const createProgram = createAsyncThunk( "program/create", async ( data, { rejectWithValue ,getState })=>{
    try {
        
        const state = getState();
        const token = state.auth.token; 
        const config = { 
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
            params: {groupName:data.groupName ,className:data.className}
        };

        const responce = await axios.post(`${serviceURL0}/programs/saveProgram`,data, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})





// ========================== Get All program ================================ 
const getAllProgramSlice = createSlice({
    name : "getAllProgram",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetGetAllProgramState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(getAllProgram.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })
        
        .addCase(getAllProgram.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })

        .addCase(getAllProgram.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
});

// Get all Programs 
export const getAllProgram = createAsyncThunk( "program/getAll", async ( className ,{ rejectWithValue ,getState })=>{
    try {
        
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const responce = await axios.get(`${serviceURL0}/programs/getAllPrograms/${className}`, config);
        return responce.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})



const updateProgramSlice = createSlice({
    name : "ProgramUpdate",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetUpdateProgramState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(updateProgram.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })
        
        .addCase(updateProgram.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })
        
        .addCase(updateProgram.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
}); 

export const updateProgram = createAsyncThunk("Program/update", async (data, { rejectWithValue ,getState }) => {
    try {
        const { className, programId, ...restData } = data; // Destructure and exclude className and programId
        
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};;

        const response = await axios.put(`${serviceURL0}/programs/updateProgram/${className}/${programId}`, restData, config);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});


const getOneProgramSlice = createSlice({
    name : "getOneProgram",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    extraReducers : (builder)=>{
        builder
        .addCase(getOneProgram.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })
        
        .addCase(getOneProgram.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })

        .addCase(getOneProgram.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
});

// Get One Programs 
export const getOneProgram = createAsyncThunk( "program/getOne", async ( data,{ rejectWithValue ,getState })=>{
    try {
        
        const state = getState();
        const token = state.auth.token; 
        const config = { 
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" } ,
            params: {className : data.className  ,programId: data.programId}
        };

        const responce = await axios.get(`${serviceURL0}/programs/getProgram`, config);
        return responce.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})


// Delete Program == Program delete API
const deleteProgramSlice = createSlice({
    name : "deleteProgram",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetDeleteProgramState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(deleteProgram.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })

        .addCase(deleteProgram.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })

        .addCase(deleteProgram.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
});
export const deleteProgram = createAsyncThunk( "Program/delete", async ( data, { rejectWithValue ,getState })=>{
    try {
        
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};
        const responce = await axios.delete(`${serviceURL0}/programs/deleteProgram/${data.className}/${data.programId}`,config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})




// ========================== Add retailer In Program  ================================ 
const addRetailerInProgramSlice = createSlice({
    name : "addRetailerInProgram",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetaddRetailerInProgramState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(addRetailerInProgram.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })

        .addCase(addRetailerInProgram.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })

        .addCase(addRetailerInProgram.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
});

// Add retailer in program 
export const addRetailerInProgram = createAsyncThunk( "program/AddRetailer", async ( {programId, retailerId , retailerClassName ,programClassName},{ rejectWithValue ,getState })=>{
    try {
        
        const state = getState();
        const token = state.auth.token; 
        const config = { 
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
            params: {retailerClassName ,programClassName}
        };

        const responce = await axios.post(`${serviceURL0}/programs/${programId}/addRetailer/${retailerId}`,null, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});






// ========================== Add Manufacturer In Program  ================================ 
const addManufacturerInProgramSlice = createSlice({
    name : "addManufacturerInProgram",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetaddManufacturerInProgramState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(addManufacturerInProgram.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })

        .addCase(addManufacturerInProgram.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })

        .addCase(addManufacturerInProgram.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
});

// Add Manufacturer in program 
export const addManufacturerInProgram = createAsyncThunk( "program/AddManufacturer", async ( {programId, manufacturerId , manufacturerClassName,programClassName} ,{ rejectWithValue ,getState })=>{
    try {
        
        const state = getState();
        const token = state.auth.token; 
        const config = {
             headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
            params: {manufacturerClassName  ,programClassName },
        };

        const responce = await axios.post(`${serviceURL0}/programs/${programId}/addManufacturer/${manufacturerId}`,null, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});










// ========================== Add Supplier In Program  ================================ 
const addSupplierInProgramSlice = createSlice({
    name : "addSupplierInProgram",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetaddSupplierInProgramState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(addSupplierInProgram.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })

        .addCase(addSupplierInProgram.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })

        .addCase(addSupplierInProgram.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
});

// Add Supplier in program 
export const addSupplierInProgram = createAsyncThunk( "program/AddSupplier", async ( {programId, supplierId ,supplierClassName,programClassName}, { rejectWithValue ,getState })=>{
    try {
        
        const state = getState();
        const token = state.auth.token; 
        const config = { 
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
            params: {supplierClassName  ,programClassName}
        };

        const responce = await axios.post(`${serviceURL0}/programs/${programId}/addSupplier/${supplierId}`,null, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});






// ========================== Add Product In Program  ================================ 
const addProductInProgramSlice = createSlice({
    name : "addProductInProgram",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetaddProductInProgramState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(addProductInProgram.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })

        .addCase(addProductInProgram.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })

        .addCase(addProductInProgram.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
});

// Add Product in program 
export const addProductInProgram = createAsyncThunk( "program/AddProduct", async ( {programId, productId, productClassName ,programClassName} ,{ rejectWithValue ,getState })=>{
    try {
        
        const state = getState();
        const token = state.auth.token; 
        const config = { 
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
            params: {productClassName ,programClassName}
        };

        const responce = await axios.post(`${serviceURL0}/programs/${programId}/addProduct/${productId}`,null, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});




// ========================== Add Packaging In Program  ================================ 
const addPackagingInProgramSlice = createSlice({
    name : "addPackagingInProgram",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetaddPackagingInProgramState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(addPackagingInProgram.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })

        .addCase(addPackagingInProgram.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })

        .addCase(addPackagingInProgram.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
});

// Add Packaging in program 
export const addPackagingInProgram = createAsyncThunk( "program/AddPackaging", async ( {programId, packagingId ,packagingClassName ,programClassName},{ rejectWithValue ,getState })=>{
    try {
        
        const state = getState();
        const token = state.auth.token; 
        const config = { 
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
            params: {packagingClassName ,programClassName}
        };

        const responce = await axios.post(`${serviceURL0}/programs/${programId}/addPackaging/${packagingId}`,null, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});





// ========================== Add Promotion In Program  ================================ 
const addPromotionInProgramSlice = createSlice({
    name : "addPromotionInProgram",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetaddPromotionInProgramState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(addPromotionInProgram.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })

        .addCase(addPromotionInProgram.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })

        .addCase(addPromotionInProgram.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
});

// Add Promotion in program 
export const addPromotionInProgram = createAsyncThunk( "program/AddPromotion", async ( {programId, promotionId ,promotionClassName ,programClassName} ,{ rejectWithValue ,getState })=>{
    try {
        
        const state = getState();
        const token = state.auth.token; 
        const config = { 
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
            params: {promotionClassName   ,programClassName}
        };

        const responce = await axios.post(`${serviceURL0}/programs/${programId}/addPromotion/${promotionId}`,null, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});




// ========================== Add Region In Program  ================================ 
const addRegionInProgramSlice = createSlice({
    name : "addRegionInProgram",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetaddRegionInProgramState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(addRegionInProgram.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })

        .addCase(addRegionInProgram.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })

        .addCase(addRegionInProgram.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
});

// Add Region in program 
export const addRegionInProgram = createAsyncThunk( "program/AddRegion", async ( {programId, regionId ,regionClassName ,programClassName} ,{ rejectWithValue ,getState })=>{
    try {
        
        const state = getState();
        const token = state.auth.token; 
        const config = { 
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
            params: {regionClassName ,programClassName}
        };

        const responce = await axios.post(`${serviceURL0}/programs/${programId}/addRegion/${regionId}`,null, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});







// ========================== Add Group In Program  ================================ 
const addGroupInProgramSlice = createSlice({
    name : "addGroupInProgram",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetaddGroupInProgramState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(addGroupInProgram.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })

        .addCase(addGroupInProgram.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })

        .addCase(addGroupInProgram.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
});

// Add Group in program 
export const addGroupInProgram = createAsyncThunk( "program/AddGroup", async ( {programId, groupId, groupClassName , programClassName} ,{ rejectWithValue ,getState })=>{
    try {
        
        const state = getState();
        const token = state.auth.token; 
        const config = {
             headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
             params: {groupClassName   ,programClassName}
        };

        const responce = await axios.post(`${serviceURL0}/programs/${programId}/addGroup/${groupId}`,null, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});




// Delete retailer from program 

const deleteRetailerInProgramSlice = createSlice({
    name : "deleteRetailerInProgram",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetdeleteRetailerInProgramState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(deleteRetailerInProgram.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })

        .addCase(deleteRetailerInProgram.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })

        .addCase(deleteRetailerInProgram.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
});


export const deleteRetailerInProgram = createAsyncThunk( "program/deleteRetailer", async ( {programId, retailerId, programClassName} ,{ rejectWithValue ,getState })=>{
    try {
        
        const state = getState();
        const token = state.auth.token; 
        const config = {
             headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
             params: {programClassName}
        };

        const responce = await axios.delete(`${serviceURL0}/programs/${programId}/removeRetailer/${retailerId}`, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});




// Delete Manufacturer from program 

const deleteManufacturerInProgramSlice = createSlice({
    name : "deleteManufacturerInProgram",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetdeleteManufacturerInProgramState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(deleteManufacturerInProgram.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })

        .addCase(deleteManufacturerInProgram.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })

        .addCase(deleteManufacturerInProgram.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
});


export const deleteManufacturerInProgram = createAsyncThunk( "program/deleteManufacturer", async ( {programId, manufacturerId, programClassName} ,{ rejectWithValue ,getState })=>{
    try {
        
        const state = getState();
        const token = state.auth.token; 
        const config = {
             headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
             params: {programClassName}
        };

        const responce = await axios.delete(`${serviceURL0}/programs/${programId}/removeManufacturer/${manufacturerId}`, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});



// Delete Product from program 

const deleteProductInProgramSlice = createSlice({
    name : "deleteProductInProgram",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetdeleteProductInProgramState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(deleteProductInProgram.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })

        .addCase(deleteProductInProgram.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })

        .addCase(deleteProductInProgram.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
});


export const deleteProductInProgram = createAsyncThunk( "program/deleteProduct", async ( {programId, productId, programClassName} ,{ rejectWithValue ,getState })=>{
    try {
        
        const state = getState();
        const token = state.auth.token; 
        const config = {
             headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
             params: {programClassName}
        };

        const responce = await axios.delete(`${serviceURL0}/programs/${programId}/removeProduct/${productId}`, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});


// Delete Supplier from program 

const deleteSupplierInProgramSlice = createSlice({
    name : "deleteSupplierInProgram",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetdeleteSupplierInProgramState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(deleteSupplierInProgram.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })

        .addCase(deleteSupplierInProgram.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })

        .addCase(deleteSupplierInProgram.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
});


export const deleteSupplierInProgram = createAsyncThunk( "program/deleteSupplier", async ( {programId, supplierId, programClassName} ,{ rejectWithValue ,getState })=>{
    try {
        
        const state = getState();
        const token = state.auth.token; 
        const config = {
             headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
             params: {programClassName}
        };

        const responce = await axios.delete(`${serviceURL0}/programs/${programId}/removeSupplier/${supplierId}`, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});












export {

    createProgramSlice,
    getAllProgramSlice,
    updateProgramSlice,
    getOneProgramSlice,
    deleteProgramSlice,

    addRetailerInProgramSlice,
    addManufacturerInProgramSlice,
    addSupplierInProgramSlice,
    addProductInProgramSlice,
    addPackagingInProgramSlice,
    addPromotionInProgramSlice,
    addRegionInProgramSlice,
    addGroupInProgramSlice,

    deleteRetailerInProgramSlice,
    deleteSupplierInProgramSlice,
    deleteManufacturerInProgramSlice,
    deleteProductInProgramSlice



}


export const {resetProgramState} = createProgramSlice.actions;
export const {resetaddRetailerInProgramState} = addRetailerInProgramSlice.actions;
export const {resetaddManufacturerInProgramState} = addManufacturerInProgramSlice.actions;
export const {resetaddSupplierInProgramState} = addSupplierInProgramSlice.actions;
export const {resetaddProductInProgramState} = addProductInProgramSlice.actions;
export const {resetaddPackagingInProgramState} = addPackagingInProgramSlice.actions;
export const {resetaddPromotionInProgramState} = addPromotionInProgramSlice.actions;
export const {resetaddRegionInProgramState} = addRegionInProgramSlice.actions;
export const {resetaddGroupInProgramState} = addGroupInProgramSlice.actions;


export const {resetUpdateProgramState} = updateProgramSlice.actions;



export const {resetdeleteRetailerInProgramState} = deleteRetailerInProgramSlice.actions;
export const {resetdeleteManufacturerInProgramState} = deleteManufacturerInProgramSlice.actions;
export const {resetdeleteSupplierInProgramState} = deleteSupplierInProgramSlice.actions;
export const {resetdeleteProductInProgramState} = deleteProductInProgramSlice.actions;


export const {resetDeleteProgramState} = deleteProgramSlice.actions

export const {resetGetAllProgramState} = getAllProgramSlice.actions;
;





import React from "react";
// import ScrollAnimation from "react-animate-on-scroll";
import { Outlet } from "react-router";
import Header from "../components/HomeComponents/Header";
import Footer from "../components/HomeComponents/Footer";


const Homepage = ()=> {
  return (
    <>
    <Header/>
    <Outlet/>
    <Footer/>
    </>
  );
}

export default Homepage;

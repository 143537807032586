import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from 'axios';
import STATUSES from '../Statuses';
import config from '../../config';

// const serviceURL0 = config.SERVICE_0_URL;
const serviceURL1 = config.SERVICE_1_URL;
const serviceURL2 = config.SERVICE_2_URL;
const serviceURL3 = config.SERVICE_3_URL;
const serviceURL4 = config.SERVICE_4_URL;
const serviceURL5 = config.SERVICE_5_URL;
const serviceURL6 = config.SERVICE_6_URL;
const serviceURL7 = config.SERVICE_7_URL;
const serviceURL8 = config.SERVICE_8_URL;




// Delete Product == Product delete API
const deleteProductSlice = createSlice({
    name : "deleteProduct",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetDeleteProductState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(deleteProduct.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })

        .addCase(deleteProduct.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })

        .addCase(deleteProduct.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
});
export const deleteProduct = createAsyncThunk( "Product/delete", async ( data, { rejectWithValue ,getState })=>{
    try {
        
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};
        const responce = await axios.delete(`${serviceURL1}/product/deleteProduct/${data.className}/${data.productId}`,config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

// update Product ==  Product update API
const updateProductSlice = createSlice({
    name : "ProductUpdate",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetUpdateProductState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(updateProduct.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })
        
        .addCase(updateProduct.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })
        
        .addCase(updateProduct.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
}); 
export const updateProduct = createAsyncThunk( "Product/update", async (  data , { rejectWithValue ,getState })=>{
    try {
        const { className, productId, ...restData } = data; 
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const responce = await axios.put(`${serviceURL1}/product/updateProduct/${className}/${productId}`,restData, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})







// Delete Retailer == Retailer delete API
const deleteRetailerSlice = createSlice({
    name : "deleteRetailer",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetDeleteRetailerState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(deleteRetailer.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })

        .addCase(deleteRetailer.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })

        .addCase(deleteRetailer.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
});
export const deleteRetailer = createAsyncThunk( "Retailer/delete", async ( data, { rejectWithValue ,getState })=>{
    try {
        
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};
        const responce = await axios.delete(`${serviceURL2}/retailers/deleteRetailer/${data.className}/${data.retailerId}`,config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})


// update Retailer ==  Retailer update API
const updateRetailerSlice = createSlice({
    name : "RetailerUpdate",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetUpdateRetailerState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(updateRetailer.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })
        
        .addCase(updateRetailer.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })
        
        .addCase(updateRetailer.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
}); 
export const updateRetailer = createAsyncThunk( "Retailer/update", async (  data , { rejectWithValue ,getState })=>{
    try {
        const { className, retailerId, ...restData } = data; 
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const responce = await axios.put(`${serviceURL2}/retailers/updateRetailer/${className}/${retailerId}`,restData, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})







// Delete Manufacturer == Manufacturer delete API
const deleteManufacturerSlice = createSlice({
    name : "deleteManufacturer",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetDeleteManufacturerState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(deleteManufacturer.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })

        .addCase(deleteManufacturer.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })

        .addCase(deleteManufacturer.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
});
export const deleteManufacturer = createAsyncThunk( "Manufacturer/delete", async ( data, { rejectWithValue ,getState })=>{
    try {
        
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};
        const responce = await axios.delete(`${serviceURL3}/manufacturers/deleteManufacturer/${data.className}/${data.manufacturerId}`,config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})


// update Manufacturer ==  Manufacturer update API
const updateManufacturerSlice = createSlice({
    name : "ManufacturerUpdate",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetUpdateManufacturerState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(updateManufacturer.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })
        
        .addCase(updateManufacturer.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })
        
        .addCase(updateManufacturer.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
}); 
export const updateManufacturer = createAsyncThunk( "Manufacturer/update", async (  data , { rejectWithValue ,getState })=>{
    try {
        const { className, manufacturerId, ...restData } = data; 
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const responce = await axios.put(`${serviceURL3}/manufacturers/updateManufacturer/${className}/${manufacturerId}`,restData, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})







// Delete Supplier == Supplier delete API
const deleteSupplierSlice = createSlice({
    name : "deleteSupplier",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetDeleteSupplierState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(deleteSupplier.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })

        .addCase(deleteSupplier.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })

        .addCase(deleteSupplier.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
});
export const deleteSupplier = createAsyncThunk( "Supplier/delete", async ( data, { rejectWithValue ,getState })=>{
    try {
        
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};
        const responce = await axios.delete(`${serviceURL4}/suppliers/deleteSupplier/${data.className}/${data.supplierId}`,config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})


// update Supplier ==  Supplier update API
const updateSupplierSlice = createSlice({
    name : "SupplierUpdate",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetUpdateSupplierState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(updateSupplier.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })
        
        .addCase(updateSupplier.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })
        
        .addCase(updateSupplier.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
}); 
export const updateSupplier = createAsyncThunk( "Supplier/update", async (  data , { rejectWithValue ,getState })=>{
    try {
        const { className, supplierId, ...restData } = data; 
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const responce = await axios.put(`${serviceURL4}/suppliers/updateSupplier/${className}/${supplierId}`,restData, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})








// Delete Packaging == Packaging delete API
const deletePackagingSlice = createSlice({
    name : "deletePackaging",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetDeletePackagingState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(deletePackaging.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })

        .addCase(deletePackaging.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })

        .addCase(deletePackaging.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
});
export const deletePackaging = createAsyncThunk( "Packaging/delete", async ( data, { rejectWithValue ,getState })=>{
    try {
        
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};
        const responce = await axios.delete(`${serviceURL5}/packagings/deletePackaging/${data.className}/${data.packagingId}`,config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

// update Packaging ==  Packaging update API
const updatePackagingSlice = createSlice({
    name : "PackagingUpdate",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetUpdatePackagingState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(updatePackaging.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })
        
        .addCase(updatePackaging.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })
        
        .addCase(updatePackaging.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
}); 
export const updatePackaging = createAsyncThunk( "Packaging/update", async (  data , { rejectWithValue ,getState })=>{
    try {
        const { className, packagingId, ...restData } = data; 
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const responce = await axios.put(`${serviceURL5}/packagings/updatePackaging/${className}/${packagingId}`,restData, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})








// Delete Promotion == Promotion delete API
const deletePromotionSlice = createSlice({
    name : "deletePromotion",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetDeletePromotionState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(deletePromotion.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })

        .addCase(deletePromotion.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })

        .addCase(deletePromotion.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
});
export const deletePromotion = createAsyncThunk( "Promotion/delete", async ( data, { rejectWithValue ,getState })=>{
    try {
        
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};
        const responce = await axios.delete(`${serviceURL6}/promotions/deletePromotion/${data.className}/${data.promotionId}`,config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

// update Promotion ==  Promotion update API
const updatePromotionSlice = createSlice({
    name : "PromotionUpdate",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetUpdatePromotionState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(updatePromotion.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })
        
        .addCase(updatePromotion.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })
        
        .addCase(updatePromotion.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
}); 
export const updatePromotion = createAsyncThunk( "Promotion/update", async (  data , { rejectWithValue ,getState })=>{
    try {
        const { className, promotionId, ...restData } = data; 
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const responce = await axios.put(`${serviceURL6}/promotions/updatePromotion/${className}/${promotionId}`,restData, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})






// Delete Region == Region delete API
const deleteRegionSlice = createSlice({
    name : "deleteRegion",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetDeleteRegionState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(deleteRegion.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })

        .addCase(deleteRegion.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })

        .addCase(deleteRegion.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
});
export const deleteRegion = createAsyncThunk( "Region/delete", async ( data, { rejectWithValue ,getState })=>{
    try {
        
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};
        const responce = await axios.delete(`${serviceURL7}/regions/deleteRegion/${data.className}/${data.regionId}`,config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})


// update Region ==  Region update API
const updateRegionSlice = createSlice({
    name : "RegionUpdate",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetUpdateRegionState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(updateRegion.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })
        
        .addCase(updateRegion.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })
        
        .addCase(updateRegion.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
}); 
export const updateRegion = createAsyncThunk( "Region/update", async (  data , { rejectWithValue ,getState })=>{
    try {
        const { className, regionId, ...restData } = data; 
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const responce = await axios.put(`${serviceURL7}/regions/updateRegion/${className}/${regionId}`,restData, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})








// Delete Group == Group delete API
const deleteGroupSlice = createSlice({
    name : "deleteGroup",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetDeleteGroupState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(deleteGroup.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })

        .addCase(deleteGroup.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })

        .addCase(deleteGroup.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
});
export const deleteGroup = createAsyncThunk( "Group/delete", async ( data, { rejectWithValue ,getState })=>{
    try {
        
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};
        const responce = await axios.delete(`${serviceURL8}/group/deleteGroup/${data.className}/${data.groupId}`,config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

// update Group ==  Group update API
const updateGroupSlice = createSlice({
    name : "GroupUpdate",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetUpdateGroupState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(updateGroup.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })
        
        .addCase(updateGroup.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })
        
        .addCase(updateGroup.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
}); 
export const updateGroup = createAsyncThunk( "Group/update", async (  data , { rejectWithValue ,getState })=>{
    try {
        const { className, groupId, ...restData } = data; 
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const responce = await axios.put(`${serviceURL8}/group/updateGroup/${className}/${groupId}`,restData, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})












export {
    deleteProductSlice,
    updateProductSlice,

    deleteRetailerSlice,
    updateRetailerSlice,
    
    deleteManufacturerSlice,   
    updateManufacturerSlice,

    deleteSupplierSlice,
    updateSupplierSlice,
   
    deletePackagingSlice,
    updatePackagingSlice,
   
    deletePromotionSlice,
    updatePromotionSlice,
  
    deleteRegionSlice, 
    updateRegionSlice,
 
    deleteGroupSlice,
    updateGroupSlice,
  
}



export const {resetUpdateProductState} = updateProductSlice.actions;
export const {resetUpdateRetailerState} = updateRetailerSlice.actions;
export const {resetUpdateManufacturerState} = updateManufacturerSlice.actions;
export const {resetUpdateSupplierState} = updateSupplierSlice.actions;
export const {resetUpdatePackagingState} = updatePackagingSlice.actions;
export const {resetUpdatePromotionState} = updatePromotionSlice.actions;
export const {resetUpdateRegionState} = updateRegionSlice.actions;
export const {resetUpdateGroupState} = updateGroupSlice.actions;



export const {resetDeleteProductState} = deleteProductSlice.actions;
export const {resetDeleteRetailerState} = deleteRetailerSlice.actions;
export const {resetDeleteManufacturerState} = deleteManufacturerSlice.actions;
export const {resetDeleteSupplierState} = deleteSupplierSlice.actions;
export const {resetDeletePackagingState} = deletePackagingSlice.actions;
export const {resetDeletePromotionState} = deletePromotionSlice.actions;
export const {resetDeleteRegionState} = deleteRegionSlice.actions;
export const {resetDeleteGroupState} = deleteGroupSlice.actions;

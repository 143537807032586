import React, { useEffect, useState } from "react";
import "./Login.css";
import login_img from "../../../assets/Images/Sign up-amico 1.png";
import trident_logo from "../../../assets/Images/image80.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { toast } from 'react-toastify';
import { useDispatch } from "react-redux";
import { resetUserRegister, userRegister } from "../../../store/Slices/userAuthSlice";
import { useSelector } from "react-redux";
import STATUSES from "../../../store/Statuses";
import { LoaderCircle } from "../../Common/Loader/Loader";
import { Link} from "react-router-dom";
import eye from "../../../assets/Images/eye.svg";
import eyeOff from "../../../assets/Images/eye.svg";

const SignUp = () => {

  const dispatch = useDispatch();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [userEmail , setUserEmail] = useState("");
  const [userRole ,  setUserRole] = useState("");
  const [userPassword , setUserPassword] = useState("");
  const [userName , setUserName] = useState("");

  const [emailFilled, setEmailFilled] = useState(false);
  const [roleFilled, setRoleFilled] = useState(false);
  const [passwordFilled, setPasswordFilled] = useState(false);
  const [nameFilled, setNameFilled] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const handleSubmit = () => {
    if (!userName) {
      setNameFilled(true);
      return;
    }
    if (!userEmail) {
      setEmailFilled(true);
      return;
    }
    if (!userPassword) {
      setPasswordFilled(true);
      return;
    }
    if (!userRole) {
      setRoleFilled(true);
      return;
    }
    if (userPassword.length < 6) {
      setPasswordError(true);
      return;
    }
    if (userEmail , userPassword , userRole,userName){
      
      console.log(userName, userEmail , userPassword , userRole);
      const data = {name: userName, email: userEmail ,password: userPassword ,role : {id :userRole}}
      dispatch(userRegister(data));
    }
  };


  const {data  , status  ,error} = useSelector((state)=>state.UserRegister);

  useEffect(()=>{
    if (status===STATUSES.SUCCESS) {
      toast.success(data);
      dispatch(resetUserRegister({}));
      setUserEmail("");
      setUserRole("");
      setUserPassword("");
      setUserName("");
      setEmailFilled(false);
      setRoleFilled(false);
      setPasswordFilled(false);
      setNameFilled(false);
      setPasswordError(false);
      console.log("Cleard state and input ")
    }
    else if(status === STATUSES.ERROR){
      toast.error(error ? error : "Something Went Wrong");
      dispatch(resetUserRegister({}));
    }
  }, [data, status ,error,dispatch]);

  console.log("Sign up!!")


  const handleIconClick = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <>
      <div className="login_con">
        <div className="login_box">
          <div className="col2">
            <img src={login_img} alt={"hero"} id="login_img" />
          </div>
          <div className="col1">
            <img src={trident_logo} alt={"trident_logo"} id="trident_logo"/>
            <div className="common_bg login">
              <h3 className="main_heading">Create Account</h3>
              <div className={'login_input_box'}>
                <input className={`input_ ${nameFilled ? 'error' : ''}`} type="text" placeholder="User name" name="userName" value={userName} onChange={(e) => {setUserName(e.target.value); setNameFilled(false); }}/>
                {nameFilled && <div className="error_message">* Name is required</div>}
              </div>
              <div className={'login_input_box'}>
                <input className={`input_ ${emailFilled ? 'error' : ''}`} type="email" placeholder="Email Id" name="userEmail" value={userEmail} onChange={(e) => {setUserEmail(e.target.value); setEmailFilled(false); }}/>
                {emailFilled && <div className="error_message">* Email is required</div>}
              </div>
              <div className={'login_input_box login_input_boxpass'}>
                <input className={`input_ ${passwordFilled ? 'error' : ''}`} type={passwordVisible ? 'text' : 'password'} placeholder="Password" name="userPassword" value={userPassword} onChange={(e) => {
                    setUserPassword(e.target.value);
                    setPasswordFilled(false);
                    if (e.target.value.length >= 6) {
                      setPasswordError(false);
                    } else {
                      setPasswordError(true);
                    }
                  }}/>
                <div class="input-icon-div" onClick={handleIconClick}>
                      <img src={passwordVisible ? eyeOff : eye}/>
                </div>
                {passwordFilled && <div className="error_message">* Password is required</div>}
                {passwordError && <div className="error_message">* Password must be at least 6 characters long</div>}
              </div>  
              <div className={'login_input_box'}>
                <select className={`input_ ${roleFilled ? 'error' : ''}`} placeholder="Role" name="userRole" id="" value={userRole} onChange={(e) => { setUserRole(e.target.value); setRoleFilled(false); }}>
                  <option value="">Select role</option>
                  <option value="3">User</option>
                </select>
                
                {roleFilled && <div className="error_message">* Role is required</div>}
              </div>
              <div className="reset_btn">
                  <span>
                    <input
                    className={`input_ ${passwordFilled ? 'error' : ''}`}
                    type="checkbox"
                    name="remember_me"
                    value={''}
                  />
                    I accept all the <Link>terms and conditions </Link>
                  </span> 
                </div>    
              <button onClick={handleSubmit} className={status === STATUSES.LOADING ? 'common_btn disabled' : 'common_btn1'} disabled={status === STATUSES.LOADING}  >
                  {status === STATUSES.LOADING ? <LoaderCircle/> : "SIGN UP"}
              </button>
              <p className="login_text_bottom">You alraedy have an account? <Link  to='/login'>Log in</Link></p>     
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUp;

import React from 'react';
import { Link } from 'react-router-dom';

const PMShome = () => {
  return (
    <div>
      <div>PMShome Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum, eaque nam quam incidunt, vero quas ullam culpa saepe labore facilis velit numquam eveniet rem animi delectus perferendis in asperiores minima? Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repellendus autem accusamus est iure? Autem iste repellendus, laborum blanditiis maxime enim facilis deleniti repudiandae minima nulla in nostrum eligendi? Quisquam, placeat. Lorem ipsum, dolor sit amet consectetur adipisicing elit. Est dicta cupiditate nihil ullam, alias perspiciatis laudantium animi consequatur? Aperiam maxime repellendus asperiores quaerat iusto minus quisquam odit blanditiis veniam vitae.</div>


      <Link to={'allEmployees'}> All Employees</Link>
    </div>
  )
}

export default PMShome;
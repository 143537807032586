import React from 'react';
import { getAllPromotion } from '../../../../../store/Slices/marketMapTableSlices';
import { getAllPromotionAttributes, getOnePromotionAttributes } from '../../../../../store/Slices/marketMapAttributeSlice';
import TableHomeCommon from '../../../MarketMapsComponents/TableHomeCommon/TableHomeCommon';
import { getPromotionImg } from '../../../../../store/Slices/marketMapImageSlice';

const PromotionTableHome = () => {
  
    return (
      <TableHomeCommon
        title="Promotion"
        getAllTableDataLink = {getAllPromotion}  
        getAllAttributesLink={getAllPromotionAttributes}
        getOneAttributesLink={getOnePromotionAttributes}
  
        getAllTableDataSelector = {(state) => state.AllPromotions}
        allAttributesGroupSelector={(state) => state.AllPromotionAttributeGroup}
        oneAttributeGroupSelector={(state) => state.OnePromotionAttributesGroup}
        classNamePrefix="Promotion"
  
        linkForRedirectSetting = "Add-Promotion-Attributes"
        additionalAttributesName = "promotionAdditionalAttributes"
        getImageLink = {getPromotionImg}
        getImagesSelector = {(state) => state.GetPromotionImg.images}
        getImagesStatusSelector = {(state) => state.GetPromotionImg.status}
        getID = "promotionId"
  
        add_button_url={"Add-Promotion"}
        edit_button_url={"Edit-Promotion"}
  
      />
    );
  };

export default PromotionTableHome;




import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from 'axios';
import STATUSES from '../Statuses';
import config from '../../config';

const service_AUTH_URL = config.SERVICE_AUTH_URL;




// ========================== User Register ================================ 

const userRegisterSlice = createSlice({
    name : "userRegister",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetUserRegister :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(userRegister.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })

        .addCase(userRegister.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })

        .addCase(userRegister.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
});


// user register 
export const userRegister = createAsyncThunk( "user/register", async ( data, { rejectWithValue ,getState })=>{
    try {
        const config = { headers: { "Content-Type": "application/json" } }

        const responce = await axios.post(`${service_AUTH_URL}/auth/register`,data, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})






// ========================== User Token ================================ 

const userTokenSlice = createSlice({
    name : "userToken",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetUserToken :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(userToken.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })

        .addCase(userToken.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })

        .addCase(userToken.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
});


// user Token 
export const userToken = createAsyncThunk( "user/Token", async ( data, { rejectWithValue ,getState })=>{
    try {
        const config = { headers: { "Content-Type": "application/json" } }

        const responce = await axios.post(`${service_AUTH_URL}/auth/token`,data, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})





// ========================== User validate ================================ 

const userValidateSlice = createSlice({
    name : "userValidate",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetUserValidate :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(userValidate.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })

        .addCase(userValidate.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })

        .addCase(userValidate.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
});


// user Validate 
export const userValidate= createAsyncThunk( "user/Validate", async ( data, { rejectWithValue ,getState })=>{
    try {

        const responce = await axios.get(`${service_AUTH_URL}/auth/validate`,{params: {token:data.token }},);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})







// ========================== User Login ================================ 

const userLoginSlice = createSlice({
    name : "userLogin",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetUserLogin :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(userLogin.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })

        .addCase(userLogin.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })

        .addCase(userLogin.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
});


// user Login 
export const userLogin= createAsyncThunk( "user/Login", async ( data, { rejectWithValue ,getState })=>{
    try {
        const config = { 
            headers: { "Content-Type": "application/json" , "Authorization": `Bearer ${data.token}`}
    }

        const responce = await axios.post(`${service_AUTH_URL}/auth/login`,data, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})




// ========================== User Logout ================================ 

const userLogoutSlice = createSlice({
    name : "userLogout",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetUserLogout :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(userLogout.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })

        .addCase(userLogout.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })

        .addCase(userLogout.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
});


// user Logout 
export const userLogout= createAsyncThunk( "user/Logout", async ( _, { rejectWithValue ,getState})=>{
    try {
        const state = getState();
        const token = state.auth.token; // Adjust according to your auth slice
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};


        const responce = await axios.post(`${service_AUTH_URL}/auth/logout`,null, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})







const authSlice = createSlice({
  name: 'auth',
  initialState : {
    token: null,
    userDetails: null,
  },
  reducers: {
    setAuthToken: (state, action) => {
      state.token = action.payload;
    },
    setUserDetails: (state, action) => {
      state.userDetails = action.payload;
    },
    clearAuth: (state) => {
      state.token = null;
      state.userDetails = null;
    },
    rehydrateAuth: (state, action) => {
      const { token, userDetails } = action.payload;
      state.token = token;
      state.userDetails = userDetails;
    },
  },
});


const userPasswordResetSlice = createSlice({
    name : "userPasswordReset",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetUserPasswordReset :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(userPasswordReset.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })

        .addCase(userPasswordReset.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })

        .addCase(userPasswordReset.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
});


// user reset Password 
export const userPasswordReset = createAsyncThunk( "user/userPasswordReset", async ( data, { rejectWithValue ,getState })=>{
    try {
        const config = { headers: { "Content-Type": "application/json" } }

        const responce = await axios.post(`${service_AUTH_URL}/auth/reset-password`,data, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data?.error || "Something went wrong");
    }
})




export {
    userRegisterSlice,
    userTokenSlice,
    userValidateSlice,
    userLoginSlice,
    userLogoutSlice,
    userPasswordResetSlice,
    
    authSlice
}

export const { setAuthToken, setUserDetails, clearAuth ,rehydrateAuth} = authSlice.actions;

export const {resetUserRegister} = userRegisterSlice.actions;
export const {resetUserToken} = userTokenSlice.actions;
export const {resetUserValidate} = userValidateSlice.actions;
export const {resetUserLogin} = userLoginSlice.actions;
export const {resetUserLogout} = userLogoutSlice.actions;
export const {resetUserPasswordReset} = userPasswordResetSlice.actions;

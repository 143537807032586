import React from "react";
// import ScrollAnimation from "react-animate-on-scroll";
import { Col, Container, Row } from "react-bootstrap";
import heroimage from "../../../assets/Images/banner.png";
import bannertext from "../../../assets/Images/bannertext.svg";
import styles from "./Products.module.css";

function Products() {
  return (
    <>
      <div className={styles.bannerSectionArea}>
        <Container>
          <Row className={styles.productsParentMain}>
            <Col sm={12} md={7} className={`text-v-center`}>
              <div>
                {/* <ScrollAnimation
                  animateIn="fadeInUp"
                  initiallyVisible={true}
                  > */}
                {/* <h1 className={styles.heroText}>
                    There is a better way to take your company <b>DIGITAL</b>
                  </h1> */}
                <img
                  style={{ alignSelf: "center" }}
                  src={bannertext}
                  alt={"hero"}
                  className={styles.bannertextimg}
                />
                {/* </ScrollAnimation> */}
                {/* <ScrollAnimation
                  animateIn="fadeInUp"
                  initiallyVisible={true}
                  duration="5s"
                  delay ="3s"
                > */}
                <p className={styles.heroTextP}>
                  With ONIFIED.AI digitalize your business in an intelligent
                  way.
                </p>
                <div className={styles.formCTAdiv}>
                  <div>
                    <p className={styles.formCTAtext}>
                      Start work efficiently with <br></br>Onified SaaS product
                    </p>
                  </div>
                </div>
                {/* </ScrollAnimation> */}
              </div>
            </Col>
            <Col sm={12} md={5} className={`i-center`}>
              {/* <ScrollAnimation
                animateIn="flipInY"
                initiallyVisible={true}
              > */}
              <img
                style={{ width: 450, alignSelf: "center" }}
                src={heroimage}
                alt={"hero"}
                className={styles.heroImg}
              />
              {/* </ScrollAnimation> */}
            </Col>
          </Row>
        </Container>
        <Container className={styles.bannerBoxContainer}>
          <Row>
            <Col sm={12} md={3}>
              <div className={styles.bannerBox}>
                <p className={styles.bannerBoxHead}>15,000+</p>
                <p className={styles.bannerBoxDesc}>Trusted User</p>
              </div>
            </Col>
            <Col sm={12} md={3}>
              <div className={styles.bannerBox}>
                <p className={styles.bannerBoxHead}>98%</p>
                <p className={styles.bannerBoxDesc}>Satisfied Customers</p>
              </div>
            </Col>
            <Col sm={12} md={3}>
              <div className={styles.bannerBox}>
                <p className={styles.bannerBoxHead}>10+</p>
                <p className={styles.bannerBoxDesc}>Integrated Applications</p>
              </div>
            </Col>
            <Col sm={12} md={3}>
              <div className={styles.bannerBox}>
                <p className={styles.bannerBoxHead}>100+</p>
                <p className={styles.bannerBoxDesc}>AI Business Insights</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Products;

import React from 'react';
import { faStarOfLife } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ShowMandatoryStarInTable = ({mandatory}) => {
  return (
    mandatory ? <i className="show_mandatory_star_InTable" style={{fontSize:6.5 , color:"red"}}><FontAwesomeIcon icon={faStarOfLife}/></i> : null
  )
}

export default ShowMandatoryStarInTable;
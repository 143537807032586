import React from "react";
import "./AllEmployee.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faTrash, faUser } from "@fortawesome/free-solid-svg-icons"; 
import { Link } from "react-router-dom";

const AllEmployee = () => {
  return (
    <div className="allEmployee">
      <div className="allEmployee_header">
        <div>All Employees</div>
        <div>
          <Link to={'addNewProfile'}><button className="employees_btn common_btn2">Add new Employee</button></Link> 
          <Link to={'importEmployees'}> <button className="employees_btn common_btn">Import Empolyees Profile</button></Link>
        </div>
      </div>

      <div className="all_employees_list">
        <table>
          <thead>
            <tr>
              <th>Employee</th>
              <th>Employee ID</th>
              <th>Role</th>
              <th>Date of joining</th>
              <th>Action</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td className="employee_info">
                <div className="employee_img_box">
                  <i >
                    <FontAwesomeIcon icon={faUser} className="employee_img_icon" />
                  </i>
                </div>
                <div className="employee_info_box">
                  <span>Pankaj Kumar</span> <span>pankajkumar@org.in</span>
                </div>
              </td>
              <td>AUBXCP234</td>
              <td>HOD</td>
              <td>02/9/2022</td>
              <td className="employee_action"> 
                <i><FontAwesomeIcon icon={faPencil} /></i>
                <i><FontAwesomeIcon icon={faTrash} /></i>
              </td>
            </tr> 
            <tr>
              <td className="employee_info">
                <div className="employee_img_box">
                  <i>
                    <FontAwesomeIcon icon={faUser} className="employee_img_icon" />
                  </i>
                </div>
                <div className="employee_info_box">
                  <span>Pankaj Kumar</span> <span>pankajkumar@org.in</span>
                </div>
              </td>
              <td>AUBXCP234</td>
              <td>HOD</td>
              <td>02/9/2022</td>
              <td className="employee_action"> 
                <i><FontAwesomeIcon icon={faPencil} /></i>
                <i><FontAwesomeIcon icon={faTrash} /></i>
              </td>
            </tr> 
            <tr>
              <td className="employee_info">
                <div className="employee_img_box">
                  <i >
                    <FontAwesomeIcon icon={faUser} className="employee_img_icon" />
                  </i>
                </div>
                <div className="employee_info_box">
                  <span>Pankaj Kumar</span> <span>pankajkumar@org.in</span>
                </div>
              </td>
              <td>AUBXCP234</td>
              <td>HOD</td>
              <td>02/9/2022</td>
              <td className="employee_action"> 
                <i><FontAwesomeIcon icon={faPencil} /></i>
                <i><FontAwesomeIcon icon={faTrash} /></i>
              </td>
            </tr> 
            <tr>
              <td className="employee_info">
                <div className="employee_img_box">
                  <i >
                    <FontAwesomeIcon icon={faUser} className="employee_img_icon" />
                  </i>
                </div>
                <div className="employee_info_box">
                  <span>Pankaj Kumar</span> <span>pankajkumar@org.in</span>
                </div>
              </td>
              <td>AUBXCP234</td>
              <td>HOD</td>
              <td>02/9/2022</td>
              <td className="employee_action"> 
                <i><FontAwesomeIcon icon={faPencil} /></i>
                <i><FontAwesomeIcon icon={faTrash} /></i>
              </td>
            </tr> 
            <tr>
              <td className="employee_info">
                <div className="employee_img_box">
                  <i >
                    <FontAwesomeIcon icon={faUser} className="employee_img_icon" />
                  </i>
                </div>
                <div className="employee_info_box">
                  <span>Pankaj Kumar</span> <span>pankajkumar@org.in</span>
                </div>
              </td>
              <td>AUBXCP234</td>
              <td>HOD</td>
              <td>02/9/2022</td>
              <td className="employee_action"> 
                <i><FontAwesomeIcon icon={faPencil} /></i>
                <i><FontAwesomeIcon icon={faTrash} /></i>
              </td>
            </tr> 
            <tr>
              <td className="employee_info">
                <div className="employee_img_box">
                  <i >
                    <FontAwesomeIcon icon={faUser} className="employee_img_icon" />
                  </i>
                </div>
                <div className="employee_info_box">
                  <span>Pankaj Kumar</span> <span>pankajkumar@org.in</span>
                </div>
              </td>
              <td>AUBXCP234</td>
              <td>HOD</td>
              <td>02/9/2022</td>
              <td className="employee_action"> 
                <i><FontAwesomeIcon icon={faPencil} /></i>
                <i><FontAwesomeIcon icon={faTrash} /></i>
              </td>
            </tr> 
            <tr>
              <td className="employee_info">
                <div className="employee_img_box">
                  <i >
                    <FontAwesomeIcon icon={faUser} className="employee_img_icon" />
                  </i>
                </div>
                <div className="employee_info_box">
                  <span>Pankaj Kumar</span> <span>pankajkumar@org.in</span>
                </div>
              </td>
              <td>AUBXCP234</td>
              <td>HOD</td>
              <td>02/9/2022</td>
              <td className="employee_action"> 
                <i><FontAwesomeIcon icon={faPencil} /></i>
                <i><FontAwesomeIcon icon={faTrash} /></i>
              </td>
            </tr> 
            <tr>
              <td className="employee_info">
                <div className="employee_img_box">
                  <i >
                    <FontAwesomeIcon icon={faUser} className="employee_img_icon" />
                  </i>
                </div>
                <div className="employee_info_box">
                  <span>Pankaj Kumar</span> <span>pankajkumar@org.in</span>
                </div>
              </td>
              <td>AUBXCP234</td>
              <td>HOD</td>
              <td>02/9/2022</td>
              <td className="employee_action"> 
                <i><FontAwesomeIcon icon={faPencil} /></i>
                <i><FontAwesomeIcon icon={faTrash} /></i>
              </td>
            </tr> 
            <tr>
              <td className="employee_info">
                <div className="employee_img_box">
                  <i >
                    <FontAwesomeIcon icon={faUser} className="employee_img_icon" />
                  </i>
                </div>
                <div className="employee_info_box">
                  <span>Pankaj Kumar</span> <span>pankajkumar@org.in</span>
                </div>
              </td>
              <td>AUBXCP234</td>
              <td>HOD</td>
              <td>02/9/2022</td>
              <td className="employee_action"> 
                <i><FontAwesomeIcon icon={faPencil} /></i>
                <i><FontAwesomeIcon icon={faTrash} /></i>
              </td>
            </tr> 
          </tbody>
        </table>

      </div>

    </div>

  );
};

export default AllEmployee;

import React, { useCallback, useEffect, useState } from "react";
import "./MarketMapHome.css";
import { BarChart,Bar,  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend,ResponsiveContainer, ComposedChart, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Radar, PieChart, Pie, LineChart, Line } from 'recharts';
import 'animate.css';
import { getAllProgram } from "../../../../store/Slices/marketMapProgram";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import STATUSES from "../../../../store/Statuses";
import Loader from "../../../Common/Loader/Loader";
import img from '../../../../assets/Images/MarketMaps/AddData.png'
import { useNavigate } from "react-router";

const MarketMapHome = () => {

  const dispatch = useDispatch();
  // const navigate = useNavigate();


  // const { token, userDetails } = useSelector((state) => state.auth);
  // useEffect(()=>{
  //   if(!token && !userDetails){
  //     navigate('/login');
  //   }
  // }, [])

  useEffect(()=>{
    dispatch(getAllProgram("Program"));
  },[]);

  // Fetch data status and error for selected table
  const { data :tableData, status :tableStatus, error:tableError} = useSelector((state) => state.AllPrograms);

  console.log("tableData IN HOME", tableData);

  const [barChartData, setBarChartData] = useState([]);
  const [areaChartData, setAreaChartData] = useState([]);
  const [vBarChartData, setvBarChartData] = useState([]);
  const [radarChartData, setRadarChartData] = useState([]);
  const [pieChartData, setPieChartData] = useState([]);

  const filterData = useCallback(()=>{
    if(tableData && tableData.length>0 && typeof tableData !== "string"){
      const newDataArray = tableData.map(item => ({
        "Program Name": item.groupAttributes["Program name"] || "",
        "Yearly Volume of the program":  Number(item.groupAttributes["Yearly Volume of the program"]) || 0,
        "Retailer name": item.retailers.length > 0 ? item.retailers[0].groupAttributes["Retailer name"] || "" : "",
      }));
      setBarChartData(newDataArray);

      const newDataArray2 = tableData.map(item => ({
        "Program Name": item.groupAttributes["Program name"] || "",
        "Yearly Volume of the program":  Number(item.groupAttributes["Yearly Volume of the program"]) || 0,
        "Retailer name": item.retailers.length > 0 ? item.retailers[0].groupAttributes["Retailer name"] || "" : "",
      }));
      setAreaChartData(newDataArray2);

      const newDataArray3 = tableData.map(item => ({
        "Program Name": item.groupAttributes["Program name"] || "",
        "Yearly Volume of the program":  Number(item.groupAttributes["Yearly Volume of the program"]) || 0,
        "Supplier name": item.suppliers.length > 0 ? item.suppliers[0].groupAttributes["Supplier name"] || "" : "",
      }));
      setvBarChartData(newDataArray3);

      const newDataArray4 = tableData.map(item => ({
        "Program Name": item.groupAttributes["Program name"] || "",
        "Yearly Volume of the program":  Number(item.groupAttributes["Yearly Volume of the program"]) || 0,
        "Annual sale":  Number(item.groupAttributes["Annual sale"]) || 0,
        "Supplier name": item.suppliers.length > 0 ? item.suppliers[0].groupAttributes["Supplier name"] || "" : "",
        "Manufacturer name": item.manufacturers.length > 0 ? item.manufacturers[0].groupAttributes["Manufacturer name"] || "" : "",
      }));
      setRadarChartData(newDataArray4);

      const newDataArray5 = tableData.map(item => ({
        "name": item.suppliers.length > 0 ? item.suppliers[0].groupAttributes["Supplier name"] || "" : "",
        "Yearly Volume of the program":  Number(item.groupAttributes["Yearly Volume of the program"]) || 0,
      }));
      setPieChartData(newDataArray5);


    }

  },[tableData]) 

  useEffect(()=>{
    if(tableStatus === STATUSES.SUCCESS){
      filterData();
    }
  },[filterData,tableStatus]);

  console.log("barChartData", barChartData)
  console.log("area", areaChartData)
  console.log("v Bar", vBarChartData)

  
  return <div className="marketmap_home">

    <div className="col">
      {
        tableStatus === STATUSES.LOADING ?(
          <Loader/>
        ) : tableStatus === STATUSES.ERROR ? (
          <div className="error_in_home_map">Error - Something went wrong</div>
        ): tableStatus === STATUSES.SUCCESS ?(<>{
          barChartData?.length >0 ?(
            <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={barChartData}
              margin = {{
                top: 5,
                right: 30,
                left: 0,
                bottom: 5
              }}
            >
              <CartesianGrid strokeDasharray="8 8" />
              <XAxis dataKey={"Program Name"} tickLine={false} stroke="#000000" tick={{ fontSize: 11 }} />
              <YAxis stroke="#000000" tick={{ fontSize: 11 }} />
              <Tooltip />
              <Legend iconSize={10} tick={{fontSize:9}} />
              <CartesianGrid stroke = "#000000" strokeDasharray="5 5"/>
              <Bar dataKey="Yearly Volume of the program" fill="#ff6384"/>
              <Bar dataKey="Retailer name" fill="#36a2eb"/>
            </BarChart>
            </ResponsiveContainer>
          ): <NoDataAtHomeMap/>
        }</>):null
      }
    </div>

    <div className="col">
    {
        tableStatus === STATUSES.LOADING ?(
          <Loader/>
        ) : tableStatus === STATUSES.ERROR ? (
          <div className="error_in_home_map">Error - Something went wrong</div>
        ): tableStatus === STATUSES.SUCCESS ?(<>{
          areaChartData?.length >0 ?(
            <ResponsiveContainer width="100%" height="100%">      
              <AreaChart
                width={1000}
                height={600}
                data={areaChartData}
                syncId = "anyId"
                margin = {{
                  top: 10,
                  right: 30,
                  left: 0,
                  bottom: 0
                }}
              >
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                  <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
                </linearGradient>
                
                <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
                  <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
                </linearGradient>
              </defs>
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey="Retailer name" stroke="#000000" tick={{ fontSize: 11 }}/>
                <YAxis stroke="#000000" tick={{ fontSize: 11 }}/>
                <Tooltip/>
                <Legend/>
                <Area type="monotone" dataKey="Yearly Volume of the program" stroke="#8884d8" fill="#8884d8"/>
                <Area type="monotone" dataKey="Program Name" stroke="#8884d8" fill="#ff6384"/>
              </AreaChart>
            </ResponsiveContainer>
          ): <NoDataAtHomeMap/>
        }</>):null
      }
    </div>

    <div className="col">
      {
          tableStatus === STATUSES.LOADING ?(
            <Loader/>
          ) : tableStatus === STATUSES.ERROR ? (
            <div className="error_in_home_map">Error - Something went wrong</div>
          ): tableStatus === STATUSES.SUCCESS ?(<>{
            vBarChartData?.length >0 ?(
              <ResponsiveContainer width="100%" height="100%">
                <ComposedChart
                  layout="vertical"
                  width={1000}
                  height={600}
                  data={vBarChartData}
                  margin={{
                    top: 30,
                    right: 20,
                    bottom: 20,
                    left: 10
                  }}
                >
                  <CartesianGrid stroke="#f5f5f5" />
                  <XAxis type="number" tick={{ fontSize: 11 }}/>
                  <YAxis dataKey={"Supplier name"} type="category" scale="band" stroke="#000000" tick={{ fontSize: 11 }}/>
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="Program Name" fill="#ff6384" />
                  <Bar dataKey="Yearly Volume of the program" fill="#36a2eb" />
                </ComposedChart>
             </ResponsiveContainer>
            ): <NoDataAtHomeMap/>
          }</>):null
        }
    </div>

    <div className="col">
    {
          tableStatus === STATUSES.LOADING ?(
            <Loader/>
          ) : tableStatus === STATUSES.ERROR ? (
            <div className="error_in_home_map">Error - Something went wrong</div>
          ): tableStatus === STATUSES.SUCCESS ?(<>{
            radarChartData?.length >0 ?(
              <ResponsiveContainer width="100%" height="100%">
                <RadarChart 
                  cx={190}
                  cy={160}
                  outerRadius={120}
                  width={740}
                  height={600}
                  data={radarChartData}
                  tick={{ fontSize: 11 }}
                >
                  <PolarGrid />
                  <Tooltip/>
                  <PolarAngleAxis dataKey="Supplier name" tick={{ fontSize: 11 }} />
                  <PolarRadiusAxis />
                  <Radar
                    name="Yearly Volume of the program"
                    dataKey="Yearly Volume of the program"
                    stroke="#36a2eb"
                    fill="#36a2eb"
                    fillOpacity={0.6}
                    tick={{ fontSize: 11 }}
                  />
                  <Radar
                    name="Program Name"
                    dataKey="Program Name"
                    stroke="#36a2eb"
                    fill="#36a2eb"
                    fillOpacity={0.6}
                    tick={{ fontSize: 11 }}
                  />
                  <Legend />
                </RadarChart>
              </ResponsiveContainer>
            ): <NoDataAtHomeMap/>
          }</>):null
        }      
    </div>

    <div className="col">

      {
        tableStatus === STATUSES.LOADING ?(
          <Loader/>
        ) : tableStatus === STATUSES.ERROR ? (
          <div className="error_in_home_map">Error - Something went wrong</div>
        ): tableStatus === STATUSES.SUCCESS ?(<>{
          pieChartData?.length >0 ?(
            <PieChart width={1000} height={600}>
              <Pie
                dataKey="Yearly Volume of the program"
                data={pieChartData}
                cx={190}
                cy={160}
                innerRadius={60}
                outerRadius={120}
                fill="#36a2eb"
                label
                name="Supplier Name"
              />
              <Legend />
              <Tooltip />
            </PieChart>
          ): <NoDataAtHomeMap/>
        }</>):null
      }

     
    </div>

    <div className="col">
        {
          tableStatus === STATUSES.LOADING ?(
            <Loader/>
          ) : tableStatus === STATUSES.ERROR ? (
            <div className="error_in_home_map">Error - Something went wrong</div>
          ): tableStatus === STATUSES.SUCCESS ?(<>{
            barChartData?.length >0 ?(
              <ResponsiveContainer width="100%" height="100%">
              <BarChart
              width={500}
              height={300}
              data={barChartData}
              margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5
              }}
              >
              <CartesianGrid strokeDasharray="3 3" />
              <YAxis tick={{ fontSize: 11 }} />
              <Tooltip />
              <Legend />
              <XAxis dataKey={"Retailer name"} tick={{ fontSize: 11 }}/>
              <Bar dataKey="Yearly Volume of the program" stackId="a" fill="#ff6384"/>
              </BarChart>
            </ResponsiveContainer>
            ): <NoDataAtHomeMap/>
          }</>):null
        }
    </div>

    <div className="col">

       {
          tableStatus === STATUSES.LOADING ?(
            <Loader/>
          ) : tableStatus === STATUSES.ERROR ? (
            <div className="error_in_home_map">Error - Something went wrong</div>
          ): tableStatus === STATUSES.SUCCESS ?(<>{
            radarChartData?.length >0 ?(
              <ResponsiveContainer width="100%" height="100%">
              <LineChart width={1000} height={600} data={radarChartData} >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="Manufacturer name" padding={{ left: 30, right: 30 }} stroke="#000000" tick={{ fontSize: 11 }} />
                <YAxis stroke="#000000" tick={{ fontSize: 11 }} />
                <Tooltip />
                <Legend />
                
                <Line type="monotone" dataKey="Program Name" stroke="#36a2eb" />
                <Line
                  type="monotone"
                  dataKey="Yearly Volume of the program"
                  stroke="#ff0000"
                />
                <Line
                  type="monotone"
                  dataKey="Annual sale"
                  stroke="#000000"
                />
              </LineChart>
            </ResponsiveContainer>
            ): <NoDataAtHomeMap/>
          }</>):null
        }

       
    </div>

  </div>;
};

export default MarketMapHome;


export const NoDataAtHomeMap = () =>{
  return (
    <div className="no_data_at_home_maps">
      <img src={img} alt="No data" />
      <h6>No data found in the program to display in chart</h6>
    </div>
  )
}
import React, { useEffect, useRef, useState } from 'react';
import * as echarts from 'echarts';
import './BubbleChart.css';
import DataSelection, { DataSelectionButton } from '../../../MarketMapsComponents/DataSelection/DataSelection';
import { NoDataINGraph } from '../../../MarketMapsComponents/NoDataInAttributes/NoDataInAttributes';
import { useSelector } from 'react-redux';

const BubbleChart = () => {
  const [toggle, setToggle] = useState(false);
  // const dataToShowOnX = useSelector((state) => state.shortedXaxisData);
  // const dataToShowOnY = useSelector((state) => state.shortedYaxisData);
  // const dataToShowOnZ = useSelector((state) => state.shortedZaxisData);
  const [dataToShowOnX , setDataToShowOnX] = useState([]);
  const [dataToShowOnY , setDataToShowOnY] = useState([]);
  const [dataToShowOnZ , setDataToShowOnZ] = useState([]);

  const chartRef = useRef(null);

  useEffect(() => {
    if (!chartRef.current) return;

    // Check if there is no data
    if (!dataToShowOnX.length || !dataToShowOnY.length || !dataToShowOnZ.length) return;

    // Initialize ECharts instance
    const chart = echarts.init(chartRef.current);

    // Extract keys for axis labels
    const xAxisKey = Object.keys(dataToShowOnX[0])[0];
    const yAxisKey = Object.keys(dataToShowOnY[0])[0];
    const zAxisKey = Object.keys(dataToShowOnZ[0])[0];

    // Prepare data for ECharts
    const data = dataToShowOnX.map((item, index) => ({
      name: `${item[xAxisKey]} - ${dataToShowOnY[index][yAxisKey]}`,
      value: [
        item[xAxisKey] && Array.isArray(item[xAxisKey]) ? item[xAxisKey][0] : item[xAxisKey] || "Unknown",
        dataToShowOnY[index][yAxisKey] && Array.isArray(dataToShowOnY[index][yAxisKey]) ? dataToShowOnY[index][yAxisKey][0] : dataToShowOnY[index][yAxisKey] || "Unknown",
        dataToShowOnZ[index][zAxisKey]
      ]
    }));

    // Extract unique categories and regions
    const categories = Array.from(new Set(dataToShowOnX.map(item => item[xAxisKey] && Array.isArray(item[xAxisKey]) ? item[xAxisKey][0] : item[xAxisKey] || "Unknown")));
    const regions = Array.from(new Set(dataToShowOnY.map(item => item[yAxisKey] && Array.isArray(item[yAxisKey]) ? item[yAxisKey][0] : item[yAxisKey] || "Unknown")));

    // Determine the max value of zAxisKey to scale bubble sizes
    const maxZValue = Math.max(...dataToShowOnZ.map(item => item[zAxisKey]));

    const option = {
      tooltip: {
        trigger: 'item',
        formatter: (params) => {
          const data = params.data;
          return `${xAxisKey}: ${data.value[0]}<br/>${yAxisKey}: ${data.value[1]}<br/>${zAxisKey}: ${data.value[2]}`;
        }
      },
      grid: {
        left: '10%',
        right: '10%',
        bottom: '10%',
        top: '10%',
        containLabel: true
      },
      xAxis: {
        type: 'category',
        data: categories,
        name: xAxisKey,
        axisLabel: {
          rotate: 45
        },
        splitLine: {
          show: true,
          lineStyle: {
            type: 'dotted',
            color: '#000000'
          }
        }
      },
      yAxis: {
        type: 'category',
        data: regions,
        name: yAxisKey,
        splitLine: {
          show: true,
          lineStyle: {
            type: 'dotted',
            color: '#000000'
          }
        }
      },
      series: [{
        type: 'scatter',
        symbolSize: function (data) {
          // Scale symbol size based on z value and maxZValue
          return Math.sqrt(data[2] / maxZValue) * 50; // Adjust the multiplier (50) as needed
        },
        data: data
      }]
    };

    // Set options and render chart
    chart.setOption(option);

    // Resize chart with window resize
    const resizeChart = () => chart.resize();
    window.addEventListener('resize', resizeChart);

    return () => {
      window.removeEventListener('resize', resizeChart);
      chart.dispose();
    };
  }, [dataToShowOnX, dataToShowOnY, dataToShowOnZ]);

  // Check if there is no data
  const noData = !dataToShowOnX.length || !dataToShowOnY.length || !dataToShowOnZ.length;

   
  const [barsDataValues, setBarsDataValues] = useState([]);
  return (
    <>
      <DataSelectionButton toggle={toggle} setToggle={setToggle} />
      <DataSelection toggle={toggle} setToggle={setToggle} setDataToShowOnX={setDataToShowOnX} setDataToShowOnY={setDataToShowOnY} setDataToShowOnZ={setDataToShowOnZ} setBarsDataValues= {setBarsDataValues}/>
      
      <div className= {toggle ? "chart_page open" : "chart_page"}>
        {noData ? (
          <NoDataINGraph />
        ) : (
          <div ref={chartRef} className='chart_container_bubble'></div>
        )}
      </div>
    </>
  );
};

export default BubbleChart;

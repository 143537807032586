import React, { useEffect, useState } from "react";
import "./EditProgram.css";
import { useDispatch, useSelector } from "react-redux";
import ErrorBox from "../../../../Common/ErrorBox/ErrorBox";
import Loader from "../../../../Common/Loader/Loader";
import RenderWarning from "../../../MarketMapsComponents/RenderWarning/RenderWarning";
import STATUSES from "../../../../../store/Statuses";
import {
  deleteProgram,
  getAllProgram,
  resetDeleteProgramState,
} from "../../../../../store/Slices/marketMapProgram";
import {
  deleteProgramImg,
  getProgramImg,
  resetdeleteProgramImgState,
} from "../../../../../store/Slices/marketMapImageSlice";
import {
  getAllProgramAttributes,
  getOneGroupAttributes,
  getOnePackagingAttributes,
  getOneProgramAttributes,
  getOnePromotionAttributes,
  getOneRegionAttributes,
  resetOneGroupAttributesGroupState,
  resetOnePackagingAttributesGroupState,
  resetOnePromotionAttributesGroupState,
  resetOneRegionAttributesGroupState,
  resetOneRegionGroupState,
} from "../../../../../store/Slices/marketMapAttributeSlice";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import Star from "../../../MarketMapsComponents/NoDataInTable/ShowMandatoryStarInTable";
import Edit from "../../../../../assets/Images/Edit.svg";
import Delete from "../../../../../assets/Images/delete.svg";

const EditProgram = () => {
  const getID = "programId";

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [imageStatus, setImageStatus] = useState({});
  const [imageErrors, setImageErrors] = useState({});

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  // [1] Get all attributes list
  useEffect(() => {
    dispatch(getAllProgramAttributes({}));
  }, [dispatch]);

  const {
    data: AttributesGroupsData,
    status: AttributeGroupStatus,
    error: AttributeGroupError,
  } = useSelector((state) => state.AllProgramAttributeGroup);

  // [2] Get single attributes list by selected groupName
  const [groupNameToShowAttributes, setGroupNameToShowAttributes] =
    useState("");
  const [groupNameOfProduct, setGroupNameOfProduct] = useState("");
  const [selectedGroupIndex, setSelectedGroupIndex] = useState(1);

  const handleGroupSelection = (e, groupName, selectedIndex) => {
    setGroupNameOfProduct(groupName);
    setSelectedGroupIndex(selectedIndex + 1);
    setDropdownOpen(!dropdownOpen);
  };

  // set initial First program Class Name
  useEffect(() => {
    if (AttributesGroupsData.length > 0) {
      setGroupNameToShowAttributes(AttributesGroupsData[0].groupName);
      setSelectedGroupIndex(1);
    }
  }, [AttributesGroupsData]);

  useEffect(() => {
    if (groupNameToShowAttributes.length > 0) {
      dispatch(getOneProgramAttributes(groupNameToShowAttributes));
    }
  }, [dispatch, groupNameToShowAttributes]);

  const {
    data: singleAttributeGroupData,
    status: singleAttributeGroupStatus,
    error: singleAttributeGroupError,
  } = useSelector((state) => state.OneProgramAttributesGroup);

  const {
    groupAttributes,
    programAdditionalAttributes: additionalAttributes,
    imagesAttributes,
    productType,
  } = singleAttributeGroupData;

  // set initial First product group name from the program Attributes group
  useEffect(() => {
    if (
      singleAttributeGroupStatus === STATUSES.SUCCESS &&
      singleAttributeGroupData &&
      singleAttributeGroupData.productType?.productGroup?.length > 0
    ) {
      setGroupNameOfProduct(
        singleAttributeGroupData.productType.productGroup[0]
      );
    }
  }, [singleAttributeGroupData, singleAttributeGroupStatus]);

  // console.log(singleAttributeGroupData);
  // [3] Get all data according to selected group name
  const classNameToFetch =
    selectedGroupIndex === 1 ? "Program" : `${"Program"}${selectedGroupIndex}`;
  useEffect(() => {
    if (singleAttributeGroupStatus === STATUSES.SUCCESS) {
      dispatch(getAllProgram(classNameToFetch));
    }
  }, [dispatch, singleAttributeGroupStatus, selectedGroupIndex]);

  const { data, status, error } = useSelector((state) => state.AllPrograms);
  console.log(data);

  const [regionGroupName, setRegionGroupName] = useState("");
  const [packagingGroupName, setPackagingGroupName] = useState("");
  const [promotionGroupName, setPromotionGroupName] = useState("");
  const [groupGroupName, setGroupGroupName] = useState("");

  useEffect(() => {
    setRegionGroupName("");
    setPackagingGroupName("");
    setPromotionGroupName("");
    setGroupGroupName("");
    dispatch(resetOneRegionAttributesGroupState({}));
    dispatch(resetOnePackagingAttributesGroupState({}));
    dispatch(resetOnePromotionAttributesGroupState({}));
    dispatch(resetOneGroupAttributesGroupState({}));
  }, [selectedGroupIndex]);

  useEffect(() => {
    if (status === STATUSES.SUCCESS) {
      if (typeof data !== "string" && data.length > 0) {
        // Find the first non-empty regionInProgram
        const regionItem = data.find(
          (item) => item?.regionInProgram?.groupName
        );
        if (regionItem) {
          setRegionGroupName(regionItem.regionInProgram.groupName);
        }

        // Find the first non-empty packagingInProgram
        const packagingItem = data.find(
          (item) => item?.packagingInProgram?.groupName
        );
        if (packagingItem) {
          setPackagingGroupName(packagingItem.packagingInProgram.groupName);
        }

        // Find the first non-empty promotionInProgram
        const promotionItem = data.find(
          (item) => item?.promotionInProgram?.groupName
        );
        if (promotionItem) {
          setPromotionGroupName(promotionItem.promotionInProgram.groupName);
        }

        // Find the first non-empty groupInProgram
        const groupItem = data.find((item) => item?.groupInProgram?.groupName);
        if (groupItem) {
          setGroupGroupName(groupItem.groupInProgram.groupName);
        }
      }
    }
  }, [status, data]);

  console.log("regionGroupName = ", regionGroupName);
  console.log("packagingGroupName = ", packagingGroupName);
  console.log("promotionGroupName = ", promotionGroupName);
  console.log("groupGroupName = ", groupGroupName);

  useEffect(() => {
    if (status === STATUSES.SUCCESS) {
      {
        if (typeof data !== "string") {
          if (regionGroupName.length > 0) {
            dispatch(getOneRegionAttributes(regionGroupName));
          }
          if (packagingGroupName.length > 0) {
            dispatch(getOnePackagingAttributes(packagingGroupName));
          }
          if (promotionGroupName.length > 0) {
            dispatch(getOnePromotionAttributes(promotionGroupName));
          }
          if (groupGroupName.length > 0) {
            dispatch(getOneGroupAttributes(groupGroupName));
          }
        }
      }
    }
  }, [status, regionGroupName, packagingGroupName, groupGroupName]);

  // Region Attribute Calling =======================
  const {
    data: singleRegionAttributeGroupData,
    status: singleRegionAttributeGroupStatus,
    error: singleRegionAttributeGroupError,
  } = useSelector((state) => state.OneRegionAttributesGroup);

  const {
    groupAttributes: RegionGroupAttributes,
    regionAdditionalAttributes: RegionAdditionalAttributes,
    imagesAttributes: RegionImagesAttributes,
  } = singleRegionAttributeGroupData;

  // Packaging Attribute Calling =======================
  const {
    data: singlePackagingAttributeGroupData,
    status: singlePackagingAttributeGroupStatus,
    error: singlePackagingAttributeGroupError,
  } = useSelector((state) => state.OnePackagingAttributesGroup);

  const {
    groupAttributes: PackagingGroupAttributes,
    packagingAdditionalAttributes: PackagingAdditionalAttributes,
    imagesAttributes: PackagingImagesAttributes,
  } = singlePackagingAttributeGroupData;

  // Promotion Attribute Calling =======================
  const {
    data: singlePromotionAttributeGroupData,
    status: singlePromotionAttributeGroupStatus,
    error: singlePromotionAttributeGroupError,
  } = useSelector((state) => state.OnePromotionAttributesGroup);

  const {
    groupAttributes: PromotionGroupAttributes,
    promotionAdditionalAttributes: PromotionAdditionalAttributes,
    imagesAttributes: PromotionImagesAttributes,
  } = singlePromotionAttributeGroupData;

  // Group Attribute Calling =======================
  const {
    data: singleGroupAttributeGroupData,
    status: singleGroupAttributeGroupStatus,
    error: singleGroupAttributeGroupError,
  } = useSelector((state) => state.OneGroupAttributesGroup);

  const {
    groupAttributes: GroupGroupAttributes,
    groupAdditionalAttributes: GroupAdditionalAttributes,
    imagesAttributes: GroupImagesAttributes,
  } = singleGroupAttributeGroupData;

  console.log(
    "singleRegionAttributeGroupData ==  ",
    singleRegionAttributeGroupData
  );
  console.log(
    "singlePackagingAttributeGroupData ==  ",
    singlePackagingAttributeGroupData
  );
  console.log(
    "singlePromotionAttributeGroupData ==  ",
    singlePromotionAttributeGroupData
  );
  console.log(
    "singleGroupAttributeGroupData ==  ",
    singleGroupAttributeGroupData
  );

  console.log("RegionGroupAttributes --", RegionGroupAttributes);
  console.log("RegionAdditionalAttributes --", RegionAdditionalAttributes);

  // const { products , manufacturers ,suppliers , retailers} = data;
  // console.log("products", typeof data);
  // console.log( " sddsdd" ,data[0]);

  const imageUrls = useSelector((state) => state.GetProgramImg.images);

  useEffect(() => {
    if (singleAttributeGroupStatus === STATUSES.SUCCESS) {
      if (status === STATUSES.SUCCESS) {
        if (typeof data !== "string") {
          data.forEach((item) => {
            const id = item[getID];
            const attributes = {
              ...groupAttributes,
              ...additionalAttributes,
              ...PromotionGroupAttributes,
              ...PromotionAdditionalAttributes,
              ...PackagingGroupAttributes,
              ...PackagingAdditionalAttributes,
              ...RegionGroupAttributes,
              ...RegionAdditionalAttributes,
              ...GroupGroupAttributes,
              ...GroupAdditionalAttributes,
            };
            Object.keys(attributes || {}).forEach((attributeKey) => {
              if (attributes[attributeKey].type === "Image") {
                setImageStatus((prevState) => ({
                  ...prevState,
                  [`${id}_${attributeKey}`]: STATUSES.LOADING,
                }));
                dispatch(
                  getProgramImg({ [getID]: id, imageName: attributeKey })
                )
                  .then(() => {
                    setImageStatus((prevState) => ({
                      ...prevState,
                      [`${id}_${attributeKey}`]: STATUSES.SUCCESS,
                    }));
                  })
                  .catch((err) => {
                    setImageStatus((prevState) => ({
                      ...prevState,
                      [`${id}_${attributeKey}`]: STATUSES.ERROR,
                    }));
                    setImageErrors((prevState) => ({
                      ...prevState,
                      [`${id}_${attributeKey}`]: err.message,
                    }));
                  });
              }
            });
          });
        }
      }
    }
  }, [
    data,
    singleAttributeGroupData,
    singleAttributeGroupStatus,
    status,
    dispatch,
    PromotionGroupAttributes,
    PromotionAdditionalAttributes,
    PackagingGroupAttributes,
    PackagingAdditionalAttributes,
    RegionGroupAttributes,
    RegionAdditionalAttributes,
    GroupGroupAttributes,
    GroupAdditionalAttributes,
  ]);

  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this item?")) {
      dispatch(deleteProgram({ programId: id, className: classNameToFetch }));
    }
  };

  const {
    data: deleteData,
    status: deleteStatus,
    error: deleteError,
  } = useSelector((state) => state.DeleteProgram);

  useEffect(() => {
    if (deleteData && deleteStatus === STATUSES.SUCCESS) {
      toast.success(deleteData);
      dispatch(resetDeleteProgramState({}));
      // Fetch the updated list of all after deletion
      dispatch(getAllProgram(classNameToFetch));
    }
    if (deleteStatus === STATUSES.ERROR) {
      toast.error(deleteError ? deleteError : "Something went Wrong");
      dispatch(resetDeleteProgramState({}));
    }
  }, [deleteData, deleteStatus, deleteError, dispatch, classNameToFetch]);

  const handleDeleteImg = (id, attributeKey) => {
    if (window.confirm("Are you sure you want to delete this image?")) {
      dispatch(deleteProgramImg({ programId: id, imageName: attributeKey }));
    }
  };

  const {
    data: deleteDataImg,
    status: deleteStatusImg,
    error: deleteErrorImg,
  } = useSelector((state) => state.DeleteProgramImg);

  useEffect(() => {
    if (deleteDataImg && deleteStatusImg === STATUSES.SUCCESS) {
      toast.success(deleteDataImg);
      dispatch(resetdeleteProgramImgState({}));
      // Fetch the updated list of all after deletion
      dispatch(getAllProgram(classNameToFetch));
    }
    if (deleteStatusImg === STATUSES.ERROR) {
      toast.error(deleteErrorImg);
      dispatch(resetdeleteProgramImgState({}));
    }
  }, [
    deleteDataImg,
    deleteStatusImg,
    deleteErrorImg,
    dispatch,
    classNameToFetch,
  ]);

  const handelUpdateTableToggle = (id, productType) => {
    console.log(id);

    const classNameToFetch =
      selectedGroupIndex === 1
        ? "Program"
        : `${"Program"}${selectedGroupIndex}`;
    localStorage.setItem("selectedClassName", classNameToFetch);
    localStorage.setItem("selectedProgramId", id);
    localStorage.setItem("selectedProductType", productType);
    localStorage.setItem(
      "selectedGroupNameToShowAttributes",
      groupNameToShowAttributes
    );
    localStorage.setItem(
      "selectedGroupNameToShowAttributes",
      groupNameToShowAttributes
    );
    if (id && classNameToFetch) {
      navigate("edit-One-Program");
    }
  };

  const keys = singleAttributeGroupData
    ? Object.keys(singleAttributeGroupData.groupAttributes || {})
    : [];
  const additionalKeys = singleAttributeGroupData
    ? Object.keys(singleAttributeGroupData.programAdditionalAttributes || {})
    : [];

  return (
    <div className="common_table_page edit_program_page">
      {/* <TableHomeButtonHeader add_button_url={add_button_url} edit_button_url={edit_button_url} table_heading={`Programs - ${groupNameToShowAttributes}`} data={data} status={status} /> */}
      <div className="table_home_button_header 1">
        {" "}
        <h6>
          Program{" "}
          {groupNameToShowAttributes && `- ${groupNameToShowAttributes}`}{" "}
          {groupNameOfProduct && `- ${groupNameOfProduct}`}
        </h6>
      </div>

      <div className="market_table_container 2">
        <div className="market_table">
          {AttributeGroupStatus === STATUSES.LOADING ? (
            <Loader />
          ) : AttributeGroupStatus === STATUSES.ERROR ? (
            <ErrorBox message={AttributeGroupError?.message} />
          ) : AttributeGroupStatus === STATUSES.SUCCESS ? (
            AttributesGroupsData?.length === 0 ? (
              <RenderWarning message={`No data found, please add data`} />
            ) : (
              <>
                <div
                  className={`dropdown-icon ${dropdownOpen ? "open" : ""}`}
                  onClick={toggleDropdown}
                >
                  {" "}
                  &#8645;
                </div>

                {dropdownOpen && (
                  <div className={`dropdown ${dropdownOpen ? "" : "closed"}`}>
                    <div className="dropdown-options">
                      {singleAttributeGroupStatus === STATUSES.LOADING ? (
                        <div className="option">Loading..</div>
                      ) : singleAttributeGroupStatus === STATUSES.ERROR ? (
                        <div className="option">Something Went wrong</div>
                      ) : singleAttributeGroupStatus === STATUSES.SUCCESS ? (
                        <>
                          {productType?.productGroup.map(
                            (productCLass, index) => (
                              <div
                                key={index}
                                className={`option ${
                                  groupNameOfProduct === productCLass
                                    ? "selected"
                                    : ""
                                }`}
                                onClick={(e) =>
                                  handleGroupSelection(e, productCLass, index)
                                }
                              >
                                {productCLass}
                              </div>
                            )
                          )}
                        </>
                      ) : null}
                    </div>
                  </div>
                )}

                {singleAttributeGroupStatus === STATUSES.LOADING ? (
                  <Loader />
                ) : singleAttributeGroupStatus === STATUSES.ERROR ? (
                  <ErrorBox message={singleAttributeGroupError?.message} />
                ) : singleAttributeGroupStatus === STATUSES.SUCCESS ? (
                  status === STATUSES.LOADING ? (
                    <Loader />
                  ) : status === STATUSES.ERROR ? (
                    <ErrorBox message={error?.message} />
                  ) : status === STATUSES.SUCCESS ? (
                    data && typeof data === "string" ? (
                      <RenderWarning
                        message={`No data found in ${groupNameToShowAttributes}`}
                      />
                    ) : (
                      <>
                        <table>
                          <tbody>
                            <tr>
                              <td>Operations</td>
                              {data.map((item, i) => (
                                <td key={i}>
                                  <div className="edit_btns_container">
                                    <i
                                      onClick={() =>
                                        handelUpdateTableToggle(
                                          item.programId,
                                          item.productType
                                        )
                                      }
                                    >
                                      <img src={Edit} className="w20" />
                                    </i>
                                    <i
                                      onClick={() =>
                                        handleDelete(item.programId)
                                      }
                                    >
                                      <img src={Delete} className="w20" />
                                    </i>
                                  </div>
                                </td>
                              ))}
                            </tr>
                            {Object.keys(groupAttributes).map(
                              (attributeKey, index) => (
                                <React.Fragment
                                  key={`${attributeKey}_${index}`}
                                >
                                  {index === 1 && (
                                    <tr key="Users-row">
                                      <td>User</td>
                                      {data.map((item, i) => (
                                        <td key={i}> {item?.name}</td>
                                      ))}
                                    </tr>
                                  )}

                                  {index === 1 && (
                                    <tr key="Status-row">
                                      <td>Status</td>
                                      {data.map((item, i) => (
                                        <td key={i}>
                                          {item?.products.length > 0 &&
                                          item?.retailers.length > 0 &&
                                          item?.manufacturers.length > 0 &&
                                          item?.suppliers.length > 0 &&
                                          item?.packagingInProgram &&
                                          Object.keys(item.packagingInProgram)
                                            .length > 0 &&
                                          item?.promotionInProgram &&
                                          Object.keys(item.promotionInProgram)
                                            .length > 0 &&
                                          item?.regionInProgram &&
                                          Object.keys(item.regionInProgram)
                                            .length > 0
                                            ? "Complete"
                                            : "Incomplete"}
                                        </td>
                                      ))}
                                    </tr>
                                  )}
                                  <tr>
                                    <td>
                                      {
                                        groupAttributes[attributeKey]
                                          .nametoDisplay
                                      }{" "}
                                      <Star
                                        mandatory={
                                          groupAttributes[attributeKey]
                                            ?.mandatory
                                        }
                                      />
                                    </td>
                                    {data.map((item, dataIndex) => (
                                      <td key={dataIndex}>
                                        {groupAttributes[attributeKey].type ===
                                        "Image" ? (
                                          imageStatus[
                                            `${item[getID]}_${attributeKey}`
                                          ] === STATUSES.LOADING ? (
                                            "Loading..."
                                          ) : imageStatus[
                                              `${item[getID]}_${attributeKey}`
                                            ] === STATUSES.ERROR ? (
                                            "Something went wrong"
                                          ) : imageStatus[
                                              `${item[getID]}_${attributeKey}`
                                            ] === STATUSES.SUCCESS ? (
                                            imageUrls[
                                              `${item[getID]}_${attributeKey}`
                                            ] ? (
                                              <div className="image_container">
                                                <img
                                                  src={
                                                    imageUrls[
                                                      `${item[getID]}_${attributeKey}`
                                                    ]
                                                  }
                                                  alt="images of data"
                                                />
                                                <i
                                                  onClick={() =>
                                                    handleDeleteImg(
                                                      item[getID],
                                                      attributeKey
                                                    )
                                                  }
                                                >
                                                  <FontAwesomeIcon
                                                    icon={faTrash}
                                                  />
                                                </i>
                                              </div>
                                            ) : (
                                              "Not found"
                                            )
                                          ) : null
                                        ) : item.groupAttributes[
                                            attributeKey
                                          ] &&
                                          item.groupAttributes[attributeKey]
                                            .length > 0 ? (
                                          item.groupAttributes[
                                            attributeKey
                                          ].join(", ")
                                        ) : (
                                          "Not found"
                                        )}
                                      </td>
                                    ))}
                                  </tr>
                                  {groupAttributes[attributeKey].type ===
                                    "NestedDropdown" && (
                                    <tr
                                      key={`${attributeKey}_${index}_additional`}
                                    >
                                      <td>
                                        {
                                          groupAttributes[attributeKey]
                                            .nametoDisplay2
                                        }{" "}
                                        <Star
                                          mandatory={
                                            groupAttributes[attributeKey]
                                              ?.mandatory
                                          }
                                        />
                                      </td>
                                      {data.map((item, dataIndex) => (
                                        <td key={dataIndex}>
                                          {item.groupAttributes[
                                            `${attributeKey}nEsTeD`
                                          ] &&
                                          item.groupAttributes[
                                            `${attributeKey}nEsTeD`
                                          ].length > 0
                                            ? item.groupAttributes[
                                                `${attributeKey}nEsTeD`
                                              ].join(", ")
                                            : "Not found"}
                                        </td>
                                      ))}
                                    </tr>
                                  )}
                                </React.Fragment>
                              )
                            )}

                            {Object.keys(additionalAttributes).map(
                              (attributeKey, index) => (
                                <React.Fragment
                                  key={`${attributeKey}_${index}`}
                                >
                                  <tr>
                                    <td>
                                      {
                                        additionalAttributes[attributeKey]
                                          .nametoDisplay
                                      }{" "}
                                      <Star
                                        mandatory={
                                          additionalAttributes[attributeKey]
                                            ?.mandatory
                                        }
                                      />
                                    </td>
                                    {data.map((item, dataIndex) => (
                                      <td key={dataIndex}>
                                        {additionalAttributes[attributeKey]
                                          .type === "Image" ? (
                                          imageStatus[
                                            `${item[getID]}_${attributeKey}`
                                          ] === STATUSES.LOADING ? (
                                            "Loading..."
                                          ) : imageStatus[
                                              `${item[getID]}_${attributeKey}`
                                            ] === STATUSES.ERROR ? (
                                            <ErrorBox
                                              message={
                                                imageErrors[
                                                  `${item[getID]}_${attributeKey}`
                                                ] || "Something went wrong"
                                              }
                                            />
                                          ) : imageStatus[
                                              `${item[getID]}_${attributeKey}`
                                            ] === STATUSES.SUCCESS ? (
                                            imageUrls[
                                              `${item[getID]}_${attributeKey}`
                                            ] ? (
                                              <div className="image_container">
                                                <img
                                                  src={
                                                    imageUrls[
                                                      `${item[getID]}_${attributeKey}`
                                                    ]
                                                  }
                                                  alt="images of data"
                                                />
                                                <i
                                                  onClick={() =>
                                                    handleDeleteImg(
                                                      item[getID],
                                                      attributeKey
                                                    )
                                                  }
                                                >
                                                  <FontAwesomeIcon
                                                    icon={faTrash}
                                                  />
                                                </i>
                                              </div>
                                            ) : (
                                              "Not found"
                                            )
                                          ) : null
                                        ) : item.programAdditionalAttributes[
                                            attributeKey
                                          ] &&
                                          item.programAdditionalAttributes[
                                            attributeKey
                                          ].length > 0 ? (
                                          item.programAdditionalAttributes[
                                            attributeKey
                                          ].join(", ")
                                        ) : (
                                          "Not found"
                                        )}
                                      </td>
                                    ))}
                                  </tr>
                                  {additionalAttributes[attributeKey].type ===
                                    "NestedDropdown" && (
                                    <tr
                                      key={`${attributeKey}_${index}_additional`}
                                    >
                                      <td>
                                        {
                                          additionalAttributes[attributeKey]
                                            .nametoDisplay2
                                        }{" "}
                                        <Star
                                          mandatory={
                                            additionalAttributes[attributeKey]
                                              ?.mandatory
                                          }
                                        />
                                      </td>
                                      {data.map((item, dataIndex) => (
                                        <td key={dataIndex}>
                                          {item.programAdditionalAttributes[
                                            `${attributeKey}nEsTeD`
                                          ] &&
                                          item.programAdditionalAttributes[
                                            `${attributeKey}nEsTeD`
                                          ].length > 0
                                            ? item.programAdditionalAttributes[
                                                `${attributeKey}nEsTeD`
                                              ].join(", ")
                                            : "Not found"}
                                        </td>
                                      ))}
                                    </tr>
                                  )}
                                </React.Fragment>
                              )
                            )}

                            {Object.keys(imagesAttributes).map((imageKey) => {
                              const { groupsName, attgroups } =
                                imagesAttributes[imageKey];
                              return (
                                <React.Fragment key={imageKey}>
                                  {Object.keys(groupsName).map(
                                    (groupName, index) => {
                                      return (
                                        <React.Fragment
                                          key={`${imageKey}-${groupName}`}
                                        >
                                          {Object.keys(attgroups).map(
                                            (attribute, index) => (
                                              <React.Fragment
                                                key={`${groupName}-${attribute}`}
                                              >
                                                <tr>
                                                  <td>{`${groupsName[groupName].nametoDisplay} ${attgroups[attribute].nametoDisplay}`}</td>
                                                  {data.map(
                                                    (item, dataIndex) => {
                                                      return (
                                                        <td key={dataIndex}>
                                                          {item
                                                            .imagesAttributes[
                                                            `${groupName} ${attribute}`
                                                          ] !== undefined &&
                                                          item.imagesAttributes[
                                                            `${groupName} ${attribute}`
                                                          ].length > 0
                                                            ? item.imagesAttributes[
                                                                `${groupName} ${attribute}`
                                                              ].join(", ")
                                                            : "Not found"}
                                                        </td>
                                                      );
                                                    }
                                                  )}
                                                </tr>
                                                {attgroups[attribute].type ===
                                                  "NestedDropdown" && (
                                                  <tr>
                                                    <td>{`${groupsName[groupName].nametoDisplay} ${attgroups[attribute].nametoDisplay2}`}</td>
                                                    {data.map(
                                                      (item, dataIndex) => {
                                                        return (
                                                          <td key={dataIndex}>
                                                            {item
                                                              .imagesAttributes[
                                                              `${groupName} ${attribute}nEsTeD`
                                                            ] !== undefined &&
                                                            item
                                                              .imagesAttributes[
                                                              `${groupName} ${attribute}nEsTeD`
                                                            ].length > 0
                                                              ? item.imagesAttributes[
                                                                  `${groupName} ${attribute}nEsTeD`
                                                                ].join(", ")
                                                              : "Not found"}
                                                          </td>
                                                        );
                                                      }
                                                    )}
                                                  </tr>
                                                )}
                                              </React.Fragment>
                                            )
                                          )}
                                        </React.Fragment>
                                      );
                                    }
                                  )}
                                </React.Fragment>
                              );
                            })}

                            <tr>
                              <td>
                                Products <Star mandatory={true} />
                              </td>
                              {data.map((item, itemIndex) => (
                                <td key={itemIndex}>
                                  {item.products.length === 0
                                    ? "Not found"
                                    : item.products
                                        .map(
                                          (product) =>
                                            product?.groupAttributes[
                                              "Product name"
                                            ][0]
                                        )
                                        .join(" , ")}
                                </td>
                              ))}
                            </tr>

                            <tr>
                              <td>
                                Retailers <Star mandatory={true} />
                              </td>
                              {data.map((item, itemIndex) => (
                                <td key={itemIndex}>
                                  {item.retailers.length === 0
                                    ? "Not found"
                                    : item.retailers
                                        .map(
                                          (retailer) =>
                                            retailer?.groupAttributes[
                                              "Retailer name"
                                            ][0]
                                        )
                                        .join(" , ")}
                                </td>
                              ))}
                            </tr>

                            <tr>
                              <td>
                                Manufacturers <Star mandatory={true} />
                              </td>
                              {data.map((item, itemIndex) => (
                                <td key={itemIndex}>
                                  {item.manufacturers.length === 0
                                    ? "Not found"
                                    : item.manufacturers
                                        .map(
                                          (manufacturer) =>
                                            manufacturer?.groupAttributes[
                                              "Manufacturer name"
                                            ][0]
                                        )
                                        .join(" , ")}
                                </td>
                              ))}
                            </tr>

                            <tr>
                              <td>
                                Suppliers <Star mandatory={true} />
                              </td>
                              {data.map((item, itemIndex) => (
                                <td key={itemIndex}>
                                  {item.suppliers.length === 0
                                    ? "Not found"
                                    : item.suppliers
                                        .map(
                                          (supplier) =>
                                            supplier?.groupAttributes[
                                              "Supplier name"
                                            ][0]
                                        )
                                        .join(" , ")}
                                </td>
                              ))}
                            </tr>

                            {packagingGroupName.length > 0 &&
                            singlePackagingAttributeGroupStatus ===
                              STATUSES.SUCCESS ? (
                              <>
                                {Object.keys(PackagingGroupAttributes).map(
                                  (attributeKey, index) => (
                                    <React.Fragment
                                      key={`${attributeKey}_${index}`}
                                    >
                                      <tr>
                                        <td>
                                          {
                                            PackagingGroupAttributes[
                                              attributeKey
                                            ].nametoDisplay
                                          }{" "}
                                          <Star
                                            mandatory={
                                              PackagingGroupAttributes[
                                                attributeKey
                                              ]?.mandatory
                                            }
                                          />
                                        </td>
                                        {data.map((item, dataIndex) => (
                                          <td key={dataIndex}>
                                            {PackagingGroupAttributes[
                                              attributeKey
                                            ].type === "Image" ? (
                                              imageStatus[
                                                `${item[getID]}_${attributeKey}`
                                              ] === STATUSES.LOADING ? (
                                                "Loading..."
                                              ) : imageStatus[
                                                  `${item[getID]}_${attributeKey}`
                                                ] === STATUSES.ERROR ? (
                                                "Something went wrong"
                                              ) : imageStatus[
                                                  `${item[getID]}_${attributeKey}`
                                                ] === STATUSES.SUCCESS ? (
                                                imageUrls[
                                                  `${item[getID]}_${attributeKey}`
                                                ] ? (
                                                  <div className="image_container">
                                                    <img
                                                      src={
                                                        imageUrls[
                                                          `${item[getID]}_${attributeKey}`
                                                        ]
                                                      }
                                                      alt="images of data"
                                                    />
                                                    <i
                                                      onClick={() =>
                                                        handleDeleteImg(
                                                          item[getID],
                                                          attributeKey
                                                        )
                                                      }
                                                    >
                                                      <FontAwesomeIcon
                                                        icon={faTrash}
                                                      />
                                                    </i>
                                                  </div>
                                                ) : (
                                                  "Not found"
                                                )
                                              ) : null
                                            ) : item?.packagingInProgram &&
                                              Object.keys(
                                                item.packagingInProgram
                                              ).length > 0 &&
                                              item?.packagingInProgram
                                                ?.groupAttributes[
                                                attributeKey
                                              ] &&
                                              item?.packagingInProgram
                                                ?.groupAttributes[attributeKey]
                                                .length > 0 ? (
                                              item?.packagingInProgram?.groupAttributes[
                                                attributeKey
                                              ].join(", ")
                                            ) : (
                                              "Not found"
                                            )}
                                          </td>
                                        ))}
                                      </tr>
                                      {PackagingGroupAttributes[attributeKey]
                                        .type === "NestedDropdown" && (
                                        <tr
                                          key={`${attributeKey}_${index}_additional`}
                                        >
                                          <td>
                                            {
                                              PackagingGroupAttributes[
                                                attributeKey
                                              ].nametoDisplay2
                                            }{" "}
                                            <Star
                                              mandatory={
                                                PackagingGroupAttributes[
                                                  attributeKey
                                                ]?.mandatory
                                              }
                                            />
                                          </td>
                                          {data.map((item, dataIndex) => (
                                            <td key={dataIndex}>
                                              {item?.packagingInProgram &&
                                              Object.keys(
                                                item.packagingInProgram
                                              ).length > 0 &&
                                              item?.packagingInProgram
                                                ?.groupAttributes[
                                                `${attributeKey}nEsTeD`
                                              ] &&
                                              item?.packagingInProgram
                                                ?.groupAttributes[
                                                `${attributeKey}nEsTeD`
                                              ].length > 0
                                                ? item?.packagingInProgram?.groupAttributes[
                                                    `${attributeKey}nEsTeD`
                                                  ].join(", ")
                                                : "Not found"}
                                            </td>
                                          ))}
                                        </tr>
                                      )}
                                    </React.Fragment>
                                  )
                                )}
                                {Object.keys(PackagingAdditionalAttributes).map(
                                  (attributeKey, index) => (
                                    <React.Fragment
                                      key={`${attributeKey}_${index}`}
                                    >
                                      <tr>
                                        <td>
                                          {
                                            PackagingAdditionalAttributes[
                                              attributeKey
                                            ].nametoDisplay
                                          }{" "}
                                          <Star
                                            mandatory={
                                              PackagingAdditionalAttributes[
                                                attributeKey
                                              ]?.mandatory
                                            }
                                          />
                                        </td>
                                        {data.map((item, dataIndex) => (
                                          <td key={dataIndex}>
                                            {PackagingAdditionalAttributes[
                                              attributeKey
                                            ].type === "Image" ? (
                                              imageStatus[
                                                `${item[getID]}_${attributeKey}`
                                              ] === STATUSES.LOADING ? (
                                                "Loading..."
                                              ) : imageStatus[
                                                  `${item[getID]}_${attributeKey}`
                                                ] === STATUSES.ERROR ? (
                                                <ErrorBox
                                                  message={
                                                    imageErrors[
                                                      `${item[getID]}_${attributeKey}`
                                                    ] || "Something went wrong"
                                                  }
                                                />
                                              ) : imageStatus[
                                                  `${item[getID]}_${attributeKey}`
                                                ] === STATUSES.SUCCESS ? (
                                                imageUrls[
                                                  `${item[getID]}_${attributeKey}`
                                                ] ? (
                                                  <div className="image_container">
                                                    <img
                                                      src={
                                                        imageUrls[
                                                          `${item[getID]}_${attributeKey}`
                                                        ]
                                                      }
                                                      alt="images of data"
                                                    />
                                                    <i
                                                      onClick={() =>
                                                        handleDeleteImg(
                                                          item[getID],
                                                          attributeKey
                                                        )
                                                      }
                                                    >
                                                      <FontAwesomeIcon
                                                        icon={faTrash}
                                                      />
                                                    </i>
                                                  </div>
                                                ) : (
                                                  "Not found"
                                                )
                                              ) : null
                                            ) : item?.packagingInProgram &&
                                              Object.keys(
                                                item.packagingInProgram
                                              ).length > 0 &&
                                              item?.packagingInProgram
                                                ?.packagingAdditionalAttributes[
                                                attributeKey
                                              ] &&
                                              item?.packagingInProgram
                                                ?.packagingAdditionalAttributes[
                                                attributeKey
                                              ].length > 0 ? (
                                              item?.packagingInProgram?.packagingAdditionalAttributes[
                                                attributeKey
                                              ].join(", ")
                                            ) : (
                                              "Not found"
                                            )}
                                          </td>
                                        ))}
                                      </tr>
                                      {PackagingAdditionalAttributes[
                                        attributeKey
                                      ].type === "NestedDropdown" && (
                                        <tr
                                          key={`${attributeKey}_${index}_additional`}
                                        >
                                          <td>
                                            {
                                              PackagingAdditionalAttributes[
                                                attributeKey
                                              ].nametoDisplay2
                                            }{" "}
                                            <Star
                                              mandatory={
                                                PackagingAdditionalAttributes[
                                                  attributeKey
                                                ]?.mandatory
                                              }
                                            />
                                          </td>
                                          {data.map((item, dataIndex) => (
                                            <td key={dataIndex}>
                                              {item?.packagingInProgram &&
                                              Object.keys(
                                                item.packagingInProgram
                                              ).length > 0 &&
                                              item?.packagingInProgram
                                                ?.packagingAdditionalAttributes[
                                                `${attributeKey}nEsTeD`
                                              ] &&
                                              item?.packagingInProgram
                                                ?.packagingAdditionalAttributes[
                                                `${attributeKey}nEsTeD`
                                              ].length > 0
                                                ? item?.packagingInProgram?.packagingAdditionalAttributes[
                                                    `${attributeKey}nEsTeD`
                                                  ].join(", ")
                                                : "Not found"}
                                            </td>
                                          ))}
                                        </tr>
                                      )}
                                    </React.Fragment>
                                  )
                                )}
                              </>
                            ) : null}

                            {promotionGroupName.length > 0 &&
                            singlePromotionAttributeGroupStatus ===
                              STATUSES.SUCCESS ? (
                              <>
                                {Object.keys(PromotionGroupAttributes).map(
                                  (attributeKey, index) => (
                                    <React.Fragment
                                      key={`${attributeKey}_${index}`}
                                    >
                                      <tr>
                                        <td>
                                          {
                                            PromotionGroupAttributes[
                                              attributeKey
                                            ].nametoDisplay
                                          }{" "}
                                          <Star
                                            mandatory={
                                              PromotionGroupAttributes[
                                                attributeKey
                                              ]?.mandatory
                                            }
                                          />
                                        </td>
                                        {data.map((item, dataIndex) => (
                                          <td key={dataIndex}>
                                            {PromotionGroupAttributes[
                                              attributeKey
                                            ].type === "Image" ? (
                                              imageStatus[
                                                `${item[getID]}_${attributeKey}`
                                              ] === STATUSES.LOADING ? (
                                                "Loading..."
                                              ) : imageStatus[
                                                  `${item[getID]}_${attributeKey}`
                                                ] === STATUSES.ERROR ? (
                                                "Something went wrong"
                                              ) : imageStatus[
                                                  `${item[getID]}_${attributeKey}`
                                                ] === STATUSES.SUCCESS ? (
                                                imageUrls[
                                                  `${item[getID]}_${attributeKey}`
                                                ] ? (
                                                  <div className="image_container">
                                                    <img
                                                      src={
                                                        imageUrls[
                                                          `${item[getID]}_${attributeKey}`
                                                        ]
                                                      }
                                                      alt="images of data"
                                                    />
                                                    <i
                                                      onClick={() =>
                                                        handleDeleteImg(
                                                          item[getID],
                                                          attributeKey
                                                        )
                                                      }
                                                    >
                                                      <FontAwesomeIcon
                                                        icon={faTrash}
                                                      />
                                                    </i>
                                                  </div>
                                                ) : (
                                                  "Not found"
                                                )
                                              ) : null
                                            ) : item?.promotionInProgram &&
                                              Object.keys(
                                                item.promotionInProgram
                                              ).length > 0 &&
                                              item?.promotionInProgram
                                                ?.groupAttributes[
                                                attributeKey
                                              ] &&
                                              item?.promotionInProgram
                                                ?.groupAttributes[attributeKey]
                                                .length > 0 ? (
                                              item?.promotionInProgram?.groupAttributes[
                                                attributeKey
                                              ].join(", ")
                                            ) : (
                                              "Not found"
                                            )}
                                          </td>
                                        ))}
                                      </tr>
                                      {PromotionGroupAttributes[attributeKey]
                                        .type === "NestedDropdown" && (
                                        <tr
                                          key={`${attributeKey}_${index}_additional`}
                                        >
                                          <td>
                                            {
                                              PromotionGroupAttributes[
                                                attributeKey
                                              ].nametoDisplay2
                                            }{" "}
                                            <Star
                                              mandatory={
                                                PromotionGroupAttributes[
                                                  attributeKey
                                                ]?.mandatory
                                              }
                                            />
                                          </td>
                                          {data.map((item, dataIndex) => (
                                            <td key={dataIndex}>
                                              {item?.promotionInProgram &&
                                              Object.keys(
                                                item.promotionInProgram
                                              ).length > 0 &&
                                              item?.promotionInProgram
                                                ?.groupAttributes[
                                                `${attributeKey}nEsTeD`
                                              ] &&
                                              item?.promotionInProgram
                                                ?.groupAttributes[
                                                `${attributeKey}nEsTeD`
                                              ].length > 0
                                                ? item?.promotionInProgram?.groupAttributes[
                                                    `${attributeKey}nEsTeD`
                                                  ].join(", ")
                                                : "Not found"}
                                            </td>
                                          ))}
                                        </tr>
                                      )}
                                    </React.Fragment>
                                  )
                                )}
                                {Object.keys(PromotionAdditionalAttributes).map(
                                  (attributeKey, index) => (
                                    <React.Fragment
                                      key={`${attributeKey}_${index}`}
                                    >
                                      <tr>
                                        <td>
                                          {
                                            PromotionAdditionalAttributes[
                                              attributeKey
                                            ].nametoDisplay
                                          }{" "}
                                          <Star
                                            mandatory={
                                              PromotionAdditionalAttributes[
                                                attributeKey
                                              ]?.mandatory
                                            }
                                          />
                                        </td>
                                        {data.map((item, dataIndex) => (
                                          <td key={dataIndex}>
                                            {PromotionAdditionalAttributes[
                                              attributeKey
                                            ].type === "Image" ? (
                                              imageStatus[
                                                `${item[getID]}_${attributeKey}`
                                              ] === STATUSES.LOADING ? (
                                                "Loading..."
                                              ) : imageStatus[
                                                  `${item[getID]}_${attributeKey}`
                                                ] === STATUSES.ERROR ? (
                                                <ErrorBox
                                                  message={
                                                    imageErrors[
                                                      `${item[getID]}_${attributeKey}`
                                                    ] || "Something went wrong"
                                                  }
                                                />
                                              ) : imageStatus[
                                                  `${item[getID]}_${attributeKey}`
                                                ] === STATUSES.SUCCESS ? (
                                                imageUrls[
                                                  `${item[getID]}_${attributeKey}`
                                                ] ? (
                                                  <div className="image_container">
                                                    <img
                                                      src={
                                                        imageUrls[
                                                          `${item[getID]}_${attributeKey}`
                                                        ]
                                                      }
                                                      alt="images of data"
                                                    />
                                                    <i
                                                      onClick={() =>
                                                        handleDeleteImg(
                                                          item[getID],
                                                          attributeKey
                                                        )
                                                      }
                                                    >
                                                      <FontAwesomeIcon
                                                        icon={faTrash}
                                                      />
                                                    </i>
                                                  </div>
                                                ) : (
                                                  "Not found"
                                                )
                                              ) : null
                                            ) : item?.promotionInProgram &&
                                              Object.keys(
                                                item.promotionInProgram
                                              ).length > 0 &&
                                              item?.promotionInProgram
                                                ?.promotionAdditionalAttributes[
                                                attributeKey
                                              ] &&
                                              item?.promotionInProgram
                                                ?.promotionAdditionalAttributes[
                                                attributeKey
                                              ].length > 0 ? (
                                              item?.promotionInProgram?.promotionAdditionalAttributes[
                                                attributeKey
                                              ].join(", ")
                                            ) : (
                                              "Not found"
                                            )}
                                          </td>
                                        ))}
                                      </tr>
                                      {PromotionAdditionalAttributes[
                                        attributeKey
                                      ].type === "NestedDropdown" && (
                                        <tr
                                          key={`${attributeKey}_${index}_additional`}
                                        >
                                          <td>
                                            {
                                              PromotionAdditionalAttributes[
                                                attributeKey
                                              ].nametoDisplay2
                                            }{" "}
                                            <Star
                                              mandatory={
                                                PromotionAdditionalAttributes[
                                                  attributeKey
                                                ]?.mandatory
                                              }
                                            />
                                          </td>
                                          {data.map((item, dataIndex) => (
                                            <td key={dataIndex}>
                                              {item?.promotionInProgram &&
                                              Object.keys(
                                                item.promotionInProgram
                                              ).length > 0 &&
                                              item?.promotionInProgram
                                                ?.promotionAdditionalAttributes[
                                                `${attributeKey}nEsTeD`
                                              ] &&
                                              item?.promotionInProgram
                                                ?.promotionAdditionalAttributes[
                                                `${attributeKey}nEsTeD`
                                              ].length > 0
                                                ? item?.promotionInProgram?.promotionAdditionalAttributes[
                                                    `${attributeKey}nEsTeD`
                                                  ].join(", ")
                                                : "Not found"}
                                            </td>
                                          ))}
                                        </tr>
                                      )}
                                    </React.Fragment>
                                  )
                                )}
                              </>
                            ) : null}

                            {regionGroupName.length > 0 &&
                            singleRegionAttributeGroupStatus ===
                              STATUSES.SUCCESS ? (
                              <>
                                {Object.keys(RegionGroupAttributes).map(
                                  (attributeKey, index) => (
                                    <React.Fragment
                                      key={`${attributeKey}_${index}`}
                                    >
                                      <tr>
                                        <td>
                                          {
                                            RegionGroupAttributes[attributeKey]
                                              .nametoDisplay
                                          }{" "}
                                          <Star
                                            mandatory={
                                              RegionGroupAttributes[
                                                attributeKey
                                              ]?.mandatory
                                            }
                                          />
                                        </td>
                                        {data.map((item, dataIndex) => (
                                          <td key={dataIndex}>
                                            {RegionGroupAttributes[attributeKey]
                                              .type === "Image" ? (
                                              imageStatus[
                                                `${item[getID]}_${attributeKey}`
                                              ] === STATUSES.LOADING ? (
                                                "Loading..."
                                              ) : imageStatus[
                                                  `${item[getID]}_${attributeKey}`
                                                ] === STATUSES.ERROR ? (
                                                "Something went wrong"
                                              ) : imageStatus[
                                                  `${item[getID]}_${attributeKey}`
                                                ] === STATUSES.SUCCESS ? (
                                                imageUrls[
                                                  `${item[getID]}_${attributeKey}`
                                                ] ? (
                                                  <div className="image_container">
                                                    <img
                                                      src={
                                                        imageUrls[
                                                          `${item[getID]}_${attributeKey}`
                                                        ]
                                                      }
                                                      alt="images of data"
                                                    />
                                                    <i
                                                      onClick={() =>
                                                        handleDeleteImg(
                                                          item[getID],
                                                          attributeKey
                                                        )
                                                      }
                                                    >
                                                      <FontAwesomeIcon
                                                        icon={faTrash}
                                                      />
                                                    </i>
                                                  </div>
                                                ) : (
                                                  "Not found"
                                                )
                                              ) : null
                                            ) : item?.regionInProgram &&
                                              Object.keys(item.regionInProgram)
                                                .length > 0 &&
                                              item?.regionInProgram
                                                ?.groupAttributes[
                                                attributeKey
                                              ] &&
                                              item?.regionInProgram
                                                ?.groupAttributes[attributeKey]
                                                .length > 0 ? (
                                              item?.regionInProgram?.groupAttributes[
                                                attributeKey
                                              ].join(", ")
                                            ) : (
                                              "Not found"
                                            )}
                                          </td>
                                        ))}
                                      </tr>
                                      {RegionGroupAttributes[attributeKey]
                                        .type === "NestedDropdown" && (
                                        <tr
                                          key={`${attributeKey}_${index}_additional`}
                                        >
                                          <td>
                                            {
                                              RegionGroupAttributes[
                                                attributeKey
                                              ].nametoDisplay2
                                            }{" "}
                                            <Star
                                              mandatory={
                                                RegionGroupAttributes[
                                                  attributeKey
                                                ]?.mandatory
                                              }
                                            />
                                          </td>
                                          {data.map((item, dataIndex) => (
                                            <td key={dataIndex}>
                                              {item?.regionInProgram &&
                                              Object.keys(item.regionInProgram)
                                                .length > 0 &&
                                              item?.regionInProgram
                                                ?.groupAttributes[
                                                `${attributeKey}nEsTeD`
                                              ] &&
                                              item?.regionInProgram
                                                ?.groupAttributes[
                                                `${attributeKey}nEsTeD`
                                              ].length > 0
                                                ? item?.regionInProgram?.groupAttributes[
                                                    `${attributeKey}nEsTeD`
                                                  ].join(", ")
                                                : "Not found"}
                                            </td>
                                          ))}
                                        </tr>
                                      )}
                                    </React.Fragment>
                                  )
                                )}
                                {Object.keys(RegionAdditionalAttributes).map(
                                  (attributeKey, index) => (
                                    <React.Fragment
                                      key={`${attributeKey}_${index}`}
                                    >
                                      <tr>
                                        <td>
                                          {
                                            RegionAdditionalAttributes[
                                              attributeKey
                                            ].nametoDisplay
                                          }{" "}
                                          <Star
                                            mandatory={
                                              RegionAdditionalAttributes[
                                                attributeKey
                                              ]?.mandatory
                                            }
                                          />
                                        </td>
                                        {data.map((item, dataIndex) => (
                                          <td key={dataIndex}>
                                            {RegionAdditionalAttributes[
                                              attributeKey
                                            ].type === "Image" ? (
                                              imageStatus[
                                                `${item[getID]}_${attributeKey}`
                                              ] === STATUSES.LOADING ? (
                                                "Loading..."
                                              ) : imageStatus[
                                                  `${item[getID]}_${attributeKey}`
                                                ] === STATUSES.ERROR ? (
                                                <ErrorBox
                                                  message={
                                                    imageErrors[
                                                      `${item[getID]}_${attributeKey}`
                                                    ] || "Something went wrong"
                                                  }
                                                />
                                              ) : imageStatus[
                                                  `${item[getID]}_${attributeKey}`
                                                ] === STATUSES.SUCCESS ? (
                                                imageUrls[
                                                  `${item[getID]}_${attributeKey}`
                                                ] ? (
                                                  <div className="image_container">
                                                    <img
                                                      src={
                                                        imageUrls[
                                                          `${item[getID]}_${attributeKey}`
                                                        ]
                                                      }
                                                      alt="images of data"
                                                    />
                                                    <i
                                                      onClick={() =>
                                                        handleDeleteImg(
                                                          item[getID],
                                                          attributeKey
                                                        )
                                                      }
                                                    >
                                                      <FontAwesomeIcon
                                                        icon={faTrash}
                                                      />
                                                    </i>
                                                  </div>
                                                ) : (
                                                  "Not found"
                                                )
                                              ) : null
                                            ) : item?.regionInProgram &&
                                              Object.keys(item.regionInProgram)
                                                .length > 0 &&
                                              item?.regionInProgram
                                                ?.regionAdditionalAttributes[
                                                attributeKey
                                              ] &&
                                              item?.regionInProgram
                                                ?.regionAdditionalAttributes[
                                                attributeKey
                                              ].length > 0 ? (
                                              item?.regionInProgram?.regionAdditionalAttributes[
                                                attributeKey
                                              ].join(", ")
                                            ) : (
                                              "Not found"
                                            )}
                                          </td>
                                        ))}
                                      </tr>
                                      {RegionAdditionalAttributes[attributeKey]
                                        .type === "NestedDropdown" && (
                                        <tr
                                          key={`${attributeKey}_${index}_additional`}
                                        >
                                          <td>
                                            {
                                              RegionAdditionalAttributes[
                                                attributeKey
                                              ].nametoDisplay2
                                            }{" "}
                                            <Star
                                              mandatory={
                                                RegionAdditionalAttributes[
                                                  attributeKey
                                                ]?.mandatory
                                              }
                                            />
                                          </td>
                                          {data.map((item, dataIndex) => (
                                            <td key={dataIndex}>
                                              {item?.regionInProgram &&
                                              Object.keys(item.regionInProgram)
                                                .length > 0 &&
                                              item?.regionInProgram
                                                ?.regionAdditionalAttributes[
                                                `${attributeKey}nEsTeD`
                                              ] &&
                                              item?.regionInProgram
                                                ?.regionAdditionalAttributes[
                                                `${attributeKey}nEsTeD`
                                              ].length > 0
                                                ? item?.regionInProgram?.regionAdditionalAttributes[
                                                    `${attributeKey}nEsTeD`
                                                  ].join(", ")
                                                : "Not found"}
                                            </td>
                                          ))}
                                        </tr>
                                      )}
                                    </React.Fragment>
                                  )
                                )}
                              </>
                            ) : null}

                            {groupGroupName.length > 0 &&
                            singleGroupAttributeGroupStatus ===
                              STATUSES.SUCCESS ? (
                              <>
                                {Object.keys(GroupGroupAttributes).map(
                                  (attributeKey, index) => (
                                    <React.Fragment
                                      key={`${attributeKey}_${index}`}
                                    >
                                      <tr>
                                        <td>
                                          {
                                            GroupGroupAttributes[attributeKey]
                                              .nametoDisplay
                                          }{" "}
                                          <Star
                                            mandatory={
                                              GroupGroupAttributes[attributeKey]
                                                ?.mandatory
                                            }
                                          />
                                        </td>
                                        {data.map((item, dataIndex) => (
                                          <td key={dataIndex}>
                                            {GroupGroupAttributes[attributeKey]
                                              .type === "Image" ? (
                                              imageStatus[
                                                `${item[getID]}_${attributeKey}`
                                              ] === STATUSES.LOADING ? (
                                                "Loading..."
                                              ) : imageStatus[
                                                  `${item[getID]}_${attributeKey}`
                                                ] === STATUSES.ERROR ? (
                                                "Something went wrong"
                                              ) : imageStatus[
                                                  `${item[getID]}_${attributeKey}`
                                                ] === STATUSES.SUCCESS ? (
                                                imageUrls[
                                                  `${item[getID]}_${attributeKey}`
                                                ] ? (
                                                  <div className="image_container">
                                                    <img
                                                      src={
                                                        imageUrls[
                                                          `${item[getID]}_${attributeKey}`
                                                        ]
                                                      }
                                                      alt="images of data"
                                                    />
                                                    <i
                                                      onClick={() =>
                                                        handleDeleteImg(
                                                          item[getID],
                                                          attributeKey
                                                        )
                                                      }
                                                    >
                                                      <FontAwesomeIcon
                                                        icon={faTrash}
                                                      />
                                                    </i>
                                                  </div>
                                                ) : (
                                                  "Not found"
                                                )
                                              ) : null
                                            ) : item?.groupInProgram &&
                                              Object.keys(item.groupInProgram)
                                                .length > 0 &&
                                              item?.groupInProgram
                                                ?.groupAttributes[
                                                attributeKey
                                              ] &&
                                              item?.groupInProgram
                                                ?.groupAttributes[attributeKey]
                                                .length > 0 ? (
                                              item?.groupInProgram?.groupAttributes[
                                                attributeKey
                                              ].join(", ")
                                            ) : (
                                              "Not found"
                                            )}
                                          </td>
                                        ))}
                                      </tr>
                                      {GroupGroupAttributes[attributeKey]
                                        .type === "NestedDropdown" && (
                                        <tr
                                          key={`${attributeKey}_${index}_additional`}
                                        >
                                          <td>
                                            {
                                              GroupGroupAttributes[attributeKey]
                                                .nametoDisplay2
                                            }{" "}
                                            <Star
                                              mandatory={
                                                GroupGroupAttributes[
                                                  attributeKey
                                                ]?.mandatory
                                              }
                                            />
                                          </td>
                                          {data.map((item, dataIndex) => (
                                            <td key={dataIndex}>
                                              {item?.groupInProgram &&
                                              Object.keys(item.groupInProgram)
                                                .length > 0 &&
                                              item?.groupInProgram
                                                ?.groupAttributes[
                                                `${attributeKey}nEsTeD`
                                              ] &&
                                              item?.groupInProgram
                                                ?.groupAttributes[
                                                `${attributeKey}nEsTeD`
                                              ].length > 0
                                                ? item?.groupInProgram?.groupAttributes[
                                                    `${attributeKey}nEsTeD`
                                                  ].join(", ")
                                                : "Not found"}
                                            </td>
                                          ))}
                                        </tr>
                                      )}
                                    </React.Fragment>
                                  )
                                )}
                                {Object.keys(GroupAdditionalAttributes).map(
                                  (attributeKey, index) => (
                                    <React.Fragment
                                      key={`${attributeKey}_${index}`}
                                    >
                                      <tr>
                                        <td>
                                          {
                                            GroupAdditionalAttributes[
                                              attributeKey
                                            ].nametoDisplay
                                          }{" "}
                                          <Star
                                            mandatory={
                                              GroupAdditionalAttributes[
                                                attributeKey
                                              ]?.mandatory
                                            }
                                          />
                                        </td>
                                        {data.map((item, dataIndex) => (
                                          <td key={dataIndex}>
                                            {GroupAdditionalAttributes[
                                              attributeKey
                                            ].type === "Image" ? (
                                              imageStatus[
                                                `${item[getID]}_${attributeKey}`
                                              ] === STATUSES.LOADING ? (
                                                "Loading..."
                                              ) : imageStatus[
                                                  `${item[getID]}_${attributeKey}`
                                                ] === STATUSES.ERROR ? (
                                                <ErrorBox
                                                  message={
                                                    imageErrors[
                                                      `${item[getID]}_${attributeKey}`
                                                    ] || "Something went wrong"
                                                  }
                                                />
                                              ) : imageStatus[
                                                  `${item[getID]}_${attributeKey}`
                                                ] === STATUSES.SUCCESS ? (
                                                imageUrls[
                                                  `${item[getID]}_${attributeKey}`
                                                ] ? (
                                                  <div className="image_container">
                                                    <img
                                                      src={
                                                        imageUrls[
                                                          `${item[getID]}_${attributeKey}`
                                                        ]
                                                      }
                                                      alt="images of data"
                                                    />
                                                    <i
                                                      onClick={() =>
                                                        handleDeleteImg(
                                                          item[getID],
                                                          attributeKey
                                                        )
                                                      }
                                                    >
                                                      <FontAwesomeIcon
                                                        icon={faTrash}
                                                      />
                                                    </i>
                                                  </div>
                                                ) : (
                                                  "Not found"
                                                )
                                              ) : null
                                            ) : item?.groupInProgram &&
                                              Object.keys(item.groupInProgram)
                                                .length > 0 &&
                                              item?.groupInProgram
                                                ?.groupAdditionalAttributes[
                                                attributeKey
                                              ] &&
                                              item?.groupInProgram
                                                ?.groupAdditionalAttributes[
                                                attributeKey
                                              ].length > 0 ? (
                                              item?.groupInProgram?.groupAdditionalAttributes[
                                                attributeKey
                                              ].join(", ")
                                            ) : (
                                              "Not found"
                                            )}
                                          </td>
                                        ))}
                                      </tr>
                                      {GroupAdditionalAttributes[attributeKey]
                                        .type === "NestedDropdown" && (
                                        <tr
                                          key={`${attributeKey}_${index}_additional`}
                                        >
                                          <td>
                                            {
                                              GroupAdditionalAttributes[
                                                attributeKey
                                              ].nametoDisplay2
                                            }{" "}
                                            <Star
                                              mandatory={
                                                GroupAdditionalAttributes[
                                                  attributeKey
                                                ]?.mandatory
                                              }
                                            />
                                          </td>
                                          {data.map((item, dataIndex) => (
                                            <td key={dataIndex}>
                                              {item?.groupInProgram &&
                                              Object.keys(item.groupInProgram)
                                                .length > 0 &&
                                              item?.groupInProgram
                                                ?.groupAdditionalAttributes[
                                                `${attributeKey}nEsTeD`
                                              ] &&
                                              item?.groupInProgram
                                                ?.groupAdditionalAttributes[
                                                `${attributeKey}nEsTeD`
                                              ].length > 0
                                                ? item?.groupInProgram?.groupAdditionalAttributes[
                                                    `${attributeKey}nEsTeD`
                                                  ].join(", ")
                                                : "Not found"}
                                            </td>
                                          ))}
                                        </tr>
                                      )}
                                    </React.Fragment>
                                  )
                                )}
                              </>
                            ) : null}
                          </tbody>
                        </table>
                      </>
                    )
                  ) : null
                ) : null}
              </>
            )
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default EditProgram;

import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styles from "./WhyUs.module.css";
import whyus from "../../../assets/Images/whyus.png";

const WhyUs = () => {
  return (
    <>
      <div className={styles.Whyusection}>
        <Container>
          <Row>
            <Col sm={12} md={6}>
              <img style={{ alignSelf: "center" }} src={whyus} alt={"hero"} />
            </Col>
            <Col sm={12} md={6}>
              <h2 className={styles.whyushead}>Why Us ?</h2>
              <div className={styles.whyusflex}>
                <p className={styles.whyusnum}>5+</p>
                <p className={styles.whyustxt}>
                  {" "}
                  years of <br></br>Experience
                </p>
              </div>
              <p>
                With a combined 200+ years experience of our team, our team is a
                perfect blend of new age technological brekthroughs and business
                acumen. This enables the business breakthrough rather than mere
                problem solving.
              </p>
              <div className={styles.whyusflex}>
                <p className={styles.whyusnum}>100+</p>
                <p className={styles.whyustxt}>
                  Happy <br></br>clients
                </p>
              </div>
              <p>
                Our team has not only implemented the business applications, but
                solved some of the major problems of our clients. This is why so
                many satisfied clients can vouch for us.
              </p>
              <div className={styles.whyusflex}>
                <p className={styles.whyusnum}>50+</p>
                <p className={styles.whyustxt}>
                  Business <br></br>Integrations
                </p>
              </div>
              <p>
                Our team has not only implemented the business applications, but
                solved some of the major problems of our clients. This is why so
                many satisfied clients can vouch for us.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default WhyUs;

import React from 'react';
import { createSupplier, getAllSupplier, getOneSupplier, resetSupplierState } from '../../../../../store/Slices/marketMapTableSlices';
import { getAllSupplierAttributes, getOneSupplierAttributes } from '../../../../../store/Slices/marketMapAttributeSlice';
import { getSupplierImg, updateSupplierImg } from '../../../../../store/Slices/marketMapImageSlice';
import TableHomeCommon from '../../../MarketMapsComponents/TableHomeCommon/TableHomeCommon';
import { deleteSupplier, resetDeleteSupplierState, resetUpdateSupplierState, updateSupplier } from '../../../../../store/Slices/marketMapTableEditSlice';

const SupplierTableHome = () => {
  
  return (
    <TableHomeCommon
      title="Supplier"
      getAllTableDataLink = {getAllSupplier}  
      getAllAttributesLink={getAllSupplierAttributes}
      getOneAttributesLink={getOneSupplierAttributes}

      getAllTableDataSelector = {(state) => state.AllSuppliers}
      allAttributesGroupSelector={(state) => state.AllSupplierAttributeGroup}
      oneAttributeGroupSelector={(state) => state.OneSupplierAttributesGroup}
      classNamePrefix="Supplier"

      linkForRedirectSetting = "Add-Supplier-Attributes"
      additionalAttributesName = "supplierAdditionalAttributes"
      getImageLink = {getSupplierImg}
      getImagesSelector = {(state) => state.GetSupplierImg.images}
      getImagesStatusSelector = {(state) => state.GetSupplierImg.status}
      getID = "supplierId"

      add_button_url={"Add-Supplier"}
      edit_button_url={"Edit-Supplier"}


      
      deleteLink={deleteSupplier}
      deleteSelector = {(state) => state.DeleteSupplier}
      resetDeleteState = {resetDeleteSupplierState}

      createTablelink={createSupplier}
      resetTableState={resetSupplierState}
      tableDataSelector={(state) => state.Supplier}
     
      getAllDataForReferenceLink={getAllSupplier}
      getAllDataForReference={(state) => state.AllSuppliers}
      ImageUploadLink={updateSupplierImg}

      getOneTableItemLink = {getOneSupplier}
      getOnetableItemSelector = {(state) => state.OneSupplier}
      updateTableItemLink = {updateSupplier}
      updateTableItemSelector = {(state) => state.UpdateSupplier}
      resetUpdateItemState = {resetUpdateSupplierState}

    />
  );
};

export default SupplierTableHome;
import React from 'react';
import { createRetailer, getAllRetailer, getOneRetailer, resetRetailerState } from '../../../../../store/Slices/marketMapTableSlices';
import { getAllRetailerAttributes, getOneRetailerAttributes } from '../../../../../store/Slices/marketMapAttributeSlice';
import { getRetailerImg, updateRetailerImg } from '../../../../../store/Slices/marketMapImageSlice';
import TableHomeCommon from '../../../MarketMapsComponents/TableHomeCommon/TableHomeCommon';
import { deleteRetailer, resetDeleteRetailerState, resetUpdateRetailerState, updateRetailer } from '../../../../../store/Slices/marketMapTableEditSlice';

const RetailerTableHome = () => {
  
  return (
    <TableHomeCommon
      title="Retailer"
      getAllTableDataLink = {getAllRetailer}  
      getAllAttributesLink={getAllRetailerAttributes}
      getOneAttributesLink={getOneRetailerAttributes}

      getAllTableDataSelector = {(state) => state.AllRetailers}
      allAttributesGroupSelector={(state) => state.AllRetailerAttributeGroup}
      oneAttributeGroupSelector={(state) => state.OneRetailerAttributesGroup}
      classNamePrefix="Retailer"

      linkForRedirectSetting = "Add-Retailer-Attributes"
      additionalAttributesName = "retailerAdditionalAttributes"
      getImageLink = {getRetailerImg}
      getImagesSelector = {(state) => state.GetRetailerImg.images}
      getImagesStatusSelector = {(state) => state.GetRetailerImg.status}
      getID = "retailerId"

      add_button_url={"Add-Retailer"}
      edit_button_url={"Edit-Retailer"}


      
      deleteLink={deleteRetailer}
      deleteSelector = {(state) => state.DeleteRetailer}
      resetDeleteState = {resetDeleteRetailerState}

      createTablelink={createRetailer}
      resetTableState={resetRetailerState}
      tableDataSelector={(state) => state.Retailer}
     
      getAllDataForReferenceLink={getAllRetailer}
      getAllDataForReference={(state) => state.AllRetailers}
      ImageUploadLink={updateRetailerImg}

      getOneTableItemLink = {getOneRetailer}
      getOnetableItemSelector = {(state) => state.OneRetailer}
      updateTableItemLink = {updateRetailer}
      updateTableItemSelector = {(state) => state.UpdateRetailer}
      resetUpdateItemState = {resetUpdateRetailerState}

    />
  );
};

export default RetailerTableHome;




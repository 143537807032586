import React, { useState } from 'react';
// All Css 
import './MarketMapsPage.css';
import '../../components/MarketMaps/MarketMap.css';
import '../../components/MarketMaps/MarketAllPages/AllTables/CommonTableHome.css';
import '../../components/MarketMaps/MarketAllPages/AllTables/EditTableData.css';
import '../../components/MarketMaps/MarketAllPages/AllTables/AddTableData.css';

import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { Outlet, } from "react-router";
import MarketNav from '../../components/MarketMaps/MarketMapsComponents/MarketNav/MarketNav';
// import MarketTabs from '../../components/MarketMaps/MarketMapsComponents/MarketTabs/MarketTabs';
// import MarketSidebar from '../../components/MarketMaps/MarketMapsComponents/MarketSidebar/MarketSidebar';
import FilterBox from '../../components/MarketMaps/MarketMapsComponents/FilterBox/FilterBox';
import 'animate.css';
import Sidebar from '../../components/Common/SideBar/SideBar';

import {MarketSidebarData} from '../../components/MarketMaps/Data';
import { useSelector } from 'react-redux';
import UserSidebar from '../../components/Common/UserSidebar/UserSidebar';
import Breadcrumbs from '../../components/Common/Breadcrumbs/Breadcrumbs';
// import DataSelection from '../../components/MarketMaps/MarketMapsComponents/DataSelection/DataSelection';




const MarketMapsPage = () => {
  const handle = useFullScreenHandle();
  // const navigate = useNavigate();


  // const { token, userDetails } = useSelector((state) => state.auth);
  // useEffect(()=>{
  //   if(!token && !userDetails){
  //     navigate('/login');
  //   }
  // }, [])

  // user side bar toggle 
  const [userSidebarToggle, setuserSidebarToggle] = useState(false);
  const toggle_sidebar_user = () => {
    setuserSidebarToggle(!userSidebarToggle);
    // hide scrollBar 
    if (typeof window != 'undefined' && window.document) {
      document.body.style.overflow = 'hidden';
    }
  };

  // filter box toggle 
  const [filterToggle, setfilterToggle] = useState(false);
  const toggle_filter_box = () => {
    setfilterToggle(!filterToggle);
  };

  // Sidebar toggle 
  const isSideBarToggled = useSelector((state) => state.sideBarToggle);

  return (
    <div className="market_">
        <MarketNav toggle_sidebar_user={toggle_sidebar_user} toggle_filter_box = {toggle_filter_box} handle={handle}/>
        <Sidebar SidebarData={MarketSidebarData} />

        <div className={isSideBarToggled ? "market_box open" : "market_box"}> 
          <FullScreen handle={handle} className="market_outlet">
            <Breadcrumbs/>
            <Outlet/>
          </FullScreen>

          {/* User Sidebar  */}
          <div className={ userSidebarToggle ? 'user_sidebar' : 'user_sidebar user_sidebar_close'}>
            <UserSidebar toggle_sidebar_user={toggle_sidebar_user} />
          </div>
          
        </div>
 
        {/* marketMap filter  */}
        <div className={ filterToggle ? 'filter_container' : 'filter_container filter_container_close'}>
          <FilterBox toggle_filter_box={toggle_filter_box} />
        </div>
        {filterToggle || userSidebarToggle ? <div className='overlay_blur'></div>:null}
        
        {/* <DataSelection/> */}
        {/* <MarketSidebar/> */}
        
    </div>

  )
}
export default MarketMapsPage;


import {configureStore} from '@reduxjs/toolkit';
import { createGroupSlice, createManufacturerSlice, createPackagingSlice, createProductSlice, createPromotionSlice, createRegionSlice, createRetailerSlice, createSupplierSlice, getAllGroupSlice, getAllManufacturerSlice, getAllPackagingSlice, getAllProductSlice, getAllPromotionSlice, getAllRegionSlice, getAllSupplierSlice, getAllRetailerSlice, getOneGroupSlice, getOneManufacturerSlice, getOnePackagingSlice, getOneProductSlice, getOnePromotionSlice, getOneRegionSlice, getOneRetailerSlice, getOneSupplierSlice } from './Slices/marketMapTableSlices.js';
import { DataSelectionToggleSlice, SideBarToggleSlice } from './Slices/sideBarSlice.js';
import { deleteGroupSlice, deleteManufacturerSlice, deletePackagingSlice, deleteProductSlice, deletePromotionSlice, deleteRegionSlice, deleteRetailerSlice, deleteSupplierSlice, updateGroupSlice, updateManufacturerSlice, updatePackagingSlice, updateProductSlice, updatePromotionSlice, updateRegionSlice, updateRetailerSlice, updateSupplierSlice } from './Slices/marketMapTableEditSlice.js';

import { createGroupAttributeGroupSlice, createManufacturerAttributeGroupSlice, createPackagingAttributeGroupSlice, createProductAttributeGroupSlice, createProgramAttributeGroupSlice, createPromotionAttributeGroupSlice, createRegionAttributeGroupSlice, createRetailerAttributeGroupSlice, createSupplierAttributeGroupSlice, getAllGroupAttributesSlice, getAllManufacturerAttributesSlice, getAllPackagingAttributesSlice, getAllProductAttributesSlice, getAllProgramAttributesSlice, getAllPromotionAttributesSlice, getAllRegionAttributesSlice, getAllRetailerAttributesSlice, getAllSupplierAttributesSlice, getOneGroupAttributesSlice, getOneManufacturerAttributesSlice, getOnePackagingAttributesSlice, getOneProductAttributesSlice, getOneProgramAttributesSlice, getOnePromotionAttributesSlice, getOneRegionAttributesSlice, getOneRetailerAttributesSlice, getOneSupplierAttributesSlice, updateGroupAttributeGroupSlice, updateGroupGroupNameSlice, updateManufacturerAttributeGroupSlice, updateManufacturerGroupNameSlice, updatePackagingAttributeGroupSlice, updatePackagingGroupNameSlice, updateProductAttributeGroupSlice, updateProductGroupNameSlice, updateProgramAttributeGroupSlice, updateProgramGroupNameSlice, updatePromotionAttributeGroupSlice, updatePromotionGroupNameSlice, updateRegionAttributeGroupSlice, updateRegionGroupNameSlice, updateRetailerAttributeGroupSlice, updateRetailerGroupNameSlice, updateSupplierAttributeGroupSlice, updateSupplierGroupNameSlice } from './Slices/marketMapAttributeSlice.js';

import {BarChartDataSlice, shortedDataSlice, shortedXaxisDataSlice, shortedYaxisDataSlice, shortedZaxisDataSlice} from './Slices/marketMapShortedDataSlice.js';

import { addGroupInProgramSlice, addManufacturerInProgramSlice, addPackagingInProgramSlice, addProductInProgramSlice, addPromotionInProgramSlice, addRegionInProgramSlice, addRetailerInProgramSlice, addSupplierInProgramSlice, createProgramSlice, deleteManufacturerInProgramSlice, deleteProductInProgramSlice, deleteProgramSlice, deleteRetailerInProgramSlice, deleteSupplierInProgramSlice, getAllProgramSlice, getOneProgramSlice, updateProgramSlice } from './Slices/marketMapProgram.js';
import { authSlice, userLoginSlice, userLogoutSlice, userPasswordResetSlice, userRegisterSlice, userTokenSlice, userValidateSlice } from './Slices/userAuthSlice.js';
import { deleteProgramImgSlice, getGroupImgSlice, getManufacturerImgSlice, getPackagingImgSlice, getProductImgSlice, getProgramImgSlice, getPromotionImgSlice, getRegionImgSlice, getRetailerImgSlice, getSupplierImgSlice, updateGroupImgSlice, updateManufacturerImgSlice, updatePackagingImgSlice, updateProductImgSlice, updateProgramImgSlice, updatePromotionImgSlice, updateRegionImgSlice, updateRetailerImgSlice, updateSupplierImgSlice, uploadGroupImgSlice, uploadManufacturerImgSlice, uploadPackagingImgSlice, uploadProductImgSlice, uploadProgramImgSlice, uploadPromotionImgSlice, uploadRegionImgSlice, uploadRetailerImgSlice, uploadSupplierImgSlice } from './Slices/marketMapImageSlice.js';



const store = configureStore({
    reducer : {
        UserRegister : userRegisterSlice.reducer,
        UserToken : userTokenSlice.reducer,
        UserValidate : userValidateSlice.reducer,
        UserLogin : userLoginSlice.reducer,
        UserLogout : userLogoutSlice.reducer,
        UserPasswordReset : userPasswordResetSlice.reducer,
        auth : authSlice.reducer,
        
        sideBarToggle: SideBarToggleSlice.reducer,
        dataSelectionToggle: DataSelectionToggleSlice.reducer,

        Product: createProductSlice.reducer,
        AllProducts : getAllProductSlice.reducer,
        OneProduct : getOneProductSlice.reducer,
        
        Retailer : createRetailerSlice.reducer,
        AllRetailers : getAllRetailerSlice.reducer,
        OneRetailer : getOneRetailerSlice.reducer,

        Manufacturer : createManufacturerSlice.reducer,
        AllManufacturers : getAllManufacturerSlice.reducer,
        OneManufacturer : getOneManufacturerSlice.reducer,
        
        Supplier : createSupplierSlice.reducer,
        AllSuppliers : getAllSupplierSlice.reducer,
        OneSupplier : getOneSupplierSlice.reducer,
        
        Packaging : createPackagingSlice.reducer,
        AllPackagings : getAllPackagingSlice.reducer,
        OnePackaging : getOnePackagingSlice.reducer,
        
        Promotion : createPromotionSlice.reducer,
        AllPromotions : getAllPromotionSlice.reducer,
        OnePromotion : getOnePromotionSlice.reducer,
        
        Region : createRegionSlice.reducer,
        AllRegions : getAllRegionSlice.reducer,
        OneRegion : getOneRegionSlice.reducer,
        
        Group : createGroupSlice.reducer,
        AllGroups : getAllGroupSlice.reducer,
        OneGroup : getOneGroupSlice.reducer,

        DeleteProduct : deleteProductSlice.reducer,
        DeleteRetailer : deleteRetailerSlice.reducer,
        DeleteManufacturer : deleteManufacturerSlice.reducer,
        DeleteSupplier : deleteSupplierSlice.reducer,
        DeletePackaging : deletePackagingSlice.reducer,
        DeletePromotion : deletePromotionSlice.reducer,
        DeleteRegion : deleteRegionSlice.reducer,
        DeleteGroup : deleteGroupSlice.reducer,

        UpdateProduct : updateProductSlice.reducer,
        UpdateRetailer : updateRetailerSlice.reducer,
        UpdateManufacturer : updateManufacturerSlice.reducer,
        UpdateSupplier : updateSupplierSlice.reducer,
        UpdatePackaging : updatePackagingSlice.reducer,
        UpdatePromotion : updatePromotionSlice.reducer,
        UpdateRegion : updateRegionSlice.reducer,
        UpdateGroup : updateGroupSlice.reducer,



        ProductAttributeGroup : createProductAttributeGroupSlice.reducer,
        UpdateProductAttributeGroup : updateProductAttributeGroupSlice.reducer,
        AllProductAttributeGroup : getAllProductAttributesSlice.reducer,
        OneProductAttributesGroup : getOneProductAttributesSlice.reducer,
        UpdateProductGroupName : updateProductGroupNameSlice.reducer,

        RetailerAttributeGroup : createRetailerAttributeGroupSlice.reducer,
        UpdateRetailerAttributeGroup : updateRetailerAttributeGroupSlice.reducer,
        AllRetailerAttributeGroup : getAllRetailerAttributesSlice.reducer,
        OneRetailerAttributesGroup : getOneRetailerAttributesSlice.reducer,
        UpdateRetailerGroupName : updateRetailerGroupNameSlice.reducer,


        ManufacturerAttributeGroup : createManufacturerAttributeGroupSlice.reducer,
        UpdateManufacturerAttributeGroup : updateManufacturerAttributeGroupSlice.reducer,
        AllManufacturerAttributeGroup : getAllManufacturerAttributesSlice.reducer,
        OneManufacturerAttributesGroup : getOneManufacturerAttributesSlice.reducer,
        UpdateManufacturerGroupName : updateManufacturerGroupNameSlice.reducer,


        SupplierAttributeGroup : createSupplierAttributeGroupSlice.reducer,
        UpdateSupplierAttributeGroup : updateSupplierAttributeGroupSlice.reducer,
        AllSupplierAttributeGroup : getAllSupplierAttributesSlice.reducer,
        OneSupplierAttributesGroup : getOneSupplierAttributesSlice.reducer,
        UpdateSupplierGroupName : updateSupplierGroupNameSlice.reducer,
        

        PackagingAttributeGroup : createPackagingAttributeGroupSlice.reducer,
        UpdatePackagingAttributeGroup : updatePackagingAttributeGroupSlice.reducer,
        AllPackagingAttributeGroup : getAllPackagingAttributesSlice.reducer,
        OnePackagingAttributesGroup : getOnePackagingAttributesSlice.reducer,
        UpdatePackagingGroupName : updatePackagingGroupNameSlice.reducer,


        PromotionAttributeGroup : createPromotionAttributeGroupSlice.reducer,
        UpdatePromotionAttributeGroup : updatePromotionAttributeGroupSlice.reducer,
        AllPromotionAttributeGroup : getAllPromotionAttributesSlice.reducer,
        OnePromotionAttributesGroup : getOnePromotionAttributesSlice.reducer,
        UpdatePromotionGroupName : updatePromotionGroupNameSlice.reducer,


        ProgramAttributeGroup : createProgramAttributeGroupSlice.reducer,
        UpdateProgramAttributeGroup : updateProgramAttributeGroupSlice.reducer,
        AllProgramAttributeGroup : getAllProgramAttributesSlice.reducer,
        OneProgramAttributesGroup : getOneProgramAttributesSlice.reducer,
        UpdateProgramGroupName : updateProgramGroupNameSlice.reducer,


        RegionAttributeGroup : createRegionAttributeGroupSlice.reducer,
        UpdateRegionAttributeGroup : updateRegionAttributeGroupSlice.reducer,
        AllRegionAttributeGroup : getAllRegionAttributesSlice.reducer,
        OneRegionAttributesGroup : getOneRegionAttributesSlice.reducer,
        UpdateRegionGroupName : updateRegionGroupNameSlice.reducer,


        GroupAttributeGroup : createGroupAttributeGroupSlice.reducer,
        UpdateGroupAttributeGroup : updateGroupAttributeGroupSlice.reducer,
        AllGroupAttributeGroup : getAllGroupAttributesSlice.reducer,
        OneGroupAttributesGroup : getOneGroupAttributesSlice.reducer,
        UpdateGroupGroupName : updateGroupGroupNameSlice.reducer,



        Program : createProgramSlice.reducer,
        AllPrograms : getAllProgramSlice.reducer,
        UpdateProgram  : updateProgramSlice.reducer,
        OneProgram  : getOneProgramSlice.reducer,
        DeleteProgram  : deleteProgramSlice.reducer,

        RetailerInProgram : addRetailerInProgramSlice.reducer,
        ManufacturerInProgram : addManufacturerInProgramSlice.reducer,
        SupplierInProgram : addSupplierInProgramSlice.reducer,
        ProductInProgram : addProductInProgramSlice.reducer,
        PackagingInProgram : addPackagingInProgramSlice.reducer,
        PromotionInProgram : addPromotionInProgramSlice.reducer,
        RegionInProgram : addRegionInProgramSlice.reducer,
        GroupInProgram : addGroupInProgramSlice.reducer,


        DeleteRetailerInProgram : deleteRetailerInProgramSlice.reducer,
        DeleteManufacturerInProgram : deleteManufacturerInProgramSlice.reducer,
        DeleteSupplierInProgram : deleteSupplierInProgramSlice.reducer,
        DeleteProductInProgram : deleteProductInProgramSlice.reducer,



        
        UploadProductImg : uploadProductImgSlice.reducer,
        GetProductImg : getProductImgSlice.reducer,
        UpdateProductImg : updateProductImgSlice.reducer,

        UploadRetailerImg : uploadRetailerImgSlice.reducer,
        GetRetailerImg : getRetailerImgSlice.reducer,
        UpdateRetailerImg : updateRetailerImgSlice.reducer,

        UploadManufacturerImg : uploadManufacturerImgSlice.reducer,
        GetManufacturerImg : getManufacturerImgSlice.reducer,
        UpdateManufacturerImg : updateManufacturerImgSlice.reducer,

        UploadSupplierImg : uploadSupplierImgSlice.reducer,
        GetSupplierImg : getSupplierImgSlice.reducer,
        UpdateSupplierImg : updateSupplierImgSlice.reducer,

        UploadPackagingImg : uploadPackagingImgSlice.reducer,
        GetPackagingImg : getPackagingImgSlice.reducer,
        UpdatePackagingImg : updatePackagingImgSlice.reducer,

        UploadPromotionImg : uploadPromotionImgSlice.reducer,
        GetPromotionImg : getPromotionImgSlice.reducer,
        UpdatePromotionImg : updatePromotionImgSlice.reducer,

        UploadRegionImg : uploadRegionImgSlice.reducer,
        GetRegionImg : getRegionImgSlice.reducer,
        UpdateRegionImg : updateRegionImgSlice.reducer,

        UploadGroupImg : uploadGroupImgSlice.reducer,
        GetGroupImg : getGroupImgSlice.reducer,
        UpdateGroupImg : updateGroupImgSlice.reducer,

        UploadProgramImg : uploadProgramImgSlice.reducer,
        GetProgramImg : getProgramImgSlice.reducer,
        UpdateProgramImg : updateProgramImgSlice.reducer,
        DeleteProgramImg : deleteProgramImgSlice.reducer,



        shortedData : shortedDataSlice.reducer,
        BarChartData : BarChartDataSlice.reducer,


        shortedXaxisData : shortedXaxisDataSlice.reducer,
        shortedYaxisData : shortedYaxisDataSlice.reducer,
        shortedZaxisData : shortedZaxisDataSlice.reducer,

    }
});



export default store;
import React from 'react';
import './SettingHome.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCube, faIndustry, faShop, faSitemap, faBoxOpen, faSquarePollVertical, faLayerGroup, faGlobe, faUsersRays, faAnglesRight } from "@fortawesome/free-solid-svg-icons";
import img from '../../../../assets/Images/product.svg'
import img1 from '../../../../assets/Images/retail.svg'
import img2 from '../../../../assets/Images/manufacture.svg'
import img3 from '../../../../assets/Images/supplier.svg'
import img4 from '../../../../assets/Images/package.svg'
import img6 from '../../../../assets/Images/program.svg'
import img7 from '../../../../assets/Images/earth.svg'
import img8 from '../../../../assets/Images/groups.svg'

// import ProductAtt from '../AllAttributes/ProductAtt';
// import RetailerAtt from '../AllAttributes/RetailerAtt';
// import ManufacturerAtt from '../AllAttributes/ManufacturerAtt';
// import SupplierAtt from '../AllAttributes/SupplierAtt';
// import PackagingAtt from '../AllAttributes/PackagingAtt';
// import MarketingCallOutAtt from '../AllAttributes/MarketingCallOutAtt';
// import ProgramAtt from '../AllAttributes/ProgramAtt';
// import RegionAtt from '../AllAttributes/RegionAtt';
// import GroupAtt from '../AllAttributes/GroupAtt';
import { Link } from 'react-router-dom';


const SettingHome = () => {
 

  const data = [
    {
      cover: <img src={img} alt="Product" />,
      title: "Product",
      decs: "Select and add new attributes in the product table and give them a group name.",
      link : "Add-Product-Attributes"
    },
    {
      cover: <img src={img1} alt="Product" />,
      title: "Retailer",
      decs: "Select and add new attributes in the retailer table and give them a group name.",
      link : "Add-Retailer-Attributes"
    },
    {
      cover: <img src={img2} alt="Product" />,
      title: "Manufacturer",
      decs: "Select and add new attributes in the manufacturer table and give them a group name.",
      link : "Add-Manufacturer-Attributes"
    },
    {
      cover:  <img src={img3} alt="Product" />,
      title: "Supplier",
      decs: "Select and add new attributes in the supplier table and give them a group name.",
      link : "Add-Supplier-Attributes"
    },
    {
      cover:  <img src={img4} alt="Product" />,
      title: "Packaging Details",
      decs: "Select and add new attributes in the packaging details table and give them a group name.",
      link : "Add-Packaging-Details-Attributes"
    },
    {
      cover: <img src={img1} alt="Product" />,
      title: "Marketing Callout",
      decs: "Select and add new attributes in the marketing callout table and give them a group name.",
      link : "Add-Marketing-Callout-Attributes"
    },
    {
      cover: <img src={img6} alt="Product" />,
      title: "Program",
      decs: "Select and add new attributes in the program table and give them a group name.",
      link : "Add-Program-Attributes"
    },
    {
      cover: <img src={img7} alt="Product" />,
      title: "Region",
      decs: "Select and add new attributes in the region table and give them a group name.",
      link : "Add-Region-Attributes"
    },
    {
      cover: <img src={img8} alt="Product" />,
      title: "All Customized Group",
      decs: "Select and add new attributes in the customized group table and give them a group name.",
      link : "Add-Groups-Attributes"
    },
  ]
  
  return (
    <div className='market_map_setting'>
    <h5 className='text-center'>Settings</h5>
    <div className="attributes_container">
        <div className='attributes_grid'>
        {data.map((item, index) => {
            return (
              <div className={"attributes_box animate__animated animate__zoomIn"} key={index}>
                <Link to={item.link} style={{textDecoration: "none"}} >
                  <div className="attributes_card">          
                    <div className="details">

                      <div className="col1">
                        <div className=''>
                          <i>{item.cover}</i>
                        </div>
                        <h6>{item.title}</h6>
                      </div>
                      
                      <div className="col1">
                        <p>{item.decs}</p>
                      </div>
                      {/* <i><FontAwesomeIcon icon={faAnglesRight}/></i> */}
                    </div>
                  </div>
                </Link>
              </div>
            )
          })}
        </div>

      </div>
    </div>
  )
}

export default SettingHome;










// import React, { useState } from 'react';
// import './SettingHome.css';
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faCube, faIndustry, faShop, faSitemap, faBoxOpen, faSquarePollVertical, faLayerGroup, faGlobe, faUsersRays, faChevronDown, faChevronUp, faChevronRight } from "@fortawesome/free-solid-svg-icons";

// import ProductAtt from '../AllAttributes/ProductAtt';
// import RetailerAtt from '../AllAttributes/RetailerAtt';
// import ManufacturerAtt from '../AllAttributes/ManufacturerAtt';
// import SupplierAtt from '../AllAttributes/SupplierAtt';
// import PackagingAtt from '../AllAttributes/PackagingAtt';
// import MarketingCallOutAtt from '../AllAttributes/MarketingCallOutAtt';
// import ProgramAtt from '../AllAttributes/ProgramAtt';
// import RegionAtt from '../AllAttributes/RegionAtt';
// import GroupAtt from '../AllAttributes/GroupAtt';


// const SettingHome = () => {
 
//   const [selected , setSelected] = useState(null);
//   const handleShowHide = (i)=>{
//     if(selected === i){
//       return setSelected(null);
//     }
//     setSelected(i);
//     console.log(selected);
//   }


//   const data = [
//     {
//       cover: <i><FontAwesomeIcon icon={faCube} /></i>,
//       title: "Product",
//       decs: "Select and add new attributes in the product table and give them a group name.",
//       link : "AddProductAttributes"
//     },
//     {
//       cover: <i><FontAwesomeIcon icon={faShop} /></i>,
//       title: "Retailer",
//       decs: "Select and add new attributes in the retailer table and give them a group name.",
//       link : "AddRetailerAttributes"
//     },
//     {
//       cover: <i><FontAwesomeIcon icon={faIndustry} /></i>,
//       title: "Manufacturer",
//       decs: "Select and add new attributes in the manufacturer table and give them a group name.",
//       link : "AddManufacturerAttributes"
//     },
//     {
//       cover:  <i><FontAwesomeIcon icon={faSitemap} /></i>,
//       title: "Supplier",
//       decs: "Select and add new attributes in the supplier table and give them a group name.",
//       link : "AddSupplierAttributes"
//     },
//     {
//       cover:  <i><FontAwesomeIcon icon={faBoxOpen} /></i>,
//       title: "Packaging Details",
//       decs: "Select and add new attributes in the packaging details table and give them a group name.",
//       link : "AddPackagingDetailsAttributes"
//     },
//     {
//       cover:  <i><FontAwesomeIcon icon={faSquarePollVertical} /></i>,
//       title: "Marketing Callout",
//       decs: "Select and add new attributes in the marketing callout table and give them a group name.",
//       link : "AddMarketingCalloutAttributes"
//     },
//     {
//       cover:  <i><FontAwesomeIcon icon={faLayerGroup} /></i>,
//       title: "Program",
//       decs: "Select and add new attributes in the program table and give them a group name.",
//       link : "AddProgramAttributes"
//     },
//     {
//       cover:  <i><FontAwesomeIcon icon={faGlobe} /></i>,
//       title: "Region",
//       decs: "Select and add new attributes in the region table and give them a group name.",
//       link : "AddRegionAttributes"
//     },
//     {
//       cover:  <i><FontAwesomeIcon icon={faUsersRays} /></i>,
//       title: "Groups",
//       decs: "Select and add new attributes in the groups table and give them a group name.",
//       link : "AddGroupsAttributes"
//     },
//   ]
  
//   return (
//     <div className='market_map_setting'>
//     <h5>Settings</h5>
//     <div className="attributes_container">
//         <h6>Select attributes</h6>
        
//           {data.map((item, index) => {
//             return (
//               <div className={selected===index ? "attributes_box open" : "attributes_box"} key={index}>
//                 <div className="attributes_card open" onClick={() => handleShowHide(index)}>          
//                   <div className="details">

//                     <div className="col1">
//                       <div className='img'>
//                         <i>{item.cover}</i>
//                       </div>
//                       <h6>{item.title}</h6>
//                     </div>
                    
//                     <div className="col1">
//                       <p>{item.decs}</p>
//                     </div>
//                     <i><FontAwesomeIcon icon={selected===index?faChevronRight : faChevronDown }/></i>
//                   </div>
//                 </div>
//                 <div className={selected===index ? "attributes_components open" : "attributes_components"}>
//                   <ProductAtt/>
//                 </div>
//               </div>
//             )
//           })}
          

//       </div>
//     </div>
//   )
// }

// export default SettingHome;

import React from 'react';
import { createProduct, getAllProduct, resetProductState } from '../../../../../store/Slices/marketMapTableSlices';
import { getAllProductAttributes, getOneProductAttributes } from '../../../../../store/Slices/marketMapAttributeSlice.js';
import TableAddCommon from '../../../MarketMapsComponents/TableAddCommon/TableAddCommon.jsx';
import { uploadProductImg } from '../../../../../store/Slices/marketMapImageSlice.js';


const AddProductTable = () => {
   
  return (
    <TableAddCommon
      title="Product"
      getAllAttributesLink={getAllProductAttributes}
      getOneAttributesLink={getOneProductAttributes}
      createTablelink={createProduct}
      resetTableState={resetProductState}
      tableDataSelector={(state) => state.Product}
      allAttributesGroupSelector={(state) => state.AllProductAttributeGroup}
      oneAttributeGroupSelector={(state) => state.OneProductAttributesGroup}
      classNamePrefix="Product"
      linkForRedirectSetting = "Add-Product-Attributes"
      additionalAttributesName = "productAdditionalAttributes"
      getAllDataForReferenceLink = {getAllProduct}
      getAllDataForReference = {(state) => state.AllProducts}
      ImageUploadLink = {uploadProductImg}
      getID = "productId"
    />
  );
  
}

export default AddProductTable;




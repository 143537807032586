import React from 'react';
import './MarketNav.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faMoon, faUpRightAndDownLeftFromCenter, faUser } from "@fortawesome/free-solid-svg-icons";
import logoImg from '../../../../assets/logo/OnifiedFinalLogo.png'
import trident_logo from "../../../../assets/Images/image80.png";
import { Link } from 'react-router-dom';

const MarketNav = ({toggle_sidebar_user, toggle_filter_box ,handle}) => {

  return (
    <div className='market_nav' >
      <div className='col1'>
        {/* <Link to={'/market-map'}>
          <li className='logo_container'> <img src={logoImg} alt="logo img" /> </li>
        </Link> */}
         <h6 className='marketmapTitle'>MarketMap</h6>
      </div>
      <div className='col1'>
        {/* <li onClick={toggle_filter_box}><FontAwesomeIcon icon={faFilter} className="market_icon" /></li>
        <li><FontAwesomeIcon icon={faMoon} className="market_icon" /></li> */}
        <img src={trident_logo} alt={"trident_logo"} id="trident_logo"/>
        <li className="user_icon" onClick={toggle_sidebar_user}><FontAwesomeIcon icon={faUser} className="market_icon" /></li>
        {/* <li onClick={handle.enter} ><FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} className="market_icon" /></li> */}
        
      </div>
    </div>
  )
}


export default MarketNav;
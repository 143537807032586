import React, { useEffect, useState } from 'react';
import './AllStep.css';
import { getAllProduct } from '../../../../store/Slices/marketMapTableSlices.js';
import { useDispatch, useSelector } from 'react-redux';
import STATUSES from '../../../../store/Statuses.js';
import { toast } from 'react-toastify';
import { getAllProductAttributes } from '../../../../store/Slices/marketMapAttributeSlice.js';
import { addProductInProgram, resetaddProductInProgramState ,getOneProgram ,deleteProductInProgram, resetdeleteProductInProgramState} from '../../../../store/Slices/marketMapProgram.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

const ProductStep = ({ onComplete, programId, programClassName, selectedProductType }) => {
  const dispatch = useDispatch();

  console.log("selectedProductType --" , selectedProductType)

  useEffect(() => {
    dispatch(getAllProductAttributes({}));
  }, [dispatch]);

  const { data: AttributesGroupsData, status: AttributeGroupStatus, error: AttributeGroupError } = useSelector((state) => state.AllProductAttributeGroup);

  const [selectedGroupIndex, setSelectedGroupIndex] = useState(0);
  const [selectedItemId, setSelectedItemId] = useState("");

  useEffect(() => {
    if(AttributeGroupStatus=== STATUSES.SUCCESS){
      if (selectedProductType.length > 0) {
        const selectedIndex = AttributesGroupsData.findIndex((group) => group.groupName === selectedProductType) + 1;
        setSelectedGroupIndex(selectedIndex);
      }
    }
  }, [selectedProductType, AttributesGroupsData, AttributeGroupStatus]);

  useEffect(() => {
    if (selectedGroupIndex > 0) {
      const classNameToFetch = selectedGroupIndex === 1 ? `Product` : `Product${selectedGroupIndex}`;
      dispatch(getAllProduct(classNameToFetch));
    }
  }, [selectedGroupIndex, dispatch]);

  const { data, status } = useSelector((state) => state.AllProducts);

  useEffect(() => {
    if (selectedProductType.length === 0) {
      setSelectedItemId("");
    }
  }, [selectedProductType]);


  useEffect(()=>{
    if(programId.length>0){
      if(programClassName.length>0){
        dispatch(getOneProgram({ className :programClassName , programId : programId}))
      }
    }
  },[programClassName, programId,]);

  const {data: OneTableItemData , status:OneTableItemStatus ,error:OneTableItemError} = useSelector((state) => state.OneProgram);

  console.log(OneTableItemData);
  
  // change icon color of step
  useEffect(() => {
    if (OneTableItemStatus === STATUSES.SUCCESS && OneTableItemData.length > 0) {
      onComplete(5, OneTableItemData[0]?.products.length > 0);
    }
  }, [OneTableItemStatus, OneTableItemData, onComplete]);

  const saveData = () => {
    if (!programId) {
      toast.error("Program not found. Please create a program first.");
      return;
    }
    if (!selectedItemId) {
      toast.error("Please select a product.");
      return;
    }

    const productClassName = selectedGroupIndex === 1 ? `Product` : `Product${selectedGroupIndex}`;
    dispatch(addProductInProgram({ programId, productId: selectedItemId, productClassName, programClassName }));
    setSelectedItemId('');
  }

  const { data: AddedData, status: AddedStatus, error: AddedError } = useSelector((state) => state.ProductInProgram);

  useEffect(() => {
    if (AddedData && AddedStatus === STATUSES.SUCCESS) {
      toast.success(AddedData);
      dispatch(resetaddProductInProgramState({}));
         // Fetch the updated list of Products after deletion
      dispatch(getOneProgram({ className: programClassName, programId }));
    }
    if (AddedStatus === STATUSES.ERROR) {
      toast.error(AddedError ? AddedError : "Something went Wrong");
      dispatch(resetaddProductInProgramState({}));
    }
  }, [AddedData, AddedStatus, AddedError,  dispatch, programClassName, programId]);

  const deleteData = (id)=>{
    dispatch(deleteProductInProgram({productId : id , programId , programClassName}))
  }

  const {data: DeletedData  ,status : DeletedStatus, error :DeletedError } = useSelector((state)=> state.DeleteProductInProgram);

  useEffect(() => {
    if (DeletedData && DeletedStatus === STATUSES.SUCCESS) {
      toast.success(DeletedData);
      dispatch(resetdeleteProductInProgramState({}));
      // Fetch the updated list of Products after deletion
      dispatch(getOneProgram({ className: programClassName, programId }));
    }
    if (DeletedStatus === STATUSES.ERROR) {
      toast.error(DeletedError ? DeletedError :"Something went Wrong");
      dispatch(resetdeleteProductInProgramState({}));
    }
  }, [DeletedData, DeletedStatus, DeletedError,  dispatch, programClassName, programId]);

  return (
    <>
      {selectedProductType.length === 0 ? (
        <div className="step_input_container">
          <h2>Please select program first</h2>
        </div>
      ) : (
        <>
          <div className="step_input_container">
            <h5 className='steps_heading'>Select from existing products</h5>
            <select
              className='select_step_data'
              value={selectedProductType}
              required
              disabled={true}
            >
              <option value="">Select Product</option>
              {AttributeGroupStatus === STATUSES.LOADING ? (
                <option className='error_option' value="">Loading...</option>
              ) : AttributeGroupStatus === STATUSES.ERROR ? (
                <option className='error_option' value="">{AttributeGroupError}</option>
              ) : AttributeGroupStatus === STATUSES.SUCCESS ? (
                AttributesGroupsData.length === 0 ? (
                  <option className='error_option' value="">No Groups found</option>
                ) : (
                  AttributesGroupsData.map((item) => (
                    <option className='select_step_value' value={item.groupName} key={item.groupId}>
                      {item.groupName}
                    </option>
                  ))
                )
              ) : null}
            </select>

            {selectedProductType.length > 0 &&
              <select className='select_step_data' type="text" required value={selectedItemId} onChange={(e) => setSelectedItemId(e.target.value)}>
                <option value="" className='select_step_value'>Select Product</option>
                {status === STATUSES.LOADING ? (
                  <option value="" className='error_option'>Loading...</option>
                ) :
                  status === STATUSES.ERROR ? (
                    <option value="" className='error_option'>Something went wrong</option>
                  ) :
                    status === STATUSES.SUCCESS ? (
                      data && typeof data === "string" ? (
                        <option value="" className='error_option'>No products found</option>
                      ) : (
                        data?.map((item) => (
                          <option key={item.productId} value={item.productId} className='select_step_value'>
                            {item.groupAttributes["Product name"]}
                          </option>
                        ))
                      )
                    )
                      : null
                }
              </select>
            }
          </div>

          <div className="step_btn_contianer">
            <button className={`common_btn`} style={{ width: 150 }} onClick={saveData}>Save</button>
          </div>

          <div className='saved_data_to_remove'>
          <h4 >Saved Products </h4>
          {OneTableItemStatus === STATUSES.SUCCESS && OneTableItemData && OneTableItemData[0] && (
            OneTableItemData[0].products.length === 0 ? (
              <p>No products found</p>
            ) :
            <div>
              {OneTableItemData[0].products.map((product, index) => (
                <p key={index}>{product?.groupAttributes["Product name"][0]} <i onClick={() => deleteData(product.productId)}><FontAwesomeIcon icon={faTrash} /></i></p>
              ))}
            </div>
          )}
        </div>
        </>
      )}
    </>
  );
}

export default ProductStep;




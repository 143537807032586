import React from 'react';
import './UserPersonalSetting.css';

const UserPersonalSetting = () => {
  return (
    <div>UserPersonalSetting</div>
  )
}

export default UserPersonalSetting;

import React from "react";
// import ScrollAnimation from "react-animate-on-scroll";
import { Col, Container, Row } from "react-bootstrap";
import {Link} from "react-router-dom";
import styles from "./RequestDemo.module.css";

function RequestDemo() {
  return (
    <section className={styles.ctaSectionArea}>
      <Container>
        <Row className="d-flex my-5">
          <Col col={12}>
          {/* <ScrollAnimation animateIn='flipInX'> */}
            <div className="text-center">
              <h1 className={`main-heading ${styles.iconText} mt-0`}>
                Is Your Business ONIFIED yet !
              </h1>
              <Link to="/request" className={styles.requestcta}><button  className='common_btn' style={{width:250}}>Request a Demo</button></Link>
            </div>
            {/* </ScrollAnimation> */}
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default RequestDemo;

import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import React from "react";
import Homepage from "../pages/Homepage";
import Ekyc from "../components/Ekyc";
import ErrorPage from "../pages/ErrorPage";
import HomeComponents from "../components/HomeComponents";
import Login from "../components/HomeComponents/Login/Login";
import SighUp from "../components/HomeComponents/Login/SignUp";
import RequestPage from "../components/HomeComponents/RequestPage/RequestPage";

import MarketMapsPage from "../pages/MarketMapsPage/MarketMapsPage";

import AddList from "../components/MarketMaps/MarketAllPages/AddList/AddList";
import PMSpage from "../pages/PMSpage/PMSpage";
import UserProfile from "../components/Common/User/UserProfile/UserProfile";
import PMShome from "../components/PMS/PMSallPages/PMShome/PMShome";
import UserProfileEdit from "../components/Common/User/UserProfileEdit/UserProfileEdit";
import AllEmployee from "../components/Common/User/ALLEmployee/AllEmployee";
import AddEmployeeProfile from "../components/Common/User/AddEmployeeProfile/AddEmployeeProfile";
import ImportEmployees from "../components/Common/User/ImportEmployees/ImportEmployees";
import UserPersonalSetting from "../components/Common/User/UserPersonalSetting/UserPersonalSetting";
import Features from "../components/HomeComponents/Features/Features";
import MarketMapFeatures from "../components/HomeComponents/AllFeatures/MarketMapFeatures";
import FirstPage from "../components/Common/FirstPage/FirstPage";
import ProductTableHome from "../components/MarketMaps/MarketAllPages/AllTables/ProductTable/ProductTableHome";
import AddProductTable from "../components/MarketMaps/MarketAllPages/AllTables/ProductTable/AddProductTable";
import UserPage from "../pages/UserPage/UserPage";
import RetailerTableHome from "../components/MarketMaps/MarketAllPages/AllTables/RetailerTable/RetailerTableHome";
import AddRetailerTable from "../components/MarketMaps/MarketAllPages/AllTables/RetailerTable/AddRetailerTable";
import AddManufacturerTable from "../components/MarketMaps/MarketAllPages/AllTables/ManufacturerTable/AddManufacturerTable";
import ManufacturerTableHome from "../components/MarketMaps/MarketAllPages/AllTables/ManufacturerTable/ManufacturerTableHome";
import SupplierTableHome from "../components/MarketMaps/MarketAllPages/AllTables/SupplierTable/SupplierTableHome";
import AddSupplierTable from "../components/MarketMaps/MarketAllPages/AllTables/SupplierTable/AddSupplierTable";
import PackagingTableHome from "../components/MarketMaps/MarketAllPages/AllTables/PackagingDetailTable/PackagingTableHome";
import AddPackagingTable from "../components/MarketMaps/MarketAllPages/AllTables/PackagingDetailTable/AddPackagingTable";
import AddPromotionTable from "../components/MarketMaps/MarketAllPages/AllTables/MarketingCallOutTable/AddPromotionTable";
import PromotionTablehome from "../components/MarketMaps/MarketAllPages/AllTables/MarketingCallOutTable/PromotionTablehome";

import RegionTableHome from "../components/MarketMaps/MarketAllPages/AllTables/RegionTable/RegionTableHome";
import AddRegionTable from "../components/MarketMaps/MarketAllPages/AllTables/RegionTable/AddRegionTable";
import GroupTableHome from "../components/MarketMaps/MarketAllPages/AllTables/GroupTable/GroupTableHome";
import AddGroupTable from "../components/MarketMaps/MarketAllPages/AllTables/GroupTable/AddGroupTable";

import SettingHome from "../components/MarketMaps/Settings/SettingHome/SettingHome";
import ProgramHome from "../components/MarketMaps/MarketAllPages/Program/ProgramHome/ProgramHome";

// All Charts from market map
import Barchart from "../components/MarketMaps/MarketAllPages/AllCharts/Barchart/Barchart";
import Areachart from "../components/MarketMaps/MarketAllPages/AllCharts/Areachart/Areachart";
import Linechart from "../components/MarketMaps/MarketAllPages/AllCharts/Linechart/Linechart";
import Radarchart from "../components/MarketMaps/MarketAllPages/AllCharts/Radarchart/Radarchart";
import Vbarchart from "../components/MarketMaps/MarketAllPages/AllCharts/Vbarchart/Vbarchart";
import Piechart from "../components/MarketMaps/MarketAllPages/AllCharts/Piechart/Piechart";
import BubbleChart from "../components/MarketMaps/MarketAllPages/AllCharts/BubbleChart/BubbleChart";
import StackedBarChart from "../components/MarketMaps/MarketAllPages/AllCharts/StackedBarChart/StackedBarChart";

import AddNewProgram from "../components/MarketMaps/MarketAllPages/Program/AddNewProgram/AddNewProgram";
import MarketMapHome from "../components/MarketMaps/MarketAllPages/MarketMapHome/MarketMapHome";
// import EditProduct from "../components/MarketMaps/MarketAllPages/AllTables/ProductTable/EditProduct";
// import EditRetailer from "../components/MarketMaps/MarketAllPages/AllTables/RetailerTable/EditRetailer";
// import EditManufacturer from "../components/MarketMaps/MarketAllPages/AllTables/ManufacturerTable/EditManufacturer";
// import EditSupplier from "../components/MarketMaps/MarketAllPages/AllTables/SupplierTable/EditSupplier";
// import EditPackaging from "../components/MarketMaps/MarketAllPages/AllTables/PackagingDetailTable/EditPackaging";
// import EditPromotion from "../components/MarketMaps/MarketAllPages/AllTables/MarketingCallOutTable/EditPromotion";
// import EditRegion from "../components/MarketMaps/MarketAllPages/AllTables/RegionTable/EditRegion";
// import EditGroup from "../components/MarketMaps/MarketAllPages/AllTables/GroupTable/EditGroup";
import ViewAllPrograms from "../components/MarketMaps/MarketAllPages/Program/ViewAllPrograms/ViewAllPrograms";
import ProductAtt from "../components/MarketMaps/Settings/AllAttributes/ProductAtt";
import RetailerAtt from "../components/MarketMaps/Settings/AllAttributes/RetailerAtt";
import ManufacturerAtt from "../components/MarketMaps/Settings/AllAttributes/ManufacturerAtt";
import SupplierAtt from "../components/MarketMaps/Settings/AllAttributes/SupplierAtt";
import PackagingAtt from "../components/MarketMaps/Settings/AllAttributes/PackagingAtt";
import MarketingCallOutAtt from "../components/MarketMaps/Settings/AllAttributes/MarketingCallOutAtt";
import ProgramAtt from "../components/MarketMaps/Settings/AllAttributes/ProgramAtt";
import RegionAtt from "../components/MarketMaps/Settings/AllAttributes/RegionAtt";
import GroupAtt from "../components/MarketMaps/Settings/AllAttributes/GroupAtt";
import EditProgram from "../components/MarketMaps/MarketAllPages/Program/EditProgram/EditProgram";
import { useSelector } from "react-redux";
import EditOneProgram from "../components/MarketMaps/MarketAllPages/Program/EditOneProgram/EditOneProgram";
import config from "../config";
import ProfileUpdate from "../components/HomeComponents/ProfileUpdate/ProfileUpdate";

function MyRoutes() {
  const companyName = config.SERVICE_COMPANY_NAME;

  console.log("companyName", companyName);
  console.log("Server name=", process.env.REACT_APP_ENV_NAME);

  const { token, userDetails } = useSelector((state) => state.auth);

  return (
    <BrowserRouter>
      <Routes>
        {/* Home  */}
        <Route path="/" element={<Homepage />}>
          <Route index path="/" element={<HomeComponents />} />
          <Route path="/ekyc" element={<Ekyc />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SighUp />} />
          <Route path="/request" element={<RequestPage />} />
          <Route path="/features" element={<Features />} />
          <Route
            path="/features/market-map-features"
            element={<MarketMapFeatures />}
          />
        </Route>

        {/* Market Map */}
        {/* {
          token && userDetails ? ( */}
        <Route path="market-map" element={<MarketMapsPage />}>
          <Route index path="" element={<MarketMapHome />} />

          {/* All Charts  */}
          <Route path="Bar-Chart" element={<Barchart />} />
          <Route path="Pie-Chart" element={<Piechart />} />
          <Route path="Area-Chart" element={<Areachart />} />
          <Route path="Bubble-Chart" element={<BubbleChart />} />
          <Route path="Line-Chart" element={<Linechart />} />
          <Route path="Radar-Chart" element={<Radarchart />} />
          <Route path="Vertical-Bar-Chart" element={<Vbarchart />} />
          <Route path="Stacked-Bar-Chart" element={<StackedBarChart />} />
          {/* <Route  path='Add-List' element={<AddList/>}/> */}

          <Route path="Tables" element={<AddList />} />

          {/* All table pages */}
          <Route path="Tables/Product-Table" element={<ProductTableHome />} />
          <Route
            path="Tables/Product-Table/Add-Product"
            element={<AddProductTable />}
          />
          {/* <Route path="Tables/Product-Table/Edit-Product" element={<EditProduct/>}/> */}

          <Route path="Tables/Retailer-Table" element={<RetailerTableHome />} />
          <Route
            path="Tables/Retailer-Table/Add-Retailer"
            element={<AddRetailerTable />}
          />
          {/* <Route path="Tables/Retailer-Table/Edit-Retailer" element={<EditRetailer/>}/> */}

          <Route
            path="Tables/Manufacturer-Table"
            element={<ManufacturerTableHome />}
          />
          <Route
            path="Tables/Manufacturer-Table/Add-Manufacturer"
            element={<AddManufacturerTable />}
          />
          {/* <Route path="Tables/Manufacturer-Table/Edit-Manufacturer" element={<EditManufacturer/>}/> */}

          <Route path="Tables/Supplier-Table" element={<SupplierTableHome />} />
          <Route
            path="Tables/Supplier-Table/Add-Supplier"
            element={<AddSupplierTable />}
          />
          {/* <Route path="Tables/Supplier-Table/Edit-Supplier" element={<EditSupplier/>}/> */}

          <Route
            path="Tables/Packaging-Table"
            element={<PackagingTableHome />}
          />
          <Route
            path="Tables/Packaging-Table/Add-Packaging"
            element={<AddPackagingTable />}
          />
          {/* <Route path="Tables/Packaging-Table/Edit-Packaging" element={<EditPackaging/>}/> */}

          <Route
            path="Tables/Promotion-Table"
            element={<PromotionTablehome />}
          />
          <Route
            path="Tables/Promotion-Table/Add-Promotion"
            element={<AddPromotionTable />}
          />
          {/* <Route path="Tables/Promotion-Table/Edit-Promotion" element={<EditPromotion/>}/> */}

          <Route path="Tables/Region-Table" element={<RegionTableHome />} />
          <Route
            path="Tables/Region-Table/Add-Region"
            element={<AddRegionTable />}
          />
          {/* <Route path="Tables/Region-Table/Edit-Region" element={<EditRegion/>}/> */}

          <Route path="Tables/Group-Table" element={<GroupTableHome />} />
          <Route
            path="Tables/Group-Table/Add-Group"
            element={<AddGroupTable />}
          />
          {/* <Route path="Tables/Group-Table/Edit-Group" element={<EditGroup/>}/> */}

          {/* programs  */}
          <Route path="programs" element={<ProgramHome />} />
          <Route path="programs/add-New-Program" element={<AddNewProgram />} />
          <Route
            path="programs/view-All-Programs"
            element={<ViewAllPrograms />}
          />
          <Route path="programs/edit-All-Programs" element={<EditProgram />} />
          <Route
            path="programs/edit-All-Programs/edit-One-Program"
            element={<EditOneProgram />}
          />

          {/* Settings  */}
          <Route path="market-Map-Settings" element={<SettingHome />} />

          {/* {Attributes Setting} */}
          <Route
            path="market-Map-Settings/Add-Product-Attributes"
            element={<ProductAtt />}
          />
          <Route
            path="market-Map-Settings/Add-Retailer-Attributes"
            element={<RetailerAtt />}
          />
          <Route
            path="market-Map-Settings/Add-Manufacturer-Attributes"
            element={<ManufacturerAtt />}
          />
          <Route
            path="market-Map-Settings/Add-Supplier-Attributes"
            element={<SupplierAtt />}
          />
          <Route
            path="market-Map-Settings/Add-Packaging-Details-Attributes"
            element={<PackagingAtt />}
          />
          <Route
            path="market-Map-Settings/Add-Marketing-Callout-Attributes"
            element={<MarketingCallOutAtt />}
          />
          <Route
            path="market-Map-Settings/Add-Program-Attributes"
            element={<ProgramAtt />}
          />
          <Route
            path="market-Map-Settings/Add-Region-Attributes"
            element={<RegionAtt />}
          />
          <Route
            path="market-Map-Settings/Add-Groups-Attributes"
            element={<GroupAtt />}
          />

          <Route path="update-profile" element={<ProfileUpdate />} />
        </Route>

        {/* ) : (
            <Route path="market-map" element={<Navigate to="/login" />} />
            
           )
        } */}

        {/* PMS  */}
        <Route path="pms" element={<PMSpage />}>
          <Route index path="" element={<PMShome />} />
        </Route>

        {/* User Profile  */}
        <Route path="profile" element={<UserPage />}>
          {/* <Route index path="" element={<PMShome/>}/>   */}
          <Route index path="" element={<UserProfile />} />
          <Route path="editProfile" element={<UserProfileEdit />} />
          <Route path="userPersonalSetting" element={<UserPersonalSetting />} />
          <Route path="allEmployees" element={<AllEmployee />} />
          <Route
            path="allEmployees/addNewProfile"
            element={<AddEmployeeProfile />}
          />
          <Route
            path="allEmployees/importEmployees"
            element={<ImportEmployees />}
          />
        </Route>

        {/* After login the user  */}
        <Route path="home" element={<FirstPage />} />

        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default MyRoutes;

import React from 'react';
import { createManufacturer, getAllManufacturer, getOneManufacturer, resetManufacturerState } from '../../../../../store/Slices/marketMapTableSlices';
import { getAllManufacturerAttributes, getOneManufacturerAttributes } from '../../../../../store/Slices/marketMapAttributeSlice';
import { getManufacturerImg, updateManufacturerImg } from '../../../../../store/Slices/marketMapImageSlice';
import TableHomeCommon from '../../../MarketMapsComponents/TableHomeCommon/TableHomeCommon';
import { deleteManufacturer, resetDeleteManufacturerState, resetUpdateManufacturerState, updateManufacturer } from '../../../../../store/Slices/marketMapTableEditSlice';

const ManufacturerTableHome = () => {
  
  return (
    <TableHomeCommon
      title="Manufacturer"
      getAllTableDataLink = {getAllManufacturer}  
      getAllAttributesLink={getAllManufacturerAttributes}
      getOneAttributesLink={getOneManufacturerAttributes}

      getAllTableDataSelector = {(state) => state.AllManufacturers}
      allAttributesGroupSelector={(state) => state.AllManufacturerAttributeGroup}
      oneAttributeGroupSelector={(state) => state.OneManufacturerAttributesGroup}
      classNamePrefix="Manufacturer"

      linkForRedirectSetting = "Add-Manufacturer-Attributes"
      additionalAttributesName = "manufacturerAdditionalAttributes"
      getImageLink = {getManufacturerImg}
      getImagesSelector = {(state) => state.GetManufacturerImg.images}
      getImagesStatusSelector = {(state) => state.GetManufacturerImg.status}
      getID = "manufacturerId"

      add_button_url={"Add-Manufacturer"}
      edit_button_url={"Edit-Manufacturer"}


      
      deleteLink={deleteManufacturer}
      deleteSelector = {(state) => state.DeleteManufacturer}
      resetDeleteState = {resetDeleteManufacturerState}

      createTablelink={createManufacturer}
      resetTableState={resetManufacturerState}
      tableDataSelector={(state) => state.Manufacturer}
     
      getAllDataForReferenceLink={getAllManufacturer}
      getAllDataForReference={(state) => state.AllManufacturers}
      ImageUploadLink={updateManufacturerImg}

      getOneTableItemLink = {getOneManufacturer}
      getOnetableItemSelector = {(state) => state.OneManufacturer}
      updateTableItemLink = {updateManufacturer}
      updateTableItemSelector = {(state) => state.UpdateManufacturer}
      resetUpdateItemState = {resetUpdateManufacturerState}

    />
  );
};

export default ManufacturerTableHome;




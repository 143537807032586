import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styles from "./Integrations.module.css";

import Apps from "../../../assets/Images/Apps.png";
import arrows from "../../../assets/Images/arrows.png";
import auth from "../../../assets/Images/auth.png";
import bitcoin from "../../../assets/Images/bitcoin.png";
import cards from "../../../assets/Images/cards.png";
import chats from "../../../assets/Images/chats.png";
import oms from "../../../assets/Images/oms.svg";
import ticket from "../../../assets/Images/ticket.svg";
import kyc from "../../../assets/Images/kyc.svg";
import bi from "../../../assets/Images/bi.svg";
import img from "../../../assets/Images/marketmaps.svg";
import pdf from "../../../assets/Images/pdf.png";
import performance from "../../../assets/Images/performance.svg";
import shopfloor from "../../../assets/Images/shopfloor.svg";
import track from "../../../assets/Images/track.svg";
import asset from "../../../assets/Images/asset.svg";
import store from "../../../assets/Images/store.svg";
import { Link } from "react-router-dom";
// import ScrollAnimation from "react-animate-on-scroll";

const IntegrationItem = ({ icon, title, url }) => {
  return (
    <div className={styles.collg20}>
      {/* <ScrollAnimation animateIn="flipInY" animateOut="flipOutY"> */}
        <Link to={{ pathname: url }} className={`p-2 ${styles.integrationItem}`}>
          <img src={icon} alt={title} />
          <h6 className={styles.iconText}>{title}</h6>
        </Link>
      {/* </ScrollAnimation> */}
    </div>
  );
};

function Integrations() {
  const IntegrationObject = [
    {
      icon: img,
      title: "Market Maps",
      url: "/market-map",
    },
    {
      icon: oms,
      title: "Order Management System",
      url: "/ekyc",
    },
    {
      icon: bi,
      title: "BI & Analytics",
      url: "/ekyc",
    },
    {
      icon: performance,
      title: "Performance Management System",
      url: "/pms",
    },
    {
      icon: kyc,
      title: "E-KYC",
      url: "/ekyc",
    },
    {
      icon: shopfloor,
      title: "Shopfloor Management",
      url: "/ekyc",
    },
    {
      icon: ticket,
      title: "Ticketing System",
      url: "/ekyc",
    },
    {
      icon: track,
      title: "Track and Trace",
      url: "/ekyc",
    },
    {
      icon: asset,
      title: "Asset Management",
      url: "/ekyc",
    },
    {
      icon: store,
      title: "Direct to Store",
      url: "/ekyc",
    },
  ];

  return (
    <>
      <div className={styles.bannerSectionArea}>
        <Container className={styles.integrationsContainer}>
          <h1 className={`main-heading ${styles.iconText}`}>Onified Toolkit</h1>
          <p className={`sub-body ${styles.iconText}`}>Specific problems have specific solutions Choose from our wide range of tools to Onifiy your business</p>
          <Row className="g-md-4 my-3">
            {IntegrationObject.map((elem, index) => {
              return (
                <IntegrationItem
                  key={index}
                  icon={elem.icon}
                  title={elem.title}
                  url={elem.url}
                />
              );
            })}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Integrations;

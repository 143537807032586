import React from "react";
import { createProduct, getAllProduct, getOneProduct, resetProductState } from "../../../../../store/Slices/marketMapTableSlices";
import { getAllProductAttributes, getOneProductAttributes} from "../../../../../store/Slices/marketMapAttributeSlice";
import { getProductImg, updateProductImg, uploadProductImg } from "../../../../../store/Slices/marketMapImageSlice";
import TableHomeCommon from "../../../MarketMapsComponents/TableHomeCommon/TableHomeCommon";
import { deleteProduct, resetDeleteProductState, resetUpdateProductState, updateProduct } from "../../../../../store/Slices/marketMapTableEditSlice";

const ProductTableHome = () => {
  
  return (
    <TableHomeCommon
      title="Product"
      getAllTableDataLink = {getAllProduct}  
      getAllAttributesLink={getAllProductAttributes}
      getOneAttributesLink={getOneProductAttributes}

      getAllTableDataSelector = {(state) => state.AllProducts}
      allAttributesGroupSelector={(state) => state.AllProductAttributeGroup}
      oneAttributeGroupSelector={(state) => state.OneProductAttributesGroup}
      classNamePrefix="Product"

      linkForRedirectSetting = "Add-Product-Attributes"
      additionalAttributesName = "productAdditionalAttributes"
      getImageLink = {getProductImg}
      getImagesSelector = {(state) => state.GetProductImg.images}
      getImagesStatusSelector = {(state) => state.GetProductImg.status}
      getID = "productId"

      add_button_url={"Add-Product"}
      edit_button_url={"Edit-Product"}


      
      deleteLink={deleteProduct}
      deleteSelector = {(state) => state.DeleteProduct}
      resetDeleteState = {resetDeleteProductState}
      createTablelink={createProduct}
      resetTableState={resetProductState}
      tableDataSelector={(state) => state.Product}
     
      getAllDataForReferenceLink={getAllProduct}
      getAllDataForReference={(state) => state.AllProducts}
      ImageUploadLink={uploadProductImg}
      ImageUpdateLink={updateProductImg}

      getOneTableItemLink = {getOneProduct}
      getOnetableItemSelector = {(state) => state.OneProduct}
      updateTableItemLink = {updateProduct}
      updateTableItemSelector = {(state) => state.UpdateProduct}
      resetUpdateItemState = {resetUpdateProductState}

    />
  );
};

export default ProductTableHome;

import React from 'react';
import './RenderWarning.css';
import img from '../../../../assets/Images/MarketMaps/AddData.png'
import { Link } from 'react-router-dom';

const RenderWarning = ({message,showLink}) => {
  return (
    <div className='select_warning'>
        <img src={img} alt="imgs" />
        <h6>{message}</h6>
        {showLink?<Link to={`/market-map/market-Map-Settings/${showLink}`}>Create Attributes List</Link>:null}
    </div>
  )
}

export default RenderWarning;
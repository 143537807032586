import React, { useEffect } from 'react';
import './UserSidebar.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMultiply, faUser} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { clearAuth, resetUserLogout, userLogout } from '../../../store/Slices/userAuthSlice';
import { useDispatch } from 'react-redux';
import STATUSES from '../../../store/Statuses';
import { LoaderCircle } from '../../Common/Loader/Loader';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';



const UserSidebar = ({toggle_sidebar_user}) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();


  const close_user_sidebar = ()=>{
    toggle_sidebar_user()
    document.body.style.overflow = 'unset';
  }
  const userDetails = useSelector(state => state.auth.userDetails);
  const {data , status , error} = useSelector(state => state.UserLogout);

  const handleLogout = () => {
    dispatch(userLogout())
  }
  useEffect(()=>{
    if(status === STATUSES.SUCCESS){
      navigate('/');
      Cookies.remove('onifiedPKtoken');
      localStorage.removeItem('onifiedPKData');
      dispatch(clearAuth());
      dispatch(resetUserLogout({}));
    }
    if(status== STATUSES.ERROR){
      toast.error("Something went wrong");
    }
  },[status]);
  
  return (
    <div className='user_sidebar_container'>
        <FontAwesomeIcon icon={faMultiply} className="user_close_icon" onClick={close_user_sidebar} />
        <div className="user_box">
            <FontAwesomeIcon icon={faUser} className="user_icon"/>

            {/* <p>Pankaj kumar</p>
            <p>pankajkumar@gmail.com</p>
            <p><span>Role -</span> Organization Admin</p> */}
            {userDetails && (
              <>
                <p>{userDetails?.username}</p>
                {/* <p>{userDetails?.username}</p> */}
                <p><span>Role -</span> {userDetails?.authorities[0]?.authority}</p>
              </>
            )}
            <div className="btn_box">
              {/* <Link to={"/profile"} className='common_btn' target='_blank' >My Account</Link> */}
              <button className={status === STATUSES.LOADING ? 'common_btn disabled' : 'common_btn'} onClick={handleLogout} disabled={status === STATUSES.LOADING} >
                {status === STATUSES.LOADING ? <LoaderCircle/> : "Log Out"}
              </button>
            </div>
        </div>
    </div>
  )
}

export default UserSidebar;
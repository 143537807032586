import React from 'react';
import { createRetailer, getAllRetailer, resetRetailerState } from '../../../../../store/Slices/marketMapTableSlices';
import { getAllRetailerAttributes, getOneRetailerAttributes } from '../../../../../store/Slices/marketMapAttributeSlice.js';
import TableAddCommon from '../../../MarketMapsComponents/TableAddCommon/TableAddCommon.jsx';
import { uploadRetailerImg } from '../../../../../store/Slices/marketMapImageSlice.js';


const AddRetailerTable = () => {
  return (
    <TableAddCommon
      title="Retailer"
      getAllAttributesLink={getAllRetailerAttributes}
      getOneAttributesLink={getOneRetailerAttributes}
      createTablelink={createRetailer}
      resetTableState={resetRetailerState}
      tableDataSelector={(state) => state.Retailer}
      allAttributesGroupSelector={(state) => state.AllRetailerAttributeGroup}
      oneAttributeGroupSelector={(state) => state.OneRetailerAttributesGroup}
      classNamePrefix="Retailer"
      linkForRedirectSetting = "Add-Retailer-Attributes"
      additionalAttributesName = "retailerAdditionalAttributes"
      getAllDataForReferenceLink = {getAllRetailer}
      getAllDataForReference = {(state) => state.AllRetailers}
      ImageUploadLink = {uploadRetailerImg}
      getID = "retailerId"
    />
  );
}

export default AddRetailerTable;


















// import React ,{ useEffect, useState } from 'react';
// import { createRetailer, resetRetailerState } from '../../../../../store/Slices/marketMapTableSlices';
// import { useDispatch, useSelector} from 'react-redux';
// import STATUSES from '../../../../../store/Statuses.js';
// import { toast } from "react-toastify";
// import { getAllRetailerAttributes, getOneRetailerAttributes } from '../../../../../store/Slices/marketMapAttributeSlice.js';
// import Loader from '../../../../Common/Loader/Loader.jsx';
// import RenderWarning from '../../../MarketMapsComponents/RenderWarning/RenderWarning.jsx';


// const AddRetailerTable = () => {
//   const dispatch = useDispatch();
 
//   //[1 ]Get All Attributes Group 
//   useEffect(()=>{
//     dispatch(getAllRetailerAttributes({}));
//   },[dispatch]);
  
//   const {data:AttributesGroupsData ,  status:AttributeGroupStatus , error:AttributeGroupError  } = useSelector((state) => state.AllRetailerAttributeGroup);


//   //[2] Select the Single Attribute Group 
//   const [groupNameToShowAttributes ,setGroupNameToShowAttributes] = useState("");
//   const [selectedGroupIndex, setSelectedGroupIndex] = useState(0); // Initialized to -1

//   // Function to handle the selection change
//   const handleGroupSelection = (e) => {
//     const selectedIndex = e.target.selectedIndex;
//     setGroupNameToShowAttributes(e.target.value);
//     setSelectedGroupIndex(selectedIndex ); // Subtracting 1 to account for the "Select Group" option
//   };

//   useEffect(()=>{
//       if(groupNameToShowAttributes.length>0){
//           dispatch(getOneRetailerAttributes(groupNameToShowAttributes));
//       }
//   },[dispatch ,groupNameToShowAttributes]);

//   const {data: singleAttributeGroupData , status:singleAttributeGroupStatus ,error:singleAttributeGroupError} = useSelector((state) => state.OneRetailerAttributesGroup);
//   console.log(singleAttributeGroupData);

//   console.log(groupNameToShowAttributes);
//   console.log(selectedGroupIndex);
//   const classNameToSave = selectedGroupIndex===1 ?`Retailer` : `Retailer${selectedGroupIndex}`
//   console.log(classNameToSave);


//   //[3] Handel Submit The data 
//   const [InputData, setInputData] = useState({});
//   const [additionalInputData, setAdditionalInputData] = useState({});

//   const changeInputData = (e) => {
//     const { name, value, type } = e.target;
//     const newValue = type === 'number' ? parseFloat(value) : value.trim();
//       setInputData({
//         ...InputData,
//         [name]: newValue,
//       });
//   };
  
//   const changeAdditionalInputData = (e) => {
//     const { name, value, type } = e.target;
//     const newValue = type === 'number' ? parseFloat(value) : value.trim();
//       setAdditionalInputData({
//         ...additionalInputData,
//         [name]: newValue,
//       });
//   };

  
//   const handleSubmitData = (e) => {
//     e.preventDefault();
//     // Filter out empty values from inputData
//     const filteredInputData = Object.fromEntries(
//       Object.entries(InputData).filter(([key, value]) => value !== undefined && value !== null && value !== '')
//     );
//     // Filter out empty values from additionalInputData
//     const filteredAdditionalInputData = Object.fromEntries(
//       Object.entries(additionalInputData).filter(([key, value]) => value !== undefined && value !== null && value !== '')
//     );
  
//     if (Object.keys(filteredInputData).length === 0 && Object.keys(filteredAdditionalInputData).length === 0) {
//       toast.warning("Please fill in some fields to add new retailer");
//     } else {
//       const data = {
//         groupAttributes: filteredInputData,
//         retailerAdditionalAttributes: filteredAdditionalInputData,
//         groupName: groupNameToShowAttributes,
//         className: classNameToSave
//       };
  
//       dispatch(createRetailer(data));
//       console.log(data);
//     }
//   };
  
//   const {  data, status , error } = useSelector((state) => state.Retailer);

//   useEffect(()=>{
//     if (data && status===STATUSES.SUCCESS) {
//       toast.success("Created Successfully");
//       dispatch(resetRetailerState({}));
//     }
//     if (error){
//       toast.error(error);
//       dispatch(resetRetailerState({}));
//     }
//   }, [data ,status ,error ,dispatch]);


//   useEffect(() => {
//     // Clear input data when group name changes
//     setInputData({});
//     setAdditionalInputData({});
//   }, [groupNameToShowAttributes]);




 
//   return (
//     <>
    
//     <div className='common_table_page add_table_page'>

//       <div className="select_att_group_button_Container">
//         <h6>Add Retailer</h6>
//         <select className='select_step_data' type="text" required value={groupNameToShowAttributes} onChange={handleGroupSelection} >
//           <option value="" className='select_step_value'>Select Group</option>
//           {
//             AttributeGroupStatus === STATUSES.LOADING ? (
//               <option className='error_option'>Loading...</option>
//             ) : 
//             AttributeGroupStatus === STATUSES.ERROR ? (
//               <option className='error_option'>Something went wrong {AttributeGroupError}</option>
//             ) : 
//             Object.keys(AttributesGroupsData).length > 0 && AttributesGroupsData.map((item, index) =>
//               <option value={item.groupName} className='select_step_value' key={item.groupId}>{item.groupName}</option>
//             )
//           }
//         </select>
//       </div>

   
//       <> 
//         <div className="add_table_data_container">
//           {
//             AttributeGroupStatus === STATUSES.LOADING ? (
//               <Loader/>
//             ) :(
//               Object.keys(AttributesGroupsData).length===0 ?(
//                 <RenderWarning message={"Please Create a Group of Attributes to add retailer"} showLink={"Retailer"} />
//               ):(              
//                   groupNameToShowAttributes.length===0?(
//                     <RenderWarning message={"Please Select the Group of Attributes to add retailer"} />
//                   ):(
//                     <>
//                   {

//                     singleAttributeGroupStatus === STATUSES.LOADING?(
//                       <Loader/>
//                     ):singleAttributeGroupStatus===STATUSES.ERROR? (
//                       <h6>Something went wrong {singleAttributeGroupError.message}</h6>
//                     ):singleAttributeGroupStatus===STATUSES.SUCCESS?(
//                         (Object.keys(singleAttributeGroupData.groupAttributes).length===0 && Object.keys(singleAttributeGroupData.retailerAdditionalAttributes).length === 0)?(
//                         <>
//                           <h6 className='att_header'>Attributes Group - <span>{singleAttributeGroupData?.groupName}</span></h6>
//                           <RenderWarning message={"No attributes found to add retailer"} />
//                         </>
//                         ):(
                          
//                         <>
//                           <h6 className='att_header'>Attributes Group - <span>{singleAttributeGroupData?.groupName}</span></h6>
//                           <div className="all_attributes_to_add_data">
//                               {
//                                 Object.keys(singleAttributeGroupData.groupAttributes).map((i ,index)=>(
//                                   <div className='_input_box' key={index} >
//                                     <input className='table_input' type={singleAttributeGroupData.groupAttributes[i].type==="String"?"text":singleAttributeGroupData.groupAttributes[i].type==="Number"?"number":null} required name={i} onChange={changeInputData}/>
//                                     <span>{singleAttributeGroupData.groupAttributes[i].nametoDisplay}</span>
//                                   </div>
//                                 ))
//                               }
//                               {
//                                 Object.keys(singleAttributeGroupData.retailerAdditionalAttributes).map((i ,index)=>(
//                                   <div className='_input_box' key={index} >
//                                     <input className='table_input' type={singleAttributeGroupData.retailerAdditionalAttributes[i].type==="String"?"text":singleAttributeGroupData.retailerAdditionalAttributes[i].type==="Number"?"number":null} required name={i} onChange={changeAdditionalInputData} />
//                                     <span>{singleAttributeGroupData.retailerAdditionalAttributes[i].nametoDisplay}</span>
//                                   </div>
//                                 ))
//                               }
//                           </div>
//                           <div className="submit_table_data_btn">
//                             <button onClick={handleSubmitData} className ={'common_btn'} style={{width: 300}}>Save</button>
//                           </div>
//                         </>                          
//                         )
//                       ):null                    
//                   }
//                     </>
//                   )
//               )
//             )
//           }
//         </div>
//       </>
//     </div>

    
//     </>
//   )
// }

// export default AddRetailerTable;


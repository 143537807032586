// import React, { useState } from "react";
// import "./SideBar.css";
// import { Link } from "react-router-dom";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
// import { useSelector ,useDispatch } from "react-redux";
// import { sideBartoggle } from "../../../store/Slices/sideBarSlice";


// const SideBarItems = ({ item,index }) => {
//   const dispatch = useDispatch();
//   const [ShowSubNav, setShowSubNav] = useState(false);
//   const isSideBarToggled = useSelector((state) => state.sideBarToggle);

  
//   // Show Sub Nav 
//   const handleSubNav =()=>{
//     // setShowSubNav(!ShowSubNav);
//     if(isSideBarToggled===true){
//       setShowSubNav(!ShowSubNav);
//       dispatch(sideBartoggle());
//     }
//     else{
//       setShowSubNav(!ShowSubNav);
//     }
//   }
  

     
//   if (item.subNav){
//     return (
//         <div className="sidebar_item" key ={index} >
//           <div  className={isSideBarToggled ? "sidebar_title open" : "sidebar_title"} onClick={handleSubNav} >
//              <span className={isSideBarToggled ? "Sidebar_icon open" : "Sidebar_icon"}>
//                 {item.title_icon}
//              </span> 
//             {item.title}
//             <FontAwesomeIcon icon={faCaretDown} style={isSideBarToggled ? {display:"none"}:""} className ={ShowSubNav ? "toggle_btn open" : "toggle_btn"} onClick={handleSubNav} />

//           </div>
//           {
//             !isSideBarToggled && 
//             <div className={ShowSubNav ? "sidebar_content open" : "sidebar_content"} >
//             { item.subNav.map((child, index) => 
//               <div key={index}> 
//                 <SideBarItems item={child} index={index}/>
//               </div>)
              
//             }
//             </div>
//           }
//         </div>
//       );
//   }
//   else{
//     return (
//         <Link to = {item.path} className="sidebar_item" >
//           <div className={isSideBarToggled ? "sidebar_title open" : "sidebar_title"}  >
//               <span className={isSideBarToggled ? "Sidebar_icon open" : "Sidebar_icon"}>
//                 {item.title_icon}
//               </span> 
//             {item.title}
//           </div>
//         </Link>
//       );
//   }
     
// };

// export default SideBarItems;


import React, { useState } from "react";
import "./SideBar.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";
import { sideBartoggle } from "../../../store/Slices/sideBarSlice";

const SideBarItems = ({ item, activePath }) => {
  const dispatch = useDispatch();
  const [showSubNav, setShowSubNav] = useState(false);
  const isSideBarToggled = useSelector((state) => state.sideBarToggle);

  const isActive = activePath === item.path;

  // Show Sub Nav
  const handleSubNav = () => {
    if (isSideBarToggled === true) {
      setShowSubNav(!showSubNav);
      dispatch(sideBartoggle());
    } else {
      setShowSubNav(!showSubNav);
    }
  };
  

  if (item.subNav) {
    return (
      <div className={`sidebar_item ${isActive ? "active" : ""}`}>
        <div
          className={isSideBarToggled ? "sidebar_title open" : "sidebar_title"}
          onClick={handleSubNav}
        >
          <span className={isSideBarToggled ? "Sidebar_icon open" : "Sidebar_icon"}>
            {item.title_icon}
          </span>
          {item.title}
          <FontAwesomeIcon
            icon={faAngleDown}
            style={isSideBarToggled ? { display: "none" } : ""}
            className={showSubNav ? "toggle_btn open" : "toggle_btn"}
            onClick={handleSubNav}
          />
        </div>
        {!isSideBarToggled && (
          <div className={showSubNav ? "sidebar_content open" : "sidebar_content"}>
            {item.subNav.map((child, index) => (
              <div key={index}>
                <SideBarItems item={child} activePath={activePath} />
              </div>
            ))}
          </div>
        )}
      </div>
      
    );
  } else {
    return (
      <Link
        to={item.path}
        className={`sidebar_item ${isActive ? "active" : ""}`}
      >
        <div className={isSideBarToggled ? "sidebar_title open" : "sidebar_title"}>
          <span className={isSideBarToggled ? "Sidebar_icon open" : "Sidebar_icon"}>
            {item.title_icon}
          </span>
          {item.title}
        </div>
      </Link>
    );
  }
};

export default SideBarItems;


import React, { useEffect, useState } from 'react';
// import './AddNewProgram.css';
import StepNavigation from '../../../MarketMapsComponents/StepNavigation/StepNavigation';
import ProductStep from '../../../MarketMapsComponents/AllProgramStepsEdit/ProductStep';
import SupplierStep from '../../../MarketMapsComponents/AllProgramStepsEdit/SupplierStep';
import GroupsStep from '../../../MarketMapsComponents/AllProgramStepsEdit/GroupsStep';
import ManufacturerStep from '../../../MarketMapsComponents/AllProgramStepsEdit/ManufacturerStep';
import MarketingStep from '../../../MarketMapsComponents/AllProgramStepsEdit/MarketingStep';
import PackagingStep from '../../../MarketMapsComponents/AllProgramStepsEdit/PackagingStep';
import ProgramStep from '../../../MarketMapsComponents/AllProgramStepsEdit/ProgramStep';
import RegionStep from '../../../MarketMapsComponents/AllProgramStepsEdit/RegionStep';
import RetailerStep from '../../../MarketMapsComponents/AllProgramStepsEdit/RetailerStep';
import { getAllGroupAttributes, getAllPackagingAttributes, getAllProgramAttributes, getAllPromotionAttributes, getAllRegionAttributes, getOneGroupAttributes, getOnePackagingAttributes, getOneProgramAttributes, getOnePromotionAttributes, getOneRegionAttributes, resetOneGroupAttributesGroupState, resetOnePackagingAttributesGroupState, resetOnePromotionAttributesGroupState, resetOneRegionAttributesGroupState } from '../../../../../store/Slices/marketMapAttributeSlice';
import { createProgram, getAllProgram, resetProgramState, resetUpdateProgramState, updateProgram } from '../../../../../store/Slices/marketMapProgram';
import { updateProgramImg, uploadPackagingImg, uploadProgramImg, uploadPromotionImg, uploadRegionImg } from '../../../../../store/Slices/marketMapImageSlice';
import { createGroup, createPackaging, createPromotion, createRegion, getAllGroup, getAllPackaging, getAllPromotion, getAllRegion, resetPackagingState, resetPromotionState, resetRegionState } from '../../../../../store/Slices/marketMapTableSlices';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

const EditOneProgram = () => {
  const labelArray = ['Program', 'Retailer', 'Manufacturer', 'Supplier', 'Product', 'Packaging', 'Marketing Callout', 'Region'];
  // const labelArray = ['Program', 'Retailer', 'Manufacturer', 'Supplier', 'Product', 'Packaging', 'Marketing Callout', 'Region', 'Groups'];
  const [currentStep, updateCurrentStep] = useState(1);
  const [productGroupName , setProductGroupName] = useState("");

  const [programId, setProgramId] = useState('');
  const [programClassName , setProgramClassName] = useState("");
  const [selectedProductType, setSelectedProductType] = useState('');
  const [selectedGroupNameToShowAttributes, setSelectedGroupNameToShowAttributes] = useState('');
  const [displayProgramName , setDisplayProgramName] = useState("");

  function updateStep(step) {
    updateCurrentStep(step);
  }
  console.log(programId);

  const [completedSteps, setCompletedSteps] = useState([]);

  // const handleStepCompletion = (step) => {
  //   if (!completedSteps.includes(step)) {
  //     setCompletedSteps([...completedSteps, step]);
  //   }
  // };
  const handleStepCompletion = (step, completed) => {
    if (completed) {
      if (!completedSteps.includes(step)) {
        setCompletedSteps((prevSteps) => [...prevSteps, step]);
      }
    } else {
      if (completedSteps.includes(step)) {
        setCompletedSteps((prevSteps) => prevSteps.filter((s) => s !== step));
      }
    }
  };


 
  useEffect(() => {
    // Retrieve values from local storage when the component mounts
    const storedProgramId = localStorage.getItem('selectedProgramId');
    const storedClassName = localStorage.getItem('selectedClassName');
    const storedProductType = localStorage.getItem('selectedProductType');
    const storedGroupNameToShowAttributes = localStorage.getItem('selectedGroupNameToShowAttributes');

    if (storedProgramId) setProgramId(storedProgramId);
    if (storedClassName) setProgramClassName(storedClassName);
    if (storedProductType) setSelectedProductType(storedProductType);
    if (storedGroupNameToShowAttributes) setSelectedGroupNameToShowAttributes(storedGroupNameToShowAttributes);
  }, []);

  console.log(programClassName);
  console.log(programId);
  console.log("selectedGroupNameToShowAttributes", selectedGroupNameToShowAttributes);

  const [groupNameOfPackagingToSend , setGroupNameOfPackagingToSend] = useState("");
  const [groupNameOfPromotionToSend , setGroupNameOfPromotionToSend] = useState("");
  const [groupNameOfRegionToSend , setGroupNameOfRegionToSend] = useState("");
  const [groupNameOfGroupToSend , setGroupNameOfGroupToSend] = useState("");


  return (
    <>
    <div className='common_program_space'>

      <div className="step_header">
        <h6 className='step_heading'>Update Program {displayProgramName && <span style={{fontWeight:600}} >- {displayProgramName}</span>}</h6>
        <div className='step_progress 2'>
          <StepNavigation labelArray={labelArray} currentStep={currentStep} updateStep={updateStep} completedSteps={completedSteps} />
        </div>
      </div>

      {/* <div className="step-content">{renderCurrentStep()}</div> */}
      <div className="step-content">
          {currentStep === 1 && 
          
          <ProgramStep 
            onComplete={handleStepCompletion} 
            setProgramId={setProgramId} 
            setProgramClassName={setProgramClassName} 
            setProductGroupName={setProductGroupName} 
            productGroupName = {productGroupName}

            programId= {programId}
            programClassName={programClassName}
            selectedProductType={selectedProductType}
            selectedGroupNameToShowAttributes={selectedGroupNameToShowAttributes}



            title="Program"
            getAllAttributesLink={getAllProgramAttributes}
            getOneAttributesLink={getOneProgramAttributes}
            createTablelink={createProgram}
            resetTableState={resetProgramState}
            tableDataSelector={(state) => state.Program}
            allAttributesGroupSelector={(state) => state.AllProgramAttributeGroup}
            oneAttributeGroupSelector={(state) => state.OneProgramAttributesGroup}
            classNamePrefix="Program"
            linkForRedirectSetting = "Add-Program-Attributes"
            additionalAttributesName = "programAdditionalAttributes"
            getAllDataForReferenceLink = {getAllProgram}
            getAllDataForReference = {(state) => state.AllPrograms}
            ImageUpdateLink = {updateProgramImg}
            getID = "programId"

            updateTableItemLink = {updateProgram}
            updateTableItemSelector = {(state) => state.UpdateProgram}
            resetUpdateItemState = {resetUpdateProgramState}

            setGroupNameOfPackagingToSend={setGroupNameOfPackagingToSend}
            setGroupNameOfPromotionToSend={setGroupNameOfPromotionToSend}
            setGroupNameOfRegionToSend={setGroupNameOfRegionToSend}
            setGroupNameOfGroupToSend={setGroupNameOfGroupToSend}
            setDisplayProgramName={setDisplayProgramName}

          />
          }
          {currentStep === 2 && <RetailerStep onComplete={handleStepCompletion}  programId={programId} programClassName ={programClassName} productGroupName={productGroupName}/>}

          {currentStep === 3 && <ManufacturerStep onComplete={handleStepCompletion}  programId={programId} programClassName ={programClassName} productGroupName={productGroupName}/>}

          {currentStep === 4 && <SupplierStep onComplete={handleStepCompletion}  programId={programId} programClassName ={programClassName} productGroupName={productGroupName}/>}

          {currentStep === 5 && 
          <ProductStep onComplete={handleStepCompletion} programId={programId} programClassName ={programClassName} productGroupName={productGroupName} selectedProductType={selectedProductType}/>
          }
          
          {currentStep === 6 && 
          <PackagingStep 
            onComplete={handleStepCompletion} 
            programId={programId} programClassName ={programClassName} productGroupName={productGroupName}
            title="Packaging"
            getAllAttributesLink={getAllPackagingAttributes}
            getOneAttributesLink={getOnePackagingAttributes}
            createTablelink={createPackaging}
            resetTableState={resetUpdateProgramState}
            tableDataSelector={(state) => state.Packaging}
            allAttributesGroupSelector={(state) => state.AllPackagingAttributeGroup}
            oneAttributeGroupSelector={(state) => state.OnePackagingAttributesGroup}
            classNamePrefix="Packaging"
            linkForRedirectSetting = "Add-Packaging-Attributes"
            additionalAttributesName = "packagingAdditionalAttributes"
            getAllDataForReferenceLink = {getAllPackaging}
            getAllDataForReference = {(state) => state.AllPackagings}
            ImageUpdateLink = {updateProgramImg}
            getID = "packagingId"

            groupNameOfTableToSend={groupNameOfPackagingToSend}
            restOneAttributeGroupState = {resetOnePackagingAttributesGroupState}
          />}


          {currentStep === 7 && 
          <MarketingStep 
            onComplete={handleStepCompletion}
            programId={programId} programClassName ={programClassName} productGroupName={productGroupName}
            title="Promotion"
            getAllAttributesLink={getAllPromotionAttributes}
            getOneAttributesLink={getOnePromotionAttributes}
            createTablelink={createPromotion}
            resetTableState={resetUpdateProgramState}
            tableDataSelector={(state) => state.Promotion}
            allAttributesGroupSelector={(state) => state.AllPromotionAttributeGroup}
            oneAttributeGroupSelector={(state) => state.OnePromotionAttributesGroup}
            classNamePrefix="Promotion"
            linkForRedirectSetting = "Add-Promotion-Attributes"
            additionalAttributesName = "promotionAdditionalAttributes"
            getAllDataForReferenceLink = {getAllPromotion}
            getAllDataForReference = {(state) => state.AllPromotions}
            ImageUpdateLink = {updateProgramImg}
            getID = "promotionId"

            groupNameOfTableToSend={groupNameOfPromotionToSend}
            restOneAttributeGroupState = {resetOnePromotionAttributesGroupState}

          />}

          {currentStep === 8 && 
          <RegionStep 
            onComplete={handleStepCompletion}
            programId={programId}
            programClassName ={programClassName} 
            productGroupName={productGroupName}

            title="Region"
            getAllAttributesLink={getAllRegionAttributes}
            getOneAttributesLink={getOneRegionAttributes}
            createTablelink={createRegion}
            resetTableState={resetUpdateProgramState}
            tableDataSelector={(state) => state.Region}
            allAttributesGroupSelector={(state) => state.AllRegionAttributeGroup}
            oneAttributeGroupSelector={(state) => state.OneRegionAttributesGroup}
            classNamePrefix="Region"
            linkForRedirectSetting = "Add-Region-Attributes"
            additionalAttributesName = "regionAdditionalAttributes"
            getAllDataForReferenceLink = {getAllRegion}
            getAllDataForReference = {(state) => state.AllRegions}
            ImageUpdateLink = {updateProgramImg}
            getID = "regionId"

            groupNameOfTableToSend={groupNameOfRegionToSend}
            restOneAttributeGroupState = {resetOneRegionAttributesGroupState}
          
          />
          }
          {currentStep === 9 && <GroupsStep 
            onComplete={handleStepCompletion}
            programId={programId} programClassName ={programClassName} productGroupName={productGroupName}
            title="Group"
            getAllAttributesLink={getAllGroupAttributes}
            getOneAttributesLink={getOneGroupAttributes}
            createTablelink={createGroup}
            resetTableState={resetUpdateProgramState}
            tableDataSelector={(state) => state.Group}
            allAttributesGroupSelector={(state) => state.AllGroupAttributeGroup}
            oneAttributeGroupSelector={(state) => state.OneGroupAttributesGroup}
            classNamePrefix="Group"
            linkForRedirectSetting = "Add-Group-Attributes"
            additionalAttributesName = "groupAdditionalAttributes"
            getAllDataForReferenceLink = {getAllGroup}
            getAllDataForReference = {(state) => state.AllGroups}
            ImageUpdateLink = {updateProgramImg}
            getID = "groupId"            

            groupNameOfTableToSend={groupNameOfGroupToSend}
            restOneAttributeGroupState = {resetOneGroupAttributesGroupState}
          />}
        </div>

      <div className='step_btn_group'>
        <button className="primaryButton" disabled={currentStep === 1} onClick={() => updateStep(currentStep - 1)}>
          <i><FontAwesomeIcon icon={faArrowLeft} /></i>
        </button>
        <button
          className="primaryButton"
          disabled={currentStep === labelArray.length}
          onClick={() => updateStep(currentStep + 1)}
        >
          <i><FontAwesomeIcon icon={faArrowRight} /></i>
        </button>
      </div>
    </div>
    </>
  );
};

export default EditOneProgram;


import React from 'react';
import './NoDataInAttributes.css';
import img from '../../../../assets/Images/Attributes/img1.png';
import img2 from '../../../../assets/Images/MarketMaps/noDataGraph.svg';
 
const NoDataInAttributes = ({NodataHeading}) => {
  return (
    <div className='no_data_in_attributes'>
        <img src={img} alt="no_data_in_attribute" />
        <h6>{NodataHeading}</h6>
    </div>
  )
}

export default NoDataInAttributes;

export const NoDataINGraph = () =>{
  return(
    <div className='no_data_in_graph'>
      <img src={img2} alt="nodata" />
      <h6>Please select the data to show in the chart</h6>
    </div>
  )
}
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

const UserProfileEdit = () => {
  return (
    <>
      <div className="profile_box">
        <i className="user_icon_box">
          <FontAwesomeIcon icon={faUser} className="user_icon"/>
        </i>
      </div>
      <div className="profile_container">
        <div className="profile_heading_box">
          <h5>Edit my Profile</h5>
        </div>

        <div className="user_info_header">
          <div className="user_info_heading">Basic Information</div>
          <div className="user_info_heading">Personal Information</div>
          <div className="user_info_heading">Work Information</div>
        </div>

        <div className="user_info_box">
          <div className="user_info_col">
            <div className="user_detail">
              Employe ID <span></span>
            </div>
            <div className="user_detail">
              Email Address <span></span>
            </div>
          </div>
          <div className="user_info_col">
            <div className="user_detail">
              First Name <span></span>
            </div>
            <div className="user_detail">
              Last Name <span></span>
            </div>
          </div>
          <div className="user_info_col">
            <div className="user_detail">
              Nick Name<span></span>
            </div>
            <div className="user_detail">
              Other ID <span></span>
            </div>
          </div>
        </div>

        <div className="user_info_box">
          <div className="user_info_col">
            <div className="user_detail">
              Date of birth<span></span>
            </div>
            <div className="user_detail">
              Gender<span></span>
            </div>
          </div>
          <div className="user_info_col">
            <div className="user_detail">
              Marital Status<span></span>
            </div>
            <div className="user_detail">
              Present Address<span></span>
            </div>
          </div>
          <div className="user_info_col">
            <div className="user_detail">
              Permanent Address<span></span>
            </div>
            <div className="user_detail">
              Personal Mobile Number<span></span>
            </div>
          </div>
          <div className="user_info_col">
            <div className="user_detail">
              Personal Email Address<span></span>
            </div>
            <div className="user_detail">
              UAN<span></span>
            </div>
          </div>
          <div className="user_info_col">
            <div className="user_detail">
              PAN<span></span>
            </div>
            <div className="user_detail">
              Aadhaar<span></span>
            </div>
          </div>
        </div>

        <div className="user_info_box">
          <div className="user_info_col">
            <div className="user_detail">
              Department<span></span>
            </div>
            <div className="user_detail">
              Designation<span></span>
            </div>
          </div>
          <div className="user_info_col">
            <div className="user_detail">
              Work Location<span></span>
            </div>
            <div className="user_detail">
              Role<span></span>
            </div>
          </div>
          <div className="user_info_col">
            <div className="user_detail">
              Employment Type<span></span>
            </div>
            <div className="user_detail">
              Employee Status<span></span>
            </div>
          </div>
          <div className="user_info_col">
            <div className="user_detail">
              Date of Joining<span></span>
            </div>
            <div className="user_detail">
              Current Experience<span></span>
            </div>
          </div>
          <div className="user_info_col">
            <div className="user_detail">
              Total Experience<span></span>
            </div>
            <div className="user_detail">
              Organization email<span></span>
            </div>
          </div>
          <div className="user_info_col">
            <div className="user_detail">
              CTC<span></span>
            </div>
            <div className="user_detail">
              Hierarchy Information<span></span>
            </div>
          </div>
          <div className="user_info_col">
            <div className="user_detail">
              Skill Set<span></span>
            </div>
            <div className="user_detail">
              Source of Hire<span></span>
            </div>
          </div>
          <div className="user_info_col">
            <div className="user_detail">
              Total time with us<span></span>
            </div>
            <div className="user_detail">
              Other Info<span></span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserProfileEdit;

import React from "react";
import "./TableHomeButtonHeader.css";
import { Link } from "react-router-dom";

const TableHomeButtonHeader = ({
  add_button_url,
  edit_button_url,
  table_heading,
  data,
  status,
  showOperationsButton,
  handleShowOperationsButton,
  AttributesGroupsData,
  handleGroupSelection,
  groupNameToShowAttributes,
}) => {
  // console.log(add_button_url,edit_button_url,table_heading ,data ,status)

  const handleSelectChange = (e) => {
    const selectedIndex = e.target.selectedIndex;
    const selectedGroupName = e.target.value;
    handleGroupSelection(e, selectedGroupName, selectedIndex);
  };

  return (
    <div className="table_home_button_header 2">
      <h6 className="lists_heading">
        {table_heading}
        <select
          className="tablehome_select"
          onChange={handleSelectChange}
          value={groupNameToShowAttributes}
        >
          {AttributesGroupsData.length > 0 &&
            AttributesGroupsData.map((item, index) => (
              <>
                <option
                  className={`option ${
                    groupNameToShowAttributes === item.groupName
                      ? "selected"
                      : ""
                  }`}
                  key={item.groupId}
                >
                  {item.groupName}
                </option>
              </>
            ))}
        </select>
      </h6>
      {add_button_url && table_heading && data && status ? (
        <div>
          <Link
            to={add_button_url}
            className="common_btn"
            style={{ maxWidth: 200, marginLeft: 10 }}
          >
            Add Data
          </Link>
          {typeof data !== "string" ? (
            <button
              className="common_btn2"
              style={{ maxWidth: 200, marginLeft: 10 }}
              onClick={handleShowOperationsButton}
            >
              {" "}
              {showOperationsButton ? "Hide Update Data" : "Update Data"}
            </button>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default TableHomeButtonHeader;

import React, { useEffect, useState } from "react";
import "./TableAddCommon.css";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Loader from "../../../Common/Loader/Loader";
import RenderWarning from "../RenderWarning/RenderWarning";
import STATUSES from "../../../../store/Statuses";
import Multiselect from "multiselect-react-dropdown";

const TableAddCommon = ({
  title,
  getAllAttributesLink,
  getOneAttributesLink,
  createTablelink,
  resetTableState,
  tableDataSelector,
  allAttributesGroupSelector,
  oneAttributeGroupSelector,
  classNamePrefix,
  linkForRedirectSetting,
  additionalAttributesName,
  getAllDataForReferenceLink,
  getAllDataForReference,
  ImageUploadLink,
  getID,
}) => {
  const dispatch = useDispatch();

  //[1 ]Get All Attributes Group
  useEffect(() => {
    dispatch(getAllAttributesLink({}));
  }, [dispatch]);

  const {
    data: AttributesGroupsData,
    status: AttributeGroupStatus,
    error: AttributeGroupError,
  } = useSelector(allAttributesGroupSelector);

  //[2] Select the Single Attribute Group
  const [groupNameToShowAttributes, setGroupNameToShowAttributes] =
    useState("");
  const [selectedGroupIndex, setSelectedGroupIndex] = useState(0);

  // Function to handle the selection change
  const handleGroupSelection = (e) => {
    const selectedIndex = e.target.selectedIndex;
    setGroupNameToShowAttributes(e.target.value);
    setSelectedGroupIndex(selectedIndex);
  };

  useEffect(() => {
    if (groupNameToShowAttributes.length > 0) {
      dispatch(getOneAttributesLink(groupNameToShowAttributes));
    }
  }, [dispatch, groupNameToShowAttributes]);

  const {
    data: singleAttributeGroupData,
    status: singleAttributeGroupStatus,
    error: singleAttributeGroupError,
  } = useSelector(oneAttributeGroupSelector);

  const classNameToSave =
    selectedGroupIndex === 1
      ? classNamePrefix
      : `${classNamePrefix}${selectedGroupIndex}`;

  const {
    groupAttributes,
    [additionalAttributesName]: additionalAttributes,
    imagesAttributes,
  } = singleAttributeGroupData;

  // console.log(singleAttributeGroupData);
  // console.log(groupNameToShowAttributes);
  // console.log(selectedGroupIndex);
  // console.log(classNameToSave);

  //[3] Handel Submit The data
  const [groupInputData, setGroupInputData] = useState({});
  const [additionalInputData, setAdditionalInputData] = useState({});
  const [additionalValidationErrors, setAdditionalValidationErrors] = useState(
    {}
  );
  const [groupValidationErrors, setGroupValidationErrors] = useState({});

  useEffect(() => {
    if (singleAttributeGroupStatus === STATUSES.SUCCESS) {
      setAdditionalInputData((prevState) => {
        const updatedState = { ...prevState };

        Object.keys(additionalAttributes).forEach((key) => {
          // Do not initialize for type "Multiselect"
          if (additionalAttributes[key].type === "Image") {
            return;
          }
          if (additionalAttributes[key].type === "MultiSelect") {
            if (!updatedState[key]) {
              updatedState[key] = [];
            }
            return;
          }

          // Initialize the value if not already present
          if (!updatedState[key]) {
            updatedState[key] = [""];
          }

          // Initialize child attribute for NestedDropdown type
          if (additionalAttributes[key].type === "NestedDropdown") {
            const childAttributeName = `${key}nEsTeD`;
            if (!updatedState[childAttributeName]) {
              updatedState[childAttributeName] = [""];
            }
          }
        });

        return updatedState;
      });
    }
  }, [additionalAttributes, singleAttributeGroupStatus]);

  useEffect(() => {
    if (singleAttributeGroupStatus === STATUSES.SUCCESS) {
      setAddNestedDropdownValues((prevState) => {
        const updatedState = { ...prevState };
        Object.keys(additionalAttributes).forEach((key) => {
          if (additionalAttributes[key].type === "NestedDropdown") {
            if (!updatedState[key]) {
              updatedState[key] = [""];
            }
          }
        });
        return updatedState;
      });
    }
  }, [additionalAttributes, singleAttributeGroupStatus]);

  useEffect(() => {
    if (singleAttributeGroupStatus === STATUSES.SUCCESS) {
      setGroupInputData((prevState) => {
        const updatedState = { ...prevState };
        Object.keys(groupAttributes).forEach((key) => {
          if (groupAttributes[key].type === "Image") {
            return;
          }
          if (groupAttributes[key].type === "MultiSelect") {
            if (!updatedState[key]) {
              updatedState[key] = [];
            }
            return;
          }

          if (!updatedState[key]) {
            updatedState[key] = [""];
          }

          // Initialize child attribute for NestedDropdown type
          if (groupAttributes[key].type === "NestedDropdown") {
            const childAttributeName = `${key}nEsTeD`;
            if (!updatedState[childAttributeName]) {
              updatedState[childAttributeName] = [""];
            }
          }
        });
        return updatedState;
      });
    }
  }, [groupAttributes, singleAttributeGroupStatus]);

  useEffect(() => {
    if (singleAttributeGroupStatus === STATUSES.SUCCESS) {
      setGroupNestedDropdownValues((prevState) => {
        const updatedState = { ...prevState };
        Object.keys(groupAttributes).forEach((key) => {
          if (groupAttributes[key].type === "NestedDropdown") {
            if (!updatedState[key]) {
              updatedState[key] = [""];
            }
          }
        });
        return updatedState;
      });
    }
  }, [groupAttributes, singleAttributeGroupStatus]);

  // Inside the component, define a new state for mandatory errors
  const [groupMandatoryErrors, setGroupMandatoryErrors] = useState({});
  const [additionalMandatoryErrors, setAdditionalMandatoryErrors] = useState(
    {}
  );

  const [groupNestedDropdownValues, setGroupNestedDropdownValues] = useState(
    {}
  );
  const [addNestedDropdownValues, setAddNestedDropdownValues] = useState({});

  // console.log("groupInputData ====", groupInputData);
  // console.log(" groupValidationErrors ===" ,  groupValidationErrors);
  // console.log("groupMandatoryErrors ===" , groupMandatoryErrors)
  console.log("groupNestedDropdownValues ===", groupNestedDropdownValues);

  useEffect(() => {
    // Clear input data when group name changes
    setGroupInputData({});
    setAdditionalInputData({});
    setAddNestedDropdownValues({});
    setGroupNestedDropdownValues({});
    setGroupValidationErrors({});
    setAdditionalValidationErrors({});
    setGroupMandatoryErrors({});
    setAdditionalMandatoryErrors({});

    setInputValues({});
    setNestedDropdownValues({});
    setImageValidationErrors({});

    setImageFiles({});
    setImageMandatoryErrors({});
  }, [groupNameToShowAttributes]);

  // [4] Choose reference
  useEffect(() => {
    if (singleAttributeGroupStatus === STATUSES.SUCCESS) {
      const classNameToSave =
        selectedGroupIndex === 1
          ? classNamePrefix
          : `${classNamePrefix}${selectedGroupIndex}`;
      dispatch(getAllDataForReferenceLink(classNameToSave));
    }
  }, [singleAttributeGroupStatus]);

  const {
    data: AllDataForReference,
    status: statusForReference,
    error: errorForReference,
  } = useSelector(getAllDataForReference);

  // console.log(AllDataForReference);

  const ensureNonEmptyArraysInAdditional = (
    attributes,
    additionalInputData
  ) => {
    const updatedAttributes = {};

    // Iterate over additionalInputData to construct updatedAttributes
    Object.keys(additionalInputData).forEach((key) => {
      if (attributes.hasOwnProperty(key)) {
        updatedAttributes[key] = attributes[key];
      } else {
        updatedAttributes[key] = additionalInputData[key];
      }

      // Ensure non-empty arrays
      if (
        (Array.isArray(updatedAttributes[key]) &&
          updatedAttributes[key].length === 0) ||
        updatedAttributes[key] === null
      ) {
        updatedAttributes[key] = [""];
      }
    });

    return updatedAttributes;
  };

  const ensureNonEmptyArraysInGroup = (attributes, additionalInputData) => {
    const updatedAttributes = {};

    // Iterate over groupInputData to construct updatedAttributes
    Object.keys(groupInputData).forEach((key) => {
      if (attributes.hasOwnProperty(key)) {
        updatedAttributes[key] = attributes[key];
      } else {
        updatedAttributes[key] = additionalInputData[key];
      }

      // Ensure non-empty arrays
      if (
        (Array.isArray(updatedAttributes[key]) &&
          updatedAttributes[key].length === 0) ||
        updatedAttributes[key] === null
      ) {
        updatedAttributes[key] = [""];
      }
    });

    return updatedAttributes;
  };

  const handleReferenceSelection = (e) => {
    const selectedId = e.target.value;

    if (!selectedId) {
      console.log("Selected ID is empty.");
      return;
    }

    // Log the AllDataForReference for debugging
    console.log(
      "AllDataForReference:",
      JSON.stringify(AllDataForReference, null, 2)
    );

    const selectedReference = AllDataForReference.find(
      (ref) => ref[getID] === selectedId
    );

    if (!selectedReference) {
      console.log("Selected reference not found in AllDataForReference.");
      return;
    }

    // Log the selected reference for debugging
    console.log(
      "Selected Reference:",
      JSON.stringify(selectedReference, null, 2)
    );

    let {
      groupAttributes: selectedGroupAttributes,
      [additionalAttributesName]: selectedAdditionalAttributes,
      imagesAttributes: selectedImagesAttributes,
    } = selectedReference;
    selectedGroupAttributes = ensureNonEmptyArraysInGroup(
      selectedGroupAttributes,
      groupInputData
    );
    selectedAdditionalAttributes = ensureNonEmptyArraysInAdditional(
      selectedAdditionalAttributes,
      additionalInputData
    );
    // Log the selected attributes for debugging
    console.log("Selected Group Attributes:", selectedGroupAttributes);
    console.log(
      "Selected Additional Attributes:",
      selectedAdditionalAttributes
    );

    setGroupInputData(selectedGroupAttributes);
    setAdditionalInputData(selectedAdditionalAttributes);

    // Update addNestedDropdownValues based on selectedAdditionalAttributes
    const newAddNestedDropdownValues = {};
    Object.keys(selectedAdditionalAttributes).forEach((key) => {
      if (key.endsWith("nEsTeD")) {
        const parentKey = key.slice(0, -"nEsTeD".length);
        const childKey = key;
        newAddNestedDropdownValues[parentKey] =
          selectedAdditionalAttributes[parentKey];
        newAddNestedDropdownValues[childKey] =
          selectedAdditionalAttributes[childKey];
      }
    });
    setAddNestedDropdownValues(newAddNestedDropdownValues);

    // Log newAddNestedDropdownValues for debugging
    console.log("New Add Nested Dropdown Values:", newAddNestedDropdownValues);

    // Update groupNestedDropdownValues based on selectedGroupAttributes
    const newGroupNestedDropdownValues = {};
    Object.keys(selectedGroupAttributes).forEach((key) => {
      if (key.endsWith("nEsTeD")) {
        const parentKey = key.slice(0, -"nEsTeD".length);
        const childKey = key;
        newGroupNestedDropdownValues[parentKey] =
          selectedGroupAttributes[parentKey];
        newGroupNestedDropdownValues[childKey] =
          selectedGroupAttributes[childKey];
      }
    });
    setGroupNestedDropdownValues(newGroupNestedDropdownValues);

    const newNestedDropdownValues = {};
    Object.keys(selectedImagesAttributes).forEach((key) => {
      if (key.endsWith("nEsTeD")) {
        const parentKey = key.slice(0, -"nEsTeD".length);
        newNestedDropdownValues[parentKey] = selectedImagesAttributes[parentKey]
          ? selectedImagesAttributes[parentKey][0]
          : "";
        newNestedDropdownValues[key] = selectedImagesAttributes[key]
          ? selectedImagesAttributes[key][0]
          : "";
      }
    });

    setNestedDropdownValues(newNestedDropdownValues);

    setInputValues(selectedImagesAttributes);

    // Log newGroupNestedDropdownValues for debugging
    console.log(
      "New Group Nested Dropdown Values:",
      newGroupNestedDropdownValues
    );
  };

  const validateImageAttribute = (type, value, name) => {
    const specialCharsRegex = /[~`!@#$%^&*()\-+=\[\]{};:'"|\\,.<>\/?_]/g;
    const hasSpecialChars = specialCharsRegex.test(value);
    const hasAlphabets = /[a-zA-Z]/.test(value);
    let errorMessage = null;

    if (value === "") {
      // Clear error if the value is empty
      setImageValidationErrors((prevErrors) => ({
        ...prevErrors,
        [name]: null,
      }));
      return value;
    }

    if (type === "Number") {
      if (hasSpecialChars || hasAlphabets) {
        errorMessage = hasSpecialChars
          ? "Special characters are not allowed"
          : "Alphabets are not allowed";
      }
    } else if (type === "Percent" || type === "Decimal") {
      const specialCharsRegex = /[~`!@#$%^&*()\-+=\[\]{};:'"|\\,<>\/?_]/g;
      const hasSpecialChars = specialCharsRegex.test(value);
      if (hasSpecialChars || hasAlphabets) {
        errorMessage = hasSpecialChars
          ? "Special characters are not allowed"
          : "Alphabets are not allowed";
      }
    } else if (type === "Email") {
      const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
      errorMessage = isValidEmail ? null : "Invalid email format";
    } else if (type === "URL") {
      const isValidURL = /^(ftp|http|https):\/\/[^ "]+$/.test(value);
      errorMessage = isValidURL ? null : "Invalid URL format";
    } else if (type === "Phone") {
      const isValidPhone = /^[0-9]{10}$/.test(value);
      errorMessage = isValidPhone ? null : "Invalid phone number format";
    } else if (type === "DateTime" || type === "Date") {
      errorMessage = null;
    } else if (type === "nestedDropdown") {
      errorMessage = null;
    } else {
      errorMessage = hasSpecialChars
        ? "Special characters are not allowed"
        : null;
    }

    setImageValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMessage,
    }));

    return value;
  };

  const [inputValues, setInputValues] = useState({});
  const [nestedDropdownValues, setNestedDropdownValues] = useState({});
  const [imageValidationErrors, setImageValidationErrors] = useState({});

  // console.log(inputValues)
  // console.log(nestedDropdownValues)
  // console.log(groupNestedDropdownValues)
  const handleInputChange = (name, selectedValues, type) => {
    const attributeName = name;
    let newValue = selectedValues;

    if (type) {
      newValue = validateImageAttribute(type, newValue, attributeName);
    }

    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [attributeName]: [newValue],
    }));
  };

  const saveSetMultiSelectValues = (name, selectedValues) => {
    setInputValues((prevData) => ({
      ...prevData,
      [name]: selectedValues,
    }));
  };

  const handleSetNestedDropdownSelection = (imageKey, groupName, i, value) => {
    const parentAttributeName = `${groupName} ${i}`;
    const nestedAttributeName = `${parentAttributeName}nEsTeD`;

    setNestedDropdownValues((prevValues) => ({
      ...prevValues,
      [parentAttributeName]: [value], // Store the parent value in an array
    }));

    setInputValues((prevData) => ({
      ...prevData,
      [parentAttributeName]: [value], // Store the parent value in an array
      [nestedAttributeName]: value ? prevData[nestedAttributeName] || [] : [], // Preserve the nested value in an array
    }));

    // Trigger validation for parent attribute
    const parentType = imagesAttributes[imageKey].attgroups[i]?.type;
    console.log("parentType-------------", parentType);
    if (parentType) {
      validateImageAttribute(parentType, value, parentAttributeName);
    }
  };

  // console.log("inputValues", inputValues);
  console.log("nestedDropdownValues", nestedDropdownValues);
  // console.log("imageValidationErrors", imageValidationErrors);

  //  Group =================================================================================
  const validateGroup = (type, value, name, index) => {
    const specialCharsRegex = /[~`!@#$%^&*()\-+=\[\]{};:'"|\\,.<>\/?_]/g;
    const hasSpecialChars = specialCharsRegex.test(value);
    const hasAlphabets = /[a-zA-Z]/.test(value);
    let errorMessage = null;

    if (value === "") {
      setGroupValidationErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        if (updatedErrors[name]) {
          updatedErrors[name][index] = null;
        }
        return updatedErrors;
      });
      return value;
    }

    if (type === "Number") {
      if (hasSpecialChars || hasAlphabets) {
        errorMessage = hasSpecialChars
          ? "Special characters are not allowed"
          : "Alphabets are not allowed";
      }
    } else if (type === "Percent" || type === "Decimal") {
      const specialCharsRegex = /[~`!@#$%^&*()\-+=\[\]{};:'"|\\,<>\/?_]/g;
      const hasSpecialChars = specialCharsRegex.test(value);
      if (hasSpecialChars || hasAlphabets) {
        errorMessage = hasSpecialChars
          ? "Special characters are not allowed"
          : "Alphabets are not allowed";
      }
    } else if (type === "Email") {
      const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
      errorMessage = isValidEmail ? null : "Invalid email format";
    } else if (type === "URL") {
      const isValidURL = /^(ftp|http|https):\/\/[^ "]+$/.test(value);
      errorMessage = isValidURL ? null : "Invalid URL format";
    } else if (type === "Phone") {
      const isValidPhone = /^[0-9]{10}$/.test(value);
      errorMessage = isValidPhone ? null : "Invalid phone number format";
    } else if (type === "DateTime" || type === "Date") {
      errorMessage = null;
    } else {
      errorMessage = hasSpecialChars
        ? "Special characters are not allowed"
        : null;
    }

    setGroupValidationErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      if (!updatedErrors[name]) {
        updatedErrors[name] = [];
      }
      updatedErrors[name][index] = errorMessage;
      return updatedErrors;
    });

    return value;
  };

  // else if (type === "String"){
  //   const maxLength = 25;
  //   if (value.length > maxLength) {
  //     errorMessage = 'Length cannot be more than 25';
  //   }
  // }

  const changeGroupInputData = (e, i, index) => {
    const { name, value } = e.target;
    let newValue = value;

    const isChildAttribute = name.endsWith("nEsTeD");
    const parentName = isChildAttribute
      ? name.slice(0, -"nEsTeD".length)
      : name;

    const fieldType = groupAttributes[parentName]?.type;
    const childDataType = groupAttributes[parentName]?.childDataType;

    if (fieldType && !isChildAttribute) {
      newValue = validateGroup(fieldType, newValue, name, index);
    }

    if (childDataType && isChildAttribute) {
      newValue = validateGroup(childDataType, newValue, name, index);

      // Also validate the parent attribute to update its mandatory error state
      const parentFieldType = groupAttributes[parentName]?.type;
      if (parentFieldType) {
        validateGroup(
          parentFieldType,
          groupInputData[parentName][index],
          parentName,
          index
        );
      }
    }

    setGroupMandatoryErrors((prevErrors) => ({
      ...prevErrors,
      [name]: newValue ? null : prevErrors[name],
    }));

    setGroupInputData((prevData) => ({
      ...prevData,
      [name]: prevData[name].map((val, idx) =>
        idx === index ? newValue : val
      ),
    }));

    // For nested child inputs, update the corresponding parent value
    if (isChildAttribute) {
      const parentIndex = index;
      const parentValue = groupInputData[parentName][parentIndex];
      const nestedAttributeName = `${parentName}nEsTeD`;
      setGroupInputData((prevData) => ({
        ...prevData,
        [nestedAttributeName]: prevData[nestedAttributeName].map((val, idx) =>
          idx === parentIndex ? newValue : val
        ),
      }));

      // Also validate the parent attribute to update its mandatory error state
      const parentFieldType = groupAttributes[parentName]?.type;
      if (parentFieldType) {
        validateGroup(parentFieldType, parentValue, parentName, parentIndex);
      }
    }
  };

  const handleAddAnotherGroup = (inputKey) => {
    const newField = "";
    const updatedState = (prevState) => ({
      ...prevState,
      [inputKey]: [...prevState[inputKey], newField],
    });

    setGroupInputData(updatedState);

    if (groupAttributes[inputKey].type === "NestedDropdown") {
      const childAttributeName = `${inputKey}nEsTeD`;

      // Add a new field for the child attribute
      setGroupInputData((prevState) => ({
        ...prevState,
        [childAttributeName]: [...prevState[childAttributeName], newField],
      }));

      setGroupNestedDropdownValues((prevState) => ({
        ...prevState,
        [inputKey]: [...prevState[inputKey], newField],
      }));

      // Check if there are validation errors for the specific nestedDropdown being added
      const hasValidationErrors = groupValidationErrors[
        childAttributeName
      ]?.some((error) => error !== null);

      // Enable the "Add" button only if there are no validation errors for the specific nestedDropdown
      setGroupValidationErrors((prevErrors) => ({
        ...prevErrors,
        [childAttributeName]: hasValidationErrors
          ? prevErrors[childAttributeName]
          : null,
      }));
    }
  };

  const handleRemoveFieldGroup = (inputKey, index) => {
    setGroupInputData((prevState) => {
      const updatedValues = prevState[inputKey].filter((_, i) => i !== index);
      return {
        ...prevState,
        [inputKey]: updatedValues,
      };
    });

    // Clear validation error for the removed field
    setGroupValidationErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      if (updatedErrors[inputKey]) {
        updatedErrors[inputKey] = updatedErrors[inputKey].filter(
          (_, i) => i !== index
        );
      }
      return updatedErrors;
    });

    if (groupAttributes[inputKey]?.type === "NestedDropdown") {
      const childAttributeName = `${inputKey}nEsTeD`;
      setGroupInputData((prevState) => {
        const updatedChildValues =
          prevState[childAttributeName]?.filter((_, i) => i !== index) || [];
        return {
          ...prevState,
          [childAttributeName]: updatedChildValues,
        };
      });

      // Clear validation error for the removed child field
      setGroupValidationErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        if (updatedErrors[childAttributeName]) {
          updatedErrors[childAttributeName] = updatedErrors[
            childAttributeName
          ].filter((_, i) => i !== index);
        }
        return updatedErrors;
      });

      setGroupNestedDropdownValues((prevState) => {
        const updatedValues = { ...prevState };
        updatedValues[inputKey] =
          updatedValues[inputKey]?.filter((_, i) => i !== index) || [];
        return updatedValues;
      });
    }
  };

  const handleGroupNestedDropdownSelection = (name, value, index) => {
    const nestedAttributeName = `${name}nEsTeD`;
    let newValue = value;
    const parentType = groupAttributes[name].type;

    // console.log("parentType",parentType )
    setGroupNestedDropdownValues((prevValues) => ({
      ...prevValues,
      [name]: prevValues[name].map((val, idx) => (idx === index ? value : val)), // Update the parent value
    }));

    // Update both parent and child values in the state
    setGroupInputData((prevData) => ({
      ...prevData,
      [name]: prevData[name].map((val, idx) => (idx === index ? value : val)), // Update the parent value
      [nestedAttributeName]: prevData[nestedAttributeName].map((val, idx) =>
        idx === index ? "" : val
      ), // Reset child value
    }));
    if (parentType) {
      validateGroup(parentType, newValue, name, index);
    }

    // Clear any errors related to parent and child
    setGroupMandatoryErrors((prevErrors) => ({
      ...prevErrors,
      [name]: null, // Clear parent error
      [nestedAttributeName]: null, // Clear child error
    }));
  };

  const saveGroupMultiSelectValues = (name, selectedValues) => {
    setGroupInputData((prevData) => ({
      ...prevData,
      [name]: selectedValues,
    }));
    setGroupMandatoryErrors((prevErrors) => ({
      ...prevErrors,
      [name]: selectedValues.length ? null : prevErrors[name],
    }));
  };

  //  Addittional=================================================================================
  const validateAdditional = (type, value, name, index) => {
    const specialCharsRegex = /[~`!@#$%^&*()\-+=\[\]{};:'"|\\,.<>\/?_]/g;
    const hasSpecialChars = specialCharsRegex.test(value);
    const hasAlphabets = /[a-zA-Z]/.test(value);
    let errorMessage = null;

    if (value === "") {
      setAdditionalValidationErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        if (updatedErrors[name]) {
          updatedErrors[name][index] = null;
        }
        return updatedErrors;
      });
      return value;
    }

    if (type === "Number") {
      if (hasSpecialChars || hasAlphabets) {
        errorMessage = hasSpecialChars
          ? "Special characters are not allowed"
          : "Alphabets are not allowed";
      }
    } else if (type === "Percent" || type === "Decimal") {
      const specialCharsRegex = /[~`!@#$%^&*()\-+=\[\]{};:'"|\\,<>\/?_]/g;
      const hasSpecialChars = specialCharsRegex.test(value);
      if (hasSpecialChars || hasAlphabets) {
        errorMessage = hasSpecialChars
          ? "Special characters are not allowed"
          : "Alphabets are not allowed";
      }
    } else if (type === "Email") {
      const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
      errorMessage = isValidEmail ? null : "Invalid email format";
    } else if (type === "URL") {
      const isValidURL = /^(ftp|http|https):\/\/[^ "]+$/.test(value);
      errorMessage = isValidURL ? null : "Invalid URL format";
    } else if (type === "Phone") {
      const isValidPhone = /^[0-9]{10}$/.test(value);
      errorMessage = isValidPhone ? null : "Invalid phone number format";
    } else if (type === "DateTime" || type === "Date") {
      errorMessage = null;
    } else {
      errorMessage = hasSpecialChars
        ? "Special characters are not allowed"
        : null;
    }

    setAdditionalValidationErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      if (!updatedErrors[name]) {
        updatedErrors[name] = [];
      }
      updatedErrors[name][index] = errorMessage;
      return updatedErrors;
    });

    return value;
  };

  const changeAdditionalInputData = (e, i, index) => {
    const { name, value } = e.target;
    let newValue = value;

    const isChildAttribute = name.endsWith("nEsTeD");
    const parentName = isChildAttribute
      ? name.slice(0, -"nEsTeD".length)
      : name;

    const fieldType = additionalAttributes[parentName]?.type;
    const childDataType = additionalAttributes[parentName]?.childDataType;

    if (fieldType && !isChildAttribute) {
      newValue = validateAdditional(fieldType, newValue, name, index);
    }

    if (childDataType && isChildAttribute) {
      newValue = validateAdditional(childDataType, newValue, name, index);

      // Also validate the parent attribute to update its mandatory error state
      const parentFieldType = additionalAttributes[parentName]?.type;
      if (parentFieldType) {
        validateAdditional(
          parentFieldType,
          additionalInputData[parentName][index],
          parentName,
          index
        );
      }
    }

    setAdditionalMandatoryErrors((prevErrors) => ({
      ...prevErrors,
      [name]: newValue ? null : prevErrors[name],
    }));

    setAdditionalInputData((prevData) => ({
      ...prevData,
      [name]: prevData[name].map((val, idx) =>
        idx === index ? newValue : val
      ),
    }));

    // For nested child inputs, update the corresponding parent value
    if (isChildAttribute) {
      const parentIndex = index;
      const parentValue = additionalInputData[parentName][parentIndex];
      const nestedAttributeName = `${parentName}nEsTeD`;
      setAdditionalInputData((prevData) => ({
        ...prevData,
        [nestedAttributeName]: prevData[nestedAttributeName].map((val, idx) =>
          idx === parentIndex ? newValue : val
        ),
      }));

      // Also validate the parent attribute to update its mandatory error state
      const parentFieldType = additionalAttributes[parentName]?.type;
      if (parentFieldType) {
        validateAdditional(
          parentFieldType,
          parentValue,
          parentName,
          parentIndex
        );
      }
    }
  };

  const handleAddAnotherAdditional = (inputKey) => {
    const newField = "";
    const updatedState = (prevState) => ({
      ...prevState,
      [inputKey]: [...prevState[inputKey], newField],
    });

    setAdditionalInputData(updatedState);

    if (additionalAttributes[inputKey].type === "NestedDropdown") {
      const childAttributeName = `${inputKey}nEsTeD`;

      // Add a new field for the child attribute
      setAdditionalInputData((prevState) => ({
        ...prevState,
        [childAttributeName]: [...prevState[childAttributeName], newField],
      }));

      setAddNestedDropdownValues((prevState) => ({
        ...prevState,
        [inputKey]: [...prevState[inputKey], newField],
      }));

      // Check if there are validation errors for the specific nestedDropdown being added
      const hasValidationErrors = additionalValidationErrors[
        childAttributeName
      ]?.some((error) => error !== null);

      // Enable the "Add" button only if there are no validation errors for the specific nestedDropdown
      setAdditionalValidationErrors((prevErrors) => ({
        ...prevErrors,
        [childAttributeName]: hasValidationErrors
          ? prevErrors[childAttributeName]
          : null,
      }));
    }
  };

  const handleRemoveFieldAdditional = (inputKey, index) => {
    setAdditionalInputData((prevState) => {
      const updatedValues = prevState[inputKey].filter((_, i) => i !== index);
      return {
        ...prevState,
        [inputKey]: updatedValues,
      };
    });

    // Clear validation error for the removed field
    setAdditionalValidationErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      if (updatedErrors[inputKey]) {
        updatedErrors[inputKey] = updatedErrors[inputKey].filter(
          (_, i) => i !== index
        );
      }
      return updatedErrors;
    });

    if (additionalAttributes[inputKey]?.type === "NestedDropdown") {
      const childAttributeName = `${inputKey}nEsTeD`;
      setAdditionalInputData((prevState) => {
        const updatedChildValues =
          prevState[childAttributeName]?.filter((_, i) => i !== index) || [];
        return {
          ...prevState,
          [childAttributeName]: updatedChildValues,
        };
      });

      // Clear validation error for the removed child field
      setAdditionalValidationErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        if (updatedErrors[childAttributeName]) {
          updatedErrors[childAttributeName] = updatedErrors[
            childAttributeName
          ].filter((_, i) => i !== index);
        }
        return updatedErrors;
      });

      setAddNestedDropdownValues((prevState) => {
        const updatedValues = { ...prevState };
        updatedValues[inputKey] =
          updatedValues[inputKey]?.filter((_, i) => i !== index) || [];
        return updatedValues;
      });
    }
  };

  const handleAdditionalNestedDropdownSelection = (name, value, index) => {
    const nestedAttributeName = `${name}nEsTeD`;
    let newValue = value;
    const parentType = additionalAttributes[name].type;

    // console.log("parentType",parentType )
    setAddNestedDropdownValues((prevValues) => ({
      ...prevValues,
      [name]: prevValues[name].map((val, idx) => (idx === index ? value : val)), // Update the parent value
    }));

    // Update both parent and child values in the state
    setAdditionalInputData((prevData) => ({
      ...prevData,
      [name]: prevData[name].map((val, idx) => (idx === index ? value : val)), // Update the parent value
      [nestedAttributeName]: prevData[nestedAttributeName].map((val, idx) =>
        idx === index ? "" : val
      ), // Reset child value
    }));
    if (parentType) {
      validateAdditional(parentType, newValue, name, index);
    }

    // Clear any errors related to parent and child
    setAdditionalMandatoryErrors((prevErrors) => ({
      ...prevErrors,
      [name]: null, // Clear parent error
      [nestedAttributeName]: null, // Clear child error
    }));
  };

  const saveAdditionlMultiSelectValues = (name, selectedValues) => {
    setAdditionalInputData((prevData) => ({
      ...prevData,
      [name]: selectedValues,
    }));
    setAdditionalMandatoryErrors((prevErrors) => ({
      ...prevErrors,
      [name]: selectedValues.length ? null : prevErrors[name],
    }));
  };

  // =============================== images =========================
  const [imageFiles, setImageFiles] = useState({});
  const [imageMandatoryErrors, setImageMandatoryErrors] = useState({});
  // console.log("imageFiles -----" , imageFiles)
  // console.log("imageMandatoryErrors -----" , imageMandatoryErrors)

  const handleImageChange = (event, attributeName) => {
    const file = event.target.files[0];

    if (file) {
      setImageFiles((prevFiles) => ({
        ...prevFiles,
        [attributeName]: file,
      }));

      // Clear mandatory error for the image attribute
      setImageMandatoryErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        if (updatedErrors[attributeName]) {
          updatedErrors[attributeName] = null;
        }
        return updatedErrors;
      });
    }
  };

  const handleSubmitData = (e) => {
    e.preventDefault();

    // Check if there are any validation errors
    const hasValidationErrors = (errorObj) =>
      Object.values(errorObj)
        .flat()
        .some((error) => error !== null);

    if (
      hasValidationErrors(additionalValidationErrors) ||
      hasValidationErrors(groupValidationErrors) ||
      hasValidationErrors(imageValidationErrors)
    ) {
      toast.warning("Please fix validation errors before saving.");
      return;
    }

    // Trim values of all arrays in additionalInputData
    const trimmedAdditionalInputData = Object.fromEntries(
      Object.entries(additionalInputData).map(([key, value]) => [
        key,
        Array.isArray(value)
          ? value.map((val) => (typeof val === "string" ? val.trim() : val))
          : value,
      ])
    );
    // Trim values of all arrays in groupInputData
    const trimmedGroupInputData = Object.fromEntries(
      Object.entries(groupInputData).map(([key, value]) => [
        key,
        Array.isArray(value)
          ? value.map((val) => (typeof val === "string" ? val.trim() : val))
          : value,
      ])
    );

    // Remove empty strings from arrays
    const filteredAdditionalInputData = Object.fromEntries(
      Object.entries(trimmedAdditionalInputData).map(([key, value]) => [
        key,
        Array.isArray(value) ? value.filter((val) => val !== "") : value,
      ])
    );
    const filteredGroupInputData = Object.fromEntries(
      Object.entries(trimmedGroupInputData).map(([key, value]) => [
        key,
        Array.isArray(value) ? value.filter((val) => val !== "") : value,
      ])
    );

    // Check mandatory fields
    const newAdditionalMandatoryErrors = {};
    Object.entries(additionalAttributes).forEach(([key, value]) => {
      if (value.type === "Image") {
        return;
      }
      if (
        value.mandatory &&
        (!filteredAdditionalInputData[key] ||
          filteredAdditionalInputData[key].length === 0)
      ) {
        newAdditionalMandatoryErrors[key] = "This field is mandatory";
      }
      // Check mandatory fields for nested dropdowns
      if (value.type === "NestedDropdown") {
        const childAttributeName = `${key}nEsTeD`;

        (filteredAdditionalInputData[key] || []).forEach(
          (parentValue, index) => {
            const childValue = filteredAdditionalInputData[childAttributeName]
              ? filteredAdditionalInputData[childAttributeName][index]
              : "";

            if (
              value.mandatory &&
              (!parentValue ||
                parentValue.trim() === "" ||
                !childValue ||
                childValue.trim() === "")
            ) {
              newAdditionalMandatoryErrors[childAttributeName] =
                newAdditionalMandatoryErrors[childAttributeName] || [];
              newAdditionalMandatoryErrors[childAttributeName][index] =
                "Both fields are mandatory";
            }
          }
        );
      }
    });
    setAdditionalMandatoryErrors(newAdditionalMandatoryErrors);

    const newGroupMandatoryErrors = {};
    Object.entries(groupAttributes).forEach(([key, value]) => {
      if (value.type === "Image") {
        return;
      }
      if (
        value.mandatory &&
        (!filteredGroupInputData[key] ||
          filteredGroupInputData[key].length === 0)
      ) {
        newGroupMandatoryErrors[key] = "This field is mandatory";
      }
      // Check mandatory fields for nested dropdowns
      if (value.type === "NestedDropdown") {
        const childAttributeName = `${key}nEsTeD`;

        (filteredGroupInputData[key] || []).forEach((parentValue, index) => {
          const childValue = filteredGroupInputData[childAttributeName]
            ? filteredGroupInputData[childAttributeName][index]
            : "";

          if (
            value.mandatory &&
            (!parentValue ||
              parentValue.trim() === "" ||
              !childValue ||
              childValue.trim() === "")
          ) {
            newGroupMandatoryErrors[childAttributeName] =
              newGroupMandatoryErrors[childAttributeName] || [];
            newGroupMandatoryErrors[childAttributeName][index] =
              "Both fields are mandatory";
          }
        });
      }
    });
    setGroupMandatoryErrors(newGroupMandatoryErrors);

    const newImageMandatoryErrors = {};
    Object.entries(groupAttributes).forEach(([key, value]) => {
      if (value.type === "Image") {
        if (value.mandatory && !imageFiles[key]) {
          newImageMandatoryErrors[key] = "This field is mandatory";
        }
      }
    });
    Object.entries(additionalAttributes).forEach(([key, value]) => {
      if (value.type === "Image") {
        if (value.mandatory && !imageFiles[key]) {
          newImageMandatoryErrors[key] = "This field is mandatory";
        }
      }
    });
    setImageMandatoryErrors(newImageMandatoryErrors);

    // Show mandatory errors

    if (
      Object.values(newAdditionalMandatoryErrors).some(
        (error) => error !== null
      )
    ) {
      toast.warning("Please fill in all mandatory fields before saving.");
      return;
    }
    if (
      Object.values(newGroupMandatoryErrors).some((error) => error !== null)
    ) {
      toast.warning("Please fill in all mandatory fields before saving.");
      return;
    }
    if (
      Object.values(newImageMandatoryErrors).some((error) => error !== null)
    ) {
      toast.warning("Please fill in all mandatory fields before saving.");
      return;
    }

    const data = {
      groupAttributes: filteredGroupInputData,
      [additionalAttributesName]: filteredAdditionalInputData,
      imagesAttributes: inputValues,
      groupName: groupNameToShowAttributes,
      className: classNameToSave,
    };

    dispatch(createTablelink(data));
    console.log("data===", data);

    // toast.success('Data saved successfully.');
  };

  const { data, status, error } = useSelector(tableDataSelector);

  useEffect(() => {
    if (data && status === STATUSES.SUCCESS) {
      const createdID = data; // Assuming the ID is in `data.id`

      if (createdID && imageFiles) {
        Object.entries(imageFiles).forEach(async ([key, file]) => {
          const imageFormData = new FormData();
          imageFormData.append("images", file);
          const paramData = { [getID]: data, imageName: key };
          await dispatch(
            ImageUploadLink({ formData: imageFormData, ...paramData })
          );
        });
      }

      toast.success("Created Successfully");
      dispatch(resetTableState({}));
    }
    if (error) {
      toast.error(error);
      dispatch(resetTableState({}));
    }
  }, [data, status, error]);

  const HoverTitle = (type, varDescription, mandatory) => {
    const displayType = type === "String" ? "Text - Single line" : type;
    return `Data type - ${displayType}\nDescription - ${varDescription}\nMandatory - ${
      mandatory ? "Yes" : "No"
    }`;
  };

  const renderStar = (mandatory) => {
    return mandatory ? <i className="mandatory_star">*</i> : null;
  };

  return (
    <>
      <div className="common_table_page add_table_productpage">
        <div className="select_att_group_button_Container">
          <div>
            <h6 className="heading_title">Add {title}</h6>
          </div>
          <div className="first_filter">
            <select
              className="select_step_data"
              type="text"
              required
              value={groupNameToShowAttributes}
              onChange={handleGroupSelection}
            >
              <option value="" className="select_step_value">
                Select Group
              </option>
              {AttributeGroupStatus === STATUSES.LOADING ? (
                <option className="error_option" value="">
                  Loading...
                </option>
              ) : AttributeGroupStatus === STATUSES.ERROR ? (
                <option className="error_option" value="">
                  {AttributeGroupError}
                </option>
              ) : (
                Object.keys(AttributesGroupsData).length > 0 &&
                AttributesGroupsData.map((item, index) => (
                  <option
                    value={item.groupName}
                    className="select_step_value"
                    key={item.groupId}
                  >
                    {item.groupName}
                  </option>
                ))
              )}
            </select>
          </div>
        </div>

        <>
          <div className="add_table_data_container">
            {AttributeGroupStatus === STATUSES.LOADING ? (
              <Loader />
            ) : AttributeGroupStatus === STATUSES.SUCCESS ? (
              Object.keys(AttributesGroupsData).length === 0 ? (
                <RenderWarning
                  message={`Please create a group of attributes to add ${title.toLowerCase()}`}
                  showLink={linkForRedirectSetting}
                />
              ) : groupNameToShowAttributes.length === 0 ? (
                <RenderWarning
                  message={`Please select the group of attributes to add ${title.toLowerCase()}`}
                />
              ) : (
                <>
                  {singleAttributeGroupStatus === STATUSES.LOADING ? (
                    <Loader />
                  ) : singleAttributeGroupStatus === STATUSES.ERROR ? (
                    <h6>
                      Something went wrong {singleAttributeGroupError.message}
                    </h6>
                  ) : singleAttributeGroupStatus === STATUSES.SUCCESS ? (
                    Object.keys(groupAttributes).length === 0 &&
                    Object.keys(additionalAttributes).length === 0 &&
                    Object.keys(imagesAttributes).length === 0 ? (
                      <>
                        <h6 className="att_header">
                          Attributes Group -{" "}
                          <span>{singleAttributeGroupData?.groupName}</span>
                        </h6>
                        <RenderWarning
                          message={`No attributes found to add ${groupNameToShowAttributes}`}
                        />
                      </>
                    ) : (
                      <>
                        <div className="att_header">
                          {/* <span>Attributes Group - <strong>{singleAttributeGroupData?.groupName}</strong></span> */}
                          <select
                            className="select_step_data choosse_reference"
                            type="text"
                            required
                            onChange={handleReferenceSelection}
                          >
                            <option value="" className="select_step_value">
                              Choose reference
                            </option>
                            {statusForReference === STATUSES.LOADING ? (
                              <option className="error_option">
                                Loading...
                              </option>
                            ) : statusForReference === STATUSES.ERROR ? (
                              <option className="error_option">
                                Something went wrong {errorForReference}
                              </option>
                            ) : statusForReference === STATUSES.SUCCESS ? (
                              AllDataForReference &&
                              typeof AllDataForReference === "string" ? (
                                <option className="error_option">{`No ${title} found to choose reference`}</option>
                              ) : (
                                AllDataForReference?.map((item, index) => {
                                  const firstKey =
                                    Object.keys(groupAttributes)[0]; // Get the first key dynamically
                                  return (
                                    <option
                                      value={item[getID]}
                                      className="select_step_value"
                                      key={index}
                                    >
                                      {item.groupAttributes[firstKey]}
                                    </option>
                                  );
                                })
                              )
                            ) : null}
                          </select>
                        </div>

                        <div className="all_attributes_to_add_data add_product_page">
                          {
                            <>
                              {Object.keys(groupAttributes).map((i, index) => {
                                if (groupAttributes[i].type === "Date") {
                                  return (
                                    <div key={index} className="inp_cont">
                                      {(groupInputData[i] || []).map(
                                        (value, valueIndex) => (
                                          <div
                                            className="_input_box"
                                            key={valueIndex}
                                          >
                                            {renderStar(
                                              groupAttributes[i]?.mandatory
                                            )}

                                            <input
                                              className="table_input product"
                                              type="date"
                                              value={value}
                                              placeholder={
                                                groupAttributes[i].nametoDisplay
                                              }
                                              onChange={(e) =>
                                                changeGroupInputData(
                                                  e,
                                                  i,
                                                  valueIndex
                                                )
                                              }
                                              name={i}
                                              required
                                              title={HoverTitle(
                                                groupAttributes[i]?.type,
                                                groupAttributes[i]
                                                  ?.varDescription,
                                                groupAttributes[i]?.mandatory
                                              )}
                                            />
                                            <span>
                                              {groupAttributes[i].nametoDisplay}
                                            </span>
                                            {valueIndex !== 0 && (
                                              <em
                                                onClick={() =>
                                                  handleRemoveFieldGroup(
                                                    i,
                                                    valueIndex
                                                  )
                                                }
                                              >
                                                &times;
                                              </em>
                                            )}
                                            {/* Display mandatory error if present */}
                                            {groupMandatoryErrors[i] && (
                                              <div className="input_error">
                                                {groupMandatoryErrors[i]}
                                              </div>
                                            )}
                                            {groupValidationErrors[i]?.[
                                              valueIndex
                                            ] && (
                                              <div className="input_error">
                                                {
                                                  groupValidationErrors[i][
                                                    valueIndex
                                                  ]
                                                }
                                              </div>
                                            )}
                                          </div>
                                        )
                                      )}
                                      {groupAttributes[i].repeat && (
                                        <button
                                          onClick={() =>
                                            handleAddAnotherGroup(i)
                                          }
                                          disabled={
                                            groupInputData[i]?.some(
                                              (value) => value.trim() === ""
                                            ) ||
                                            Object.values(
                                              groupValidationErrors[i] || {}
                                            ).some((error) => error !== null)
                                          }
                                        >
                                          +
                                        </button>
                                      )}
                                    </div>
                                  );
                                } else if (
                                  groupAttributes[i].type === "Image"
                                ) {
                                  return (
                                    <div class="inp_cont">
                                      <div key={index} className="_input_box">
                                        {renderStar(
                                          groupAttributes[i]?.mandatory
                                        )}
                                        <input
                                          className="table_input image_input 77"
                                          placeholder={
                                            groupAttributes[i].nametoDisplay
                                          }
                                          type="file"
                                          name={i}
                                          onChange={(e) =>
                                            handleImageChange(e, i)
                                          }
                                          accept="image/*"
                                          required
                                          title={HoverTitle(
                                            groupAttributes[i]?.type,
                                            groupAttributes[i]?.varDescription,
                                            groupAttributes[i]?.mandatory
                                          )}
                                        />
                                        <span className="file_type">
                                          {groupAttributes[i].nametoDisplay}
                                        </span>

                                        {imageMandatoryErrors[i] && (
                                          <div className="input_error">
                                            {imageMandatoryErrors[i]}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  );
                                } else if (
                                  groupAttributes[i].type === "DateTime"
                                ) {
                                  return (
                                    <div className="inp_cont" key={index}>
                                      {(groupInputData[i] || []).map(
                                        (value, valueIndex) => (
                                          <div
                                            className="_input_box"
                                            key={valueIndex}
                                          >
                                            {renderStar(
                                              groupAttributes[i]?.mandatory
                                            )}

                                            <input
                                              className="table_input product"
                                              type="datetime-local"
                                              value={value}
                                              placeholder={
                                                groupAttributes[i].nametoDisplay
                                              }
                                              onChange={(e) =>
                                                changeGroupInputData(
                                                  e,
                                                  i,
                                                  valueIndex
                                                )
                                              }
                                              name={i}
                                              required
                                              title={HoverTitle(
                                                groupAttributes[i]?.type,
                                                groupAttributes[i]
                                                  ?.varDescription,
                                                groupAttributes[i]?.mandatory
                                              )}
                                            />
                                            <span>
                                              {groupAttributes[i].nametoDisplay}
                                            </span>
                                            {valueIndex !== 0 && (
                                              <em
                                                onClick={() =>
                                                  handleRemoveFieldGroup(
                                                    i,
                                                    valueIndex
                                                  )
                                                }
                                              >
                                                &times;
                                              </em>
                                            )}
                                            {/* Display mandatory error if present */}
                                            {groupMandatoryErrors[i] && (
                                              <div className="input_error">
                                                {groupMandatoryErrors[i]}
                                              </div>
                                            )}
                                            {groupValidationErrors[i]?.[
                                              valueIndex
                                            ] && (
                                              <div className="input_error">
                                                {
                                                  groupValidationErrors[i][
                                                    valueIndex
                                                  ]
                                                }
                                              </div>
                                            )}
                                          </div>
                                        )
                                      )}
                                      {groupAttributes[i].repeat && (
                                        <button
                                          onClick={() =>
                                            handleAddAnotherGroup(i)
                                          }
                                          disabled={
                                            groupInputData[i]?.some(
                                              (value) => value.trim() === ""
                                            ) ||
                                            Object.values(
                                              groupValidationErrors[i] || {}
                                            ).some((error) => error !== null)
                                          }
                                        >
                                          +
                                        </button>
                                      )}
                                    </div>
                                  );
                                } else if (
                                  groupAttributes[i].type === "MultiSelect"
                                ) {
                                  return (
                                    <div
                                      style={{ position: "relative" }}
                                      key={index}
                                      title={HoverTitle(
                                        groupAttributes[i]?.type,
                                        groupAttributes[i]?.varDescription,
                                        groupAttributes[i]?.mandatory
                                      )}
                                    >
                                      {renderStar(
                                        groupAttributes[i]?.mandatory
                                      )}

                                      <Multiselect
                                        isObject={false}
                                        options={groupAttributes[i].options}
                                        placeholder={
                                          groupAttributes[i].nametoDisplay
                                        }
                                        onSelect={(selectedList) =>
                                          saveGroupMultiSelectValues(
                                            i,
                                            selectedList
                                          )
                                        }
                                        onRemove={(selectedList) =>
                                          saveGroupMultiSelectValues(
                                            i,
                                            selectedList
                                          )
                                        }
                                        selectedValues={groupInputData[i] || []}
                                        required
                                      />
                                      {groupMandatoryErrors[i] && (
                                        <div className="input_error multiSelectError">
                                          {groupMandatoryErrors[i]}
                                        </div>
                                      )}
                                    </div>
                                  );
                                } else if (
                                  groupAttributes[i].type === "Dropdown"
                                ) {
                                  return (
                                    <div key={index} className="inp_cont">
                                      {(groupInputData[i] || []).map(
                                        (value, valueIndex) => (
                                          <div
                                            className="_input_box"
                                            key={valueIndex}
                                            title={HoverTitle(
                                              groupAttributes[i]?.type,
                                              groupAttributes[i]
                                                ?.varDescription,
                                              groupAttributes[i]?.mandatory
                                            )}
                                          >
                                            <select
                                              className="table_input product table_inputfirst"
                                              value={value}
                                              onChange={(e) =>
                                                changeGroupInputData(
                                                  e,
                                                  i,
                                                  valueIndex
                                                )
                                              }
                                              name={i}
                                              required
                                            >
                                              <option value="">
                                                Select{" "}
                                                {
                                                  groupAttributes[i]
                                                    .nametoDisplay
                                                }
                                              </option>
                                              {groupAttributes[i].options.map(
                                                (option, optionIndex) => (
                                                  <option
                                                    key={optionIndex}
                                                    value={option}
                                                  >
                                                    {option}
                                                  </option>
                                                )
                                              )}
                                            </select>
                                            <span>
                                              {groupAttributes[i].nametoDisplay}
                                            </span>
                                            {valueIndex !== 0 && (
                                              <em
                                                onClick={() =>
                                                  handleRemoveFieldGroup(
                                                    i,
                                                    valueIndex
                                                  )
                                                }
                                              >
                                                &times;
                                              </em>
                                            )}
                                            {/* Display mandatory error if present */}
                                            {groupMandatoryErrors[i] && (
                                              <div className="input_error">
                                                {groupMandatoryErrors[i]}
                                              </div>
                                            )}
                                            {groupValidationErrors[i]?.[
                                              valueIndex
                                            ] && (
                                              <div className="input_error">
                                                {
                                                  groupValidationErrors[i][
                                                    valueIndex
                                                  ]
                                                }
                                              </div>
                                            )}
                                          </div>
                                        )
                                      )}
                                      {groupAttributes[i].repeat && (
                                        <button
                                          onClick={() =>
                                            handleAddAnotherGroup(i)
                                          }
                                          disabled={
                                            groupInputData[i]?.some(
                                              (value) => value.trim() === ""
                                            ) ||
                                            Object.values(
                                              groupValidationErrors[i] || {}
                                            ).some((error) => error !== null)
                                          }
                                        >
                                          +
                                        </button>
                                      )}
                                    </div>
                                  );
                                } else if (
                                  groupAttributes[i].type === "NestedDropdown"
                                ) {
                                  const parentAttributeName = i;
                                  const childAttributeName = `${i}nEsTeD`;

                                  // Check if any parent values are present
                                  const isParentValueAdded =
                                    groupInputData[parentAttributeName] &&
                                    groupInputData[parentAttributeName].some(
                                      (value) => value.trim() !== ""
                                    );

                                  // Check if any child values are present
                                  const isChildValueAdded =
                                    groupInputData[childAttributeName] &&
                                    groupInputData[childAttributeName].some(
                                      (value) => value.trim() !== ""
                                    );

                                  // Check if all sets have both parent and child values
                                  const allSetsHaveValues = (
                                    groupInputData[parentAttributeName] || []
                                  ).every((_, valueIndex) => {
                                    const isParentValuePresent =
                                      groupInputData[parentAttributeName][
                                        valueIndex
                                      ] &&
                                      groupInputData[parentAttributeName][
                                        valueIndex
                                      ].trim() !== "";
                                    const isChildValuePresent =
                                      groupInputData[childAttributeName] &&
                                      groupInputData[childAttributeName][
                                        valueIndex
                                      ] &&
                                      groupInputData[childAttributeName][
                                        valueIndex
                                      ].trim() !== "";
                                    return (
                                      isParentValuePresent &&
                                      isChildValuePresent
                                    );
                                  });

                                  return (
                                    <div className="inp_cont" key={index}>
                                      <>
                                        {(groupInputData[i] || []).map(
                                          (value, valueIndex) => (
                                            <div
                                              className="nested_input"
                                              key={valueIndex}
                                            >
                                              <div
                                                className="_input_box"
                                                title={HoverTitle(
                                                  groupAttributes[i]?.type,
                                                  groupAttributes[i]
                                                    ?.varDescription,
                                                  groupAttributes[i]?.mandatory
                                                )}
                                              >
                                                <select
                                                  className="table_input product table_inputfirst"
                                                  value={value}
                                                  onChange={(e) =>
                                                    handleGroupNestedDropdownSelection(
                                                      i,
                                                      e.target.value,
                                                      valueIndex
                                                    )
                                                  }
                                                  name={i}
                                                  required
                                                >
                                                  <option
                                                    value=""
                                                    className="selectfirstopt"
                                                  >
                                                    Select{" "}
                                                    {
                                                      groupAttributes[i]
                                                        .nametoDisplay
                                                    }
                                                  </option>
                                                  {groupAttributes[
                                                    i
                                                  ].options.map(
                                                    (option, optionIndex) => (
                                                      <option
                                                        key={optionIndex}
                                                        value={option}
                                                      >
                                                        {option}
                                                      </option>
                                                    )
                                                  )}
                                                </select>
                                                <span>
                                                  {
                                                    groupAttributes[i]
                                                      .nametoDisplay
                                                  }
                                                </span>
                                                {/* Display mandatory error if present */}
                                                {groupMandatoryErrors[i] && (
                                                  <div className="input_error">
                                                    {groupMandatoryErrors[i]}
                                                  </div>
                                                )}
                                                {groupValidationErrors[i]?.[
                                                  valueIndex
                                                ] && (
                                                  <div className="input_error">
                                                    {
                                                      groupValidationErrors[i][
                                                        valueIndex
                                                      ]
                                                    }
                                                  </div>
                                                )}
                                              </div>
                                              {/* Render child inputs based on the index of groupNestedDropdownValues */}
                                              {groupNestedDropdownValues[i] &&
                                                groupNestedDropdownValues[i][
                                                  valueIndex
                                                ] && (
                                                  <>
                                                    {/* Render child inputs based on childDataType */}
                                                    {groupAttributes[i]
                                                      .childDataType ===
                                                      "Dropdown" && (
                                                      <div
                                                        className="_input_box"
                                                        title={HoverTitle(
                                                          groupAttributes[i]
                                                            ?.childDataType,
                                                          groupAttributes[i]
                                                            ?.varDescription,
                                                          groupAttributes[i]
                                                            ?.mandatory
                                                        )}
                                                      >
                                                        <select
                                                          className="table_input product table_inputfirst"
                                                          value={
                                                            groupInputData[
                                                              childAttributeName
                                                            ][valueIndex]
                                                          }
                                                          onChange={(e) =>
                                                            changeGroupInputData(
                                                              e,
                                                              childAttributeName,
                                                              valueIndex
                                                            )
                                                          }
                                                          name={
                                                            childAttributeName
                                                          }
                                                          required
                                                        >
                                                          <option value="">
                                                            Select{" "}
                                                            {
                                                              groupAttributes[i]
                                                                .nametoDisplay2
                                                            }
                                                          </option>
                                                          {groupAttributes[
                                                            i
                                                          ].nestedOptions[
                                                            groupAttributes[
                                                              i
                                                            ].options.indexOf(
                                                              value
                                                            )
                                                          ].map(
                                                            (
                                                              nestedOption,
                                                              nestedIndex
                                                            ) => (
                                                              <option
                                                                key={
                                                                  nestedIndex
                                                                }
                                                                value={
                                                                  nestedOption
                                                                }
                                                              >
                                                                {nestedOption}
                                                              </option>
                                                            )
                                                          )}
                                                        </select>
                                                        <span>
                                                          {
                                                            groupAttributes[i]
                                                              .nametoDisplay2
                                                          }
                                                        </span>
                                                      </div>
                                                    )}
                                                    {groupAttributes[i]
                                                      .childDataType ===
                                                      "Date" && (
                                                      <div
                                                        className="_input_box"
                                                        title={HoverTitle(
                                                          groupAttributes[i]
                                                            ?.childDataType,
                                                          groupAttributes[i]
                                                            ?.varDescription,
                                                          groupAttributes[i]
                                                            ?.mandatory
                                                        )}
                                                      >
                                                        <input
                                                          className="table_input product"
                                                          type="date"
                                                          required
                                                          name={
                                                            childAttributeName
                                                          }
                                                          value={
                                                            groupInputData[
                                                              childAttributeName
                                                            ][valueIndex]
                                                          }
                                                          placeholder={
                                                            groupAttributes[i]
                                                              .nametoDisplay2
                                                          }
                                                          onChange={(e) =>
                                                            changeGroupInputData(
                                                              e,
                                                              childAttributeName,
                                                              valueIndex
                                                            )
                                                          }
                                                        />
                                                        <span>
                                                          {
                                                            groupAttributes[i]
                                                              .nametoDisplay2
                                                          }
                                                        </span>
                                                      </div>
                                                    )}
                                                    {groupAttributes[i]
                                                      .childDataType ===
                                                      "DateTime" && (
                                                      <div
                                                        className="_input_box"
                                                        title={HoverTitle(
                                                          groupAttributes[i]
                                                            ?.childDataType,
                                                          groupAttributes[i]
                                                            ?.varDescription,
                                                          groupAttributes[i]
                                                            ?.mandatory
                                                        )}
                                                      >
                                                        <input
                                                          className="table_input product"
                                                          type="datetime-local"
                                                          required
                                                          name={
                                                            childAttributeName
                                                          }
                                                          value={
                                                            groupInputData[
                                                              childAttributeName
                                                            ][valueIndex]
                                                          }
                                                          placeholder={
                                                            groupAttributes[i]
                                                              .nametoDisplay2
                                                          }
                                                          onChange={(e) =>
                                                            changeGroupInputData(
                                                              e,
                                                              childAttributeName,
                                                              valueIndex
                                                            )
                                                          }
                                                        />
                                                        <span>
                                                          {
                                                            groupAttributes[i]
                                                              .nametoDisplay2
                                                          }
                                                        </span>
                                                      </div>
                                                    )}
                                                    {(groupAttributes[i]
                                                      .childDataType ===
                                                      "String" ||
                                                      groupAttributes[i]
                                                        .childDataType ===
                                                        "Number" ||
                                                      groupAttributes[i]
                                                        .childDataType ===
                                                        "Decimal" ||
                                                      groupAttributes[i]
                                                        .childDataType ===
                                                        "Percent" ||
                                                      groupAttributes[i]
                                                        .childDataType ===
                                                        "Email" ||
                                                      groupAttributes[i]
                                                        .childDataType ===
                                                        "URL" ||
                                                      groupAttributes[i]
                                                        .childDataType ===
                                                        "Phone") && (
                                                      <div
                                                        className="_input_box"
                                                        title={HoverTitle(
                                                          groupAttributes[i]
                                                            ?.childDataType,
                                                          groupAttributes[i]
                                                            ?.varDescription,
                                                          groupAttributes[i]
                                                            ?.mandatory
                                                        )}
                                                      >
                                                        <input
                                                          className="table_input product"
                                                          type="text"
                                                          placeholder={
                                                            groupAttributes[i]
                                                              .nametoDisplay2
                                                          }
                                                          required
                                                          name={
                                                            childAttributeName
                                                          }
                                                          value={
                                                            groupInputData[
                                                              childAttributeName
                                                            ][valueIndex]
                                                          }
                                                          onChange={(e) =>
                                                            changeGroupInputData(
                                                              e,
                                                              childAttributeName,
                                                              valueIndex
                                                            )
                                                          }
                                                        />
                                                        <span>
                                                          {
                                                            groupAttributes[i]
                                                              .nametoDisplay2
                                                          }
                                                        </span>
                                                      </div>
                                                    )}
                                                    {/* Display mandatory error if present */}
                                                    {groupMandatoryErrors[
                                                      childAttributeName
                                                    ] && (
                                                      <div className="input_error">
                                                        {
                                                          groupMandatoryErrors[
                                                            childAttributeName
                                                          ]
                                                        }
                                                      </div>
                                                    )}
                                                    {groupValidationErrors[
                                                      childAttributeName
                                                    ]?.[valueIndex] && (
                                                      <div className="input_error">
                                                        {
                                                          groupValidationErrors[
                                                            childAttributeName
                                                          ][valueIndex]
                                                        }
                                                      </div>
                                                    )}
                                                  </>
                                                )}

                                              {valueIndex !== 0 && (
                                                <em
                                                  onClick={() =>
                                                    handleRemoveFieldGroup(
                                                      i,
                                                      valueIndex
                                                    )
                                                  }
                                                >
                                                  &times;
                                                </em>
                                              )}
                                            </div>
                                          )
                                        )}

                                        {/* Conditionally render the "Add" button only once */}
                                        {groupAttributes[i].repeat && (
                                          <button
                                            onClick={() =>
                                              handleAddAnotherGroup(i)
                                            }
                                            disabled={
                                              !isParentValueAdded ||
                                              !isChildValueAdded ||
                                              !allSetsHaveValues ||
                                              Object.values(
                                                groupValidationErrors[i] || {}
                                              ).some(
                                                (error) => error !== null
                                              ) ||
                                              Object.values(
                                                groupValidationErrors[
                                                  childAttributeName
                                                ] || {}
                                              ).some((error) => error !== null)
                                            }
                                          >
                                            +
                                          </button>
                                        )}
                                      </>
                                    </div>
                                  );
                                } else {
                                  return (
                                    <div key={index} className="inp_cont">
                                      {(groupInputData[i] || []).map(
                                        (value, valueIndex) => (
                                          <div
                                            className="_input_box"
                                            key={valueIndex}
                                            title={HoverTitle(
                                              groupAttributes[i]?.type,
                                              groupAttributes[i]
                                                ?.varDescription,
                                              groupAttributes[i]?.mandatory
                                            )}
                                          >
                                            {renderStar(
                                              groupAttributes[i]?.mandatory
                                            )}
                                            <input
                                              className="table_input product"
                                              type="text"
                                              value={
                                                groupInputData[i][valueIndex]
                                              }
                                              placeholder={
                                                groupAttributes[i].nametoDisplay
                                              }
                                              onChange={(e) =>
                                                changeGroupInputData(
                                                  e,
                                                  i,
                                                  valueIndex
                                                )
                                              }
                                              name={i}
                                              required
                                            />
                                            <span>
                                              {groupAttributes[i].nametoDisplay}
                                            </span>
                                            {valueIndex !== 0 && (
                                              <em
                                                onClick={() =>
                                                  handleRemoveFieldGroup(
                                                    i,
                                                    valueIndex
                                                  )
                                                }
                                              >
                                                &times;
                                              </em>
                                            )}
                                            {/* Display mandatory error if present */}
                                            {groupMandatoryErrors[i] && (
                                              <div className="input_error">
                                                {groupMandatoryErrors[i]}
                                              </div>
                                            )}
                                            {groupValidationErrors[i]?.[
                                              valueIndex
                                            ] && (
                                              <div className="input_error">
                                                {
                                                  groupValidationErrors[i][
                                                    valueIndex
                                                  ]
                                                }
                                              </div>
                                            )}
                                          </div>
                                        )
                                      )}
                                      {groupAttributes[i].repeat && (
                                        <button
                                          onClick={() =>
                                            handleAddAnotherGroup(i)
                                          }
                                          disabled={
                                            groupInputData[i]?.some(
                                              (value) => value.trim() === ""
                                            ) ||
                                            Object.values(
                                              groupValidationErrors[i] || {}
                                            ).some((error) => error !== null)
                                          }
                                        >
                                          +
                                        </button>
                                      )}
                                    </div>
                                  );
                                }
                              })}
                            </>
                          }

                          {
                            <>
                              {Object.keys(additionalAttributes).map(
                                (i, index) => {
                                  if (additionalAttributes[i].type === "Date") {
                                    return (
                                      <div key={index} className="inp_cont">
                                        {(additionalInputData[i] || []).map(
                                          (value, valueIndex) => (
                                            <div
                                              className="_input_box"
                                              key={valueIndex}
                                            >
                                              {renderStar(
                                                additionalAttributes[i]
                                                  ?.mandatory
                                              )}
                                              <input
                                                className="table_input product"
                                                type="date"
                                                value={value}
                                                placeholder={
                                                  additionalAttributes[i]
                                                    .nametoDisplay
                                                }
                                                onChange={(e) =>
                                                  changeAdditionalInputData(
                                                    e,
                                                    i,
                                                    valueIndex
                                                  )
                                                }
                                                name={i}
                                                required
                                                title={HoverTitle(
                                                  additionalAttributes[i]?.type,
                                                  additionalAttributes[i]
                                                    ?.varDescription,
                                                  additionalAttributes[i]
                                                    ?.mandatory
                                                )}
                                              />
                                              <span>
                                                {
                                                  additionalAttributes[i]
                                                    .nametoDisplay
                                                }
                                              </span>
                                              {valueIndex !== 0 && (
                                                <em
                                                  onClick={() =>
                                                    handleRemoveFieldAdditional(
                                                      i,
                                                      valueIndex
                                                    )
                                                  }
                                                >
                                                  &times;
                                                </em>
                                              )}
                                              {/* Display mandatory error if present */}
                                              {additionalMandatoryErrors[i] && (
                                                <div className="input_error">
                                                  {additionalMandatoryErrors[i]}
                                                </div>
                                              )}
                                              {additionalValidationErrors[i]?.[
                                                valueIndex
                                              ] && (
                                                <div className="input_error">
                                                  {
                                                    additionalValidationErrors[
                                                      i
                                                    ][valueIndex]
                                                  }
                                                </div>
                                              )}
                                            </div>
                                          )
                                        )}
                                        {additionalAttributes[i].repeat && (
                                          <button
                                            onClick={() =>
                                              handleAddAnotherAdditional(i)
                                            }
                                            disabled={
                                              additionalInputData[i]?.some(
                                                (value) => value.trim() === ""
                                              ) ||
                                              Object.values(
                                                additionalValidationErrors[i] ||
                                                  {}
                                              ).some((error) => error !== null)
                                            }
                                          >
                                            +
                                          </button>
                                        )}
                                      </div>
                                    );
                                  } else if (
                                    additionalAttributes[i].type === "Image"
                                  ) {
                                    return (
                                      <div className="inp_cont">
                                        <div key={index} className="_input_box">
                                          {renderStar(
                                            additionalAttributes[i]?.mandatory
                                          )}
                                          <input
                                            className="table_input product image_input"
                                            type="file"
                                            name={i}
                                            placeholder={
                                              additionalAttributes[i]
                                                .nametoDisplay
                                            }
                                            onChange={(e) =>
                                              handleImageChange(e, i)
                                            }
                                            accept="image/*"
                                            required
                                            title={HoverTitle(
                                              additionalAttributes[i]?.type,
                                              additionalAttributes[i]
                                                ?.varDescription,
                                              additionalAttributes[i]?.mandatory
                                            )}
                                          />
                                          <span className="file_type">
                                            {
                                              additionalAttributes[i]
                                                .nametoDisplay
                                            }
                                          </span>

                                          {imageMandatoryErrors[i] && (
                                            <div className="input_error">
                                              {imageMandatoryErrors[i]}
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    );
                                  } else if (
                                    additionalAttributes[i].type === "DateTime"
                                  ) {
                                    return (
                                      <div className="inp_cont" key={index}>
                                        {(additionalInputData[i] || []).map(
                                          (value, valueIndex) => (
                                            <div
                                              className="_input_box"
                                              key={valueIndex}
                                            >
                                              {renderStar(
                                                additionalAttributes[i]
                                                  ?.mandatory
                                              )}

                                              <input
                                                className="table_input product"
                                                type="datetime-local"
                                                value={value}
                                                placeholder={
                                                  additionalAttributes[i]
                                                    .nametoDisplay
                                                }
                                                onChange={(e) =>
                                                  changeAdditionalInputData(
                                                    e,
                                                    i,
                                                    valueIndex
                                                  )
                                                }
                                                name={i}
                                                required
                                                title={HoverTitle(
                                                  additionalAttributes[i]?.type,
                                                  additionalAttributes[i]
                                                    ?.varDescription,
                                                  additionalAttributes[i]
                                                    ?.mandatory
                                                )}
                                              />
                                              <span>
                                                {
                                                  additionalAttributes[i]
                                                    .nametoDisplay
                                                }
                                              </span>
                                              {valueIndex !== 0 && (
                                                <em
                                                  onClick={() =>
                                                    handleRemoveFieldAdditional(
                                                      i,
                                                      valueIndex
                                                    )
                                                  }
                                                >
                                                  &times;
                                                </em>
                                              )}
                                              {/* Display mandatory error if present */}
                                              {additionalMandatoryErrors[i] && (
                                                <div className="input_error">
                                                  {additionalMandatoryErrors[i]}
                                                </div>
                                              )}
                                              {additionalValidationErrors[i]?.[
                                                valueIndex
                                              ] && (
                                                <div className="input_error">
                                                  {
                                                    additionalValidationErrors[
                                                      i
                                                    ][valueIndex]
                                                  }
                                                </div>
                                              )}
                                            </div>
                                          )
                                        )}
                                        {additionalAttributes[i].repeat && (
                                          <button
                                            onClick={() =>
                                              handleAddAnotherAdditional(i)
                                            }
                                            disabled={
                                              additionalInputData[i]?.some(
                                                (value) => value.trim() === ""
                                              ) ||
                                              Object.values(
                                                additionalValidationErrors[i] ||
                                                  {}
                                              ).some((error) => error !== null)
                                            }
                                          >
                                            +
                                          </button>
                                        )}
                                      </div>
                                    );
                                  } else if (
                                    additionalAttributes[i].type ===
                                    "MultiSelect"
                                  ) {
                                    return (
                                      <div
                                        style={{ position: "relative" }}
                                        key={index}
                                        title={HoverTitle(
                                          additionalAttributes[i]?.type,
                                          additionalAttributes[i]
                                            ?.varDescription,
                                          additionalAttributes[i]?.mandatory
                                        )}
                                      >
                                        {renderStar(
                                          additionalAttributes[i]?.mandatory
                                        )}
                                        <Multiselect
                                          isObject={false}
                                          options={
                                            additionalAttributes[i].options
                                          }
                                          placeholder={
                                            additionalAttributes[i]
                                              .nametoDisplay
                                          }
                                          title={`Data type- ${additionalAttributes[i].type}\nDescription- ${additionalAttributes[i].varDescription}`}
                                          onSelect={(selectedList) =>
                                            saveAdditionlMultiSelectValues(
                                              i,
                                              selectedList
                                            )
                                          }
                                          onRemove={(selectedList) =>
                                            saveAdditionlMultiSelectValues(
                                              i,
                                              selectedList
                                            )
                                          }
                                          selectedValues={
                                            additionalInputData[i] || []
                                          }
                                          required
                                        />
                                        {additionalMandatoryErrors[i] && (
                                          <div className="input_error multiSelectError">
                                            {additionalMandatoryErrors[i]}
                                          </div>
                                        )}
                                      </div>
                                    );
                                  } else if (
                                    additionalAttributes[i].type === "Dropdown"
                                  ) {
                                    return (
                                      <div key={index} className="inp_cont">
                                        {(additionalInputData[i] || []).map(
                                          (value, valueIndex) => (
                                            <div
                                              className="_input_box"
                                              key={valueIndex}
                                              title={HoverTitle(
                                                additionalAttributes[i]?.type,
                                                additionalAttributes[i]
                                                  ?.varDescription,
                                                additionalAttributes[i]
                                                  ?.mandatory
                                              )}
                                            >
                                              {renderStar(
                                                additionalAttributes[i]
                                                  ?.mandatory
                                              )}
                                              <select
                                                className="table_input product table_inputfirst"
                                                value={value}
                                                onChange={(e) =>
                                                  changeAdditionalInputData(
                                                    e,
                                                    i,
                                                    valueIndex
                                                  )
                                                }
                                                name={i}
                                                required
                                              >
                                                <option value="">
                                                  Select{" "}
                                                  {
                                                    additionalAttributes[i]
                                                      .nametoDisplay
                                                  }
                                                </option>
                                                {additionalAttributes[
                                                  i
                                                ].options.map(
                                                  (option, optionIndex) => (
                                                    <option
                                                      key={optionIndex}
                                                      value={option}
                                                    >
                                                      {option}
                                                    </option>
                                                  )
                                                )}
                                              </select>
                                              <span>
                                                {
                                                  additionalAttributes[i]
                                                    .nametoDisplay
                                                }
                                              </span>
                                              {valueIndex !== 0 && (
                                                <em
                                                  onClick={() =>
                                                    handleRemoveFieldAdditional(
                                                      i,
                                                      valueIndex
                                                    )
                                                  }
                                                >
                                                  &times;
                                                </em>
                                              )}
                                              {/* Display mandatory error if present */}
                                              {additionalMandatoryErrors[i] && (
                                                <div className="input_error">
                                                  {additionalMandatoryErrors[i]}
                                                </div>
                                              )}
                                              {additionalValidationErrors[i]?.[
                                                valueIndex
                                              ] && (
                                                <div className="input_error">
                                                  {
                                                    additionalValidationErrors[
                                                      i
                                                    ][valueIndex]
                                                  }
                                                </div>
                                              )}
                                            </div>
                                          )
                                        )}
                                        {additionalAttributes[i].repeat && (
                                          <button
                                            onClick={() =>
                                              handleAddAnotherAdditional(i)
                                            }
                                            disabled={
                                              additionalInputData[i]?.some(
                                                (value) => value.trim() === ""
                                              ) ||
                                              Object.values(
                                                additionalValidationErrors[i] ||
                                                  {}
                                              ).some((error) => error !== null)
                                            }
                                          >
                                            +
                                          </button>
                                        )}
                                      </div>
                                    );
                                  } else if (
                                    additionalAttributes[i].type ===
                                    "NestedDropdown"
                                  ) {
                                    const parentAttributeName = i;
                                    const childAttributeName = `${i}nEsTeD`;

                                    // Check if any parent values are present
                                    const isParentValueAdded =
                                      additionalInputData[
                                        parentAttributeName
                                      ] &&
                                      additionalInputData[
                                        parentAttributeName
                                      ].some((value) => value.trim() !== "");

                                    // Check if any child values are present
                                    const isChildValueAdded =
                                      additionalInputData[childAttributeName] &&
                                      additionalInputData[
                                        childAttributeName
                                      ].some((value) => value.trim() !== "");

                                    // Check if all sets have both parent and child values
                                    const allSetsHaveValues = (
                                      additionalInputData[
                                        parentAttributeName
                                      ] || []
                                    ).every((_, valueIndex) => {
                                      const isParentValuePresent =
                                        additionalInputData[
                                          parentAttributeName
                                        ][valueIndex] &&
                                        additionalInputData[
                                          parentAttributeName
                                        ][valueIndex].trim() !== "";
                                      const isChildValuePresent =
                                        additionalInputData[
                                          childAttributeName
                                        ] &&
                                        additionalInputData[childAttributeName][
                                          valueIndex
                                        ] &&
                                        additionalInputData[childAttributeName][
                                          valueIndex
                                        ].trim() !== "";
                                      return (
                                        isParentValuePresent &&
                                        isChildValuePresent
                                      );
                                    });

                                    return (
                                      <div className="inp_cont" key={index}>
                                        <>
                                          {(additionalInputData[i] || []).map(
                                            (value, valueIndex) => (
                                              <div
                                                className="nested_input"
                                                key={valueIndex}
                                              >
                                                <div
                                                  className="_input_box"
                                                  title={HoverTitle(
                                                    additionalAttributes[i]
                                                      ?.type,
                                                    additionalAttributes[i]
                                                      ?.varDescription,
                                                    additionalAttributes[i]
                                                      ?.mandatory
                                                  )}
                                                >
                                                  {renderStar(
                                                    additionalAttributes[i]
                                                      ?.mandatory
                                                  )}

                                                  <select
                                                    className="table_input product table_inputfirst"
                                                    value={value}
                                                    onChange={(e) =>
                                                      handleAdditionalNestedDropdownSelection(
                                                        i,
                                                        e.target.value,
                                                        valueIndex
                                                      )
                                                    }
                                                    name={i}
                                                    required
                                                  >
                                                    <option value="">
                                                      {
                                                        additionalAttributes[i]
                                                          .nametoDisplay
                                                      }
                                                    </option>
                                                    {additionalAttributes[
                                                      i
                                                    ].options.map(
                                                      (option, optionIndex) => (
                                                        <option
                                                          key={optionIndex}
                                                          value={option}
                                                        >
                                                          {option}
                                                        </option>
                                                      )
                                                    )}
                                                  </select>
                                                  <span>
                                                    {
                                                      additionalAttributes[i]
                                                        .nametoDisplay
                                                    }
                                                  </span>
                                                  {/* Display mandatory error if present */}
                                                  {additionalMandatoryErrors[
                                                    i
                                                  ] && (
                                                    <div className="input_error">
                                                      {
                                                        additionalMandatoryErrors[
                                                          i
                                                        ]
                                                      }
                                                    </div>
                                                  )}
                                                  {additionalValidationErrors[
                                                    i
                                                  ]?.[valueIndex] && (
                                                    <div className="input_error">
                                                      {
                                                        additionalValidationErrors[
                                                          i
                                                        ][valueIndex]
                                                      }
                                                    </div>
                                                  )}
                                                </div>

                                                {/* Render child inputs based on the index of addNestedDropdownValues */}
                                                {addNestedDropdownValues[i] &&
                                                  addNestedDropdownValues[i][
                                                    valueIndex
                                                  ] && (
                                                    <>
                                                      {/* Render child inputs based on childDataType */}
                                                      {additionalAttributes[i]
                                                        .childDataType ===
                                                        "Dropdown" && (
                                                        <div
                                                          className="_input_box"
                                                          title={HoverTitle(
                                                            additionalAttributes[
                                                              i
                                                            ]?.childDataType,
                                                            additionalAttributes[
                                                              i
                                                            ]?.varDescription,
                                                            additionalAttributes[
                                                              i
                                                            ]?.mandatory
                                                          )}
                                                        >
                                                          <select
                                                            className="table_input product table_inputfirst"
                                                            value={
                                                              additionalInputData[
                                                                childAttributeName
                                                              ][valueIndex]
                                                            }
                                                            onChange={(e) =>
                                                              changeAdditionalInputData(
                                                                e,
                                                                childAttributeName,
                                                                valueIndex
                                                              )
                                                            }
                                                            name={
                                                              childAttributeName
                                                            }
                                                            required
                                                          >
                                                            <option value="">
                                                              {
                                                                additionalAttributes[
                                                                  i
                                                                ].nametoDisplay2
                                                              }
                                                            </option>
                                                            {additionalAttributes[
                                                              i
                                                            ].nestedOptions[
                                                              additionalAttributes[
                                                                i
                                                              ].options.indexOf(
                                                                value
                                                              )
                                                            ].map(
                                                              (
                                                                nestedOption,
                                                                nestedIndex
                                                              ) => (
                                                                <option
                                                                  key={
                                                                    nestedIndex
                                                                  }
                                                                  value={
                                                                    nestedOption
                                                                  }
                                                                >
                                                                  {nestedOption}
                                                                </option>
                                                              )
                                                            )}
                                                          </select>
                                                          <span>
                                                            {
                                                              additionalAttributes[
                                                                i
                                                              ].nametoDisplay2
                                                            }
                                                          </span>
                                                        </div>
                                                      )}
                                                      {additionalAttributes[i]
                                                        .childDataType ===
                                                        "Date" && (
                                                        <div
                                                          className="_input_box"
                                                          title={HoverTitle(
                                                            additionalAttributes[
                                                              i
                                                            ]?.childDataType,
                                                            additionalAttributes[
                                                              i
                                                            ]?.varDescription,
                                                            additionalAttributes[
                                                              i
                                                            ]?.mandatory
                                                          )}
                                                        >
                                                          <input
                                                            className="table_input product"
                                                            type="date"
                                                            required
                                                            name={
                                                              childAttributeName
                                                            }
                                                            value={
                                                              additionalInputData[
                                                                childAttributeName
                                                              ][valueIndex]
                                                            }
                                                            placeholder={
                                                              additionalAttributes[
                                                                i
                                                              ].nametoDisplay2
                                                            }
                                                            onChange={(e) =>
                                                              changeAdditionalInputData(
                                                                e,
                                                                childAttributeName,
                                                                valueIndex
                                                              )
                                                            }
                                                          />
                                                          <span>
                                                            {
                                                              additionalAttributes[
                                                                i
                                                              ].nametoDisplay2
                                                            }
                                                          </span>
                                                        </div>
                                                      )}
                                                      {additionalAttributes[i]
                                                        .childDataType ===
                                                        "DateTime" && (
                                                        <div
                                                          className="_input_box"
                                                          title={HoverTitle(
                                                            additionalAttributes[
                                                              i
                                                            ]?.childDataType,
                                                            additionalAttributes[
                                                              i
                                                            ]?.varDescription,
                                                            additionalAttributes[
                                                              i
                                                            ]?.mandatory
                                                          )}
                                                        >
                                                          <input
                                                            className="table_input product"
                                                            type="datetime-local"
                                                            required
                                                            name={
                                                              childAttributeName
                                                            }
                                                            value={
                                                              additionalInputData[
                                                                childAttributeName
                                                              ][valueIndex]
                                                            }
                                                            placeholder={
                                                              additionalAttributes[
                                                                i
                                                              ].nametoDisplay2
                                                            }
                                                            onChange={(e) =>
                                                              changeAdditionalInputData(
                                                                e,
                                                                childAttributeName,
                                                                valueIndex
                                                              )
                                                            }
                                                          />
                                                          <span>
                                                            {
                                                              additionalAttributes[
                                                                i
                                                              ].nametoDisplay2
                                                            }
                                                          </span>
                                                        </div>
                                                      )}
                                                      {(additionalAttributes[i]
                                                        .childDataType ===
                                                        "String" ||
                                                        additionalAttributes[i]
                                                          .childDataType ===
                                                          "Number" ||
                                                        additionalAttributes[i]
                                                          .childDataType ===
                                                          "Decimal" ||
                                                        additionalAttributes[i]
                                                          .childDataType ===
                                                          "Percent" ||
                                                        additionalAttributes[i]
                                                          .childDataType ===
                                                          "Email" ||
                                                        additionalAttributes[i]
                                                          .childDataType ===
                                                          "URL" ||
                                                        additionalAttributes[i]
                                                          .childDataType ===
                                                          "Phone") && (
                                                        <div
                                                          className="_input_box"
                                                          title={HoverTitle(
                                                            additionalAttributes[
                                                              i
                                                            ]?.childDataType,
                                                            additionalAttributes[
                                                              i
                                                            ]?.varDescription,
                                                            additionalAttributes[
                                                              i
                                                            ]?.mandatory
                                                          )}
                                                        >
                                                          <input
                                                            className="table_input product"
                                                            type="text"
                                                            required
                                                            name={
                                                              childAttributeName
                                                            }
                                                            value={
                                                              additionalInputData[
                                                                childAttributeName
                                                              ][valueIndex]
                                                            }
                                                            placeholder={
                                                              additionalAttributes[
                                                                i
                                                              ].nametoDisplay2
                                                            }
                                                            onChange={(e) =>
                                                              changeAdditionalInputData(
                                                                e,
                                                                childAttributeName,
                                                                valueIndex
                                                              )
                                                            }
                                                          />
                                                          <span>
                                                            {
                                                              additionalAttributes[
                                                                i
                                                              ].nametoDisplay2
                                                            }
                                                          </span>
                                                        </div>
                                                      )}
                                                      {/* Display mandatory error if present */}
                                                      {additionalMandatoryErrors[
                                                        childAttributeName
                                                      ] && (
                                                        <div className="input_error">
                                                          {
                                                            additionalMandatoryErrors[
                                                              childAttributeName
                                                            ]
                                                          }
                                                        </div>
                                                      )}
                                                      {additionalValidationErrors[
                                                        childAttributeName
                                                      ]?.[valueIndex] && (
                                                        <div className="input_error">
                                                          {
                                                            additionalValidationErrors[
                                                              childAttributeName
                                                            ][valueIndex]
                                                          }
                                                        </div>
                                                      )}
                                                    </>
                                                  )}

                                                {valueIndex !== 0 && (
                                                  <em
                                                    onClick={() =>
                                                      handleRemoveFieldAdditional(
                                                        i,
                                                        valueIndex
                                                      )
                                                    }
                                                  >
                                                    &times;
                                                  </em>
                                                )}
                                              </div>
                                            )
                                          )}

                                          {/* Conditionally render the "Add" button only once */}
                                          {additionalAttributes[i].repeat && (
                                            <button
                                              onClick={() =>
                                                handleAddAnotherAdditional(i)
                                              }
                                              disabled={
                                                !isParentValueAdded ||
                                                !isChildValueAdded ||
                                                !allSetsHaveValues ||
                                                Object.values(
                                                  additionalValidationErrors[
                                                    i
                                                  ] || {}
                                                ).some(
                                                  (error) => error !== null
                                                ) ||
                                                Object.values(
                                                  additionalValidationErrors[
                                                    childAttributeName
                                                  ] || {}
                                                ).some(
                                                  (error) => error !== null
                                                )
                                              }
                                            >
                                              +
                                            </button>
                                          )}
                                        </>
                                      </div>
                                    );
                                  } else {
                                    return (
                                      <div key={index} className="inp_cont">
                                        {(additionalInputData[i] || []).map(
                                          (value, valueIndex) => (
                                            <div
                                              className="_input_box"
                                              key={valueIndex}
                                              title={HoverTitle(
                                                additionalAttributes[i]?.type,
                                                additionalAttributes[i]
                                                  ?.varDescription,
                                                additionalAttributes[i]
                                                  ?.mandatory
                                              )}
                                            >
                                              {renderStar(
                                                additionalAttributes[i]
                                                  ?.mandatory
                                              )}

                                              <input
                                                className="table_input product"
                                                type="text"
                                                value={
                                                  additionalInputData[i][
                                                    valueIndex
                                                  ]
                                                }
                                                placeholder={
                                                  additionalAttributes[i]
                                                    .nametoDisplay
                                                }
                                                onChange={(e) =>
                                                  changeAdditionalInputData(
                                                    e,
                                                    i,
                                                    valueIndex
                                                  )
                                                }
                                                name={i}
                                                required
                                              />
                                              <span>
                                                {
                                                  additionalAttributes[i]
                                                    .nametoDisplay
                                                }
                                              </span>
                                              {valueIndex !== 0 && (
                                                <em
                                                  onClick={() =>
                                                    handleRemoveFieldAdditional(
                                                      i,
                                                      valueIndex
                                                    )
                                                  }
                                                >
                                                  &times;
                                                </em>
                                              )}
                                              {/* Display mandatory error if present */}
                                              {additionalMandatoryErrors[i] && (
                                                <div className="input_error">
                                                  {additionalMandatoryErrors[i]}
                                                </div>
                                              )}
                                              {additionalValidationErrors[i]?.[
                                                valueIndex
                                              ] && (
                                                <div className="input_error">
                                                  {
                                                    additionalValidationErrors[
                                                      i
                                                    ][valueIndex]
                                                  }
                                                </div>
                                              )}
                                            </div>
                                          )
                                        )}
                                        {additionalAttributes[i].repeat && (
                                          <button
                                            onClick={() =>
                                              handleAddAnotherAdditional(i)
                                            }
                                            disabled={
                                              additionalInputData[i]?.some(
                                                (value) => value.trim() === ""
                                              ) ||
                                              Object.values(
                                                additionalValidationErrors[i] ||
                                                  {}
                                              ).some((error) => error !== null)
                                            }
                                          >
                                            +
                                          </button>
                                        )}
                                      </div>
                                    );
                                  }
                                }
                              )}
                            </>
                          }

                          <div className="all_set_table_">
                            {Object.keys(imagesAttributes).length !== 0 && (
                              <div className="image_attributes_table_container">
                                <>
                                  {Object.keys(imagesAttributes).map(
                                    (imageKey) => {
                                      const { groupsName, attgroups } =
                                        imagesAttributes[imageKey];
                                      return (
                                        <div
                                          key={imageKey}
                                          className="one_set onesetsub"
                                        >
                                          <table>
                                            <thead>
                                              <tr>
                                                <th></th>
                                                {Object.keys(groupsName).map(
                                                  (groupName) => (
                                                    <th
                                                      key={`${imageKey}_${groupName}`}
                                                    >
                                                      {
                                                        groupsName[groupName]
                                                          .nametoDisplay
                                                      }
                                                    </th>
                                                  )
                                                )}
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {Object.keys(attgroups).map(
                                                (i) => (
                                                  <tr key={`${imageKey}_${i}`}>
                                                    <td>
                                                      {
                                                        attgroups[i]
                                                          .nametoDisplay
                                                      }
                                                    </td>
                                                    {Object.keys(
                                                      groupsName
                                                    ).map((groupName) => (
                                                      <td
                                                        key={`${imageKey}_${groupName}_${i}`}
                                                      >
                                                        {attgroups[i].type ===
                                                        "Dropdown" ? (
                                                          <>
                                                            <select
                                                              className="table_input product table_inputfirst"
                                                              name={`${groupName} ${i}`}
                                                              onChange={(e) =>
                                                                handleInputChange(
                                                                  `${groupName} ${i}`,
                                                                  e.target
                                                                    .value,
                                                                  attgroups[i]
                                                                    .type
                                                                )
                                                              }
                                                              required
                                                              placeholder=""
                                                              value={
                                                                inputValues[
                                                                  `${groupName} ${i}`
                                                                ] || ""
                                                              }
                                                              title={HoverTitle(
                                                                attgroups[i]
                                                                  ?.type,
                                                                attgroups[i]
                                                                  ?.varDescription,
                                                                attgroups[i]
                                                                  ?.mandatory
                                                              )}
                                                            >
                                                              <option value="">
                                                                {}
                                                              </option>
                                                              {attgroups[
                                                                i
                                                              ].options.map(
                                                                (
                                                                  option,
                                                                  optionIndex
                                                                ) => (
                                                                  <option
                                                                    key={
                                                                      optionIndex
                                                                    }
                                                                    value={
                                                                      option
                                                                    }
                                                                  >
                                                                    {option}
                                                                  </option>
                                                                )
                                                              )}
                                                            </select>
                                                            {imageValidationErrors[
                                                              `${groupName} ${i}`
                                                            ] && (
                                                              <span className="error-message">
                                                                {
                                                                  imageValidationErrors[
                                                                    `${groupName} ${i}`
                                                                  ]
                                                                }
                                                              </span>
                                                            )}
                                                          </>
                                                        ) : attgroups[i]
                                                            .type ===
                                                          "MultiSelect" ? (
                                                          <>
                                                            <Multiselect
                                                              isObject={false}
                                                              options={
                                                                attgroups[i]
                                                                  .options
                                                              }
                                                              placeholder={
                                                                attgroups[i]
                                                                  .nametoDisplay
                                                              }
                                                              onSelect={(
                                                                selectedList
                                                              ) =>
                                                                saveSetMultiSelectValues(
                                                                  `${groupName} ${i}`,
                                                                  selectedList
                                                                )
                                                              }
                                                              onRemove={(
                                                                selectedList
                                                              ) =>
                                                                saveSetMultiSelectValues(
                                                                  `${groupName} ${i}`,
                                                                  selectedList
                                                                )
                                                              }
                                                              selectedValues={
                                                                inputValues[
                                                                  `${groupName} ${i}`
                                                                ] || []
                                                              }
                                                              title={HoverTitle(
                                                                attgroups[i]
                                                                  ?.type,
                                                                attgroups[i]
                                                                  ?.varDescription,
                                                                attgroups[i]
                                                                  ?.mandatory
                                                              )}
                                                            />
                                                            {imageValidationErrors[
                                                              `${groupName} ${i}`
                                                            ] && (
                                                              <span className="error-message">
                                                                {
                                                                  imageValidationErrors[
                                                                    `${groupName} ${i}`
                                                                  ]
                                                                }
                                                              </span>
                                                            )}
                                                          </>
                                                        ) : attgroups[i]
                                                            .type === "Date" ? (
                                                          <>
                                                            <input
                                                              type="date"
                                                              name={`${groupName} ${i}`}
                                                              value={
                                                                inputValues[
                                                                  `${groupName} ${i}`
                                                                ] || ""
                                                              }
                                                              onChange={(e) =>
                                                                handleInputChange(
                                                                  `${groupName} ${i}`,
                                                                  e.target
                                                                    .value,
                                                                  attgroups[i]
                                                                    .type
                                                                )
                                                              }
                                                              title={HoverTitle(
                                                                attgroups[i]
                                                                  ?.type,
                                                                attgroups[i]
                                                                  ?.varDescription,
                                                                attgroups[i]
                                                                  ?.mandatory
                                                              )}
                                                            />
                                                            {imageValidationErrors[
                                                              `${groupName} ${i}`
                                                            ] && (
                                                              <span className="error-message">
                                                                {
                                                                  imageValidationErrors[
                                                                    `${groupName} ${i}`
                                                                  ]
                                                                }
                                                              </span>
                                                            )}
                                                          </>
                                                        ) : attgroups[i]
                                                            .type ===
                                                          "DateTime" ? (
                                                          <>
                                                            <input
                                                              type="datetime-local"
                                                              name={`${groupName} ${i}`}
                                                              value={
                                                                inputValues[
                                                                  `${groupName} ${i}`
                                                                ] || ""
                                                              }
                                                              onChange={(e) =>
                                                                handleInputChange(
                                                                  `${groupName} ${i}`,
                                                                  e.target
                                                                    .value,
                                                                  attgroups[i]
                                                                    .type
                                                                )
                                                              }
                                                              title={HoverTitle(
                                                                attgroups[i]
                                                                  ?.type,
                                                                attgroups[i]
                                                                  ?.varDescription,
                                                                attgroups[i]
                                                                  ?.mandatory
                                                              )}
                                                            />
                                                            {imageValidationErrors[
                                                              `${groupName} ${i}`
                                                            ] && (
                                                              <span className="error-message">
                                                                {
                                                                  imageValidationErrors[
                                                                    `${groupName} ${i}`
                                                                  ]
                                                                }
                                                              </span>
                                                            )}
                                                          </>
                                                        ) : attgroups[i]
                                                            .type ===
                                                          "NestedDropdown" ? (
                                                          <>
                                                            <select
                                                              name={`${groupName} ${i}`}
                                                              onChange={(e) =>
                                                                handleSetNestedDropdownSelection(
                                                                  imageKey,
                                                                  groupName,
                                                                  i,
                                                                  e.target.value
                                                                )
                                                              }
                                                              required
                                                              value={
                                                                inputValues[
                                                                  `${groupName} ${i}`
                                                                ] || ""
                                                              }
                                                              title={HoverTitle(
                                                                attgroups[i]
                                                                  ?.type,
                                                                attgroups[i]
                                                                  ?.varDescription,
                                                                attgroups[i]
                                                                  ?.mandatory
                                                              )}
                                                            >
                                                              <option value="">
                                                                {""}
                                                              </option>
                                                              {attgroups[
                                                                i
                                                              ].options.map(
                                                                (
                                                                  option,
                                                                  optionIndex
                                                                ) => (
                                                                  <option
                                                                    key={
                                                                      optionIndex
                                                                    }
                                                                    value={
                                                                      option
                                                                    }
                                                                  >
                                                                    {option}
                                                                  </option>
                                                                )
                                                              )}
                                                            </select>
                                                            {imageValidationErrors[
                                                              `${groupName} ${i}`
                                                            ] && (
                                                              <span className="error-message">
                                                                {
                                                                  imageValidationErrors[
                                                                    `${groupName} ${i}`
                                                                  ]
                                                                }
                                                              </span>
                                                            )}
                                                            {nestedDropdownValues[
                                                              `${groupName} ${i}`
                                                            ] &&
                                                              nestedDropdownValues[
                                                                `${groupName} ${i}`
                                                              ][0] &&
                                                              attgroups[i]
                                                                .childDataType ===
                                                                "Dropdown" && (
                                                                <>
                                                                  <select
                                                                    name={`${groupName} ${i}nEsTeD`}
                                                                    onChange={(
                                                                      e
                                                                    ) =>
                                                                      handleInputChange(
                                                                        `${groupName} ${i}nEsTeD`,
                                                                        e.target
                                                                          .value,
                                                                        attgroups[
                                                                          i
                                                                        ]
                                                                          .childDataType
                                                                      )
                                                                    }
                                                                    required
                                                                    value={
                                                                      inputValues[
                                                                        `${groupName} ${i}nEsTeD`
                                                                      ] || ""
                                                                    }
                                                                    title={HoverTitle(
                                                                      attgroups[
                                                                        i
                                                                      ]
                                                                        ?.childDataType,
                                                                      attgroups[
                                                                        i
                                                                      ]
                                                                        ?.varDescription,
                                                                      attgroups[
                                                                        i
                                                                      ]
                                                                        ?.mandatory
                                                                    )}
                                                                  >
                                                                    <option value="">
                                                                      {""}
                                                                    </option>
                                                                    {attgroups[
                                                                      i
                                                                    ].nestedOptions[
                                                                      attgroups[
                                                                        i
                                                                      ].options.indexOf(
                                                                        nestedDropdownValues[
                                                                          `${groupName} ${i}`
                                                                        ][0]
                                                                      )
                                                                    ].map(
                                                                      (
                                                                        nestedOption,
                                                                        nestedIndex
                                                                      ) => (
                                                                        <option
                                                                          key={
                                                                            nestedIndex
                                                                          }
                                                                          value={
                                                                            nestedOption
                                                                          }
                                                                        >
                                                                          {
                                                                            nestedOption
                                                                          }
                                                                        </option>
                                                                      )
                                                                    )}
                                                                  </select>
                                                                  <span>
                                                                    {
                                                                      attgroups[
                                                                        i
                                                                      ]
                                                                        .nametoDisplay2
                                                                    }
                                                                  </span>
                                                                  {imageValidationErrors[
                                                                    `${groupName} ${i}nEsTeD`
                                                                  ] && (
                                                                    <span className="error-message">
                                                                      {
                                                                        imageValidationErrors[
                                                                          `${groupName} ${i}nEsTeD`
                                                                        ]
                                                                      }
                                                                    </span>
                                                                  )}
                                                                </>
                                                              )}
                                                            {nestedDropdownValues[
                                                              `${groupName} ${i}`
                                                            ] &&
                                                              nestedDropdownValues[
                                                                `${groupName} ${i}`
                                                              ][0] &&
                                                              attgroups[i]
                                                                .childDataType ===
                                                                "Date" && (
                                                                <>
                                                                  <input
                                                                    type="date"
                                                                    required
                                                                    name={`${groupName} ${i}nEsTeD`}
                                                                    onChange={(
                                                                      e
                                                                    ) =>
                                                                      handleInputChange(
                                                                        `${groupName} ${i}nEsTeD`,
                                                                        e.target
                                                                          .value,
                                                                        attgroups[
                                                                          i
                                                                        ]
                                                                          .childDataType
                                                                      )
                                                                    }
                                                                    placeholder=""
                                                                    value={
                                                                      inputValues[
                                                                        `${groupName} ${i}nEsTeD`
                                                                      ] || ""
                                                                    }
                                                                    title={HoverTitle(
                                                                      attgroups[
                                                                        i
                                                                      ]
                                                                        ?.childDataType,
                                                                      attgroups[
                                                                        i
                                                                      ]
                                                                        ?.varDescription,
                                                                      attgroups[
                                                                        i
                                                                      ]
                                                                        ?.mandatory
                                                                    )}
                                                                  />
                                                                  <span>
                                                                    {
                                                                      attgroups[
                                                                        i
                                                                      ]
                                                                        .nametoDisplay2
                                                                    }
                                                                  </span>
                                                                  {imageValidationErrors[
                                                                    `${groupName} ${i}nEsTeD`
                                                                  ] && (
                                                                    <span className="error-message">
                                                                      {
                                                                        imageValidationErrors[
                                                                          `${groupName} ${i}nEsTeD`
                                                                        ]
                                                                      }
                                                                    </span>
                                                                  )}
                                                                </>
                                                              )}
                                                            {nestedDropdownValues[
                                                              `${groupName} ${i}`
                                                            ] &&
                                                              nestedDropdownValues[
                                                                `${groupName} ${i}`
                                                              ][0] &&
                                                              attgroups[i]
                                                                .childDataType ===
                                                                "DateTime" && (
                                                                <>
                                                                  <input
                                                                    type="datetime-local"
                                                                    required
                                                                    name={`${groupName} ${i}nEsTeD`}
                                                                    onChange={(
                                                                      e
                                                                    ) =>
                                                                      handleInputChange(
                                                                        `${groupName} ${i}nEsTeD`,
                                                                        e.target
                                                                          .value,
                                                                        attgroups[
                                                                          i
                                                                        ]
                                                                          .childDataType
                                                                      )
                                                                    }
                                                                    placeholder=""
                                                                    value={
                                                                      inputValues[
                                                                        `${groupName} ${i}nEsTeD`
                                                                      ] || ""
                                                                    }
                                                                    title={HoverTitle(
                                                                      attgroups[
                                                                        i
                                                                      ]
                                                                        ?.childDataType,
                                                                      attgroups[
                                                                        i
                                                                      ]
                                                                        ?.varDescription,
                                                                      attgroups[
                                                                        i
                                                                      ]
                                                                        ?.mandatory
                                                                    )}
                                                                  />
                                                                  <span>
                                                                    {
                                                                      attgroups[
                                                                        i
                                                                      ]
                                                                        .nametoDisplay2
                                                                    }
                                                                  </span>
                                                                  {imageValidationErrors[
                                                                    `${groupName} ${i}nEsTeD`
                                                                  ] && (
                                                                    <span className="error-message">
                                                                      {
                                                                        imageValidationErrors[
                                                                          `${groupName} ${i}nEsTeD`
                                                                        ]
                                                                      }
                                                                    </span>
                                                                  )}
                                                                </>
                                                              )}
                                                            {nestedDropdownValues[
                                                              `${groupName} ${i}`
                                                            ] &&
                                                              nestedDropdownValues[
                                                                `${groupName} ${i}`
                                                              ][0] &&
                                                              [
                                                                "String",
                                                                "Number",
                                                                "Decimal",
                                                                "Percent",
                                                                "Email",
                                                                "URL",
                                                                "Phone",
                                                              ].includes(
                                                                attgroups[i]
                                                                  .childDataType
                                                              ) && (
                                                                <>
                                                                  <input
                                                                    type="text"
                                                                    required
                                                                    name={`${groupName} ${i}nEsTeD`}
                                                                    onChange={(
                                                                      e
                                                                    ) =>
                                                                      handleInputChange(
                                                                        `${groupName} ${i}nEsTeD`,
                                                                        e.target
                                                                          .value,
                                                                        attgroups[
                                                                          i
                                                                        ]
                                                                          .childDataType
                                                                      )
                                                                    }
                                                                    value={
                                                                      inputValues[
                                                                        `${groupName} ${i}nEsTeD`
                                                                      ] || ""
                                                                    }
                                                                    title={HoverTitle(
                                                                      attgroups[
                                                                        i
                                                                      ]
                                                                        ?.childDataType,
                                                                      attgroups[
                                                                        i
                                                                      ]
                                                                        ?.varDescription,
                                                                      attgroups[
                                                                        i
                                                                      ]
                                                                        ?.mandatory
                                                                    )}
                                                                  />
                                                                  <span>
                                                                    {
                                                                      attgroups[
                                                                        i
                                                                      ]
                                                                        .nametoDisplay2
                                                                    }
                                                                  </span>
                                                                  {imageValidationErrors[
                                                                    `${groupName} ${i}nEsTeD`
                                                                  ] && (
                                                                    <span className="error-message">
                                                                      {
                                                                        imageValidationErrors[
                                                                          `${groupName} ${i}nEsTeD`
                                                                        ]
                                                                      }
                                                                    </span>
                                                                  )}
                                                                </>
                                                              )}
                                                          </>
                                                        ) : (
                                                          <>
                                                            <input
                                                              type="text"
                                                              value={
                                                                inputValues[
                                                                  `${groupName} ${i}`
                                                                ] || ""
                                                              }
                                                              onChange={(e) =>
                                                                handleInputChange(
                                                                  `${groupName} ${i}`,
                                                                  e.target
                                                                    .value,
                                                                  attgroups[i]
                                                                    .type
                                                                )
                                                              }
                                                              title={HoverTitle(
                                                                attgroups[i]
                                                                  ?.type,
                                                                attgroups[i]
                                                                  ?.varDescription,
                                                                attgroups[i]
                                                                  ?.mandatory
                                                              )}
                                                              placeholder="Enter Numeric data"
                                                            />
                                                            {imageValidationErrors[
                                                              `${groupName} ${i}`
                                                            ] && (
                                                              <span className="error-message">
                                                                {
                                                                  imageValidationErrors[
                                                                    `${groupName} ${i}`
                                                                  ]
                                                                }
                                                              </span>
                                                            )}
                                                          </>
                                                        )}
                                                      </td>
                                                    ))}
                                                  </tr>
                                                )
                                              )}
                                            </tbody>
                                          </table>
                                        </div>
                                      );
                                    }
                                  )}
                                </>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="submit_table_data_btn 6">
                          <button
                            onClick={handleSubmitData}
                            className={"common_btn"}
                            style={{ width: 300 }}
                          >
                            Save
                          </button>
                        </div>
                      </>
                    )
                  ) : null}
                </>
              )
            ) : null}
          </div>
        </>
      </div>
    </>
  );
};

export default TableAddCommon;

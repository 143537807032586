import React from 'react'
import {Col, Row} from "react-bootstrap";
import styles from "../Ekyc.module.css";
import DownloadIcon from "../../../assets/Images/download.ico";


const AadharDetails = ({
  data,
  downloadFile
}) => {

  const getFileIdFromUri = (uri) => {
    // console.log(uri);
    let temp = uri?.split('-');
    let fileId = temp?.[temp?.length - 1];
    // console.log(fileId)
    return fileId;
  };

  return (
    <Row className='w-100'>
      <Col className="col-12 mt-5 mb-2 kycCard">
        <span>
        <p className="sub-heading ">Aadhar</p>
        </span>
        {/* <span>
        <p className="sub-body">Number: XXXXXXXXXX</p>
        </span> */}
        <span>
        <p className="sub-body">Name: {data?.name ?? 'XXXXXXXXXX'}</p>
        </span>
        <span>
        <p className="sub-body">DOB: {data?.dob ?? 'XXXXXXXXXX'}</p>
        </span>
        <span>
        <p className="sub-body">Gender: {data?.gender ?? 'XXXXXXXXXX'}</p>
        </span>
        <span>
        <p className="sub-body">Mobile: {data?.mobile ?? 'XXXXXXXXXX'}</p>
        </span>
        <span>
        <p className="sub-body">Description: {data?.aadhar_details?.description ?? 'XXXXXXXXXX'}</p>
        </span>
        <span>
        <p className="sub-body">Issued Date: {data?.aadhar_details?.date ?? 'XXXXXXXXXX'}</p>
        </span>
        <span>
        <p className="sub-body">Isurer: {data?.aadhar_details?.issuer ?? 'XXXXXXXXXX'}</p>
        </span>
        <button className={styles.loginButton} onClick={()=>downloadFile(data?.aadhar_details?.uri)}>Download</button>
      </Col>
      <Col className="col-12 mb-5 kycCard">
        <span>
        <p className="sub-heading ">Files Issued ({data?.issued_files?.length ?? '--'})</p>
        </span>
        <div className={styles.issuedFilesContainer}>
            {data?.issued_files?.map((item,idx)=>(
                <div key={idx} className={`sub-body ${styles.issuedFileItem}`}>
                    <p className={styles.fileName}>
                      <span>{item?.name}</span>
                      <span className={styles.downloadIcon} onClick={()=>downloadFile(item?.uri)}>
                        <img src={DownloadIcon} alt={""} />
                      </span>
                    </p>
                    Number: {getFileIdFromUri(item?.uri)}<br/>
                    Description: {item?.description}<br/>
                    Issued Date: {item?.date}<br/>
                    Issuer: {item?.issuer}<br/>
                </div>
            ))}
        </div>
      </Col>
    </Row>
  )
};

export default AadharDetails;
import React from 'react';
import { getAllRegion } from '../../../../../store/Slices/marketMapTableSlices';
import { getAllRegionAttributes, getOneRegionAttributes } from '../../../../../store/Slices/marketMapAttributeSlice';
import { getRegionImg } from '../../../../../store/Slices/marketMapImageSlice';
import TableHomeCommon from '../../../MarketMapsComponents/TableHomeCommon/TableHomeCommon';

const RegionTableHome = () => {
  
    return (
      <TableHomeCommon
        title="Region"
        getAllTableDataLink = {getAllRegion}  
        getAllAttributesLink={getAllRegionAttributes}
        getOneAttributesLink={getOneRegionAttributes}
  
        getAllTableDataSelector = {(state) => state.AllRegions}
        allAttributesGroupSelector={(state) => state.AllRegionAttributeGroup}
        oneAttributeGroupSelector={(state) => state.OneRegionAttributesGroup}
        classNamePrefix="Region"
  
        linkForRedirectSetting = "Add-Region-Attributes"
        additionalAttributesName = "regionAdditionalAttributes"
        getImageLink = {getRegionImg}
        getImagesSelector = {(state) => state.GetRegionImg.images}
        getImagesStatusSelector = {(state) => state.GetRegionImg.status}
        getID = "regionId"
  
        add_button_url={"Add-Region"}
        edit_button_url={"Edit-Region"}
  
      />
    );
  };

export default RegionTableHome;
import React from "react";
import "./SideBar.css";
import SideBarItems from "./SideBarItems";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from 'react-redux';
import { sideBartoggle } from "../../../store/Slices/sideBarSlice";
import { Link } from 'react-router-dom';
import logoImg from '../../../assets/logo/OnifiedFinalLogo.png'

const SideBar = ({ SidebarData }) => {
  const isSideBarToggled = useSelector((state) => state.sideBarToggle);
  const dispatch = useDispatch();

  const handleSidebarToggle = () => {
    dispatch(sideBartoggle());
  };

  return (
    <>
      <div className={isSideBarToggled ? "side_bar open" : "side_bar"}>
      <Link to={'/market-map'}>
          <div className='logo_container'> <img src={logoImg} alt="logo img" /> </div>
        </Link>
        {SidebarData.map((data, i) => (
          <SideBarItems key={i} item={data} activePath={window.location.pathname} />
        ))}
        <div className={isSideBarToggled ? "minimize_btn_container open" : "minimize_btn_container"}>
          <span className={isSideBarToggled ? "minimize_btn_box open" : "minimize_btn_box"} onClick={handleSidebarToggle}>
            <FontAwesomeIcon className={isSideBarToggled ? "minimize_btn open" : "minimize_btn"} icon={faChevronLeft} />
          </span>
        </div>
      </div>
    </>
  );
};

export default SideBar;

// import React from 'react';
// import './StepNavigation.css';


// const Step = (props) => {
//   return (
//     <div className={"stepBlock" + (props.selected ? " selected" : "")}>
//         <div className="circleWrapper" onClick={() => props.updateStep(props.index + 1)}>
//             <div className="circle">{props.index + 1}</div>
//         </div>
//         <span>{props.label}</span>
//     </div>
//   )
// }

// export default Step;



import React from 'react';
import './StepNavigation.css';

const Step = ({ index, label, updateStep, selected, completed }) => {
  return (
    <div className={`stepBlock${completed ? " completed" : ""}${selected ? " selected" : ""}`}>
      <div 
        className={`circleWrapper${completed ? " completed" : ""}`} 
        onClick={() => updateStep(index + 1)}
      >
        <div className={`circle${completed ? " completed" : ""}${selected ? " selected" : ""}`}>{index + 1}</div>
      </div>
      <span>{label}</span>
    </div>
  );
};

export default Step;

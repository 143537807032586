import React from 'react';
import './AddList.css';
import Product from "../../../../assets/Images/product.png";
import Retailer from "../../../../assets/Images/retailer.png";
import Manufacturer from "../../../../assets/Images/manufacturing.png";
import Supplier from "../../../../assets/Images/supplier.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCube, faIndustry, faShop, faSitemap, faBoxOpen, faSquarePollVertical, faGlobe, faUsersRays } from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom';

const AddList = () => {
  const data = [
    {
      cover: <img src={Product} width="85" alt="logo" />,
      title: "Product",
      _to_link: "Product-Table",
      decs: "We will add details about them in the short for understanding of users.",
    },
    {
      cover: <img src={Retailer} width="75" alt="logo" />,
      title: "Retailer",
      _to_link: "Retailer-Table",
      decs: "We will add details about them in the short for understanding of users",
    },
    {
      cover: <img src={Manufacturer} width="75" alt="logo" />,
      title: "Manufacturer",
       _to_link: "Manufacturer-Table",
      decs: "We will add details about them in the short for understanding of users",
    },
    {
      cover:  <img src={Supplier} width="75" alt="logo" />,
      title: "Supplier",
       _to_link: "Supplier-Table",
      decs: "We will add details about them in the short for understanding of users",
    },
    // {
    //   cover:  <i><FontAwesomeIcon icon={faBoxOpen} /></i>,
    //   title: "Packaging Details",
    //    _to_link: "Packaging-Table",
    //   decs: "We will add details about them in the short for understanding of users",
    // },
    // {
    //   cover:  <i><FontAwesomeIcon icon={faSquarePollVertical} /></i>,
    //   title: "Marketing Callout",
    //    _to_link: "Promotion-Table",
    //   decs: "We will add details about them in the short for understanding of users",
    // },
    // {
    //   cover:  <i><FontAwesomeIcon icon={faLayerGroup} /></i>,
    //   title: "Program",
    //   _to_link: "Program-Table",
    //   decs: "We will add details about them in the short for understanding of users",
    // },
    // {
    //   cover:  <i><FontAwesomeIcon icon={faGlobe} /></i>,
    //   title: "Region",
    //   _to_link: "Region-Table",
    //   decs: "We will add details about them in the short for understanding of users",
    // },
    // {
    //   cover:  <i><FontAwesomeIcon icon={faUsersRays} /></i>,
    //   title: "Groups",
    //   _to_link: "Group-Table",
    //   decs: "We will add details about them in the short for understanding of users",
    // },
  ]
 
  return (
    <div className="common_table_page">
      <div className='add_list'>
      <h6 className='lists_heading'>List of all Tables</h6>

        <div className='services_container 1'>
          {data.map((item, index) => {
            return (
              <Link className='table_card' key={index} to={item._to_link}>
                <div className='img  animate__animated animate__zoomIn'>
                  <i>{item.cover}</i>
                </div>
                <h5>{item.title}</h5>
                <p>{item.decs}</p>
              </Link>
            )
          })}
        </div>
      
      </div>
    </div>
    
  )
}

export default AddList;
import React from 'react';
import { getAllPackaging } from '../../../../../store/Slices/marketMapTableSlices';
import { getAllPackagingAttributes, getOnePackagingAttributes } from '../../../../../store/Slices/marketMapAttributeSlice';
import TableHomeCommon from '../../../MarketMapsComponents/TableHomeCommon/TableHomeCommon';
import { getPackagingImg } from '../../../../../store/Slices/marketMapImageSlice';


const PackagingTableHome = () => {
  
    return (
      <TableHomeCommon
        title="Packaging"
        getAllTableDataLink = {getAllPackaging}  
        getAllAttributesLink={getAllPackagingAttributes}
        getOneAttributesLink={getOnePackagingAttributes}
  
        getAllTableDataSelector = {(state) => state.AllPackagings}
        allAttributesGroupSelector={(state) => state.AllPackagingAttributeGroup}
        oneAttributeGroupSelector={(state) => state.OnePackagingAttributesGroup}
        classNamePrefix="Packaging"
  
        linkForRedirectSetting = "Add-Packaging-Attributes"
        additionalAttributesName = "packagingAdditionalAttributes"
        getImageLink = {getPackagingImg}
        getImagesSelector = {(state) => state.GetPackagingImg.images}
        getImagesStatusSelector = {(state) => state.GetPackagingImg.status}
        getID = "packagingId"
  
        add_button_url={"Add-Packaging"}
        edit_button_url={"Edit-Packaging"}
  
      />
    );
  };

export default PackagingTableHome;




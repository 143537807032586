const config= {
    DIGILOCKER_CLIENT_ID: process.env.REACT_APP_DIGILOCKER_CLIENT_ID,
    DIGILOCKER_REDIRECT_URI: process.env.REACT_APP_DIGILOCKER_REDIRECT_URI,
    DIGILOCKER_RESPONSE_TYPE: process.env.REACT_APP_DIGILOCKER_RESPONSE_TYPE,
    DIGILOCKER_AUTH_URL: `${process.env.REACT_APP_DIGILOCKER_AUTH_URL}?response_type=${process.env.REACT_APP_DIGILOCKER_RESPONSE_TYPE}&client_id=${process.env.REACT_APP_DIGILOCKER_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_DIGILOCKER_REDIRECT_URI}&state=12345`,
    DIGILOCKER_AADHAR_URL: process.env.REACT_APP_DIGILOCKER_AADHAR_URL,
    DIGILOCKER_GRANT_TYPE: process.env.REACT_APP_DIGILOCKER_GRANT_TYPE,
    DIGILOCKER_CLIENT_SECRET: process.env.REACT_APP_DIGILOCKER_CLIENT_SECRET,
    DIGILOCKER_ISSUED_FILES_URL: process.env.REACT_APP_DIGILOCKER_ISSUED_FILES_URL,
    DIGILOCKER_DOWNLOAD_FILE_URL: process.env.REACT_APP_DIGILOCKER_DOWNLOAD_FILE_URL,
    APISETU_API_KEY: process.env.REACT_APP_APISETU_API_KEY,
    APISETU_CLIENT_ID: process.env.REACT_APP_APISETU_CLIENT_ID,
    APISETU_COMPANIES_URL: process.env.REACT_APP_APISETU_COMPANIES_URL,
    APISETU_DIRECTORS_URL: process.env.REACT_APP_APISETU_DIRECTORS_URL,
    APISETU_GSTN_URL: process.env.REACT_APP_APISETU_GSTN_URL,



    // Market map services API env
    SERVICE_0_URL: process.env.REACT_APP_SERVICE0_URL,
    SERVICE_1_URL: process.env.REACT_APP_SERVICE1_URL,
    SERVICE_2_URL: process.env.REACT_APP_SERVICE2_URL,

    SERVICE_3_URL: process.env.REACT_APP_SERVICE3_URL,
    SERVICE_4_URL: process.env.REACT_APP_SERVICE4_URL,

    SERVICE_5_URL: process.env.REACT_APP_SERVICE5_URL,
    SERVICE_6_URL: process.env.REACT_APP_SERVICE6_URL,

    SERVICE_7_URL: process.env.REACT_APP_SERVICE7_URL,
    SERVICE_8_URL: process.env.REACT_APP_SERVICE8_URL,

    SERVICE_AUTH_URL: process.env.REACT_APP_AUTH_URL,

    SERVICE_COMPANY_NAME: process.env.REACT_APP_COMPANY_NAME,
};

export default config;
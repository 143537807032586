import React, { useState } from 'react';
import './AddNewProgram.css';
import StepNavigation from '../../../MarketMapsComponents/StepNavigation/StepNavigation';
import ProductStep from '../../../MarketMapsComponents/AllProgramSteps/ProductStep';
import SupplierStep from '../../../MarketMapsComponents/AllProgramSteps/SupplierStep';
import GroupsStep from '../../../MarketMapsComponents/AllProgramSteps/GroupsStep';
import ManufacturerStep from '../../../MarketMapsComponents/AllProgramSteps/ManufacturerStep';
import MarketingStep from '../../../MarketMapsComponents/AllProgramSteps/MarketingStep';
import PackagingStep from '../../../MarketMapsComponents/AllProgramSteps/PackagingStep';
import ProgramStep from '../../../MarketMapsComponents/AllProgramSteps/ProgramStep';
import RegionStep from '../../../MarketMapsComponents/AllProgramSteps/RegionStep';
import RetailerStep from '../../../MarketMapsComponents/AllProgramSteps/RetailerStep';
import { getAllGroupAttributes, getAllPackagingAttributes, getAllProgramAttributes, getAllPromotionAttributes, getAllRegionAttributes, getOneGroupAttributes, getOnePackagingAttributes, getOneProgramAttributes, getOnePromotionAttributes, getOneRegionAttributes } from '../../../../../store/Slices/marketMapAttributeSlice';
import { createProgram, getAllProgram, resetProgramState, resetUpdateProgramState } from '../../../../../store/Slices/marketMapProgram';
import { uploadPackagingImg, uploadProgramImg, uploadPromotionImg, uploadRegionImg } from '../../../../../store/Slices/marketMapImageSlice';
import { createGroup, createPackaging, createPromotion, createRegion, getAllGroup, getAllPackaging, getAllPromotion, getAllRegion, resetPackagingState, resetPromotionState, resetRegionState } from '../../../../../store/Slices/marketMapTableSlices';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

const AddNewProgram = () => {
  const labelArray = ['Program', 'Retailer', 'Manufacturer', 'Supplier', 'Product', 'Packaging', 'Marketing Callout', 'Region'];
  // const labelArray = ['Program', 'Retailer', 'Manufacturer', 'Supplier', 'Product', 'Packaging', 'Marketing Callout', 'Region', 'Groups'];
  const [currentStep, updateCurrentStep] = useState(1);
  const [programId, setProgramId] = useState('');
  const [programClassName , setProgramClassName] = useState("");
  const [productGroupName , setProductGroupName] = useState("");

  const [completedSteps, setCompletedSteps] = useState([]);

  function updateStep(step) {
    updateCurrentStep(step);
  }
  console.log(programId);

  const handleStepCompletion = (step) => {
    if (!completedSteps.includes(step)) {
      setCompletedSteps([...completedSteps, step]);
    }
  };

 
  return (
    <>
    <div className='common_program_space'>

      <div className="step_header">
        <h6 className='step_heading'>Add New Program</h6>
        <div className='step_progress 1'>
          <StepNavigation labelArray={labelArray} currentStep={currentStep} updateStep={updateStep} completedSteps={completedSteps} />
        </div>
      </div>

      {/* <div className="step-content">{renderCurrentStep()}</div> */}
      <div className="step-content">
          {currentStep === 1 && 
          
          <ProgramStep 
            onComplete={() => handleStepCompletion(1)} 
            setProgramId={setProgramId} 
            setProgramClassName={setProgramClassName} 
            setProductGroupName={setProductGroupName} 
            productGroupName = {productGroupName}

            title="Program"
            getAllAttributesLink={getAllProgramAttributes}
            getOneAttributesLink={getOneProgramAttributes}
            createTablelink={createProgram}
            resetTableState={resetProgramState}
            tableDataSelector={(state) => state.Program}
            allAttributesGroupSelector={(state) => state.AllProgramAttributeGroup}
            oneAttributeGroupSelector={(state) => state.OneProgramAttributesGroup}
            classNamePrefix="Program"
            linkForRedirectSetting = "Add-Program-Attributes"
            additionalAttributesName = "programAdditionalAttributes"
            getAllDataForReferenceLink = {getAllProgram}
            getAllDataForReference = {(state) => state.AllPrograms}
            ImageUploadLink = {uploadProgramImg}
            getID = "programId"
          />
          }
          {currentStep === 2 && <RetailerStep onComplete={() => handleStepCompletion(2)}  programId={programId} programClassName ={programClassName} productGroupName={productGroupName}/>}
          {currentStep === 3 && <ManufacturerStep onComplete={() => handleStepCompletion(3)}  programId={programId} programClassName ={programClassName} productGroupName={productGroupName}/>}
          {currentStep === 4 && <SupplierStep onComplete={() => handleStepCompletion(4)}  programId={programId} programClassName ={programClassName} productGroupName={productGroupName}/>}
          {currentStep === 5 && <ProductStep onComplete={() => handleStepCompletion(5)}  programId={programId} programClassName =
          {programClassName} productGroupName={productGroupName}/>}
          
          {currentStep === 6 && 
          <PackagingStep 
            onComplete={() => handleStepCompletion(6)} 
            programId={programId} programClassName ={programClassName} productGroupName={productGroupName}
            title="Packaging"
            getAllAttributesLink={getAllPackagingAttributes}
            getOneAttributesLink={getOnePackagingAttributes}
            createTablelink={createPackaging}
            resetTableState={resetUpdateProgramState}
            tableDataSelector={(state) => state.Packaging}
            allAttributesGroupSelector={(state) => state.AllPackagingAttributeGroup}
            oneAttributeGroupSelector={(state) => state.OnePackagingAttributesGroup}
            classNamePrefix="Packaging"
            linkForRedirectSetting = "Add-Packaging-Attributes"
            additionalAttributesName = "packagingAdditionalAttributes"
            getAllDataForReferenceLink = {getAllPackaging}
            getAllDataForReference = {(state) => state.AllPackagings}
            ImageUploadLink = {uploadProgramImg}
            getID = "packagingId"
          />}


          {currentStep === 7 && 
          <MarketingStep 
            onComplete={() => handleStepCompletion(7)} 
            programId={programId} programClassName ={programClassName} productGroupName={productGroupName}
            title="Promotion"
            getAllAttributesLink={getAllPromotionAttributes}
            getOneAttributesLink={getOnePromotionAttributes}
            createTablelink={createPromotion}
            resetTableState={resetUpdateProgramState}
            tableDataSelector={(state) => state.Promotion}
            allAttributesGroupSelector={(state) => state.AllPromotionAttributeGroup}
            oneAttributeGroupSelector={(state) => state.OnePromotionAttributesGroup}
            classNamePrefix="Promotion"
            linkForRedirectSetting = "Add-Promotion-Attributes"
            additionalAttributesName = "promotionAdditionalAttributes"
            getAllDataForReferenceLink = {getAllPromotion}
            getAllDataForReference = {(state) => state.AllPromotions}
            ImageUploadLink = {uploadProgramImg}
            getID = "promotionId"
          
          />}

          {currentStep === 8 && 
          <RegionStep 
            onComplete={() => handleStepCompletion(8)} 
            programId={programId}
            programClassName ={programClassName} 
            productGroupName={productGroupName}

            title="Region"
            getAllAttributesLink={getAllRegionAttributes}
            getOneAttributesLink={getOneRegionAttributes}
            createTablelink={createRegion}
            resetTableState={resetUpdateProgramState}
            tableDataSelector={(state) => state.Region}
            allAttributesGroupSelector={(state) => state.AllRegionAttributeGroup}
            oneAttributeGroupSelector={(state) => state.OneRegionAttributesGroup}
            classNamePrefix="Region"
            linkForRedirectSetting = "Add-Region-Attributes"
            additionalAttributesName = "regionAdditionalAttributes"
            getAllDataForReferenceLink = {getAllRegion}
            getAllDataForReference = {(state) => state.AllRegions}
            ImageUploadLink = {uploadProgramImg}
            getID = "regionId"
          
          />
          }
          {currentStep === 9 && <GroupsStep 
            onComplete={() => handleStepCompletion(9)} 
            programId={programId} programClassName ={programClassName} productGroupName={productGroupName}
            title="Group"
            getAllAttributesLink={getAllGroupAttributes}
            getOneAttributesLink={getOneGroupAttributes}
            createTablelink={createGroup}
            resetTableState={resetUpdateProgramState}
            tableDataSelector={(state) => state.Group}
            allAttributesGroupSelector={(state) => state.AllGroupAttributeGroup}
            oneAttributeGroupSelector={(state) => state.OneGroupAttributesGroup}
            classNamePrefix="Group"
            linkForRedirectSetting = "Add-Group-Attributes"
            additionalAttributesName = "groupAdditionalAttributes"
            getAllDataForReferenceLink = {getAllGroup}
            getAllDataForReference = {(state) => state.AllGroups}
            ImageUploadLink = {uploadProgramImg}
            getID = "groupId"
          />}
        </div>

      <div className='step_btn_group'>
        <button className="primaryButton" disabled={currentStep === 1} onClick={() => updateStep(currentStep - 1)}><i><FontAwesomeIcon icon={faArrowLeft} /></i></button>
        <button className="primaryButton" disabled={currentStep === labelArray.length} onClick={() => updateStep(currentStep + 1)}><i><FontAwesomeIcon icon={faArrowRight} /></i></button>
      </div>
    </div>
    </>
  );
};

export default AddNewProgram;


// import React from 'react';
// import './StepNavigation.css';
// import Step from './Step';


// const StepNavigation = (props) => {
//   return (
//     <div className="stepWrapper">
//             {props.labelArray.map((item, index) => <Step key={index} index={index} label={item} updateStep={props.updateStep} selected={props.currentStep === index + 1}></Step>) }
//         </div>
//   )
// }

// export default StepNavigation;

import React from 'react';
import Step from './Step';
import './StepNavigation.css';

const StepNavigation = ({ labelArray, currentStep, updateStep, completedSteps = [] }) => {
  return (
    <div className="stepWrapper">
      {labelArray.map((item, index) => (
        <Step 
          key={index} 
          index={index} 
          label={item} 
          updateStep={updateStep} 
          selected={currentStep === index + 1} 
          completed={completedSteps.includes(index + 1)}
        />
      ))}
    </div>
  );
};

export default StepNavigation;


import React from 'react';
import './ImportEmployees.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";

const ImportEmployees = () => {
  return (
    <div className='import_employees'>
        <h6>Import Employees Profile</h6>
        <div className="import">
            <h5>Choose the file to be imported</h5>
            <i><FontAwesomeIcon icon={faUpload} /></i>
            <button className='common_btn' style={{width: 400}}>Upload file</button>
        </div>
    </div>
  )
}

export default ImportEmployees;
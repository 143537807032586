import React from 'react'
import {Col} from "react-bootstrap";
import styles from "../Ekyc.module.css";

const GstDetails = ({
    data
}) => {
  return (
    <Col className="col-12 col-md-6 mt-5 mb-5 kycCard">
        <span>
        <p className="sub-heading ">GST Details</p>
        </span>
        <span>
        <p className="sub-body">GSTIN: {data.gstin}</p>
        </span>
        <span>
        <p className="sub-body">Legal Name: {data.legalName}</p>
        </span>
        <span>
        <p className="sub-body">Constitution: {data.constitution}</p>
        </span>
        <span>
        <p className="sub-body">Center Jusrisdiction: {data.centerJurisdiction}</p>
        </span>
        <span>
        <p className="sub-body">State Jusrisdiction: {data.stateJurisdiction}</p>
        </span>
        <span>
        <p className="sub-body">Status: {data.status}</p>
        </span>
        <span>
        <p className="sub-body">Registration Date: {data.registrationDate}</p>
        </span>
        <span>
        <p className="sub-body">Tax Payer Type: {data.taxPayerType}</p>
        </span>
        <button className={styles.loginButton}>Download</button>
    </Col>
  )
};

export default GstDetails;
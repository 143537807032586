import React from 'react';
import { getAllGroup } from '../../../../../store/Slices/marketMapTableSlices';
import { getAllGroupAttributes, getOneGroupAttributes } from '../../../../../store/Slices/marketMapAttributeSlice';
import TableHomeCommon from '../../../MarketMapsComponents/TableHomeCommon/TableHomeCommon';
import { getGroupImg } from '../../../../../store/Slices/marketMapImageSlice';

const GroupTableHome = () => {
  
    return (
      <TableHomeCommon
        title="Group"
        getAllTableDataLink = {getAllGroup}  
        getAllAttributesLink={getAllGroupAttributes}
        getOneAttributesLink={getOneGroupAttributes}
  
        getAllTableDataSelector = {(state) => state.AllGroups}
        allAttributesGroupSelector={(state) => state.AllGroupAttributeGroup}
        oneAttributeGroupSelector={(state) => state.OneGroupAttributesGroup}
        classNamePrefix="Group"
  
        linkForRedirectSetting = "Add-Group-Attributes"
        additionalAttributesName = "groupAdditionalAttributes"
        getImageLink = {getGroupImg}
        getImagesSelector = {(state) => state.GetGroupImg.images}
        getImagesStatusSelector = {(state) => state.GetGroupImg.status}
        getID = "groupId"
  
        add_button_url={"Add-Group"}
        edit_button_url={"Edit-Group"}
  
      />
    );
  };

export default GroupTableHome;



import React from "react";
// import ScrollAnimation from "react-animate-on-scroll";
import { Col, Container, Row } from "react-bootstrap";
import styles from "./Functions.module.css";

const Functionitem = ({ title, body }) => {
  return (
    <Col sm={12} md={6}>
      {/* <ScrollAnimation animateIn='fadeIn'> */}
      <div className={`${styles.functionList} mb-4`} style={{marginTop:40}}>
        <h3 className={`sub-heading`}>{title}</h3>
        <p className="sub-body">{body}</p>
        <button className={styles.loginButton}>Learn More</button>
      </div>
      {/* </ScrollAnimation> */}
    </Col>
  );
};

function Functions() {
  const FunctionsList = [
    {
      title: "Sales & Marketing",
      body: "Map the market of your customers and competitors with Onified Market Maps. Manage your sales funnel with our CRM tool, Remain connected to your customers through chatbots",
    },
    {
      title: "Project Management",
      body: "Let it be a greenfield project, smaller projects or you deliver projects as a service,our Intelligent project management tool based on Critical Chain Project Management methodology, helps you deliver projects on time, every time",
    },
    {
      title: "Talent Management",
      body: "Right onboarding with our Digital signing and E-KYC toolkit helps you choose the right talent. Onified Performance management system identifies high performers and bottom performers every month, so that everyone knows where to focus.",
    },
    {
      title: "Customer Service",
      body: "Internal or External, customers will continue to be customers only when we solve a big enough problem for them, which no one else can solve better than us. Our ticketing tool helps keeping track of your customer service, seamlessly",
    },
    {
      title: "Production Management",
      body: "Whether you produce standard products (Make to Stock) or you Make goods to Order, our scheduling software based on Theory of Constraints, help you identify the bottleneck and get the most out of your production system",
    },
    {
      title: "Legal & Governnance",
      body: "Our smart contract lifecycle management tool helps you negotiate the terms with suppliers. Service level agreements (SLAs) are managed better when it has intelligent engine of Onified.ai behind it",
    },
  ];

  return (
    <Container className={styles.functionsContainer}>
      <h2 className={`main-heading`} style={{textAlign:"center"}}>Business Functions</h2>
      <Row>
        {FunctionsList.map((elem,index) => {
          return <Functionitem key={index} title={elem.title} body={elem.body} />;
        })}
      </Row>
    </Container>
  );
}

export default Functions;

import React ,{ useEffect, useState} from 'react';
import './AllStep.css';
import { getAllManufacturer } from '../../../../store/Slices/marketMapTableSlices.js';
import { useDispatch, useSelector} from 'react-redux';
import STATUSES from '../../../../store/Statuses.js';
import { toast } from 'react-toastify';
import { getAllManufacturerAttributes } from '../../../../store/Slices/marketMapAttributeSlice.js';
import { addManufacturerInProgram, deleteManufacturerInProgram, getOneProgram, resetaddManufacturerInProgramState, resetdeleteManufacturerInProgramState } from '../../../../store/Slices/marketMapProgram.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';


const ManufacturerStep = ({onComplete ,programId ,programClassName, productGroupName}) => {
  const dispatch = useDispatch();
  console.log("productGroupName@@ ", productGroupName);
  console.log("programId@@ ", programId);
  console.log("programClassName@@ ", programClassName);

  useEffect(()=>{
      dispatch(getAllManufacturerAttributes({}));
  },[dispatch]);

  const { data:AttributesGroupsData ,  status:AttributeGroupStatus , error:AttributeGroupError  } = useSelector((state) => state.AllManufacturerAttributeGroup);

  const [groupNameToShowAttributes, setGroupNameToShowAttributes] = useState("");
  const [selectedGroupIndex, setSelectedGroupIndex] = useState(0);
  const handleGroupSelection = (e, groupName, selectedIndex) => {
    setGroupNameToShowAttributes(groupName);
    setSelectedGroupIndex(selectedIndex);
  };

  
  useEffect(()=>{
    if(groupNameToShowAttributes.length>0 ){
      const classNameToFetch = selectedGroupIndex === 1 ? `Manufacturer` : `Manufacturer${selectedGroupIndex}`;
      dispatch(getAllManufacturer(classNameToFetch));
    }
  },[selectedGroupIndex, getAllManufacturer,groupNameToShowAttributes]);
  
  const {data , status} = useSelector((state) => state.AllManufacturers);
  // console.log(data);

  const [selectedItemId, setSelectedItemId] = useState("");

  useEffect(()=>{
    if(groupNameToShowAttributes.length===0){
      setSelectedItemId("");
    }
  },[groupNameToShowAttributes])


  useEffect(()=>{
      if(programId.length>0){
        if(programClassName.length>0){
          dispatch(getOneProgram({ className :programClassName , programId : programId}))
        }
      }
    
  },[programClassName, programId,]);

  const {data: OneTableItemData , status:OneTableItemStatus ,error:OneTableItemError} = useSelector((state) => state.OneProgram);

  // change icon color of step
  useEffect(() => {
    if (OneTableItemStatus === STATUSES.SUCCESS && OneTableItemData.length > 0) {
      onComplete(3, OneTableItemData[0]?.manufacturers.length > 0);
    }
  }, [OneTableItemStatus, OneTableItemData, onComplete]);

  console.log(OneTableItemData);
  const saveData = () => {
    if (!programId) {
      toast.error("Program not found. Please create a program first.");
      return;
    }
    if (!selectedItemId) {
      toast.error("Please select a Manufacturer.");
      return;
    }
    const manufacturerClassName = selectedGroupIndex === 1 ? `Manufacturer` : `Manufacturer${selectedGroupIndex}`;
    // Dispatch action to save Manufacturer in the program
    dispatch(addManufacturerInProgram({ programId, manufacturerId :selectedItemId , manufacturerClassName ,programClassName}));
    setSelectedItemId('');
  }


  const {data: AddedData  ,status : AddedStatus, error :AddedError } = useSelector((state)=> state.ManufacturerInProgram);

  useEffect(() => {
    if (AddedData && AddedStatus === STATUSES.SUCCESS) {
      toast.success(AddedData);
      dispatch(resetaddManufacturerInProgramState({}));
      // Fetch the updated list of Manufacturers after saving
      dispatch(getOneProgram({ className: programClassName, programId }));
    }
    if (AddedStatus === STATUSES.ERROR) {
      toast.error(AddedError ? AddedError :"Something went Wrong");
      dispatch(resetaddManufacturerInProgramState({}));
    }
  }, [AddedData, AddedStatus, AddedError,  dispatch, programClassName, programId]);

  const deleteData = (id)=>{
    dispatch(deleteManufacturerInProgram({manufacturerId : id , programId , programClassName}))
  }

  const {data: DeletedData , status : DeletedStatus, error :DeletedError } = useSelector((state)=> state.DeleteManufacturerInProgram);

  useEffect(() => {
    if (DeletedData && DeletedStatus === STATUSES.SUCCESS) {
      toast.success(DeletedData);
      dispatch(resetdeleteManufacturerInProgramState({}));
      // Fetch the updated list of Manufacturers after deletion
      dispatch(getOneProgram({ className: programClassName, programId }));
    }
    if (DeletedStatus === STATUSES.ERROR) {
      toast.error(DeletedError ? DeletedError :"Something went Wrong");
      dispatch(resetdeleteManufacturerInProgramState({}));
    }
  }, [DeletedData, DeletedStatus, DeletedError,  dispatch, programClassName, programId]);


   console.log(selectedItemId);
   console.log(groupNameToShowAttributes)
 
return (
 
      <> 
        <div className="step_input_container">
          <h5 className='steps_heading'>Select from existing Manufacturers</h5>

          
          <select
            className='select_step_data'
            onChange={(e) => handleGroupSelection(e, e.target.value, e.target.selectedIndex)}
            value={groupNameToShowAttributes}
            required
          >
            <option value="">Select Group</option>
            {AttributeGroupStatus === STATUSES.LOADING ? (
              <option className='error_option' value="">Loading...</option>
            ) : AttributeGroupStatus === STATUSES.ERROR ? (
              <option className='error_option' value="">{AttributeGroupError}</option>
            ) : AttributeGroupStatus === STATUSES.SUCCESS ? (
              AttributesGroupsData.length === 0 ? (
                <option className='error_option' value="">No Groups found</option>
              ) : (
                AttributesGroupsData.map((item, index) => (
                  <option className='select_step_value' value={item.groupName} key={item.groupId}>
                    {item.groupName}
                  </option>
                ))
              )
            ) : null}
          </select>

          {
            groupNameToShowAttributes.length>0 && 
            <select className='select_step_data' type="text" required value={selectedItemId} onChange={(e)=>setSelectedItemId(e.target.value)}>
            <option value="" className='select_step_value'>Select Manufacturer</option>
            {
              status=== STATUSES.LOADING ? (
                <option value="" className='error_option'>Loading...</option>
              ): 
              status === STATUSES.ERROR ?(
                <option value="" className='error_option'>Something went wrong</option>
              ) :
              status === STATUSES.SUCCESS ? (
                data && typeof data === "string" ?(
                  <option  value="" className='error_option'>No manufacturers found</option>
                ):(
                  data?.map((item, index) => {
                    const firstKey = Object.keys(item.groupAttributes)[0]; // Get the first key dynamically
                    return (
                      <option key={item.manufacturerId} value={item.manufacturerId} className='select_step_value'>
                        {item.groupAttributes["Manufacturer name"]}
                      </option>
                    );
                  })
                )
                
              )
               :null
              
              
            }
          </select>
          }

        
        

        </div>


        
        <div className="step_btn_contianer">
          <button  className = {`common_btn`} style={{width:150}} onClick={saveData}>Save</button>
        </div>

        <div className='saved_data_to_remove'>
            <h4 >Saved manufacturers </h4>
            {OneTableItemStatus === STATUSES.SUCCESS && OneTableItemData && OneTableItemData[0] && (
            OneTableItemData[0].manufacturers.length === 0 ? (
                <p>No manufacturers found</p>
            ) :
                <div>
                {OneTableItemData[0].manufacturers.map((manufacturer, index) => (
                    <p key={index}>{manufacturer.groupAttributes["Manufacturer name"][0]} <i onClick={() => deleteData(manufacturer.manufacturerId)}><FontAwesomeIcon icon={faTrash} /></i></p>
                ))}
                </div>
            )}
        </div>
      </>
)
}



export default ManufacturerStep;
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from 'axios';
import STATUSES from '../Statuses';
import config from '../../config';

const serviceURL0 = config.SERVICE_0_URL;
const serviceURL1 = config.SERVICE_1_URL;
const serviceURL2 = config.SERVICE_2_URL;
const serviceURL3 = config.SERVICE_3_URL;
const serviceURL4 = config.SERVICE_4_URL;
const serviceURL5 = config.SERVICE_5_URL;
const serviceURL6 = config.SERVICE_6_URL;
const serviceURL7 = config.SERVICE_7_URL;
const serviceURL8 = config.SERVICE_8_URL;

 

// =============================================== product attributes APIS ============================================
const createProductAttributeGroupSlice = createSlice({
    name : "ProductGroup",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetProductGroupState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(createProductAttributeGroup.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })
        
        .addCase(createProductAttributeGroup.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })
        
        .addCase(createProductAttributeGroup.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
}); 

// Create Product Attribute Group 
export const createProductAttributeGroup = createAsyncThunk( "ProductAttribute/create", async ( data, { rejectWithValue ,getState })=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const responce = await axios.post(`${serviceURL1}/productGroups/createProductGroups`,data, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})



const getAllProductAttributesSlice = createSlice({
    name : "getAllProductAttributes",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetGetAllProductAttributesGroupState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(getAllProductAttributes.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })

        .addCase(getAllProductAttributes.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })

        .addCase(getAllProductAttributes.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
});


// Get all Product Attributes group 
export const getAllProductAttributes = createAsyncThunk( "ProductAttribute/getAll", async ( {id=null} ,{ rejectWithValue ,getState })=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const responce = await axios.get(`${serviceURL1}/productGroups/allGroups`,config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data?.error || "Something went wrong");
    }
})




const getOneProductAttributesSlice = createSlice({
    name : "getOneProductAttributes",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetOneProductAttributesGroupState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(getOneProductAttributes.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })

        .addCase(getOneProductAttributes.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })

        .addCase(getOneProductAttributes.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
});


// Get single Product Attributes group 
export const getOneProductAttributes = createAsyncThunk( "ProductAttribute/getOne", async ( groupName ,{ rejectWithValue ,getState })=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const responce = await axios.get(`${serviceURL1}/productGroups/${groupName}`, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})


// update product Attributes Group 
const updateProductAttributeGroupSlice = createSlice({
    name : "ProductGroupUpdate",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetUpdateProductGroupState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(updateProductAttributeGroup.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })
        
        .addCase(updateProductAttributeGroup.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })
        
        .addCase(updateProductAttributeGroup.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
}); 

// update product Attribute Group 
export const updateProductAttributeGroup = createAsyncThunk( "ProductAttribute/update", async (  data , { rejectWithValue ,getState })=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const responce = await axios.put(`${serviceURL1}/productGroups/${data.groupName}/update-data`,data, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})




// update product Group Name
const updateProductGroupNameSlice = createSlice({
    name : "ProductGroupNameUpdate",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetUpdateProductGroupNameState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(updateProductGroupName.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })
        
        .addCase(updateProductGroupName.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })
        
        .addCase(updateProductGroupName.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
}); 

// update product Group Name
export const updateProductGroupName = createAsyncThunk( "ProductGroupName/update", async (  data , { rejectWithValue ,getState })=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const responce = await axios.put(`${serviceURL1}/productGroups/changeGroupName/${data.OldGroupName}`,data, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})



















// =============================================== Retailer attributes APIS ============================================
const createRetailerAttributeGroupSlice = createSlice({
    name : "RetailerGroup",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetRetailerGroupState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(createRetailerAttributeGroup.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })
        
        .addCase(createRetailerAttributeGroup.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })
        
        .addCase(createRetailerAttributeGroup.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
}); 

// Create Retailer Attribute Group 
export const createRetailerAttributeGroup = createAsyncThunk( "RetailerAttribute/create", async ( data, { rejectWithValue ,getState })=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const responce = await axios.post(`${serviceURL2}/retailerGroups/createRetailerGroup`,data, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

const getAllRetailerAttributesSlice = createSlice({
    name : "getAllRetailerAttributes",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetGetAllRetailerAttributesGroupState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(getAllRetailerAttributes.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })

        .addCase(getAllRetailerAttributes.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })

        .addCase(getAllRetailerAttributes.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
});


// Get all Retailer Attributes group 
export const getAllRetailerAttributes = createAsyncThunk( "RetailerAttribute/getAll", async ( {id=null} ,{ rejectWithValue ,getState })=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const responce = await axios.get(`${serviceURL2}/retailerGroups/allRetailersGroups`, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data?.error || "Something went wrong");
    }
})

const getOneRetailerAttributesSlice = createSlice({
    name : "getOneRetailerAttributes",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetOneRetailerAttributesGroupState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(getOneRetailerAttributes.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })

        .addCase(getOneRetailerAttributes.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })

        .addCase(getOneRetailerAttributes.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
});


// Get single Retailer Attributes group 
export const getOneRetailerAttributes = createAsyncThunk( "RetailerAttribute/getOne", async ( groupName ,{ rejectWithValue ,getState })=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const responce = await axios.get(`${serviceURL2}/retailerGroups/${groupName}`, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})



// update Retailer Attributes Group 
const updateRetailerAttributeGroupSlice = createSlice({
    name : "RetailerGroupUpdate",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetUpdateRetailerGroupState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(updateRetailerAttributeGroup.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })
        
        .addCase(updateRetailerAttributeGroup.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })
        
        .addCase(updateRetailerAttributeGroup.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
}); 

// update Retailer Attribute Group 
export const updateRetailerAttributeGroup = createAsyncThunk( "RetailerAttribute/update", async (  data , { rejectWithValue ,getState })=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const responce = await axios.put(`${serviceURL2}/retailerGroups/${data.groupName}/update-data`,data, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})


// update Retailer Group Name
const updateRetailerGroupNameSlice = createSlice({
    name : "RetailerGroupNameUpdate",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetUpdateRetailerGroupNameState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(updateRetailerGroupName.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })
        
        .addCase(updateRetailerGroupName.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })
        
        .addCase(updateRetailerGroupName.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
}); 

// update Retailer Group Name
export const updateRetailerGroupName = createAsyncThunk( "RetailerGroupName/update", async (  data , { rejectWithValue ,getState })=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const responce = await axios.put(`${serviceURL2}/retailerGroups/changeGroupName/${data.OldGroupName}`,data, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})












// =============================================== Manufacturer attributes APIS ============================================
const createManufacturerAttributeGroupSlice = createSlice({
    name : "ManufacturerGroup",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetManufacturerGroupState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(createManufacturerAttributeGroup.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })
        
        .addCase(createManufacturerAttributeGroup.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })
        
        .addCase(createManufacturerAttributeGroup.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
}); 

// Create Manufacturer Attribute Group 
export const createManufacturerAttributeGroup = createAsyncThunk( "ManufacturerAttribute/create", async ( data, { rejectWithValue ,getState })=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const responce = await axios.post(`${serviceURL3}/manufacturerGroups/createManufacturerGroup`,data, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

const getAllManufacturerAttributesSlice = createSlice({
    name : "getAllManufacturerAttributes",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetGetAllManufacturerAttributesGroupState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(getAllManufacturerAttributes.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })

        .addCase(getAllManufacturerAttributes.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })

        .addCase(getAllManufacturerAttributes.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
});


// Get all Manufacturer Attributes group 
export const getAllManufacturerAttributes = createAsyncThunk( "ManufacturerAttribute/getAll", async ( {id=null} ,{ rejectWithValue ,getState })=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const responce = await axios.get(`${serviceURL3}/manufacturerGroups/allManufacturersGroups`, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data?.error || "Something went wrong");
    }
})

const getOneManufacturerAttributesSlice = createSlice({
    name : "getOneManufacturerAttributes",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetOneManufacturerAttributesGroupState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(getOneManufacturerAttributes.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })

        .addCase(getOneManufacturerAttributes.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })

        .addCase(getOneManufacturerAttributes.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
});


// Get single Manufacturer Attributes group 
export const getOneManufacturerAttributes = createAsyncThunk( "ManufacturerAttribute/getOne", async ( groupName ,{ rejectWithValue ,getState })=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const responce = await axios.get(`${serviceURL3}/manufacturerGroups/${groupName}`, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})



// update Manufacturer Attributes Group 
const updateManufacturerAttributeGroupSlice = createSlice({
    name : "ManufacturerGroupUpdate",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetUpdateManufacturerGroupState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(updateManufacturerAttributeGroup.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })
        
        .addCase(updateManufacturerAttributeGroup.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })
        
        .addCase(updateManufacturerAttributeGroup.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
}); 

// update Manufacturer Attribute Group 
export const updateManufacturerAttributeGroup = createAsyncThunk( "ManufacturerAttribute/update", async (  data , { rejectWithValue ,getState })=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const responce = await axios.put(`${serviceURL3}/manufacturerGroups/${data.groupName}/update-data`,data, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})


// update Manufacturer Group Name
const updateManufacturerGroupNameSlice = createSlice({
    name : "ManufacturerGroupNameUpdate",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetUpdateManufacturerGroupNameState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(updateManufacturerGroupName.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })
        
        .addCase(updateManufacturerGroupName.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })
        
        .addCase(updateManufacturerGroupName.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
}); 

// update Manufacturer Group Name
export const updateManufacturerGroupName = createAsyncThunk( "ManufacturerGroupName/update", async (  data , { rejectWithValue ,getState })=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const responce = await axios.put(`${serviceURL3}/manufacturerGroups/changeGroupName/${data.OldGroupName}`,data, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})







// =============================================== Supplier attributes APIS ============================================
const createSupplierAttributeGroupSlice = createSlice({
    name : "SupplierGroup",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetSupplierGroupState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(createSupplierAttributeGroup.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })
        
        .addCase(createSupplierAttributeGroup.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })
        
        .addCase(createSupplierAttributeGroup.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
}); 

// Create Supplier Attribute Group 
export const createSupplierAttributeGroup = createAsyncThunk( "SupplierAttribute/create", async ( data, { rejectWithValue ,getState })=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const responce = await axios.post(`${serviceURL4}/supplierGroups/createSupplierGroup`,data, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})
 
const getAllSupplierAttributesSlice = createSlice({
    name : "getAllSupplierAttributes",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetGetAllSupplierAttributesGroupState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(getAllSupplierAttributes.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })

        .addCase(getAllSupplierAttributes.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })

        .addCase(getAllSupplierAttributes.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
});


// Get all Supplier Attributes group 
export const getAllSupplierAttributes = createAsyncThunk( "Supplier/getAll", async ( {id=null} ,{ rejectWithValue ,getState })=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const responce = await axios.get(`${serviceURL4}/supplierGroups/allSuppliersGroups`, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data?.error || "Something went wrong");
    }
})
 
const getOneSupplierAttributesSlice = createSlice({
    name : "getOneSupplierAttributes",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetOneSupplierAttributesGroupState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(getOneSupplierAttributes.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })

        .addCase(getOneSupplierAttributes.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })

        .addCase(getOneSupplierAttributes.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
});


// Get single Supplier Attributes group 
export const getOneSupplierAttributes = createAsyncThunk( "SupplierAttribute/getOne", async ( groupName ,{ rejectWithValue ,getState })=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const responce = await axios.get(`${serviceURL4}/supplierGroups/${groupName}`,config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})



// update Supplier Attributes Group 
const updateSupplierAttributeGroupSlice = createSlice({
    name : "SupplierGroupUpdate",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetUpdateSupplierGroupState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(updateSupplierAttributeGroup.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })
        
        .addCase(updateSupplierAttributeGroup.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })
        
        .addCase(updateSupplierAttributeGroup.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
}); 

// update Supplier Attribute Group 
export const updateSupplierAttributeGroup = createAsyncThunk( "SupplierAttribute/update", async (  data , { rejectWithValue ,getState })=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const responce = await axios.put(`${serviceURL4}/supplierGroups/${data.groupName}/update-data`,data, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})



// update Supplier Group Name
const updateSupplierGroupNameSlice = createSlice({
    name : "SupplierGroupNameUpdate",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetUpdateSupplierGroupNameState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(updateSupplierGroupName.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })
        
        .addCase(updateSupplierGroupName.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })
        
        .addCase(updateSupplierGroupName.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
}); 

// update Supplier Group Name
export const updateSupplierGroupName = createAsyncThunk( "SupplierGroupName/update", async (  data , { rejectWithValue ,getState })=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const responce = await axios.put(`${serviceURL4}/supplierGroups/changeGroupName/${data.OldGroupName}`,data, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})







// =============================================== Packaging attributes APIS ============================================
const createPackagingAttributeGroupSlice = createSlice({
    name : "PackagingGroup",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetPackagingGroupState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(createPackagingAttributeGroup.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })
        
        .addCase(createPackagingAttributeGroup.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })
        
        .addCase(createPackagingAttributeGroup.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
}); 

// Create Packaging Attribute Group 
export const createPackagingAttributeGroup = createAsyncThunk( "PackagingAttribute/create", async ( data, { rejectWithValue ,getState })=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const responce = await axios.post(`${serviceURL5}/packagingGroups/createPackagingGroups`,data, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})
 
const getAllPackagingAttributesSlice = createSlice({
    name : "getAllPackagingAttributes",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetGetAllPackagingAttributesGroupState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(getAllPackagingAttributes.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })

        .addCase(getAllPackagingAttributes.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })

        .addCase(getAllPackagingAttributes.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
});


// Get all Packaging Attributes group 
export const getAllPackagingAttributes = createAsyncThunk( "PackagingAttribute/getAll", async ( {id=null} ,{ rejectWithValue ,getState })=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const responce = await axios.get(`${serviceURL5}/packagingGroups/allGroups`,config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data?.error || "Something went wrong");
    }
})
 
const getOnePackagingAttributesSlice = createSlice({
    name : "getOnePackagingAttributes",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetOnePackagingAttributesGroupState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(getOnePackagingAttributes.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })

        .addCase(getOnePackagingAttributes.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })

        .addCase(getOnePackagingAttributes.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
});


// Get single Packaging Attributes group 
export const getOnePackagingAttributes = createAsyncThunk( "PackagingAttribute/getOne", async ( groupName ,{ rejectWithValue ,getState })=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const responce = await axios.get(`${serviceURL5}/packagingGroups/${groupName}`, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})


// update Packaging Attributes Group 
const updatePackagingAttributeGroupSlice = createSlice({
    name : "PackagingGroupUpdate",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetUpdatePackagingGroupState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(updatePackagingAttributeGroup.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })
        
        .addCase(updatePackagingAttributeGroup.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })
        
        .addCase(updatePackagingAttributeGroup.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
}); 

// update Packaging Attribute Group 
export const updatePackagingAttributeGroup = createAsyncThunk( "PackagingAttribute/update", async (  data , { rejectWithValue ,getState })=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const responce = await axios.put(`${serviceURL5}/packagingGroups/${data.groupName}/update-data`,data, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})


// update Packaging Group Name
const updatePackagingGroupNameSlice = createSlice({
    name : "PackagingGroupNameUpdate",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetUpdatePackagingGroupNameState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(updatePackagingGroupName.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })
        
        .addCase(updatePackagingGroupName.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })
        
        .addCase(updatePackagingGroupName.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
}); 

// update Packaging Group Name
export const updatePackagingGroupName = createAsyncThunk( "PackagingGroupName/update", async (  data , { rejectWithValue ,getState })=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const responce = await axios.put(`${serviceURL5}/packagingGroups/changeGroupName/${data.OldGroupName}`,data, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})








// =============================================== Promotion attributes APIS ============================================
const createPromotionAttributeGroupSlice = createSlice({
    name : "PromotionGroup",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetPromotionGroupState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(createPromotionAttributeGroup.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })
        
        .addCase(createPromotionAttributeGroup.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })
        
        .addCase(createPromotionAttributeGroup.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
}); 

// Create Promotion Attribute Group 
export const createPromotionAttributeGroup = createAsyncThunk( "PromotionAttribute/create", async ( data, { rejectWithValue ,getState })=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const responce = await axios.post(`${serviceURL6}/promotionGroups/createPromotionGroup`,data, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})
 
const getAllPromotionAttributesSlice = createSlice({
    name : "getAllPromotionAttributes",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetGetAllPromotionAttributesGroupState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(getAllPromotionAttributes.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })

        .addCase(getAllPromotionAttributes.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })

        .addCase(getAllPromotionAttributes.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
});


// Get all Promotion Attributes group 
export const getAllPromotionAttributes = createAsyncThunk( "Promotion/getAll", async ( {id=null} ,{ rejectWithValue ,getState })=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const responce = await axios.get(`${serviceURL6}/promotionGroups/allGroups`, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data?.error || "Something went wrong");
    }
})
 
const getOnePromotionAttributesSlice = createSlice({
    name : "getOnePromotionAttributes",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetOnePromotionAttributesGroupState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(getOnePromotionAttributes.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })

        .addCase(getOnePromotionAttributes.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })

        .addCase(getOnePromotionAttributes.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
});


// Get single Promotion Attributes group 
export const getOnePromotionAttributes = createAsyncThunk( "PromotionAttribute/getOne", async ( groupName ,{ rejectWithValue ,getState })=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const responce = await axios.get(`${serviceURL6}/promotionGroups/${groupName}`, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})


// update Promotion Attributes Group 
const updatePromotionAttributeGroupSlice = createSlice({
    name : "PromotionGroupUpdate",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetUpdatePromotionGroupState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(updatePromotionAttributeGroup.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })
        
        .addCase(updatePromotionAttributeGroup.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })
        
        .addCase(updatePromotionAttributeGroup.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
}); 

// update Promotion Attribute Group 
export const updatePromotionAttributeGroup = createAsyncThunk( "PromotionAttribute/update", async (  data , { rejectWithValue ,getState })=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const responce = await axios.put(`${serviceURL6}/promotionGroups/${data.groupName}/update-data`,data, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})


// update Promotion Group Name
const updatePromotionGroupNameSlice = createSlice({
    name : "PromotionGroupNameUpdate",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetUpdatePromotionGroupNameState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(updatePromotionGroupName.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })
        
        .addCase(updatePromotionGroupName.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })
        
        .addCase(updatePromotionGroupName.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
}); 

// update Promotion Group Name
export const updatePromotionGroupName = createAsyncThunk( "PromotionGroupName/update", async (  data , { rejectWithValue ,getState })=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const responce = await axios.put(`${serviceURL6}/promotionGroups/changeGroupName/${data.OldGroupName}`,data, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})










// =============================================== Program attributes APIS ============================================
const createProgramAttributeGroupSlice = createSlice({
    name : "ProgramGroup",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetProgramGroupState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(createProgramAttributeGroup.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })
        
        .addCase(createProgramAttributeGroup.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })
        
        .addCase(createProgramAttributeGroup.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
}); 

// Create Program Attribute Group 
export const createProgramAttributeGroup = createAsyncThunk( "ProgramAttribute/create", async ( data, { rejectWithValue ,getState })=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const responce = await axios.post(`${serviceURL0}/programGroups/createProgramGroups`,data, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

const getAllProgramAttributesSlice = createSlice({
    name : "getAllProgramAttributes",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetGetAllProgramAttributesGroupState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(getAllProgramAttributes.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })

        .addCase(getAllProgramAttributes.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })

        .addCase(getAllProgramAttributes.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
});


// Get all Program Attributes group 
export const getAllProgramAttributes = createAsyncThunk( "ProgramAttribute/getAll", async ( {id=null} ,{ rejectWithValue ,getState })=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const responce = await axios.get(`${serviceURL0}/programGroups/allGroups`, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data?.error || "Something went wrong");
    }
})
 
const getOneProgramAttributesSlice = createSlice({
    name : "getOneProgramAttributes",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetOneProgramAttributesGroupState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(getOneProgramAttributes.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })

        .addCase(getOneProgramAttributes.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })

        .addCase(getOneProgramAttributes.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
});


// Get single Program Attributes group 
export const getOneProgramAttributes = createAsyncThunk( "ProgramAttribute/getOne", async ( groupName ,{ rejectWithValue ,getState })=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const responce = await axios.get(`${serviceURL0}/programGroups/${groupName}`, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})


// update Program Attributes Group 
const updateProgramAttributeGroupSlice = createSlice({
    name : "ProgramGroupUpdate",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetUpdateProgramGroupState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(updateProgramAttributeGroup.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })
        
        .addCase(updateProgramAttributeGroup.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })
        
        .addCase(updateProgramAttributeGroup.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
}); 

// update Program Attribute Group 
export const updateProgramAttributeGroup = createAsyncThunk( "ProgramAttribute/update", async (  data , { rejectWithValue ,getState })=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const responce = await axios.put(`${serviceURL0}/programGroups/${data.groupName}/update-data`,data, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})



// update Program Group Name
const updateProgramGroupNameSlice = createSlice({
    name : "ProgramGroupNameUpdate",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetUpdateProgramGroupNameState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(updateProgramGroupName.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })
        
        .addCase(updateProgramGroupName.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })
        
        .addCase(updateProgramGroupName.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
}); 

// update Program Group Name
export const updateProgramGroupName = createAsyncThunk( "ProgramGroupName/update", async (  data , { rejectWithValue ,getState })=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const responce = await axios.put(`${serviceURL0}/programGroups/changeGroupName/${data.OldGroupName}`,data, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})










// =============================================== Region attributes APIS ============================================
const createRegionAttributeGroupSlice = createSlice({
    name : "RegionGroup",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetRegionGroupState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(createRegionAttributeGroup.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })
        
        .addCase(createRegionAttributeGroup.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })
        
        .addCase(createRegionAttributeGroup.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
}); 

// Create Region Attribute Group 
export const createRegionAttributeGroup = createAsyncThunk( "RegionAttribute/create", async ( data, { rejectWithValue ,getState })=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const responce = await axios.post(`${serviceURL7}/regionGroups/createRegionGroup`,data, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})
 
const getAllRegionAttributesSlice = createSlice({
    name : "getAllRegionAttributes",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetGetAllRegionAttributesGroupState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(getAllRegionAttributes.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })

        .addCase(getAllRegionAttributes.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })

        .addCase(getAllRegionAttributes.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
});


// Get all Region Attributes group 
export const getAllRegionAttributes = createAsyncThunk( "Region/getAll", async ( {id=null} ,{ rejectWithValue ,getState })=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const responce = await axios.get(`${serviceURL7}/regionGroups/allGroups`, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data?.error || "Something went wrong");
    }
})

const getOneRegionAttributesSlice = createSlice({
    name : "getOneRegionAttributes",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetOneRegionAttributesGroupState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(getOneRegionAttributes.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })

        .addCase(getOneRegionAttributes.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })

        .addCase(getOneRegionAttributes.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
});


// Get single Region Attributes group 
export const getOneRegionAttributes = createAsyncThunk( "RegionAttribute/getOne", async ( groupName ,{ rejectWithValue ,getState })=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const responce = await axios.get(`${serviceURL7}/regionGroups/${groupName}`, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})


// update Region Attributes Group 
const updateRegionAttributeGroupSlice = createSlice({
    name : "RegionGroupUpdate",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetUpdateRegionGroupState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(updateRegionAttributeGroup.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })
        
        .addCase(updateRegionAttributeGroup.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })
        
        .addCase(updateRegionAttributeGroup.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
}); 

// update Region Attribute Group 
export const updateRegionAttributeGroup = createAsyncThunk( "RegionAttribute/update", async (  data , { rejectWithValue ,getState })=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const responce = await axios.put(`${serviceURL7}/regionGroups/${data.groupName}/update-data`,data, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})
 

// update Region Group Name
const updateRegionGroupNameSlice = createSlice({
    name : "RegionGroupNameUpdate",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetUpdateRegionGroupNameState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(updateRegionGroupName.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })
        
        .addCase(updateRegionGroupName.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })
        
        .addCase(updateRegionGroupName.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
}); 

// update Region Group Name
export const updateRegionGroupName = createAsyncThunk( "RegionGroupName/update", async (  data , { rejectWithValue ,getState })=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const responce = await axios.put(`${serviceURL7}/regionGroups/changeGroupName/${data.OldGroupName}`,data, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})










// =============================================== Group attributes APIS ============================================
const createGroupAttributeGroupSlice = createSlice({
    name : "GroupGroup",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetGroupGroupState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(createGroupAttributeGroup.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })
        
        .addCase(createGroupAttributeGroup.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })
        
        .addCase(createGroupAttributeGroup.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
}); 

// Create Group Attribute Group 
export const createGroupAttributeGroup = createAsyncThunk( "GroupAttribute/create", async ( data, { rejectWithValue ,getState })=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const responce = await axios.post(`${serviceURL8}/organizationGroups/createOrganizationGroups`,data, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

const getAllGroupAttributesSlice = createSlice({
    name : "getAllGroupAttributes",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetGetAllGroupAttributesGroupState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(getAllGroupAttributes.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })

        .addCase(getAllGroupAttributes.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })

        .addCase(getAllGroupAttributes.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
});


// Get all Group Attributes group 
export const getAllGroupAttributes = createAsyncThunk( "Group/getAll", async ( {id=null} ,{ rejectWithValue ,getState })=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const responce = await axios.get(`${serviceURL8}/organizationGroups/allOrganizationGroups`, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data?.error || "Something went wrong");
    }
})

const getOneGroupAttributesSlice = createSlice({
    name : "getOneGroupAttributes",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetOneGroupAttributesGroupState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(getOneGroupAttributes.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })

        .addCase(getOneGroupAttributes.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })

        .addCase(getOneGroupAttributes.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
});


// Get single Group Attributes group 
export const getOneGroupAttributes = createAsyncThunk( "GroupAttribute/getOne", async ( groupName ,{ rejectWithValue ,getState })=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const responce = await axios.get(`${serviceURL8}/organizationGroups/${groupName}`,config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})


// update Group Attributes Group 
const updateGroupAttributeGroupSlice = createSlice({
    name : "GroupGroupUpdate",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetUpdateGroupGroupState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(updateGroupAttributeGroup.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })
        
        .addCase(updateGroupAttributeGroup.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })
        
        .addCase(updateGroupAttributeGroup.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
}); 

// update Group Attribute Group 
export const updateGroupAttributeGroup = createAsyncThunk( "GroupAttribute/update", async (  data , { rejectWithValue ,getState })=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const responce = await axios.put(`${serviceURL8}/organizationGroups/${data.groupName}/update-data`,data, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})


// update Group Group Name
const updateGroupGroupNameSlice = createSlice({
    name : "GroupGroupNameUpdate",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetUpdateGroupGroupNameState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(updateGroupGroupName.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })
        
        .addCase(updateGroupGroupName.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })
        
        .addCase(updateGroupGroupName.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
}); 

// update Group Group Name
export const updateGroupGroupName = createAsyncThunk( "GroupGroupName/update", async (  data , { rejectWithValue ,getState })=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const responce = await axios.put(`${serviceURL7}/organizationGroups/changeGroupName/${data.OldGroupName}`,data, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

 

export {
    createProductAttributeGroupSlice,
    getAllProductAttributesSlice,
    getOneProductAttributesSlice,
    updateProductAttributeGroupSlice,
    updateProductGroupNameSlice,

    createRetailerAttributeGroupSlice,
    getAllRetailerAttributesSlice,
    getOneRetailerAttributesSlice,
    updateRetailerAttributeGroupSlice,
    updateRetailerGroupNameSlice,


    createManufacturerAttributeGroupSlice,
    getAllManufacturerAttributesSlice,
    getOneManufacturerAttributesSlice,
    updateManufacturerAttributeGroupSlice,
    updateManufacturerGroupNameSlice,


    createSupplierAttributeGroupSlice,
    getAllSupplierAttributesSlice,
    getOneSupplierAttributesSlice,
    updateSupplierAttributeGroupSlice,
    updateSupplierGroupNameSlice,



    createPackagingAttributeGroupSlice,
    getAllPackagingAttributesSlice,
    getOnePackagingAttributesSlice,
    updatePackagingAttributeGroupSlice,
    updatePackagingGroupNameSlice,



    createPromotionAttributeGroupSlice,
    getAllPromotionAttributesSlice,
    getOnePromotionAttributesSlice,
    updatePromotionAttributeGroupSlice,
    updatePromotionGroupNameSlice,



    createProgramAttributeGroupSlice,
    getAllProgramAttributesSlice,
    getOneProgramAttributesSlice,
    updateProgramAttributeGroupSlice,
    updateProgramGroupNameSlice,



    createRegionAttributeGroupSlice,
    getAllRegionAttributesSlice,
    getOneRegionAttributesSlice,
    updateRegionAttributeGroupSlice,
    updateRegionGroupNameSlice,



    createGroupAttributeGroupSlice,
    getAllGroupAttributesSlice,
    getOneGroupAttributesSlice,
    updateGroupAttributeGroupSlice,
    updateGroupGroupNameSlice,


}

export const {resetProductGroupState} = createProductAttributeGroupSlice.actions;
export const {resetRetailerGroupState} = createRetailerAttributeGroupSlice.actions;
export const {resetManufacturerGroupState} = createManufacturerAttributeGroupSlice.actions;
export const {resetSupplierGroupState} = createSupplierAttributeGroupSlice.actions;
export const {resetPackagingGroupState} = createPackagingAttributeGroupSlice.actions;
export const {resetPromotionGroupState} = createPromotionAttributeGroupSlice.actions;
export const {resetProgramGroupState} = createProgramAttributeGroupSlice.actions;
export const {resetRegionGroupState} = createRegionAttributeGroupSlice.actions;
export const {resetGroupGroupState} = createGroupAttributeGroupSlice.actions;


export const {resetUpdateProductGroupState} = updateProductAttributeGroupSlice.actions;
export const {resetUpdateRetailerGroupState} = updateRetailerAttributeGroupSlice.actions;
export const {resetUpdateManufacturerGroupState} = updateManufacturerAttributeGroupSlice.actions;
export const {resetUpdateSupplierGroupState} = updateSupplierAttributeGroupSlice.actions;
export const {resetUpdatePackagingGroupState} = updatePackagingAttributeGroupSlice.actions;
export const {resetUpdatePromotionGroupState} = updatePromotionAttributeGroupSlice.actions;
export const {resetUpdateProgramGroupState} = updateProgramAttributeGroupSlice.actions;
export const {resetUpdateRegionGroupState} = updateRegionAttributeGroupSlice.actions;
export const {resetUpdateGroupGroupState} = updateGroupAttributeGroupSlice.actions;


export const {resetUpdateProductGroupNameState} = updateProductGroupNameSlice.actions;
export const {resetUpdateRetailerGroupNameState} = updateRetailerGroupNameSlice.actions;
export const {resetUpdateManufacturerGroupNameState} = updateManufacturerGroupNameSlice.actions;
export const {resetUpdateSupplierGroupNameState} = updateSupplierGroupNameSlice.actions;
export const {resetUpdatePackagingGroupNameState} = updatePackagingGroupNameSlice.actions;
export const {resetUpdatePromotionGroupNameState} = updatePromotionGroupNameSlice.actions;
export const {resetUpdateProgramGroupNameState} = updateProgramGroupNameSlice.actions;
export const {resetUpdateRegionGroupNameState} = updateRegionGroupNameSlice.actions;
export const {resetUpdateGroupGroupNameState} = updateGroupGroupNameSlice.actions;



export const {resetOneProductAttributesGroupState} = getOneProductAttributesSlice.actions;
export const {resetOneRetailerAttributesGroupState} = getOneRetailerAttributesSlice.actions;
export const {resetOneManufacturerAttributesGroupState} = getOneManufacturerAttributesSlice.actions;
export const {resetOneSupplierAttributesGroupState} = getOneSupplierAttributesSlice.actions;
export const {resetOnePackagingAttributesGroupState} = getOnePackagingAttributesSlice.actions;
export const {resetOnePromotionAttributesGroupState} = getOnePromotionAttributesSlice.actions;
export const {resetOneProgramAttributesGroupState} = getOneProgramAttributesSlice.actions;
export const {resetOneRegionAttributesGroupState} = getOneRegionAttributesSlice.actions;
export const {resetOneGroupAttributesGroupState} = getOneGroupAttributesSlice.actions;



export const {resetGetAllProductAttributesGroupState} = getAllProductAttributesSlice.actions;
export const {resetGetAllRetailerAttributesGroupState} = getAllRetailerAttributesSlice.actions;
export const {resetGetAllManufacturerAttributesGroupState} = getAllManufacturerAttributesSlice.actions;
export const {resetGetAllSupplierAttributesGroupState} = getAllSupplierAttributesSlice.actions;
export const {resetGetAllPackagingAttributesGroupState} = getAllPackagingAttributesSlice.actions;
export const {resetGetAllPromotionAttributesGroupState} = getAllPromotionAttributesSlice.actions;
export const {resetGetAllProgramAttributesGroupState} = getAllProgramAttributesSlice.actions;
export const {resetGetAllRegionAttributesGroupState} = getAllRegionAttributesSlice.actions;
export const {resetGetAllGroupAttributesGroupState} = getAllGroupAttributesSlice.actions;

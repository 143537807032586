import React from 'react';
import './RequestPage.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faEnvelope, faIndustry, faList, faLocationDot, faPhone, faUser } from "@fortawesome/free-solid-svg-icons";

const RequestPage = () => {
  return (
    <div className='Request_page' >
      <div className="request_box common_bg">
          <h3 className='main_heading' >Request Demo</h3>
          <div className='request_form'>
            <div className="col1">

            <div className="input_tag" >Name</div>
            <div className="inp_box">
              <i>
                <FontAwesomeIcon icon={faUser} className="input_icon" />
              </i>
              <input type="text" name="" id="" className="input_" />
            </div>

            <div className="input_tag" >Contact Number</div>
            <div className="inp_box">
              <i>
                <FontAwesomeIcon icon={faPhone} className="input_icon" />
              </i>
              <input type="number" name="" id="" className="input_" />
            </div>

            <div className="input_tag" >Employee Size</div>
            <div className="inp_box">
              <i>
                <FontAwesomeIcon icon={faList} className="input_icon" />
              </i>
              <input type="text" name="" id="" className="input_" />
            </div>

            </div>
            <div className="col1">

            <div className="input_tag" >Company Email</div>
            <div className="inp_box">
              <i>
                <FontAwesomeIcon icon={faEnvelope} className="input_icon" />
              </i>
              <input type="text" name="" id="" className="input_" />
            </div>

            <div className="input_tag" >Company Name</div>
            <div className="inp_box">
              <i>
                <FontAwesomeIcon icon={faIndustry} className="input_icon" />
              </i>
              <input type="text" name="" id="" className="input_" />
            </div>

            <div className="input_tag" >Location</div>
            <div className="inp_box">
              <i>
                <FontAwesomeIcon icon={faLocationDot} className="input_icon" />
              </i>
              <input type="text" name="" id="" className="input_" />
            </div>

            </div>
          </div>
          <div className="request_btn_box">
            <button className='common_btn' >Book a free Demo</button>
          </div>

            
      </div>
    </div>
  )
}

export default RequestPage;
import React, {useCallback, useEffect, useState, useMemo, useRef} from "react";
import { Col, Container, Form, Row, Spinner } from "react-bootstrap";
import kycImage from "../../assets/Images/kycImage.png";
import CompanyDetails from "./Details/CompanyDetails";
import GstDetails from "./Details/GstDetails";
import AadharDetails from "./Details/AadharDetails";
import config from "../../config";
import api from "../../api";
import styles from "./Ekyc.module.css";
import { useSearchParams } from "react-router-dom";

const DATA_LENGTH = {
  CIN : 21,
  GSTN : 15,
  AADHAR : 12
}

const DETAIL_TYPE = {
  CIN : "CIN",
  GSTN : "GSTN",
  AADHAR: "AADHAR"
}

const DOCTYPES = {
  AADHAR: "ADHAR"
}

const APISETU_HEADERS = {
  "X-APISETU-APIKEY" : config.APISETU_API_KEY,
  "X-APISETU-CLIENTID" : config.APISETU_CLIENT_ID,
  "Content-Type": "application/json"
};
      
const DIGILOCKER_CREDENTIALS = {
  grant_type: config.DIGILOCKER_GRANT_TYPE,
  client_id: config.DIGILOCKER_CLIENT_ID,
  client_secret: config.DIGILOCKER_CLIENT_SECRET,
  redirect_uri: config.DIGILOCKER_REDIRECT_URI
};

function Ekyc() {
  const [query, setQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [detailType, setDetailType] = useState();
  const [cinData, setCinData] = useState();
  const [gstData, setGstData] = useState();
  const [aadharData, setAadharData] = useState();
  const [accessToken, setAccessToken] = useState();
  const [searchParams] = useSearchParams();
  // const [searchParams, setSearchParams] = useSearchParams(); pankaj
  const code = useMemo(() => searchParams.get('code'),[searchParams]);
  const fetchingAadharRef = useRef(false);

  const getAadharData = useCallback(async (code)=>{
    try{
      const res = await api.get('/',{
          params: {
              method: "POST",
              url : `${config.DIGILOCKER_AADHAR_URL}`,
              data : {
                code,
                ...DIGILOCKER_CREDENTIALS
              }
          }
      });
      const access_token = res?.data?.access_token;
      const resp = await api.get('/',{
          params: {
              method: "GET",
              url : `${config.DIGILOCKER_ISSUED_FILES_URL}`,
              headers : {
                Authorization: `Bearer ${access_token}`
              }
          }
      });
      let issued_files = [...(resp?.data?.items || [])];
      let aadhar_details = issued_files?.find(item => item?.doctype === DOCTYPES.AADHAR);
      issued_files = issued_files?.filter(item => item?.doctype !== DOCTYPES.AADHAR);

      const digilockerData = {
        ...res.data,
        issued_files,
        aadhar_details
      }
      // console.log(digilockerData);
      setDetailType(DETAIL_TYPE.AADHAR);
      setAadharData(digilockerData);
      setAccessToken(access_token);
      setLoading(false);
    }catch(err){
      console.log(err);
    }
  },[])
  
  
  useEffect(()=>{
    if(code && !fetchingAadharRef.current){
      fetchingAadharRef.current = true;
      console.log("search param code - ", code);
      setLoading(true);
      getAadharData(code);
    }
  },[code, getAadharData]);
  

  const getCinData = useCallback(async (cin) => {
    try{
      const res = await api.get(`/`,{
          params: {
              method: "GET",
              url : `${config.APISETU_COMPANIES_URL}/${cin}`,
              headers : APISETU_HEADERS
          }
      });
      const resp = await api.get('/',{
          params: {
              method: "GET",
              url : `${config.APISETU_DIRECTORS_URL}/${cin}`,
              headers : APISETU_HEADERS
          }
      });
      const companyData = {
        ...res.data,
        directors: [...Object.entries(resp.data).map(entry=>entry[1])]
      }
      // console.log(companyData);
      setDetailType(DETAIL_TYPE.CIN);
      setCinData(companyData);
      setLoading(false);
    }catch(err){
      console.log(err);
    }
  },[])

  const getGstData = useCallback(async (gstn) => {
    try{
      const res = await api.get('/',{
          params: {
              method: "GET",
              url : `${config.APISETU_GSTN_URL}/${gstn}`,
              headers : APISETU_HEADERS
          }
      });
      // console.log(res.data);
      setDetailType(DETAIL_TYPE.GSTN);
      setGstData(res.data);
      setLoading(false);
    }catch(err){
      console.log(err);
    }
  },[])

 

  const downloadFile = useCallback(async (uri) => {
    if(uri){
      const res = await api.get('/',{
          params: {
              method: "GET",
              url : `${config.DIGILOCKER_DOWNLOAD_FILE_URL}/${uri}`,
              headers : {
                Authorization: `Bearer ${accessToken}`
              },
              data: {
                uri,
                "Content-Type": "application/pdf"
              }
          }
      });
      console.log(res.data);
    }
  },[accessToken]);

  const fetchData = useCallback(() => {
    setLoading(true);
    switch(query.length){
      case DATA_LENGTH.CIN : return getCinData(query);
      case DATA_LENGTH.GSTN : return getGstData(query);
      default : {
        setError("Invalid query");
        setLoading(false);
      }
    }
  },[query, getCinData, getGstData])

  const handleSubmit = (e) => {
    setError(null);
    e.preventDefault();
    fetchData(query);
  }

  const handleDownloadAadhar = (e) => {
    e.preventDefault();
    window.open(
      config.DIGILOCKER_AUTH_URL,
      '_blank'
    );
  }

  const getDetails = useCallback(() => {
    switch(detailType){
      case DETAIL_TYPE.CIN : return <CompanyDetails data={cinData} />
      case DETAIL_TYPE.GSTN : return <GstDetails data={gstData} />
      case DETAIL_TYPE.AADHAR : return <AadharDetails data={aadharData} downloadFile={downloadFile} />
      default : return null;
    }
  },[detailType, cinData, gstData, aadharData, downloadFile]);

  return (
    <>
      <section className={styles.ekycArea}>
        <Container>
          <Row>
            <Col sm={12} md={12} className="i-center mb-4 mb-md-0">
              <img
                style={{ width: 300, alignSelf: "center" }}
                src={kycImage}
                alt={"hero-1"}
                className="page-image"
              />
            </Col>
            <Col className="col-12">
              <h1 className={`main-heading ${styles.iconText}`}>E-KYC</h1>
              <h5 className="sub-title">
                Please input PAN,GST,CIN,Driving License,Vehicle Number
              </h5>
            </Col>
            <Col className="col-12">
              <div className={styles.kycFormArea}>
                <Form 
                  className="d-flex"
                  onSubmit={handleSubmit}
                >
                  <Form.Control
                    type="search"
                    placeholder="Search"
                    className="me-2"
                    aria-label="Search"
                    required
                    value={query}
                    onChange={({target})=>setQuery(target.value)}
                  />
                  <button 
                    type="submit"
                    className={styles.buttonFind}
                  >
                    Find
                  </button>
                </Form>
                {error && <small className="text-danger">{error}</small>}
                <button
                  style={{ marginTop: 30, width: "100%" }}
                  className='common_btn'
                  onClick={handleDownloadAadhar}
                >
                  Download Aadhar Or other documents from Digilocker
                </button>
              </div>
            </Col>
            <div className="d-flex justify-content-center align-items-center">
              {loading ?
                <div className={`d-flex justify-content-center align-items-center ${styles.loader}`}>
                  <div>
                    <Spinner animation="grow" variant="info" size="sm" style={{backgroundColor:"rgb(38,178,253)"}}/>
                    <Spinner animation="grow" variant="info" size="sm" className="mx-2" style={{backgroundColor:"rgb(38,178,253)"}}/>
                    <Spinner animation="grow" variant="info" size="sm" style={{backgroundColor:"rgb(38,178,253)"}}/>
                  </div>
                </div>
                :
                getDetails()
              }
            </div>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Ekyc;

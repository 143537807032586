import React from 'react';
import './PMSpage.css';
import { Outlet } from 'react-router';
import PMSnav from '../../components/PMS/PMScomponents/PMSnav/PMSnav';


const PMSpage = () => {
  return (
    <>
      <PMSnav/>
      <div className='pms_page'>
        <Outlet/>
      </div>
    </>
  )
}

export default PMSpage;